import * as React from "react";
import { DetailsList, IColumn, Selection, SelectionMode, DetailsListLayoutMode, CheckboxVisibility, IDetailsRowProps, IDetailsRowStyles, DetailsRow, IContextualMenuItem, getTheme } from '@fluentui/react';
import { CommandBar, Icon, ProgressIndicator, Spinner, SpinnerSize, TooltipHost } from "@fluentui/react";
import FormatByte from "../../Formatter/FormatByte";
import { FileSystemObject } from "../../Model/FileSystemObject";
import { batch } from "react-redux";
import ColumnContextMenu from "../ColumnContextMenu/ColumnContextMenu";
import FormatDate from "../../Formatter/FormatDate";
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import { ExternalDownloadCommand } from "../../ToolbarCommands/ExternalDownloadCommand";
import { ExternalDownloadAllCommand } from "../../ToolbarCommands/ExternalDownloadAllCommand";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useStyles from "./ExternalShareFileDetailList.style"
import { getFileSystemObjectKeyString } from "../../Utils/GetKeyString";

const theme = getTheme();

export interface ExternalShareFileDetailListProps {
	files: FileSystemObject[];
	shareId: string;
	password: string | undefined;
}

export const ExternalShareFileDetailList = (props: ExternalShareFileDetailListProps) => {
	const { t } = useTranslation();
	const [sortProperty, setSortProperty] = useState("Name");
	const [sortPropertyDirectionASC, setSortPropertyDirectionASC] = useState(false);
	const [selectedFiles, setSelectedFiles] = useState<FileSystemObject[]>([]);
	const [sortedItems, setSortedItems] = useState<FileSystemObject[]>([]);
	const styles = useStyles();

	const menuItems: IContextualMenuItem[] = [];
	const commandBarItems: IContextualMenuItem[] = [];

	const columns: IColumn[] = useMemo(() => [
		{
			key: "FileType",
			name: "",
			minWidth: 16,
			maxWidth: 16,
			data: "string",
			iconName: "TextDocument",
			onRender: (fileSystemObject: FileSystemObject) => {
				if (!fileSystemObject) return <></>;

				if (fileSystemObject.hasRunningAction) {
					return <Spinner size={SpinnerSize.small} />;
				}

				if (fileSystemObject.hasError) {
					return <TooltipHost content={fileSystemObject.errorMessage}>
						<Icon iconName={"ErrorBadge"} className="Error-File ms-fontSize-16" />
					</TooltipHost>;
				}

				if (fileSystemObject.Type === "Folder") {
					return <Icon iconName={"FabricFolder"} />;
				}

				const extension = fileSystemObject.Name.substring(fileSystemObject.Name.lastIndexOf('.'));
				const iconProps = getFileTypeIconProps({ extension: extension });
				return <Icon iconName={iconProps.iconName} />;
			}
		},
		{
			key: "Name",
			name: t("FileBase.File.Name"),
			fieldName: "Name",
			minWidth: 100,
			maxWidth: 500,
			isRowHeader: false,
			isResizable: true,
			isSorted: sortProperty === "Name",
			isSortedDescending: sortProperty === "Name" ? !sortPropertyDirectionASC : undefined,
			data: "string",
			isPadded: true,
			onColumnClick: () => {
				batch(() => {
					setSortPropertyDirectionASC(sortProperty === "Name" ? !sortPropertyDirectionASC : true)
					setSortProperty("Name");
				});
			},
			onRender: (FileSystemObject: FileSystemObject) => {
				return <ColumnContextMenu menuItems={menuItems}>
					{FileSystemObject.Name}
				</ColumnContextMenu>;
			},
		},
		{
			key: "Size",
			name: t("FileBase.File.Size"),
			fieldName: "Size",
			minWidth: 50,
			maxWidth: 100,
			isRowHeader: false,
			isResizable: true,
			isSorted: sortProperty === "Size",
			isSortedDescending: sortProperty === "Size" ? !sortPropertyDirectionASC : undefined,
			data: "string",
			isPadded: true,
			onColumnClick: () => {
				batch(() => {
					setSortPropertyDirectionASC(sortProperty === "Size" ? !sortPropertyDirectionASC : true)
					setSortProperty("Size");
				});
			},
			onRender: (fileSystemObject: FileSystemObject) => {
				if (fileSystemObject.Type === "File") {
					const formattedSize = FormatByte(fileSystemObject.Size, 2);
					return <>
						<div>
							{fileSystemObject.processedSize != null &&
								<span>
									{`${parseFloat((fileSystemObject.processedSize / formattedSize.dividend).toFixed(1))} ${t("FileBase.File.Of")} `}
								</span>
							}
							<span>
								{formattedSize.result}
							</span>
						</div>
						<div className={styles.ProgressBar}>
							{fileSystemObject.processedSize != null &&
								<ProgressIndicator percentComplete={fileSystemObject.processedSize ? fileSystemObject.processedSize / fileSystemObject.Size : 0} />
							}
						</div>
					</>;
				} else {
					return "";
				}
			}
		},
		{
			key: "folder",
			name: t("FileBase.File.Folder"),
			fieldName: "Folder",
			minWidth: 50,
			maxWidth: 200,
			isRowHeader: false,
			isResizable: true,
			isSorted: sortProperty === "Folder",
			isSortedDescending: sortProperty === "Folder" ? !sortPropertyDirectionASC : undefined,
			data: "string",
			isPadded: true,
			onColumnClick: () => {
				batch(() => {
					setSortPropertyDirectionASC(sortProperty === "Folder" ? !sortPropertyDirectionASC : true)
					setSortProperty("Folder");
				});
			},
			onRender: (fileSystemObject: FileSystemObject) => {
				if (fileSystemObject.Type === "File") {
					return fileSystemObject.Path;
				} else {
					return "";
				}
			}
		},
		{
			key: "ModifiedOn",
			name: t("FileBase.File.ModifiedOn"),
			fieldName: "ModifiedOn",
			minWidth: 50,
			maxWidth: 100,
			isRowHeader: false,
			isResizable: true,
			isSorted: sortProperty === "ModifiedOn",
			isSortedDescending: sortProperty === "ModifiedOn" ? !sortPropertyDirectionASC : undefined,
			data: "string",
			isPadded: true,
			onColumnClick: () => {
				batch(() => {
					setSortPropertyDirectionASC(sortProperty === "ModifiedOn" ? !sortPropertyDirectionASC : true)
					setSortProperty("ModifiedOn");
				});
			},
			onRender: (fileSystemObject: FileSystemObject) => {
				return FormatDate(fileSystemObject.ModifiedOn);
			}
		},
		{
			key: "ModifiedBy",
			name: t("FileBase.File.ModifiedBy"),
			fieldName: "ModifiedBy",
			minWidth: 50,
			maxWidth: 100,
			isRowHeader: false,
			isResizable: true,
			isSorted: sortProperty === "ModifiedBy",
			isSortedDescending: sortProperty === "ModifiedBy" ? !sortPropertyDirectionASC : undefined,
			data: "string",
			isPadded: true,
			onColumnClick: () => {
				batch(() => {
					setSortPropertyDirectionASC(sortProperty === "ModifiedBy" ? !sortPropertyDirectionASC : true)
					setSortProperty("ModifiedBy");
				})
			},
			onRender: (FileSystemObject: FileSystemObject) => {
				return FileSystemObject.ModifiedBy;

			}
		}
	], [menuItems, sortProperty, sortPropertyDirectionASC, styles.ProgressBar, t]);

	const selection: Selection = new Selection({
		onSelectionChanged: () => {
			if (selection.count > 0) {
				let selectedItems = selection.getSelection() as FileSystemObject[];
				let selectedFiles = selectedItems.filter(item => item.Type === "File");
				setSelectedFiles(selectedFiles);
			} else {
				setSelectedFiles([]);
			}
		}
	});

	const renderRow = (props: IDetailsRowProps | undefined) => {
		if (!props) return null;
		const customStyles: Partial<IDetailsRowStyles> = {};

		var FileSystemObject = props.item as FileSystemObject;
		if (FileSystemObject.hasError) {
			customStyles.root = {
				borderTop: "solid 1px " + theme.semanticColors.errorText,
				borderBottom: "solid 1px " + theme.semanticColors.errorText,
				backgroundColor: theme.semanticColors.errorBackground
			};
		}

		return <DetailsRow {...props} styles={customStyles} />;
	}

	useEffect(() => {
		if (props.files.length === 0) {
			return;
		}
		setSortedItems(
			props.files.slice().sort((a, b) => {
				const fileA = a as FileSystemObject;
				const fileB = b as FileSystemObject;
				const order = sortPropertyDirectionASC ? 1 : -1;
				switch (sortProperty) {
					case "Size":
						return fileA.Size > fileB.Size ? order : -order;
					case "ModifiedOn":
						return fileA.ModifiedOn > fileB.ModifiedOn ? order : -order;
					case "ModifiedBy":
						return fileA.ModifiedBy > fileB.ModifiedBy ? order : -order;
					case "Folder":
						return fileA.Path > fileB.Path ? order : -order;
					default:
						return fileA.Name.toLowerCase() > fileB.Name.toLowerCase() ? order : -order;
				}
			})
		);
	}, [props.files, sortProperty, sortPropertyDirectionASC]);

	return <>
		<ExternalDownloadAllCommand
			addCommandButton={(command) => commandBarItems.push(command)}
			triggerRerender={() => setSelectedFiles(selectedFiles.slice(0))}
			files={props.files}
			password={props.password}
			shareId={props.shareId}
		/>
		<ExternalDownloadCommand
			addCommandButton={(command) => commandBarItems.push(command)}
			triggerRerender={() => setSelectedFiles(selectedFiles.slice(0))}
			selectedFiles={selectedFiles}
			password={props.password}
			shareId={props.shareId}
		/>
		<CommandBar items={commandBarItems} />

		<div className={styles.DetailsList}>
			<DetailsList
				checkboxVisibility={CheckboxVisibility.always}
				items={sortedItems}
				compact={false}
				columns={columns}
				selectionMode={SelectionMode.multiple}
				layoutMode={DetailsListLayoutMode.justified}
				isHeaderVisible={true}
				selection={selection}
				getKey={(fileSystemObject: FileSystemObject) => "ExternalShareFileDetailList_" + getFileSystemObjectKeyString(fileSystemObject)}
				onRenderRow={renderRow}
			/>
		</div>
	</>;

}