import { Team } from "../../../Model/Team";
import { TeamAndChannelDetails } from "../../../Model/TeamAndChannelDetails";
import { TeamListActions, TeamListActionTypes } from "./Actions";
import { TeamListState } from "./State";


const initialState: TeamListState = {
	teams: undefined,
	isLoading: false,
	sortProperty: "Name",
	sortPropertyDirectionASC: true,
	searchValue: ""
}

const teamsSorted = (teams: Team[] | undefined, sortProperty: string, sortPropertyDirectionASC: boolean) => {
	if (!teams) return teams;

	return teams.sort((a, b) => {
		const order = sortPropertyDirectionASC ? 1 : -1;
		switch (sortProperty) {
			case "Id":
				return a.Id > b.Id ? order : -order;
			case "PrimaryChannelName":
				return a.PrimaryChannelName > b.PrimaryChannelName ? order : -order;
			case "Name":
			default:
				return a.Name.toLowerCase() > b.Name.toLowerCase() ? order : -order;
		}
	});
}

const createParentIdsInChannel = (teams: Team[] | undefined) => {
	if (!teams) return teams;

	teams.forEach(t => t.Channels.forEach(c => c.ParentId = t.Id));
	return teams;
}

export function teamListReducer(
	state: TeamListState = initialState,
	action: TeamListActionTypes
): TeamListState {
	switch (action.type) {

		case TeamListActions.Request:
			return {
				...state,
				isLoading: true,
				teamListLoadError: undefined
			};

		case TeamListActions.Received:
			const sortedTeams = teamsSorted(action.teams, state.sortProperty, state.sortPropertyDirectionASC);
			const teamsWithConnectedChannels = createParentIdsInChannel(sortedTeams);

			return {
				...state,
				isLoading: false,
				teams: teamsWithConnectedChannels,
				teamListLoadError: undefined
			};

		case TeamListActions.ReceiveFailed:
			return {
				...state,
				isLoading: false,
				teams: undefined,
				teamListLoadError: action.message
			};
		case TeamListActions.SortTeamList:
			let result = teamsSorted(state.teams, action.property, action.sortDirectionASC);
			return {
				...state,
				teams: result,
				sortProperty: action.property,
				sortPropertyDirectionASC: action.sortDirectionASC
			};

		case TeamListActions.RequestTeamDetails:
			{
				let newState = { ...state };
				let newTeams = [...(newState.teams ?? [])];

				let teamPosition = newTeams.findIndex((teamItem) => action.teamId === teamItem.Id)

				if (teamPosition < 0) {
					return newState;
				}

				let newTeam = { ...newTeams[teamPosition] };
				newTeam.DetailsLoadingState = "Loading";
				newTeam.Channels = [...newTeam.Channels];

				for (let i = 0; i < newTeam.Channels.length; i++) {
					let newChannel = { ...newTeam.Channels[i] };

					newChannel.DetailsLoadingState = "Loading";
					newChannel.Details = undefined;
					newTeam.Channels[i] = newChannel;
				}
				newTeams[teamPosition] = newTeam;
				newState.teams = newTeams;
				return newState;
			}

		case TeamListActions.TeamDetailsReceived:
			{
				let newState = { ...state };
				let newTeams = [...(newState.teams ?? [])];
				let teamPosition = newTeams.findIndex((teamItem) => {

					return action.team?.Id === teamItem.Id
				})

				if (teamPosition < 0) {
					return newState;
				}

				let newTeam = { ...newTeams[teamPosition] };
				newTeam.Details = action.team?.Details;
				newTeam.DetailsLoadingState = "Loaded";
				newTeam.Channels = [...newTeam.Channels];

				for (let channel of action.team?.Channels ?? []) {
					let channelPosition = newTeam.Channels.findIndex((channelItem) => channel.Id === channelItem.Id);

					if (channelPosition < 0) {
						continue;
					}
					let newChannel = { ...newTeam.Channels[channelPosition] }
					newChannel.Details = channel.Details;
					newChannel.DetailsLoadingState = "Loaded";
					newTeam.Channels[channelPosition] = newChannel;
				}
				newTeams[teamPosition] = newTeam;
				newState.teams = newTeams;
				return newState;
			}
		case TeamListActions.TeamDetailsPTLimsSaved:
			{
				let newState = { ...state };
				let newTeams = [...(newState.teams ?? [])];

				let teamPosition = newTeams.findIndex((teamItem) => action.teamId === teamItem.Id);

				if (teamPosition < 0) { return newState; }

				let newTeam = { ...newTeams[teamPosition] };
				
				let newDetails: TeamAndChannelDetails = {...newTeam.Details ?? {
					Ident: 0,
					Location: "",
					Users: []
				}};
				newDetails.Ident = action.ident;
				newDetails.Location = action.location;

				newTeam.Details = newDetails;
				//Set state for DetailsPanel
				// newTeam.DetailsSaveState = "Saved";
				
				
				newTeams[teamPosition] = newTeam;
				newState.teams = newTeams;
				return newState;
			}
		case TeamListActions.SetSearchValue:
			return {
				...state,
				searchValue: action.searchValue
			}
		case TeamListActions.TeamDetailsPTLimsSavingFailed:
			{
				let newState = { ...state };
				let newTeams = [...(newState.teams ?? [])];

				let teamPosition = newTeams.findIndex((teamItem) => action.teamId === teamItem.Id);

				if (teamPosition < 0) { return newState; }

				let newTeam = { ...newTeams[teamPosition] };
				newTeam.Channels = [...newTeam.Channels];

				let channelPosition = newTeam.Channels.findIndex((channelItem) => action.channelId === channelItem.Id);

				if (channelPosition < 0) { return newState; }

				let newChannel = { ...newTeam.Channels[channelPosition] };

				//Set state for DetailsPanel
				newChannel.DetailsSaveState = "SavingFailed";
				newTeam.Channels[channelPosition] = newChannel;
				newTeams[teamPosition] = newTeam;
				newState.teams = newTeams;
				return newState;
			}
		default:
			return state;
	}
}

