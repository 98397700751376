import { Channel } from "../../../Model/Channel"
import { Team } from "../../../Model/Team";
import { TeamList } from "../../../Tabs/TeamList/TeamList";

export enum TeamListActions {
    Request = "TeamList/Request",
    Received = "TeamList/Received",
    ReceiveFailed = "TeamList/ReceiveFailed",
    SortTeamList = "TeamList/SortTeams",
    RequestTeamDetails = "TeamList/RequestTeamDetails",
    TeamDetailsReceived = "TeamList/TeamDetailsReceived",
    TeamDetailsReceivedFailed = "TeamList/TeamDetailsReceivedFailed",
    SetSearchValue = "TeamList/SetSearchValue",
    TeamDetailsPTLimsSaved = "TeamList/TeamDetailsPTLimsSaved",
    TeamDetailsPTLimsSavingFailed = "TeamList/TeamDetailsPTLimsSavingFailed"
}

interface RequestTeamListAction {
    type: typeof TeamListActions.Request;
}

export const requestTeamList = (): RequestTeamListAction => ({
    type: TeamListActions.Request
});

interface TeamListReceivedAction {
    type: typeof TeamListActions.Received;
    teams: Team[] | undefined;
}

export const teamListReceived = (teams: Team[] | undefined): TeamListReceivedAction => ({
    type: TeamListActions.Received,
    teams: teams
});

interface TeamListReceiveFailedAction {
    type: typeof TeamListActions.ReceiveFailed;
    code: number;
    message: string;
}

export const teamListReceiveFailed = (code: number, message: string): TeamListReceiveFailedAction => ({
    type: TeamListActions.ReceiveFailed,
    code,
    message
});

interface SortTeamListAction {
    type: typeof TeamListActions.SortTeamList;
    property: string;
    sortDirectionASC: boolean;
}

export const sortTeamList = (property: string, sortDirectionASC: boolean): SortTeamListAction => ({
    type: TeamListActions.SortTeamList,
    property,
    sortDirectionASC
});

interface TeamDetailsReceivedAction {
    type: typeof TeamListActions.TeamDetailsReceived;
    team: Team | undefined;
}

export const teamDetailsReceived = (team: Team | undefined): TeamDetailsReceivedAction => ({
    type: TeamListActions.TeamDetailsReceived,
    team
});

interface RequestTeamDetailsAction {
    type: typeof TeamListActions.RequestTeamDetails;
    teamId: string;
}

export const requestTeamDetails = (teamId: string): RequestTeamDetailsAction => ({
    type: TeamListActions.RequestTeamDetails,
    teamId
});

interface TeamDetailsReceivedFailedAction {
    type: typeof TeamListActions.TeamDetailsReceivedFailed;
    code: number;
    message: string;
}

export const teamDetailsReceivedFailed = (code: number, message: string): TeamDetailsReceivedFailedAction => ({
    type: TeamListActions.TeamDetailsReceivedFailed,
    code,
    message
});


interface TeamDetailsPTLimsSavedAction {
    type: typeof TeamListActions.TeamDetailsPTLimsSaved;
    teamId: string;
    channelId: string;
    ident: number;
    location: string;
}

export const savedTeamDetailsPTLims = (teamId: string, channelId: string, ident: number, location: string): TeamDetailsPTLimsSavedAction => ({
    type: TeamListActions.TeamDetailsPTLimsSaved,
    teamId,
    channelId,
    ident,
    location
});

interface TeamDetailsPTLimsSavingFailedAction {
    type: typeof TeamListActions.TeamDetailsPTLimsSavingFailed
    teamId: string;
    channelId: string;
}

export const teamDetailsPTLimsSavingFailed = (teamId: string, channelId: string): TeamDetailsPTLimsSavingFailedAction => ({
    type: TeamListActions.TeamDetailsPTLimsSavingFailed,
    teamId,
    channelId
});

interface SetSearchValueAction {
    type: typeof TeamListActions.SetSearchValue;
    searchValue: string;
}

export const setSearchValue = (searchValue: string): SetSearchValueAction => ({
    type: TeamListActions.SetSearchValue,
    searchValue
});

export type TeamListActionTypes =
    RequestTeamListAction |
    TeamListReceivedAction |
    TeamListReceiveFailedAction |
    SortTeamListAction |
    RequestTeamDetailsAction |
    TeamDetailsReceivedAction |
    TeamDetailsReceivedFailedAction |
    SetSearchValueAction |
    TeamDetailsPTLimsSavedAction |
    TeamDetailsPTLimsSavingFailedAction;