import { RootState, externalSharesReceiveFailed, removeExternalShares } from "../../Store";
import { ApiUrlBuilder } from "../../ApiUrlBuilder";

export function stopShares(shareIds: string[]) {
	return async (dispatch: React.Dispatch<any>, getState: () => RootState): Promise<void> => {

		const { teams: { isInitialized, accessToken } } = getState();
		if (!isInitialized || !accessToken) return;

		try {
			const url = ApiUrlBuilder.StopExternalShares();
			const requestBody = {
				shareIds
			};

			const response: Response = await fetch(`/api${url}`, {
				method: "POST",
				headers: {
					"Authorization": `Bearer ${accessToken}`,
					"Content-Type": "application/json"
				},
				body: JSON.stringify(requestBody)
			});

			if (!response.ok) {					
				dispatch(externalSharesReceiveFailed(response.status, response.statusText));
				return;
			}
			
			dispatch(removeExternalShares(shareIds));
		} catch (error:any) {
			//dispatch(externalSharesReceiveFailed(500, error));
		}
	}
}