import * as React from "react";
import { resetDownloads } from "../Api/File/ResetDownloads";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../Store/useAppDispatch";
import { CommandBarCommandProps } from "../Utils/useCommands";

export const ResetDownloadsCommand = (props: CommandBarCommandProps): JSX.Element => {
	const { selectedFiles } = useAppSelector(state => state.externalShares);
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	
	if(selectedFiles && selectedFiles.length > 0) {
		props.addCommandButton({
			key: "resetDownloads",
			text: t("Commands.ResetDownloads.Command"),
			iconProps: { iconName: "Undo" },
			onClick: () => { dispatch(resetDownloads(selectedFiles)); return false; },
		});
	}

	return <>
	</>;
}