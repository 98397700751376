import * as React from "react";
import "./ChecklistValue.scss";
import { TextField } from "@fluentui/react";

export interface ChecklistTextProps {
	value: string | undefined;
	isEditing: boolean;
	onChange: (value: string | undefined) => void;
}

export class ChecklistText extends React.Component<ChecklistTextProps, {}> {
	render(): JSX.Element {
		if (this.props.isEditing) {
			return <TextField value={this.props.value} onChange={(x,newValue) => this.props.onChange(newValue)} />;
		} else {
			return <>{this.props.value}</>;
		}
	}
}