import { makeStyles } from "@fluentui/react";

export default () => {
	let styles = makeStyles(theme => ({
		StatusHeader: {},
		StatusHeaderKey: {
			fontSize: theme.fonts.xLargePlus.fontSize, // 24
		},
		StatusHeaderDescription: {
			fontSize: theme.fonts.xLarge.fontSize, // 20
		},
		StatusHeaderDetails: {
			marginTop: "1.0rem"
		}
	}));
	return styles();
};