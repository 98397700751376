import { makeStyles } from "@fluentui/react";

export default () => {
	let styles = makeStyles(theme => ({
		CopyToClipboard: {
			display: "flex",
			alignItems: "center"
		},

		CopyToClipboardContext: {
			width: "100%",
			alignItems: "center",
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis"
		},

		CopyToClipboardHovering: {
			visibility: "visible",
			color: theme.palette.neutralSecondary
		},
		CopyToClipboardNotHovering: {
			visibility: "hidden",
		}
	}));
	return styles();
};