import * as React from "react";
import { RootState, setGlobalError, setSendingContactMessage, setContactMessage } from "../../Store";
import { batch } from "react-redux";

export function sendChannelMessage(recipientEmail: string, isChannelMessage: boolean) {
	return async (dispatch: React.Dispatch<any>, getState: () => RootState): Promise<void> => {
		try {
			dispatch(setSendingContactMessage(true));
			let { contacts: { taskIdent, orderIdent, location, sendMessage: { message } }, teams: { accessToken }, file: { ident, location: fileLocation, sourceType } } = getState();

			location = location ?? fileLocation;
			if (!taskIdent && !orderIdent) {
				if (sourceType === "Task") {
					taskIdent = Number(ident);
				}
				else if (sourceType === "Order") {
					orderIdent = Number(ident);
				}
			}

			let url = `${window.location.origin}/api/locations/${location}`;
			if (taskIdent) {
				url += `/tasks/${taskIdent}/messages`;
			}
			else if (orderIdent) {
				url += `/orders/${orderIdent}/messages`;
			}

			const response: Response = await fetch(url, {
				method: "POST",
				headers: {
					"Authorization": `Bearer ${accessToken}`,
					"Content-Type": "application/json"
				},
				body: JSON.stringify({ Message: message, Recipient: recipientEmail, IsChannelMessage: isChannelMessage })
			});

			batch(() => {
				if (!response.ok) {
					dispatch(setGlobalError(`API request failed with ${response.status}: ${response.statusText}`));
				}
				dispatch(setSendingContactMessage(false));
				dispatch(setContactMessage(""));
			});

		} catch (error:any) {
			batch(() => {
				dispatch(setGlobalError(error.message));
				dispatch(setSendingContactMessage(false));
				dispatch(setContactMessage(""));
			});
		}
	};
}