import { IStyle, makeStyles } from "@fluentui/react";

export default () => {
	let styles = makeStyles(theme => ({
		Error: {
			backgroundColor: theme.semanticColors.errorBackground,
			padding: "5px",
			margin: "-5px",
		},
		Link: {
			":hover": {
				color: theme.palette.themePrimary
			} as IStyle,
			"a": {
				textDecoration: "none",
				color: "inherit",
				":hover": {
					color: theme.palette.themePrimary
				} as IStyle
			} as IStyle
		}
	}));
	return styles();
};