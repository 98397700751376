import * as React from "react";
import { removeGlobalError } from "../../Store";
import { MessageBar, MessageBarType } from "@fluentui/react";
import { PropsWithChildren } from "react";
import { useAppDispatch, useAppSelector } from "../../Store/useAppDispatch";

export interface GlobalErrorProps {
}

export const GlobalError : React.FunctionComponent<GlobalErrorProps> = (props: PropsWithChildren<GlobalErrorProps> ): JSX.Element => {
	const error: string|undefined = useAppSelector(state => state.system.globalError);

	const dispatch: React.Dispatch<any> = useAppDispatch();

	return (
		<>
			{error && <MessageBar
				messageBarType={MessageBarType.error}
				isMultiline={true}
				onDismiss={() => dispatch(removeGlobalError())} dismissButtonAriaLabel="Close">
				{error}
			</MessageBar>
			}
			{props.children}
		</>
	);
};