import * as React from "react";
import { RootState, requestFiles, filesReceived, filesReceiveFailed, selectFolder, setDriveUrl, setDrives, setDriveLoading, selectSubFolderPath } from "../../Store";
import { FileSystemObject } from "../../Model/FileSystemObject";
import { SpecialFolderIds } from "../../Model/SpecialFolder";
import { batch } from "react-redux";
import { ApiUrlBuilder } from "../../ApiUrlBuilder";
import { ODataResult } from "../../Model/ODataResult";
import { sortByName } from "../../Utils/Sort";
import { DriveRef } from "../../Model/DriveRef";
import { DriveInfo } from "../../Model/DriveInfo";
import { Task } from "../../Model/Task";
import { Order } from "../../Model/Order";
import { Tender } from "../../Model/Tender";
import { DriveType } from "../../Model/DriveType";
import { getHttpStatusText } from "../../Utils/getHttpStatusText";
import { Test } from "../../Model/Test";

const getFileSystemObjects = async (dispatch: React.Dispatch<any>, accessToken: string, drive: DriveRef): Promise<FileSystemObject[] | undefined> => {
	try {
		const url = ApiUrlBuilder.GetFileSystemObjects(drive.Location, drive.Ident, drive.Type);

		dispatch(setDriveLoading({ isFilesLoading: true, isFoldersLoading: true, ...drive }));

		const response: Response = await fetch(`/api${url}`, {
			method: "GET",
			headers: {
				"Authorization": `Bearer ${accessToken}`
			}
		});

		if (!response.ok) {
			//dispatch(filesReceiveFailed(response.status, response.statusText));
			return undefined;
		}

		const fileSystemObjectsResponse: ODataResult<FileSystemObject> = await response.json();

		for (const fileSystemObject of fileSystemObjectsResponse.value) {
			fileSystemObject.Drives = [drive];
		}

		return fileSystemObjectsResponse.value;
	} catch (error:any) {
		console.log(error);
		dispatch(filesReceiveFailed(0, error.message));
		return undefined;
	} finally {
		dispatch(setDriveLoading({ isFilesLoading: false, isFoldersLoading: false, ...drive }));
	}
}

const getDrive = async (dispatch: React.Dispatch<any>, accessToken: string, sourceType: DriveType, location: string, ident: string): Promise<DriveInfo | undefined> => {
	try {
		const url = ApiUrlBuilder.GetDrive(location, ident, sourceType);

		const response: Response = await fetch(`/api${url}`, {
			method: "GET",
			headers: {
				"Authorization": `Bearer ${accessToken}`
			}
		});

		if (!response.ok) {
			const errorMessage = response.statusText || getHttpStatusText(response.status);
			dispatch(filesReceiveFailed(response.status, errorMessage));
			return undefined;
		}

		const drive: DriveInfo = await response.json();
		return drive;
	} catch (error:any) {
		dispatch(filesReceiveFailed(0, error.message));
		return undefined;
	}
}

const getDrives = async (dispatch: React.Dispatch<any>, accessToken: string, sourceType: DriveType, location: string, ident: string): Promise<DriveRef[] | undefined> => {
	try {
		const url = sourceType === "Task" ? ApiUrlBuilder.GetTask(location, ident) + "?$expand=Orders($select=Ident,Number,Location)&$select=Ident,Number,Location"
			: sourceType === "Tender" ? ApiUrlBuilder.GetTender(location, ident) + "?$expand=Task($select=Ident,Number,Location)&$select=Ident,Number,Location"
			: sourceType === "Test" ? ApiUrlBuilder.GetTest(location, ident) + "?$select=Ident,Number,Location"
				: ApiUrlBuilder.GetOrder(location, ident) + "?$expand=Task($select=Ident,Number,Location)&$select=Ident,Number,Location";

		const response: Response = await fetch(`/api${url}`, {
			method: "GET",
			headers: {
				"Authorization": `Bearer ${accessToken}`
			}
		});

		if (!response.ok) {
			dispatch(filesReceiveFailed(response.status, response.statusText));
			return undefined;
		}

		let drives: DriveRef[] = [];
		if (sourceType === "Task") {
			const task: Task = await response.json();
			drives.push({ Type: "Task", Location: task.Location, Ident: task.Ident.toString(), Number: task.Number });
			for (const order of task.Orders) {
				drives.push({ Type: "Order", Location: order.Location, Ident: order.Ident.toString(), Number: order.Number });
			}
		} else if (sourceType === "Order") {
			const order: Order = await response.json();
			drives.push({ Type: "Order", Location: order.Location, Ident: order.Ident.toString(), Number: order.Number });
			drives.push({ Type: "Task", Location: order.Task.Location, Ident: order.Task.Ident.toString(), Number: order.Task.Number });
		} else if (sourceType === "Tender") {
			const order: Tender = await response.json();
			drives.push({ Type: "Tender", Location: order.Location, Ident: order.Ident.toString(), Number: order.Number });
			drives.push({ Type: "Task", Location: order.Task.Location, Ident: order.Task.Ident.toString(), Number: order.Task.Number });
		} else if (sourceType === "Test") {
			const test: Test = await response.json();
			drives.push({ Type: "Test", Location: test.Location, Ident: test.Ident.toString(), Number: test.Number });
		}

		return drives;
	} catch (error:any) {
		dispatch(filesReceiveFailed(0, error.message));
		return undefined;
	}
}


export function loadFilesAndFolders(sourceType: DriveType, location: string, ident: string) {
	return async (dispatch: React.Dispatch<any>, getState: () => RootState): Promise<void> => {
		try {
			const { teams: { isInitialized, accessToken, isInternalUser }, file } = getState();
			if (!isInitialized) return;

			dispatch(requestFiles(location, ident, sourceType));


			if (!accessToken) {
				dispatch(filesReceiveFailed(0, "Access token missing"));
				return;
			}
			const drive = await getDrive(dispatch, accessToken, sourceType, location, ident);
			if (!drive) return;
			dispatch(setDriveUrl(drive?.Url ?? "#"));

			const drives = await getDrives(dispatch, accessToken, sourceType, location, ident);
			if (drives) {
				dispatch(setDrives(drives));
			}

			let files: FileSystemObject[] | undefined = undefined;
			let folders: FileSystemObject[] | undefined = undefined;

			if (drives) {
				files = [];
				folders = [];

				let fileSystemObjectsRequests: Promise<FileSystemObject[] | undefined>[] = [];
				for (const drive of drives) {
					fileSystemObjectsRequests.push(getFileSystemObjects(dispatch, accessToken, drive));
				}
				

				for (const fileSystemObjectsRequest of fileSystemObjectsRequests) {
					let fileSystemObjects = await fileSystemObjectsRequest;
					if (!fileSystemObjects || fileSystemObjects.length === 0) continue;

					files = files.concat(fileSystemObjects.filter(object => object.Type === "File"));

					for (const driveFolder of fileSystemObjects.filter(object => object.Type === "Folder")) {
						const existingFolder = folders.find(folder => folder.Path === driveFolder.Path);
						if (existingFolder) {
							existingFolder.Drives != null ? existingFolder.Drives = existingFolder.Drives.concat(driveFolder.Drives) : existingFolder.Drives = driveFolder.Drives;
						} else {
							folders.push(driveFolder)
						}
					}
				}

				files = sortByName<FileSystemObject>(files);
				folders = sortByName<FileSystemObject>(folders);
			}

			if (!isInternalUser && files) {
				if (!folders) {
					folders = [];
				}
				// add missing folders
				addMissingFolders(files, folders);
			}

			batch(() => {
				if (file && folders) {
					dispatch(filesReceived(files, folders));
				}

				if ((file.location !== location || file.ident !== ident || file.sourceType !== sourceType)) {
					if (folders?.some(f => f.Path === file.selectedFolderId)) {
						dispatch(selectFolder(file.selectedFolderId));
						dispatch(selectSubFolderPath(file.selectedSubFolderPath));
					} else {
						dispatch(selectFolder(isInternalUser ? SpecialFolderIds.All : SpecialFolderIds.CustomerExchange));
					}
				}
				//dispatch(initializeContacts(order.Location, undefined, order.Ident));
			});
		} catch (error:any) {
			dispatch(filesReceiveFailed(0, error.message));
		}
	};
}

/**
 * Checks all files and adds missing folders
 * @param files files to check for missing folders
 * @param folders folder array to wich the missing folders will be added
 */
const addMissingFolders = (files: FileSystemObject[], folders: FileSystemObject[]) => {
	for (const file of files) {
		const pathParts = file.Path.split("/");
		while (pathParts.length > 0) {
			const parentPath = pathParts.join("/");
			if (!folders.some(folder => parentPath === folder.Path)) {
				folders.push({
					Id: pathParts[pathParts.length - 1],
					Name: pathParts[pathParts.length - 1],
					Path: parentPath,
					Drives: [],
					IsReadonly: true,
					Type: "Folder",
					Size: -1,
					Url: "",
					CreatedOn: "",
					CreatedBy: "",
					ModifiedOn: "",
					ModifiedBy: ""
				});
			}
			pathParts.pop();
		}
	}
}