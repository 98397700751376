import { IStyle, makeStyles } from "@fluentui/react";

export default () => {
	let styles = makeStyles(theme => ({
		DeleteFileList: {
			marginTop: "16px",

			".ms-List-cell": {
				overflow: "hidden",
				textOverflow: "ellipsis",
				whiteSpace: "nowrap",
				padding: "3px",

				":nth-child(even)": {
					background: `${theme.palette.neutralPrimary}07`,
				} as IStyle,

				i: {
					overflow: "visible",
					marginRight: "6px",
					verticalAlign: "sub",
				} as IStyle
			} as IStyle
		}
	}));
	return styles();
};