import * as React from 'react';
import logo from '../logo.svg';

export class Info extends React.Component<{}> {

	render() {
		return (
			<div className="App">
				<header className="App-header">
					<img src={logo} className="App-logo" alt="logo" />
				</header>
			</div>
		);
	}
}
