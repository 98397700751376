import i18next from "i18next";

export default function (): void {
	i18next.addResourceBundle("en", "translation", {
		Times: {
			Day: "Day",
			DayMulti: "Days"
		},
		OrderStatus: {
			Test: "Test",
			Status: "Status",
			Start: "Start",
			End: "End",
			Progress: "Progress",
			Planed: "planed",
			Detail: {
				Status: "Status",
				OrderNumber: "Order number",
				Laboratory: "Laboratory",
				Sales: "Sales",
				ProductName: "Product name"
			}
		},
		TaskStatus: {
			OrderNumber: "Order number",
			Order: "Order",
			Status: "Status",
			Start: "Start",
			End: "End (planed)",
			Progress: "Progress",
			Detail: {
				TaskNumber: "Task number",
				AccountManager: "Account Manager"
			}
		},
		TeamList: {
			TeamId: "ID",
			Title: "Name",
			Status: "Status",
			Link: "Links",
			LinkTitlePrefix: "Go to ",
			ChatDeepLink: "Conversations tab",
			StatusDeepLink: "Status tab",
			SummaryDeepLink: "Summary tab",
			FileDeepLink: "File tab",
			StatusNotFound: "",
			ChannelType: {
				Order: "Order ",
				Task: "Task ",
				Tender: "Tender ",
				Test: "Test ",
				Unknown: ""
			}
		},
		TeamDetailsPanel: {
			PTLimsTitle: "PTLims",
			Ident: "Ident",
			Location: "Location",
			Loading: "Loading",
			Saving: "Saving",
			SavingFailed: "Saving failed",
			Close: "Close",
			Edit: "Edit",
			Cancel: "Cancel",
			Save: "Save"
		},
		Invitation: {
			EMail: "Mail",
			Name: "Name",
			State: "Invitation state",
			InvitationDate: "Invitation date",
			InvitedTeam: "Team"
		},
		ReloadComponent: {
			Reload: "Retry"
		},
		TaskChecklist: {
			Description: "Description",
			Value: "Value",
			DateUpdated: "Updated on",
			UpdatedBy: "Updated by",
			Detail: {
				TaskNumber: "Task number",
				AccountManager: "Account Manager"
			},
			Boolean: {
				true: "Yes",
				false: "No"
			}
		},
		TabConfig: {
			Intro: "Fügen Sie eine weiteren Tab zu Ihrem Kanal hinzu.",
			Content: "Inhalt der Registerkarte",
			TabName: "Name der Registerkarte",
			PTLimsIdent: "Ident aus PTLims",
			PTLimsLocation: "Location code aus PTLims",
			BindPTLims: "Configure team"
		},
		FileBase: {
			File: {
				Name: "Name",
				Size: "Size",
				Tags: "Tags",
				Of: "of",
				File: "File",
				Folder: "Folder",
				DownloadFailed: "Download of file '{{file}}' failed",
				Downloads: "Downloads",
				ModifiedOn: "Modified",
				ModifiedBy: "Modified by",
				Cancel: "Cancel",
				Drive: "File",
				FilenameConflictDialogTitle: "Filename conflict",
				Overwrite: "Overwrite",
				Rename: "Rename",
				FilenameConflictMessage: "A file with the same name already exists in the folder. Do you want to overwrite or rename it?",
				FilenameRequiredErrorMessage: "Filename is required",
				FilenameInvalidCharacterErrorMessage: "Invalid characters:  \\ / : * ? \" < > |",
			},
			Folders: {
				Heading: "Folders",
				All: "All files",
				CustomerExchange: "Customer Exchange",
				SharedByLink: "Shared by Link"
			},
			Uploading: "Uploading file...",
			FileUploadMessage: "The file '{{file}}' has been uploaded to the folder '{{path}}'.",
			Downloading: "Downloading file...",
			HasHiddenFiles: "Some files are hidden by filter",
			ExternalShareSubTitle: "Valid from {{from}} to {{to}}, created by {{user}}",
			ExternalShareDownloadLinkLabel: "Link",
			ExternalShareOverdueTooltip: "This Share is exceeded and not valid anymore."
		},
		Commands: {
			Dialog: {
				Cancel:"Cancel"
			},
			IsBusy: "The target currently has running actions",
			AddFolder: {
				Command: "New Folder",
				DialogTitle: "Create a new folder",
				NewFolderName: "New folder",
				Ok: "Create",
				Cancel: "Cancel",
			},
			RenameFile: {
				Command: "Rename",
				DialogTitle: "Rename file",
				NewFileName: "New file name",
				Ok: "Rename",
				Cancel: "Cancel",
			},
			MoveFile: {
				Command: "Move",
				DialogTitle: "Move file",
				TargetFolder: "Target folder",
				Ok: "Move",
				Cancel: "Cancel",
			},
			Download: {
				Command: "Download"
			},
			DownloadAll: {
				Command: "Download All (ZIP)"
			},
			DeleteFile: {
				Command: "Delete",
				DialogTitle: "Delete",
				DeleteFileMessage: "Do you really want to delete file '{{file}}'?",
				DeleteFileMessageFolder: "Do you really want to delete folder '{{file}}' and all items in the folder?",
				DeleteFilesMessage: "Do you really want to delete {{fileCount}} files?",
				DeleteFilesMessageFolder: "Do you really want to delete these items? All items in selected folders will also be deleted.",
				Ok: "Delete",
				Cancel: "Cancel",
			},
			Refresh: {
				Command: "Refresh"
			},
			Upload: {
				Command: "Upload"
			},
			Resend: {
				Command:"Resend"
			},
			Share: {
				Command: "Share",
				DialogTitle: "Share document",
				DialogTitleMulti: "Share documents",
				Share: "Share",
				StopShare: "Stop share",
				Close: "Close",
				DownloadUrlLabel: "Download url",
				PasswordLabel: "Password",
				ShareWithCustomer: "Share with customers in the portal",
				ShareWithCustomerYes: "Yes",
				ShareWithCustomerNo: "No",
				ShareByLink: "Share via download link",
				ShareDurationLabel: "Validity period of the url",
				GenerateLink: "Generate download url"
			},
			ResetPassword: {
				Command: "Reset password",
				DialogTitle: "Reset password",
				DownloadUrlLabel: "Download url",
				PasswordLabel: "Password",
				Close: "Close",
			},
			OpenSharePoint: {
				Command: "In SharePoint öffnen"
			},
			MoveFileBetween: {
				Command: "Move to task"
			},
			PullFileFromTask: {
				CommandOrder: "Move to order",
				CommandTender: "Move to tender"
			},
			RenameFolder: {
				Command: "Rename",
				DialogTitle: "Rename folder",
				NewFolderName: "New folder name",
				Ok: "Rename",
				Cancel: "Cancel",
			},
			View: {
				Command: "View",
				List: "List",
				Tiles: "Tiles"
			},
			CopyLink: {
				Command: "Copy link"
			},
			ClosePreview: {
				Command: ""
			},
			NextPreview: {
				Command: "Next"
			},
			PrevPreview: {
				Command: "Previous"
			},
			ExtendShare: {
				Command: "Extend time",
				DialogTitle: "Extend download time frame",
				ValidToDate: "Download is valid until",
				Ok:"Extend",
				Cancel:"Cancel"
			},
			ResetDownloads: {
				Command: "Reset downloads"
			},
			StopShare: {
				Command: "Stop share"
			},
			NoActionAvailable: "No action available"
		},
		Actions: {
			Uploading: "Uploading '{{file}}'",
			Downloading: "'Downloading '{{file}}'",
			AddFolder: "Creating folder '{{folder}}'",
			DeleteFile: "Deleting file '{{file}}'",
			DeleteFolder: "Deleting folder '{{folder}}'",
			Renaming: "Renaming file '{{file}}'",
			Moving: "Moving file '{{file}}'",
			Share: "Share file '{{file}}'",
			RemoveShare: "Remove share of file '{{file}}'",
			ResetDownloads: "Reset download count of file '{{file}}'",
			GetTags: "Get available tags",
			AddTag: "Add tag {{tag}}",
			RemoveTag: "Remove tag {{tag}}"
		},
		ExternalShare: {
			Verification: {
				Title: "Filetransfer",
				Description: {
					Line1: "You have received a secure link from us to download files.",
					Line2: "Please enter the password to view the files.",
				},
				PasswordLabel: "Password",
				SubmitButtonText: "Access",
				OnAccessLoading: "Files are requested",
				ErrorMessages: {
					Error: "There has been an error. Please try again or contact the sender.",
					NotFound: "No share was found. Please contact the sender.",
					Unauthorized: "Password or share link are wrong. Please try again or contact the sender.",
				},
				Files: {
					ErrorMessages: {
						Error: "There has been an error. Please try again or contact the sender.",
						NotFound: "The mamixmum count of downloads has been reached. Please refresh the page or, if needed, contact the sender.",
						Unauthorized: "You are not authorized to execute this action. Please try again or contact the sender.",
					}
				}
			}
		},
		DatePicker: {
			months: [
				'January',
				'February',
				'March',
				'April',
				'May',
				'June',
				'July',
				'August',
				'September',
				'October',
				'November',
				'December'
			],

			shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],

			days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],

			shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],

			goToToday: 'Go to today',
			prevMonthAriaLabel: 'Go to previous month',
			nextMonthAriaLabel: 'Go to next month',
			prevYearAriaLabel: 'Go to previous year',
			nextYearAriaLabel: 'Go to next year',
			closeButtonAriaLabel: "Close calendar",
			monthPickerHeaderAriaLabel: "{0}, chose to change the year",
			yearPickerHeaderAriaLabel: "{0}, chose to change the month",

			isRequiredErrorMessage: "Date is required.",

			invalidInputErrorMessage: "No correct date format."
		},
		HttpStatusCodes : {
			100: "Continue",
			101: "Switching Protocols",
			102: "Processing",
			103: "Early Hints",
			200: "OK",
			201: "Created",
			202: "Accepted",
			203: "Non-Authoritative Information",
			204: "No Content",
			205: "Reset Content",
			206: "Partial Content",
			207: "Multi-Status",
			208: "Already Reported",
			226: "IM Used",
			300: "Multiple Choices",
			301: "Moved Permanently",
			302: "Found (Moved Temporarily)",
			303: "See Other",
			304: "Not Modified",
			305: "Use Proxy",
			306: "(reserviert)",
			307: "Temporary Redirect",
			308: "Permanent Redirect",
			400: "Bad Request",
			401: "Unauthorized",
			402: "Payment Required",
			403: "Forbidden",
			404: "Not Found",
			405: "Method Not Allowed",
			406: "Not Acceptable",
			407: "Proxy Authentication Required",
			408: "Request Timeout",
			409: "Conflict",
			410: "Gone",
			411: "Length Required",
			412: "Precondition Failed",
			413: "Payload Too Large",
			414: "URI Too Long",
			415: "Unsupported Media Type",
			416: "Range Not Satisfiable",
			417: "Expectation Failed",
			421: "Misdirected Request",
			422: "Unprocessable Entity",
			423: "Locked",
			424: "Failed Dependency",
			425: "Too Early",
			426: "Upgrade Required",
			428: "Precondition Required",
			429: "Too Many Requests",
			431: "Request Header Fields Too Large",
			451: "Unavailable For Legal Reasons",
			418: "I’m a teapot",
			420: "Policy Not Fulfilled",
			444: "No Response",
			449: "The request should be retried after doing the appropriate action",
			499: "Client Closed Request",
			500: "Internal Server Error",
			501: "Not Implemented",
			502: "Bad Gateway",
			503: "Service Unavailable",
			504: "Gateway Timeout",
			505: "HTTP Version not supported",
			506: "Variant Also Negotiates",
			507: "Insufficient Storage",
			508: "Loop Detected",
			509: "Bandwidth Limit Exceeded",
			510: "Not Extended",
			511: "Network Authentication Required"
		}
	}, true, true);
}
