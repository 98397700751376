import * as React from "react";
import { FileSystemObject } from "../Model/FileSystemObject";
import { getFileTypeIconProps } from "@fluentui/react-file-type-icons";
import { Icon, IconButton } from "@fluentui/react";
import { CopyToClipboard } from "./CopyToClipboard/CopyToClipboard";
import "./FolderWithFilesFoldable.scss";
import { areSameDrives } from "../Utils/CompareDrive";
import { getFileSystemObjectKeyString } from "../Utils/GetKeyString";
import { useAppSelector } from "../Store/useAppDispatch";

export interface FolderWithFilesFoldableProps {
	folder: FileSystemObject;
	onlyShareableFiles?: boolean;
}

export const FolderWithFilesFoldable = (props: FolderWithFilesFoldableProps): JSX.Element => {
	const { folder, onlyShareableFiles } = props;
	const [filesAreDisplayed, setFilesAreDisplayed] = React.useState(false);
	const { files, folders, drives } = useAppSelector(state => state.file);

	const nestedFiles = React.useMemo(() =>
		files?.filter((file) => folder.Path.startsWith(file.Path) && (!onlyShareableFiles || file.CanShareFile) && drives.some(drive => drive.isSelected && areSameDrives(file.Drives, [drive])))
		, [drives, files, folder.Path, onlyShareableFiles]);

	if (!nestedFiles || nestedFiles.length === 0) {
		return <></>
	}
	return <div className="FolderWithFilesFoldable">
		<CopyToClipboard key={folder.Id} text={folder.Name}>
			<Icon iconName="FabricFolder" className="Icon" />
			{folder.Name}
			<IconButton className="IconButton" iconProps={{ iconName: filesAreDisplayed ? "ChevronUpSmall" : "ChevronDownSmall" }} onClick={() => setFilesAreDisplayed(!filesAreDisplayed)} />
		</CopyToClipboard>
		{filesAreDisplayed &&
			<div className="NestedFiles">
				{folders?.filter((nestedFolder) => nestedFolder.Path.startsWith(folder.Path) && (nestedFolder.filesCount ?? 0) > 0 && nestedFolder.Path !== folder.Path && nestedFiles.some((file) => nestedFolder.Path.startsWith(file.Path)))
					.map((nestedFolder) => <FolderWithFilesFoldable key={`FolderWithFilesFoldable_${folder.Path}_${folder.Id}`} folder={nestedFolder} onlyShareableFiles />)
				}
				{nestedFiles.filter((file) => file.Path === folder.Path).map(x => {
					const extension = x.Name.substring(x.Name.lastIndexOf('.'));
					const iconProps = getFileTypeIconProps({ extension: extension });
					return <CopyToClipboard key={`FolderWithFilesFoldable_${getFileSystemObjectKeyString(x)}`} text={x.Name}>
						<Icon iconName={iconProps.iconName} className="Icon" /> {x.Name}
					</CopyToClipboard>
				})}
			</div>
		}
	</div>
}