import * as React from "react";
import { DetailsList, IColumn, Selection, SelectionMode, DetailsListLayoutMode, CheckboxVisibility, IGroup, IDetailsGroupRenderProps, IDetailsRowProps, DetailsRow, Check, css, Icon, Link, Spinner, SpinnerSize, TooltipHost } from "@fluentui/react";
import { useSelector } from "react-redux";
import { deselectExternalShare, sortSharesFiles, RootState, selectExternalShare, selectExternalShareFiles } from "../../Store";
import ColumnContextMenu from "../ColumnContextMenu/ColumnContextMenu";
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import { useMemo, useEffect } from "react";
import useStyles from "./FilesSharedByLink.style"
import { useTranslation } from "react-i18next";
import { useGlobalStyles } from "../../Global.style";
import { ExternalShare } from "../../Model/ExternalShare";
import { ExternalShareFile } from "../../Model/ExternalShareFile";
import { loadExternalShares } from "../../Api/File/LoadExternalShares";
import { Loading } from "../Loading";
import { ReloadComponent } from "../ReloadComponent/ReloadComponent";
import FormatDate from "../../Formatter/FormatDate";
import { useCommands } from "../../Utils/useCommands";
import { ExtendShareCommand, ResetDownloadsCommand, ResetSharePasswordCommand, StopShareCommand } from "../../ToolbarCommands";
import { MouseContextualMenu } from "../MouseContextualMenu/MouseContextualMenu";
import { CopyToClipboard } from "../CopyToClipboard/CopyToClipboard";
import { useAppDispatch, useAppSelector } from "../../Store/useAppDispatch";

export interface FilesSharedByLinkProps {
}

export const FilesSharedByLink = (props: FilesSharedByLinkProps) => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const { files } = useAppSelector(state => state.file);
	const { shares, loadError, status, selectedShares, shareFilesSortProperty, shareFilesSortPropertyDirectionASC, downloadUrlBase } = useAppSelector(state => state.externalShares);
	const styles = useStyles();
	const globalStyles = useGlobalStyles();

	const [menuItems, addMenuItem] = useCommands();

	useEffect(() => {
		if (status === "NotLoaded") {
			dispatch(loadExternalShares());
		}
	}, [dispatch, status]);

	const columns: IColumn[] = useMemo(() => [
		{
			key: "FileType",
			name: "",
			minWidth: 16,
			maxWidth: 16,
			data: "string",
			iconName: "TextDocument",
			onRender: (file: ExternalShareFile) => {
				if (files?.some(x => x.Name === file.name && x.Path === file.folder && x.hasRunningAction)) {
					return <Spinner size={SpinnerSize.small} />;
				}

				const extension = file.name.substring(file.name.lastIndexOf('.'));
				const iconProps = getFileTypeIconProps({ extension: extension });
				return <Icon iconName={iconProps.iconName} />;
			}
		},
		{
			key: "FromTaskDrive",
			name: "",
			minWidth: 16,
			maxWidth: 16,
			data: "string",
			iconName: "Folder",
			onRender: (file: ExternalShareFile) => {
				if (file && file.drive && file.drive.type === "Task") {
					return <Icon iconName="Folder" className="ms-fontSize-16" />;
				}
				else {
					return <></>;
				}
			}
		},
		{
			key: "name",
			name: t("FileBase.File.Name"),
			fieldName: "name",
			minWidth: 100,
			maxWidth: 500,
			isRowHeader: false,
			isResizable: true,
			isSorted: shareFilesSortProperty === "name",
			isSortedDescending: shareFilesSortProperty === "name" ? !shareFilesSortPropertyDirectionASC : undefined,
			data: "string",
			isPadded: true,
			onColumnClick: () => {
				dispatch(sortSharesFiles("name", shareFilesSortProperty === "name" ? !shareFilesSortPropertyDirectionASC : true))
			},
			onRender: (file: ExternalShareFile) => {
				return <ColumnContextMenu menuItems={menuItems}>
					{file.name}
				</ColumnContextMenu>;
			},
		},
		{
			key: "downloads",
			name: t("FileBase.File.Downloads"),
			fieldName: "downloadCount",
			minWidth: 50,
			maxWidth: 150,
			isRowHeader: false,
			isResizable: true,
			isSorted: shareFilesSortProperty === "downloadCount",
			isSortedDescending: shareFilesSortProperty === "downloadCount" ? !shareFilesSortPropertyDirectionASC : undefined,
			data: "string",
			isPadded: true,
			onColumnClick: () => {
				dispatch(sortSharesFiles("downloadCount", shareFilesSortProperty === "downloadCount" ? !shareFilesSortPropertyDirectionASC : true))
			},
			onRender: (file: ExternalShareFile) => {
				return `${file.downloadCount} ${t("FileBase.File.Of")} ${file.downloadLimit}`;
			},
		},
		{
			key: "folder",
			name: t("FileBase.File.Folder"),
			fieldName: "folder",
			minWidth: 50,
			maxWidth: 200,
			isRowHeader: false,
			isResizable: true,
			isSorted: shareFilesSortProperty === "folder",
			isSortedDescending: shareFilesSortProperty === "folder" ? !shareFilesSortPropertyDirectionASC : undefined,
			data: "string",
			isPadded: true,
			onColumnClick: () => {
				dispatch(sortSharesFiles("folder", shareFilesSortProperty === "folder" ? !shareFilesSortPropertyDirectionASC : true))
			}
		}
	], [dispatch, files, menuItems, shareFilesSortProperty, shareFilesSortPropertyDirectionASC, t]);

	const selection: Selection = useMemo(() => new Selection({
		onSelectionChanged: () => {
			if (selection.count > 0) {
				let files = selection.getSelection() as ExternalShareFile[];
				dispatch(selectExternalShareFiles(files));
			} else {
				dispatch(selectExternalShareFiles([]));
			}
		}
	}), [dispatch]);

	const { groups, shareFiles } = useMemo(() => {
		let startIndex = 0;
		let groups: IGroup[] = [];
		let shareFiles: ExternalShareFile[] = [];

		shares.forEach(share => {
			groups.push({
				key: share.id,
				name: share.id,
				data: share,
				startIndex: startIndex,
				count: share.files.length
			});
			startIndex += share.files.length;
			shareFiles.push(...share.files);
		});

		return { groups, shareFiles };
	}, [shares]);

	const onClick = React.useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>, share: ExternalShare, isSelected: boolean) => {
		dispatch(isSelected ? deselectExternalShare(share.id) : selectExternalShare(share.id));
		return false;
	}, [dispatch]);

	const today = new Date(Date.now()).toISOString();

	const onRenderDetailsHeader: IDetailsGroupRenderProps['onRenderHeader'] = React.useCallback((props) => {
		if (props?.group?.data) {
			const share = props.group.data as ExternalShare;
			const isSelected = !!selectedShares?.some(x => x.id === share.id);
			const headerClass = css(styles.GroupHeader, isSelected ? styles.GroupHeader_Selected : undefined);

			return <MouseContextualMenu menuItems={menuItems} className={headerClass}
				onClick={(event) => onClick(event, share, isSelected)}
				onContextMenu={(event) => onClick(event, share, false)}
			>
				<div className={styles.GroupHeaderSelect}>
					<Check checked={isSelected} />
				</div>
				<div className={styles.GroupHeaderTitle}>
					{share?.validTo && share.validTo < today &&
						<TooltipHost content={t("FileBase.ExternalShareOverdueTooltip")}>
							<Icon iconName="Clock" className={styles.OverdueIcon} />
						</TooltipHost>
					}
					{share.id}
				</div>
				<div className={styles.GroupHeaderLink} onClick={(event) => event.stopPropagation()}>
					<CopyToClipboard text={downloadUrlBase + share.id} className="Hide-Overflow" iconVisibility="always">
						<Link target="_blank" href={downloadUrlBase + share.id} className={styles.PaddingRight}>{t("FileBase.ExternalShareDownloadLinkLabel")}</Link>
					</CopyToClipboard>
				</div>
				<div className={styles.GroupHeaderSubTitle}>
					{t("FileBase.ExternalShareSubTitle", { from: FormatDate(share.validFrom), to: FormatDate(share.validTo), user: share.createdBy })}
				</div>
			</MouseContextualMenu>;
		}
		return null;
	}, [downloadUrlBase, menuItems, onClick, selectedShares, styles.GroupHeader, styles.GroupHeaderLink, styles.GroupHeaderSelect, styles.GroupHeaderSubTitle, styles.GroupHeaderTitle, styles.GroupHeader_Selected, styles.OverdueIcon, styles.PaddingRight, t, today]);

	const renderRow = React.useCallback((props: IDetailsRowProps | undefined) => {
		if (!props) return null;

		return <MouseContextualMenu menuItems={menuItems}>
			<DetailsRow {...props} />
		</MouseContextualMenu>
	}, [menuItems]);

	return <>
		<ResetDownloadsCommand addCommandButton={addMenuItem} />
		<ExtendShareCommand addCommandButton={addMenuItem} />
		<ResetSharePasswordCommand addCommandButton={addMenuItem} />
		<StopShareCommand addCommandButton={addMenuItem} />

		<div className={css(styles.FileDetailList, globalStyles.Scrollbar)} >

			{status === "Loading" && <Loading hideLogo={true} />}

			{status === "LoadFailed" && loadError && <ReloadComponent hideLogo={true} errorMessage={loadError} reload={() => dispatch(loadExternalShares())} />}

			{status === "Loaded" &&
				<DetailsList
					className={styles.DetailsList}
					checkboxVisibility={CheckboxVisibility.onHover}
					items={shareFiles}
					groups={groups}
					groupProps={{
						onRenderHeader: onRenderDetailsHeader
					}}
					compact={false}
					columns={columns}
					selection={selection}
					selectionMode={SelectionMode.multiple}
					layoutMode={DetailsListLayoutMode.justified}
					isHeaderVisible={true}
					getKey={(x: ExternalShareFile) => x.id}
					onRenderRow={renderRow}
				/>
			}
		</div>
	</>;

}