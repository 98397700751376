import * as React from "react";
import i18n from "../i18n";
import { loadInvitation} from "../Api/Invitation/LoadInvitation";
import { useAppDispatch } from "../Store/useAppDispatch";
import { CommandBarCommandProps } from "../Utils/useCommands";

export const RefreshInvitationCommand = (props: CommandBarCommandProps): JSX.Element => {
	const dispatch = useAppDispatch();

		props.addCommandButton({
			key: "refreshInvitation",
			text: i18n.t("Commands.Refresh.Command"),
			iconProps: { iconName: "Refresh" },
			onClick: () => {
				dispatch(loadInvitation());
				return false;
			},
		});

	return <></>;
}