import { IStyle, makeStyles } from "@fluentui/react";

export default () => {
	let styles = makeStyles(theme => ({
		SearchBox: {
             height: "100%",
			 width: "250px"
		}
	}));
	return styles();
};