import * as React from "react";
import { Persona, PersonaSize } from "@fluentui/react";
import { useSelector } from "react-redux";
import { RootState, setContactMessage } from "../../Store";
import { StatusHeaderContact } from "../StatusHeader/StatusHeader";
import { HoverCard, HoverCardType, IPlainCardProps, IIconProps, TextField, IconButton, Spinner } from "@fluentui/react";
import { sendChannelMessage } from "../../Api/Teams/SendChannelMessage";
import useStyles from "./ContactPersona.style"
import { useCallback } from "react";
import { css } from "@fluentui/react";
import { useAppDispatch, useAppSelector } from "../../Store/useAppDispatch";

export interface ContactPersonaProps {
	contact: StatusHeaderContact;
}

const sendIcon: IIconProps = { iconName: "Send" };

export const ContactPersona = (props: ContactPersonaProps): JSX.Element => {
	const { message, isSending } = useAppSelector((state: RootState) => state.contacts.sendMessage);
	const dispatch = useAppDispatch();
	const styles = useStyles();

	const onRenderPersonaCard = useCallback((contact: StatusHeaderContact): JSX.Element => {
		return (
			<div className={styles.StatusHeaderPersonaHoverCard}>
				<div className={styles.Row}>
					<div className={styles.StatusHeaderPersonaHoverCardContext}>
						<div className={styles.HeadLine}>{contact.name}</div>
						<div className={styles.Details}>
							{contact.jobFunction}<br />
							<br />
							{contact.phone}<br />
							{contact.eMail}
						</div>
					</div>
					<Persona size={PersonaSize.size56}
						className={styles.FlexEnd}
						hidePersonaDetails={true}
						imageUrl={contact.imageUrl}
						imageInitials={contact.initials} />
				</div>
				{(contact.isPartOfTeam ?? false) && <>
					<hr />
					<div className={styles.Row}>
						{!isSending && <>
							<TextField
								className={styles.FlexFill}
								value={message}
								disabled={isSending}
								placeholder={"Nachricht an " + contact.isPartOfTeam ?? false ? contact.name : "Vorgang/Auftrag"}
								onChange={(x, newValue) => dispatch(setContactMessage(newValue || ""))}
								onKeyPress={(x) => { if (x.key === "Enter") { dispatch(sendChannelMessage(contact.eMail, contact.isPartOfTeam ?? false)); } }} />
							<IconButton
								iconProps={sendIcon}
								className={css(styles.FlexEnd, styles.SendIcon)}
								disabled={isSending}
								onClick={() => dispatch(sendChannelMessage(contact.eMail, contact.isPartOfTeam ?? false))} />
						</>}
						{isSending && <Spinner
							className={styles.FlexFill}
							label="Sending..."
							labelPosition="right" />}
					</div>
				</>}
			</div>
		);
	}, [dispatch, isSending, message, styles.Details, styles.FlexEnd, styles.FlexFill, styles.HeadLine, styles.Row, styles.SendIcon, styles.StatusHeaderPersonaHoverCard, styles.StatusHeaderPersonaHoverCardContext]);

	const plainCardProps: IPlainCardProps = {
		onRenderPlainCard: onRenderPersonaCard,
		renderData: props.contact
	};

	return (<HoverCard plainCardProps={plainCardProps}
		instantOpenOnClick={true}
		type={HoverCardType.plain}>
		<Persona size={PersonaSize.size48}
			text={props.contact.name}
			secondaryText={props.contact.jobFunction}
			imageUrl={props.contact.imageUrl}
			imageInitials={props.contact.initials} />
	</HoverCard>);
}