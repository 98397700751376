import { FileSystemObject } from "../../Model/FileSystemObject";
import { ApiUrlBuilder } from "../../ApiUrlBuilder";
import { getFirstDrive } from "../../Utils/GetFirstDrive";

export async function loadImage(file: FileSystemObject, preview: boolean, accessToken: string) : Promise<string> {
	if (!file || !accessToken) return "";

	const fileDrive = getFirstDrive(file.Drives);
	const validExtensions = [".png", ".jpg", ".jpeg", ".jpe", ".jfif", ".bmp", ".gif", ".tif", ".tiff"];

	const extension = file?.Name.substring(file?.Name.lastIndexOf('.'))?.toLowerCase();	
	if(!fileDrive || validExtensions.indexOf(extension) < 0) return "";

	const url = preview 
		? ApiUrlBuilder.GetFilePreview(fileDrive, file.Path, file.Name)
		: ApiUrlBuilder.GetFile(fileDrive, file.Path, file.Name);

	const response: Response = await fetch(`/api${url}`, {
		method: "GET",
		headers: {
			"Authorization": `Bearer ${accessToken}`
		}
	});

	if (response.status === 404) {
		return "";
	}

	if (!response.ok) {
		throw new Error(`API request failed with ${response.status}: ${response.statusText}`);
	}

	var fileContent: Blob = await response.blob();

	if (!fileContent) return "";

	var contentUrl: string = await new Promise((resolve, reject) => {
		const reader: FileReader = new FileReader();
		reader.onerror = () => reject();
		reader.onload = () => {
			if (reader.result && !(reader.result instanceof ArrayBuffer)) {
				resolve(reader.result);
			} else {
				reject();
			}
		};
		if (fileContent) {
			reader.readAsDataURL(fileContent);
		} else {
			reject();
		}
	});

	return contentUrl;

}