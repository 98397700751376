import { RootState, addFiles, setRunningAction, askOverwriteFile, setFileError, removeOverwriteFileRecall, addFolder, removeFiles } from "../../Store";
import { FileSystemObject } from "../../Model/FileSystemObject";
import { SpecialFolderIds, SpecialFolders } from "../../Model/SpecialFolder";
import { ODataResult } from "../../Model/ODataResult";
import { ODataErrorResult } from "../../Model/ODataErrorResult";
import { ApiUrlBuilder } from "../../ApiUrlBuilder";
import { batch } from "react-redux";
import { Semaphore } from "../../Logic/Semaphore";
import { generateId } from "../../Utils/GenerateId";
import i18n from "../../i18n";
import { areSameDrives } from "../../Utils/CompareDrive";

var semaphore = new Semaphore('Upload',5);

export function uploadDataTransfer(dataTransfer: globalThis.DataTransfer, folderId: string | undefined, overwrite: boolean = false) {
	return async (dispatch: React.Dispatch<any>, getState: () => RootState): Promise<void> => {
		if (!dataTransfer || !folderId) { return; }

		const { teams: { isInitialized, accessToken }, file: { location, ident, sourceType, folders } } = getState();
		if (!location || !ident || !sourceType || !isInitialized || !accessToken) return;

		const specialFolder = SpecialFolders.find(specialFolder => specialFolder.Id === folderId || folderId?.startsWith(`${specialFolder.Id}/`));
		if (specialFolder?.TargetFolderId) {
			var folder = folders?.find(x => x.Id === specialFolder.TargetFolderId)

			// replace first instance of specialFolder.Id with targetFolderId (if folders are uploaded, subpaths are possible)
			if (folder) {
				folderId = folderId.replace(specialFolder.Id, specialFolder.TargetFolderId);
			}
			else {
				folderId = folderId.replace(specialFolder.Id, "");
			}
		}

		for (let i = 0; i < dataTransfer.items.length; i++) {
			const item = dataTransfer.items[i];
			if (item.kind === "file") {

				if (typeof item.webkitGetAsEntry === "function") {
					const entry = item.webkitGetAsEntry();
					if(!entry) continue;

					readEntryContentAsync(entry, folderId, dispatch);
					continue;
				}

				const file = item.getAsFile();
				if (file) {
					dispatch(uploadSingleFile(file, folderId, false));
				}
			}
		}
	}
}

function readEntryContentAsync(entry: Entry, folderId: string, dispatch: React.Dispatch<any>) {
	if (isFile(entry)) {
		var fileEntry = entry as FileEntry;
		fileEntry.file((file: globalThis.File) => {
			dispatch(uploadSingleFile(file, folderId, false));
		});
	} else if (isDirectory(entry)) {
		var directoryEntry = entry as DirectoryEntry;
		var newPath = `${folderId}/${entry.name}`;
		dispatch(addFolder({
			Id: entry.name,
			Name: entry.name,
			Path: newPath,
			Drives: [],
			IsReadonly: true,
			Type: "Folder",
			Size: -1,
			Url: "",
			CreatedOn: "",
			CreatedBy: "",
			ModifiedOn: "",
			ModifiedBy: ""
		}));
		readReaderContent(directoryEntry.createReader(), newPath, dispatch);
	}
}

function readReaderContent(reader: DirectoryReader, folderId: string, dispatch: React.Dispatch<any>) {

	reader.readEntries((entries) => {
		for (const entry of entries) {
			readEntryContentAsync(entry, folderId, dispatch);
		}
	});
}

// for TypeScript typing (type guard function)
// https://www.typescriptlang.org/docs/handbook/advanced-types.html#user-defined-type-guards
function isDirectory(entry: Entry) { //}: entry is FileSystemDirectoryEntry {
	return entry.isDirectory;
}

function isFile(entry: Entry) { //: entry is FileSystemFileEntry {
	return entry?.isFile;
}



export function uploadFiles(newFiles: FileList | globalThis.File[] | null | undefined, folderId: string | undefined) {
	return async (dispatch: React.Dispatch<any>, getState: () => RootState): Promise<void> => {
		if (!newFiles || !folderId) { return; }

		const { teams: { isInitialized, accessToken }, file: { location, ident, sourceType, folders } } = getState();
		if (!location || !ident || !sourceType || !isInitialized || !accessToken) return;

		const specialFolder = SpecialFolders.find(specialFolder => specialFolder.Id === folderId || folderId?.startsWith(`${specialFolder.Id}/`));
		if (specialFolder?.TargetFolderId) {
			var folder = folders?.find(x => x.Id === specialFolder.TargetFolderId)

			// replace first instance of specialFolder.Id with targetFolderId (if folders are uploaded, subpaths are possible)
			if (folder) {
				folderId = folderId.replace(specialFolder.Id, specialFolder.TargetFolderId);
			}
			else {
				folderId = folderId.replace(specialFolder.Id, "");
			}
		}

		for (let index: number = 0; index < newFiles.length; index++) {
			const file: globalThis.File = newFiles[index];

			dispatch(uploadSingleFile(file, folderId, false));
		}
	}
}

const addFile = async (url: string, file: globalThis.File, fileName: string, includeContent: boolean, accessToken: string): Promise<FileSystemObject | SendFileError> => {
	let body;
	if (includeContent) {
		const formData: FormData = new FormData();
		formData.append("file", file, fileName);
		body = formData;
	}
	else {
		body = "";
	}

	var response: Response = await fetch(`/api${url}`, {
		method: "POST",
		headers: {
			"Authorization": `Bearer ${accessToken}`
		},
		body: body
	});

	if (!response.ok) {

		if (response.status === 400) {
			const errorResult: ODataErrorResult = await response.json();
			return new SendFileError(response.status, errorResult?.value);
		}

		return new SendFileError(response.status, response.statusText);
	}
	const newFilesResult: ODataResult<FileSystemObject> = await response.json();
	return newFilesResult.value[0];
}

const sendChunk = async (url: string, file: Blob, accessToken: string, isFinalChunk: boolean): Promise<PartialUpload | FileSystemObject | SendFileError> => {

	const formData: FormData = new FormData();
	formData.append("content", file, "content");

	var response: Response = await fetch(`/api${url}`, {
		method: "POST",
		headers: {
			"Authorization": `Bearer ${accessToken}`
		},
		body: formData
	});

	if (!response.ok) {
		return new SendFileError(response.status, response.statusText);
	}

	if (isFinalChunk) {
		const newFilesResult: ODataResult<FileSystemObject> = await response.json();
		return newFilesResult.value[0];
	}

	const newFilesResult: PartialUpload = await response.json();
	return newFilesResult;
}

class SendFileError {
	public status: number = 0;
	public statusText: string = "";

	constructor(status: number, statusText: string) {
		this.status = status;
		this.statusText = statusText;
	}
}

interface PartialUpload {
	SessionId: string;
	BytesUploaded: number;
}

function uploadSingleFile(file: globalThis.File, folderId: string, overwrite: boolean, newName?: string) {
	return async (dispatch: React.Dispatch<any>, getState: () => RootState): Promise<void> => {
		if (!file || !folderId) { return; }

		const { teams: { isInitialized, accessToken }, file: { location, ident, sourceType, files } } = getState();

		if (!location || !ident || !sourceType || !isInitialized || !accessToken) return;

		const fileName = newName ?? file.name;

		if (!overwrite && files?.some(f => f.Name === fileName && f.Path === folderId && areSameDrives(f.Drives, [{ Ident: ident, Location: location, Type: sourceType }]))) {
			dispatch(askOverwriteFile(fileName, folderId,
				(overwrite: boolean, newName?: string) => batch(() => { // accept
					dispatch(removeOverwriteFileRecall(fileName, folderId));
					dispatch(uploadSingleFile(file, folderId, overwrite, newName))
				}),
				// reject
				() => dispatch(removeOverwriteFileRecall(fileName, folderId))
			));
			return;
		}

		const customerExchangeFolder = SpecialFolders.find(specialFolder => specialFolder.Id === SpecialFolderIds.CustomerExchange);
		const isNewFileSharedWithCustomer = !!customerExchangeFolder?.TargetFolderId && (customerExchangeFolder?.TargetFolderId === folderId || folderId.startsWith(customerExchangeFolder?.TargetFolderId))

		if (!folderId) {
			return;
		}

		const url = ApiUrlBuilder.AddFile(location, ident, sourceType, folderId, fileName, overwrite);

		const newFile: FileSystemObject = {
			Id: fileName,
			Name: fileName,
			Path: folderId,
			Type: "File",
			Size: file.size,
			hasRunningAction: true,
			processedSize: 0,
			CreatedOn: "",
			CreatedBy: "",
			ModifiedOn: "",
			ModifiedBy: "",
			Url: "",
			SharedWithCustomer: isNewFileSharedWithCustomer,
			Drives: [{ Location: location, Ident: ident, Type: sourceType, Number: "" }]
		};

		const runningActionId = generateId();
		batch(() => {
			dispatch(addFiles([newFile]));
			dispatch(setRunningAction({ id: runningActionId, message: i18n.t("Actions.Uploading", { file: newFile.Name }) }, newFile.Name, folderId));
		});

		const lock = await semaphore.acquire();
		try {
			let newFileResponse: FileSystemObject | SendFileError;
			// chunk size of about 10 parts
			let chunkSize = Math.round(newFile.Size / 10);

			// min 100 KB
			if (chunkSize < 1024 * 100) {
				chunkSize = 1024 * 100;
			}
			else if (chunkSize > 1024 * 1024 * 10) {
				// max 10 MB
				chunkSize = 1024 * 1024 * 10;
			}

			if (file.size <= chunkSize) {
				newFileResponse = await addFile(url, file, newFile.Name, true, accessToken);
			} else {
				newFileResponse = await addFile(url, file, newFile.Name, false, accessToken);
				if (!(newFileResponse instanceof SendFileError)) {
					const chunkUrl = ApiUrlBuilder.UploadFile(location, ident, sourceType, folderId, newFile.Name);

					let chunkNumber = 0;
					let sessionId = "";
					let retryCounter = 0;
					for (let i = 0; i < file.size; i += chunkSize) {
						const isFinalChunk = file.size <= i + chunkSize;
						const length = !isFinalChunk ? chunkSize : file.size - i;
						const fileChunk = file.slice(i, i + length);
						const chunkMethod = chunkNumber === 0 ? "/startupload" :
							isFinalChunk ? `/finishupload(sessionId='${sessionId}',fileOffset=${i})` :
								`/continueupload(sessionId='${sessionId}',fileOffset=${i})`;

						if (chunkNumber > 0 && !sessionId) {
							dispatch(setFileError(newFile.Id, newFile.Path, 500, "Session ID is missing. Upload failed."));
							break;
						}

						retryCounter = 0;
						let result: FileSystemObject | PartialUpload | SendFileError | undefined = undefined;
						while (retryCounter < 5) {
							result = await sendChunk(chunkUrl + chunkMethod, fileChunk, accessToken, isFinalChunk);

							if (result instanceof SendFileError) // If error, retry
							{
								retryCounter++;
								continue;
							}

							break;
						}


						if (result instanceof SendFileError) {
							dispatch(setFileError(newFile.Id, newFile.Path, result.status, result.statusText));
							return;
						} else if (!isFinalChunk) {
							sessionId = (result as PartialUpload).SessionId;
						} else {
							newFileResponse = result as FileSystemObject;
						}

						newFile.processedSize = chunkSize + (newFile.processedSize ?? 0);
						dispatch(addFiles([{...newFile}]));
						chunkNumber++;
					}
				}
			}

			if (newFileResponse instanceof SendFileError) {

				if (newFileResponse.status === 400 && newFileResponse.statusText === "File already exists") {
					dispatch(askOverwriteFile(newFile.Name, folderId,
						(overwrite: boolean, newName?: string) => batch(() => { // accept
							dispatch(removeOverwriteFileRecall(fileName, folderId));
							dispatch(uploadSingleFile(file, folderId, overwrite, newName))
						}),
						() => batch(() => {	// reject
							dispatch(removeOverwriteFileRecall(newFile.Name, folderId));
							dispatch(setRunningAction({ id: runningActionId, message: undefined }, newFile.Id, newFile.Path));
						})
					));
					return;
				}

				dispatch(setFileError(newFile.Id, newFile.Path, newFileResponse.status, newFileResponse.statusText));
				return;
			}

			newFileResponse.Drives = [{ Location: location, Ident: ident, Type: sourceType, Number: "" }];
			newFileResponse.hasRunningAction = false;

			dispatch(removeFiles([newFile]));
			dispatch(addFiles([newFileResponse]));
		}
		finally {
			dispatch(setRunningAction({ id: runningActionId, message: undefined }, newFile.Name, folderId));
			lock.release();
		}
	}
}