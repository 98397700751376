import * as React from "react";
import { renameFile } from "../Api/File/RenameFile";
import { SingleLineTextfieldDialog } from "../Components/SingleLineTextfieldDialog/SingleLineTextfieldDialog";
import { useTranslation } from "react-i18next";
import { splitFilename } from "../Utils/SplitFilename";
import { useMemo, useState } from "react";
import { getFirstDrive } from "../Utils/GetFirstDrive";
import { useAppDispatch, useAppSelector } from "../Store/useAppDispatch";
import { CommandBarCommandProps } from "../Utils/useCommands";
import { DialogType } from "@fluentui/react";

export const RenameFileCommand = (props: CommandBarCommandProps): JSX.Element => {
	const [showDialog, setShowDialog] = useState(false);
	const [newFilename, setNewFilename] = useState("");
	const [suffix, setSuffix] = useState<string | undefined>();
	const { selectedFiles, selectedFolders, isReadonlyFolder: isReadOnlyFolder } = useAppSelector(state => state.file);
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const showCommand = useMemo(() =>
	!isReadOnlyFolder && (!selectedFolders || selectedFolders.length === 0) && selectedFiles && selectedFiles.length === 1
		, [isReadOnlyFolder, selectedFiles, selectedFolders]);

	const isDisabled = useMemo(() =>
		selectedFiles?.some(file => file.hasRunningAction)
		, [selectedFiles]);

	if (showCommand) {
		props.addCommandButton({
			key: "renameFile",
			text: t("Commands.RenameFile.Command"),
			iconProps: { iconName: "Rename" },
			disabled: isDisabled,
			title: isDisabled ? t("Commands.IsBusy") : undefined,
			onClick: () => {
				if (!selectedFiles) {
					return;
				}
				const { name, suffix: newSuffix } = splitFilename(selectedFiles[0].Name)
				setNewFilename(name ?? "");
				setSuffix(newSuffix);

				setShowDialog(true); return false;
			},
		});
	}

	const dialogContentProps = {
		type: DialogType.largeHeader,
		title: t("Commands.RenameFile.DialogTitle"),
		closeButtonAriaLabel: 'Close'
	};

	const onCommand = React.useCallback(() => {
		const file = selectedFiles && selectedFiles.length > 0 ? selectedFiles[0] : undefined;
		if (!file) return;

		dispatch(renameFile(file, file.Path, `${newFilename}.${suffix}`, getFirstDrive(file.Drives)));
		setShowDialog(false);
	}, [selectedFiles, dispatch, newFilename, suffix]);

	return <>
		{showDialog &&
			<SingleLineTextfieldDialog
				dialogContentProps={dialogContentProps}
				onCommand={onCommand}
				onDismiss={() => setShowDialog(false)}
				commandIsDisabled={!newFilename}
				hidden={false}
				label={t("Commands.RenameFile.NewFileName")}
				value={newFilename}
				suffix={suffix}
				onChange={(newValue) => setNewFilename(newValue)}
				okLabel={t("Commands.RenameFile.Ok")}
			/>
		}
	</>;
}