import { OrderState } from "./State";
import { OrderActionTypes, OrderActions } from "./Actions";

const initialState: OrderState = {
	order: undefined,
	orderStatusPercent: 0,
	isLoading: false,
	location: undefined,
	orderIdent: undefined
};

export function orderReducer(
	state: OrderState = initialState,
	action: OrderActionTypes
): OrderState {
	switch (action.type) {

		case OrderActions.Request:
			return {
				...state,
				isLoading: true,
				location: action.location,
				orderIdent: action.OrderIdent,
				orderLoadError: undefined
			};

		case OrderActions.Received:
			return {
				...state,
				isLoading: false,
				order: action.Order,
				orderStatusPercent: action.OrderStatusPercent,
				orderLoadError: undefined
			};

		case OrderActions.ReceiveFailed:
			return {
				...state,
				isLoading: false,
				order: undefined,
				orderLoadError: action.message
			};

		default:
			return state;
	}
}