import * as React from "react";
import { Nav, INavLinkGroup, INavLink, INavButtonProps, ActionButton } from "@fluentui/react";
import { SpecialFolderIds, SpecialFolders } from "../../Model/SpecialFolder";
import i18n from "../../i18n";
import { useSelector, batch } from "react-redux";
import { RootState, selectFolder, setDragDropFiles, setDragDropFolders, selectFiles, selectFolders } from "../../Store";
import { renameFile } from "../../Api/File/RenameFile";
import { uploadDataTransfer } from "../../Api/File/UploadFiles";
import { moveFolder } from "../../Api/File/MoveFolder";
import useStyles from "./FolderList.style"
import { useGlobalStyles } from "../../Global.style"
import { css, Icon } from "@fluentui/react";
import { getFirstDrive } from "../../Utils/GetFirstDrive";
import { useAppDispatch, useAppSelector } from "../../Store/useAppDispatch";

export interface FolderListProps {
}

export const FolderList = (props: FolderListProps) => {
	const dispatch = useAppDispatch();
	const styles = useStyles();
	const globalStyles = useGlobalStyles();
	const { folders, selectedFolderId, dragDropFiles, dragDropFolders, filesCount, customerFilesCount, sharedByLinkFilesCount } = useAppSelector(state => state.file);
	const { isInternalUser } = useAppSelector(state => state.teams);
	const [dragOverFolderId, setDragOverFolderId] = React.useState<string | undefined>(undefined);

	const navLinks: INavLink[] = []

	const folderAll = SpecialFolders.find(specialFolder => specialFolder.Id === SpecialFolderIds.All);
	navLinks.push({
		key: SpecialFolderIds.All,
		name: i18n.t("FileBase.Folders.All"),
		url: "#",
		icon: selectedFolderId === undefined || selectedFolderId === SpecialFolderIds.All ? folderAll?.IconOpen : folderAll?.Icon,
		NumFiles: filesCount
	});

	if (isInternalUser) {
		const folderCustomer = SpecialFolders.find(specialFolder => specialFolder.Id === SpecialFolderIds.CustomerExchange);
		navLinks.push({
			key: SpecialFolderIds.CustomerExchange,
			name: i18n.t("FileBase.Folders.CustomerExchange"),
			url: "#",
			icon: selectedFolderId === SpecialFolderIds.CustomerExchange ? folderCustomer?.IconOpen : folderCustomer?.Icon,
			NumFiles: customerFilesCount
		});

		const folderSharedByLink = SpecialFolders.find(specialFolder => specialFolder.Id === SpecialFolderIds.SharedByLink);
		navLinks.push({
			key: SpecialFolderIds.SharedByLink,
			name: i18n.t("FileBase.Folders.SharedByLink"),
			url: "#",
			icon: selectedFolderId === SpecialFolderIds.SharedByLink ? folderSharedByLink?.IconOpen : folderSharedByLink?.Icon,
			NumFiles: sharedByLinkFilesCount
		});
	}

	const navGroups: INavLinkGroup[] = [{
		name: "Views",
		links: navLinks
	}];

	navGroups.push({
		name: "Folders",
		links: (folders || []).filter(x => x.Path === x.Id).map((folder) => ({
			key: folder.Id,
			name: folder.Name,
			url: "#",
			icon: selectedFolderId === folder.Id ? "FabricOpenFolderHorizontal" : "FabricFolder",
			NumFiles: folder.filesCount,
			IsReadonly: folder.IsReadonly
		}))
	});

	const initialSelectedKey = selectedFolderId || SpecialFolderIds.All;

	const onDragOver = (event: React.DragEvent<any>) => {
		event.stopPropagation();
		event.preventDefault();
		event.dataTransfer.dropEffect = "move";
		return false;
	}

	const onDragEnter = (event: React.DragEvent<any>, folderId: string | undefined) => {
		event.stopPropagation();
		event.preventDefault();
		if (folderId !== dragOverFolderId) setDragOverFolderId(folderId);
		return false;
	}

	const onDragLeave = (event: React.DragEvent<any>, folderId: string | undefined) => {
		event.stopPropagation();
		event.preventDefault();
		if (folderId === dragOverFolderId) setDragOverFolderId(undefined);
		return false;
	}

	const onDrop = (event: React.DragEvent<any>, folderId: string | undefined) => {
		if (dragDropFiles && folderId) {
			batch(() => {
				dragDropFiles.forEach(file => dispatch(renameFile(file, folderId, file.Name, getFirstDrive(file.Drives))));
			});
		}

		if (dragDropFolders && folderId) {
			batch(() => {
				dragDropFolders.forEach(folder => dispatch(moveFolder(folder, `${folderId}/${folder.Name}`)));
			});
		}

		if (folderId && event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files.length > 0) {
			const dataTransfer = event.dataTransfer;
			dispatch(uploadDataTransfer(dataTransfer, folderId, false));
		}
		batch(() => {
			dispatch(selectFiles([]));
			dispatch(selectFolders([]));
			dispatch(setDragDropFiles([]));
			dispatch(setDragDropFolders([]));
		});

		event.stopPropagation();
		event.preventDefault();
		setDragOverFolderId(undefined);
		return false;
	}

	const renderFolder = (navProps: INavButtonProps) => {

		const folderId = navProps.link?.key;
		const name = navProps.link?.name;
		const numFiles: Number = navProps.link?.NumFiles;
		const isReadonly: boolean = navProps.link?.IsReadonly ?? false;
		const icon = navProps.link?.icon;
		const isDragOverFolder = dragOverFolderId === folderId;

		return <ActionButton
			className={navProps.className + (isDragOverFolder ? ` ${styles.DropFolder}` : "")}
			iconProps={{ iconName: isDragOverFolder ? "FabricMovetoFolder" : icon }}
			onDragOver={onDragOver}
			onDragEnter={(e) => onDragEnter(e, folderId)}
			onDragLeave={(e) => onDragLeave(e, folderId)}
			onDrop={(e) => onDrop(e, folderId)}
			onClick={navProps.onClick}
		>
			<div className={styles.FolderItem}>
				<span className={styles.FolderName}>
					{name}
				</span>
				<span className={styles.Flex}>
					{isReadonly && <Icon className="ReadonlyIcon" iconName="Uneditable" />}
					{numFiles > 0 && <div className={styles.FilesCount}>{numFiles.toLocaleString()}</div>}
				</span>
			</div>
		</ActionButton>
	}

	return <Nav
		className={css(styles.FolderList, globalStyles.Scrollbar)}
		groups={navGroups}
		initialSelectedKey={initialSelectedKey}
		linkAs={renderFolder}
		onLinkClick={(e, link) => dispatch(selectFolder(link?.key))}
	/>;
}