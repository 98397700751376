import * as React from "react";
import { batch } from "react-redux";
import { setGlobalLanguage } from "../../Utils/i18nHelper";
import { selectFolder, selectSubFolderPath } from "../File/Actions";
import { TeamsApp } from "../../Model/TeamsApp";

export enum TeamsActions {
	Initialize = "Teams/Initialize",
	ChangeTheme = "Teams/ChangeTheme"
}

interface InitializeTeamsAction {
	type: typeof TeamsActions.Initialize;
	teamName: string;
	isInternalUser: boolean;
	language: string;
	accessToken: string;
	entityId?: string;
	appId?: string;
	channelId?: string;
}

export function initializeTeams() {
	return async (dispatch: React.Dispatch<any>) => {

		microsoftTeams.initialize();

		const context: microsoftTeams.Context = await getContext();

		setGlobalLanguage(context.locale);
		const accessToken: string = await getToken();

		const appId = await getTeamsAppId(accessToken);
		console.log("AppId",appId);
			
		batch(() => {
			dispatch({
				type: TeamsActions.Initialize,
				teamName: context.teamName || "",
				isInternalUser: context.userTeamRole === 0 || context.userTeamRole === 1,
				language: context.locale,
				accessToken,
				entityId: context.entityId,
				appId: appId,
				channelId: context.channelId
			} as InitializeTeamsAction);
			if (context.subEntityId) {
				const firstSlash = context.subEntityId.indexOf("/");
				if (firstSlash > 0) {
					const mainFolder = context.subEntityId.substring(0, firstSlash);
					dispatch(selectFolder(mainFolder));
					dispatch(selectSubFolderPath(context.subEntityId));
				} else {
					dispatch(selectFolder(context.subEntityId));
				}
			}
		});
	};
}

export type TeamsActionTypes = InitializeTeamsAction;

async function getContext(): Promise<microsoftTeams.Context> {
	return new Promise((resolve, reject) => {
		microsoftTeams.getContext((context) => resolve(context));
	});
}

async function getToken(): Promise<string> {
	return new Promise((resolve, reject) => {
		microsoftTeams.authentication.getAuthToken({
			resources: [],
			failureCallback: (reason) => reject(reason),
			successCallback: (token) => resolve(token)
		});
	});
}

const getTeamsAppId = async (accessToken: string) => {
	const response: Response = await fetch(`/api/teams/app`, {
		method: "GET",
		headers: {
			"Authorization": `Bearer ${accessToken}`,
			"Content-Type": "application/json"
		}
	});

	if (!response.ok) {
		return null;
	}
	
	const result = (await response.json()) as TeamsApp;
	return result?.id;
}