import * as React from "react";
import {Spinner, SpinnerSize} from "@fluentui/react";
import logo from "../logo.svg";
import './Loading.scss';

export interface LoadingProps {
	hideLogo?: boolean;
} 

export const Loading = (props: React.PropsWithChildren<LoadingProps>): JSX.Element => {	
	return (
		<div className="Loading" >
			{props.hideLogo !== true && <img src={logo} className="Loading-logo" alt="logo" />}				
			<Spinner size={SpinnerSize.large} label="Loading..." labelPosition="bottom" />
			{props.children}
		</div>
	);
}