import { CheckListActions } from "./CheckListActions";

export interface SaveFailedCheckListAction {
	type: typeof CheckListActions.SaveFailedCheckList;
	location: string;
	ident: number;
}
export const saveFailedCheckList = (location: string, ident: number): SaveFailedCheckListAction => ({
	type: CheckListActions.SaveFailedCheckList,
	location,
	ident
});