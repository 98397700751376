import * as React from "react";
import { DialogType, DialogFooter, Toggle, DefaultButton, PrimaryButton, Text, Stack } from "@fluentui/react";
import { useShareContentByLink, useShareContentWithCustomer } from "../Api/File/ShareContent";
import { FileSystemObject } from "../Model/FileSystemObject";
import { CopyToClipboard } from "../Components/CopyToClipboard/CopyToClipboard";
import { FolderWithFilesFoldable } from "../Components/FolderWithFilesFoldable";
import { Label, Separator, Icon, Shimmer, Dropdown, css } from "@fluentui/react";
import { getFileTypeIconProps } from "@fluentui/react-file-type-icons";
import useStyles from "./ShareCommand.style"
import { ExternalFileShare } from "../Model/ExternalFileShare";
import { useCallback, useMemo, useState } from "react";
import { CustomDialog } from "../Components/CustomDialog/CustomDialog"
import { areSameDrives } from "../Utils/CompareDrive";
import { useTranslation } from "react-i18next";
import { getFileSystemObjectKeyString } from "../Utils/GetKeyString";
import { useAppSelector } from "../Store/useAppDispatch";
import { CommandBarCommandProps } from "../Utils/useCommands";

const defaultSelectedShareDurationOptionKey = 7;

export const ShareCommand = (props: CommandBarCommandProps): JSX.Element => {
	const styles = useStyles();
	const { t } = useTranslation();
	const [showDialog, setShowDialog] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [shareDurationInDays, setShareDurationInDays] = useState(defaultSelectedShareDurationOptionKey);
	const [downloadUrl, setDownloadUrl] = useState("");
	const [password, setPassword] = useState("");
	const [currentFiles, setCurrentFiles] = useState<FileSystemObject[]>([]);
	const [currentFolders, setCurrentFolders] = useState<FileSystemObject[]>([]);

	const { selectedFiles, selectedFolders, files, selectedFolderSubFolders, drives, isReadonlyFolder: isReadOnlyFolder } = useAppSelector(state => state.file);
	const shareByLink = useShareContentByLink();
	const shareWithCustomer = useShareContentWithCustomer();

	const sharableFiles = useMemo(() => selectedFiles?.filter((file) => file.CanShareFile) || [], [selectedFiles]);
	const shareableFolders = useMemo(() => selectedFolders?.filter(folder => folder.CanShareFile) || [], [selectedFolders]);

	const isDisabled = useMemo(() =>
		selectedFiles?.some(file => file.hasRunningAction) || selectedFolders?.some(folder => folder.hasRunningAction)
		, [selectedFiles, selectedFolders]);

	if (!isReadOnlyFolder && (sharableFiles.length > 0 || shareableFolders.length > 0)) {
		props.addCommandButton({
			key: "share",
			text: t("Commands.Share.Command"),
			iconProps: { iconName: "Share" },
			disabled: isDisabled,
			title: isDisabled ? t("Commands.IsBusy") : undefined,
			onClick: () => {
				setCurrentFiles(sharableFiles);
				setCurrentFolders(shareableFolders)
				setDownloadUrl("");
				setPassword("");
				setIsLoading(false);
				setShowDialog(true);
				return false;
			},
		});
	}

	const shareFiles = useMemo(() => {
		return files?.filter(x => currentFiles.some(y =>
			x.Id === y.Id
			&& x.Path === y.Path
			&& areSameDrives(x.Drives, y.Drives)));
	}, [files, currentFiles]) || [];

	const shareFolders = useMemo(() => {
		return selectedFolderSubFolders?.filter(x => currentFolders.some(y =>
			x.Id === y.Id
			&& x.Name === y.Name
			&& x.Path === y.Path));
	}, [selectedFolderSubFolders, currentFolders]) || [];

	const shareFolderFiles = useMemo(() =>
		files?.filter((file) => drives.some(drive => areSameDrives(file.Drives, [drive]) && drive.isSelected) && shareFolders.some((folder) => file.Path.startsWith(folder.Path)) && file.CanShareFile) ?? []
		, [drives, files, shareFolders]);

	const isShared = useMemo(() => [...shareFiles, ...shareFolderFiles].every(x => !!x.SharedWithCustomer), [shareFiles, shareFolderFiles]);
	const hasRunningAction = useMemo(() => [...shareFiles, ...shareFolderFiles].every(x => !!x.hasRunningAction), [shareFiles, shareFolderFiles]);

	const dialogContentProps = {
		type: DialogType.largeHeader,
		title: t("Commands.Share.DialogTitle" + ((currentFiles.length + shareFolderFiles.length) > 1 ? "Multi" : "")),
		closeButtonAriaLabel: 'Close'
	};

	const onShareCommand = useCallback(async (sharedWithCustomer: boolean) => {
		const filesToChangeShare = [...shareFiles, ...shareFolderFiles].filter(file => file.SharedWithCustomer !== sharedWithCustomer);
		shareWithCustomer(filesToChangeShare, shareFiles, shareFolders, sharedWithCustomer);
	}, [shareFiles, shareFolderFiles, shareFolders, shareWithCustomer]);

	const onGenerateLink = useCallback(() => {
		if (shareFiles.length === 0 && shareFolders.length === 0) return;

		setIsLoading(true);
		shareByLink([...shareFiles, ...shareFolderFiles], shareFiles, shareFolders, shareDurationInDays).then((x: ExternalFileShare | undefined) => {
			setDownloadUrl(x?.Link || "");
			setPassword(x?.Password || "");
			setIsLoading(false);
		});
	}, [shareFiles, shareFolders, shareByLink, shareFolderFiles, shareDurationInDays]);

	const onCloseDialog = useCallback(() => {
		setDownloadUrl("");
		setPassword("");
		setShowDialog(false);
	}, [setShowDialog, setDownloadUrl, setPassword]);

	return <>
		{showDialog && <CustomDialog hidden={false} dialogContentProps={dialogContentProps} onDismiss={() => onCloseDialog()} >
			<Stack>
				<div className={styles.ShareDocumentList}>
					{shareFolders.map(folder => <FolderWithFilesFoldable key={`FolderWithFilesFoldable_${folder.Path}_${folder.Id}`} folder={folder} onlyShareableFiles />)}
					{shareFiles.map(file => {
						const extension = file.Name.substring(file.Name.lastIndexOf('.'));
						const iconProps = getFileTypeIconProps({ extension: extension });
						return <CopyToClipboard key={`FolderWithFilesFoldable_${getFileSystemObjectKeyString(file)}`} text={file.Name}>
							<Icon iconName={iconProps.iconName} className={styles.Icon} /> {file.Name}
						</CopyToClipboard>
					})}
				</div>

				<Separator />

				<Toggle
					label={t("Commands.Share.ShareWithCustomer")}
					onText={t("Commands.Share.ShareWithCustomerYes")}
					offText={t("Commands.Share.ShareWithCustomerNo")}
					checked={!!isShared}
					disabled={hasRunningAction}
					onChange={(e, checked) => { onShareCommand(!!checked); }} />

				<Separator />

				<Label>{t("Commands.Share.ShareByLink")}</Label>

				<div className={styles.ShareDurationLabel}>{t("Commands.Share.ShareDurationLabel")}</div>
				<Dropdown
					className={styles.ShareDurationDropdown}
					options={[
						{ key: 1, text: `1 ${t("Times.Day")}` },
						{ key: 2, text: `2 ${t("Times.DayMulti")}` },
						{ key: 7, text: `7 ${t("Times.DayMulti")}` },
					]}
					defaultSelectedKey={shareDurationInDays}
					disabled={isLoading} // || (downloadUrl != null && downloadUrl !== "")
					onChange={(event, option) => setShareDurationInDays(option?.key as number)}
				/>

				{!downloadUrl && <PrimaryButton disabled={isLoading} onClick={() => onGenerateLink()} text={t("Commands.Share.GenerateLink")} iconProps={{ iconName: "Link" }} />}
				{downloadUrl && <DefaultButton disabled={isLoading} onClick={() => onGenerateLink()} text={t("Commands.Share.GenerateLink")} iconProps={{ iconName: "Link" }} />}
				{(downloadUrl || isLoading) && <>
					<Separator />
					<Label>{t("Commands.Share.DownloadUrlLabel")}</Label>
					{isLoading && <Shimmer />}
					{!isLoading && <CopyToClipboard text={downloadUrl} className={css(styles.Link, "Hide-Overflow")}>
						<a target="_blank" rel="noopener noreferrer" href={downloadUrl}>{downloadUrl}</a>
					</CopyToClipboard>
					}
					<Label>{t("Commands.Share.PasswordLabel")}</Label>
					{isLoading && <Shimmer />}
					{!isLoading && <CopyToClipboard text={password} className="Hide-Overflow">
						<Text nowrap block>{password}</Text>
					</CopyToClipboard>}
				</>}
			</Stack>

			<DialogFooter>
				<DefaultButton onClick={() => onCloseDialog()} text={t("Commands.Share.Close")} />
			</DialogFooter>
		</CustomDialog>}
	</>;
}