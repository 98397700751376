import { CheckListActions } from "./CheckListActions";

export interface SetNewCheckListValueAction {
	type: typeof CheckListActions.SetNewCheckListValue;
	location: string;
	ident: number;
	newValue: string;
}
export const setNewCheckListValue = (location: string, ident: number, newValue: string): SetNewCheckListValueAction => ({
	type: CheckListActions.SetNewCheckListValue,
	location,
	ident,
	newValue
});