import React from "react";

interface IQueryParameter {
	key: string;
	value: string;
}

export class Debug extends React.Component {

	render() {

		let queryParts: IQueryParameter[]|null = null;

		if(window.location.search) {
			queryParts = window.location.search.substring(1).split("&").map(x => {
				const parts: string[] = x.split("=");
				return {
					key: parts[0],
					value: parts[1]
				} as IQueryParameter;
			});
		}

		return <>
			<ul>
				<li><span>hash:</span> {window.location.hash}</li>
				<li><span>host:</span> {window.location.host}</li>
				<li><span>hostname:</span> {window.location.hostname}</li>
				<li><span>href:</span> {window.location.href}</li>
				<li><span>origin:</span> {window.location.origin}</li>
				<li><span>pathname:</span> {window.location.pathname}</li>
				<li><span>port:</span> {window.location.port}</li>
				<li><span>protocol:</span> {window.location.protocol}</li>
				<li><span>search:</span> {window.location.search}</li>
				{queryParts && <li>
						<span>Parameters:</span>
						<ul>
							{queryParts.map((x) => <li><span>{x.key}:</span> {x.value}</li>)}
						</ul>
					</li>}
			</ul>
		</>;
	}
}