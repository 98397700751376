import * as React from "react";
import { RootState, savingCheckList, setGlobalError, saveFailedCheckList, savedCheckList } from "../../Store";
import { batch } from "react-redux";
import { CheckListValue } from "../../Model/CheckList";

export function saveCheckList(valueLocation: string, valueIdent: number) {
	return async (dispatch: React.Dispatch<any>, getState: () => RootState): Promise<void> => {
		try {
			const { teams: { accessToken }, checklist: { ident, location, driveType, checkListValues } } = getState();

			const checkListEntry = checkListValues.find(x => x.Location === valueLocation && x.Ident === valueIdent);
			if (!checkListEntry) { 
				return;
			}

			dispatch(savingCheckList(valueLocation, valueIdent));

			const requestBody : CheckListValue = {
				ValueInt: undefined,
				ValueText: undefined,
				ValueFloat: undefined,
				ValueDate: undefined,
				Line: checkListEntry.Line
			};

			switch(checkListEntry.Type?.toLowerCase()) {
				case "option":
				case "int":
					requestBody.ValueInt = Number(checkListEntry.newValue);
					break;
				case "float":
					requestBody.ValueFloat = Number(checkListEntry.newValue);
					break;
				case "date":
				case "datetime":
				case "time":
					requestBody.ValueDate = checkListEntry.newValue;
					break;
				default:
					requestBody.ValueText = checkListEntry.newValue;
					break;
			}

			const url: string = `/checklists(ident=${ident},location='${location}',type='${driveType}')/values(ident=${valueIdent},location='${valueLocation}')`;

			const response: Response = await fetch(`${window.location.origin}/api${url}`, {
				method: "PATCH",
				headers: {
					"Authorization": `Bearer ${accessToken}`,
					"Content-Type": "application/json"
				},
				body: JSON.stringify(requestBody)
			});

			if (!response.ok) {
				batch(() => {
					dispatch(setGlobalError(`Saving check list value failed with ${response.status}: ${response.statusText}`));
					dispatch(saveFailedCheckList(valueLocation, valueIdent));
				});
				return;
			}

			const newCheckListEntry: CheckListValue = await response.json();

			dispatch(savedCheckList(valueLocation, valueIdent, checkListEntry.newValue, newCheckListEntry.UpdatedBy, newCheckListEntry.DateUpdated));

		} catch (error:any) {
			batch(() => {
				dispatch(setGlobalError(error.message));
				dispatch(saveFailedCheckList(valueLocation, valueIdent));
			});
		}
	};
}