import * as React from "react";
import { IContextualMenuProps, DirectionalHint, IContextualMenuItem, IconButton, mergeStyles } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import useStyles from "./ColumnContextMenu.style"

export interface ColumnContextMenuProps {
	menuItems: IContextualMenuItem[];
	hideMenuButton?: boolean;
	className?: string;
}

export const ColumnContextMenu = (props: React.PropsWithChildren<ColumnContextMenuProps>) => {
	const { t } = useTranslation();
	const styles = useStyles();

	const menuProps: IContextualMenuProps = React.useMemo(
		() => {
			if (props.menuItems.length === 0) {
				// If there is no command available, the icon is still active but nothing happens on click
				// Unfotunately the menu cannot be hidden on zero menuItems because the availability of commands are evaluated by selected items
				// It would always hide the menu icon, except any item is seleced, then all menu icons would be shown. 
				// Workaround: on zero menuItems add a disabled info text to the menu
				props.menuItems.push({
					key: "noCommandsInfo",
					text: t("Commands.NoActionAvailable"),
					disabled: true
				});
			}
			return {
				directionalHint: DirectionalHint.rightTopEdge,
				gapSpace: 0,
				beakWidth: 20,
				directionalHintFixed: false,
				items: props.menuItems
			}
		},
		[props.menuItems, t],
	);

	return (<div className={mergeStyles(styles.ColumnContextMenu, props.className)}>
		{props.children}
		{!props.hideMenuButton &&
			<IconButton className={styles.MenuButton} menuProps={menuProps} iconProps={{ iconName: 'More' }} menuIconProps={{ hidden: true, iconName: '' }} onRenderMenuIcon={() => <></>} />
		}
	</div>);
}

export default ColumnContextMenu;