import * as React from "react";
import useStyles from "./ImageFilePreview.style"
import { FileSystemObject } from "../../Model/FileSystemObject";
import { Image } from "@fluentui/react";
import { CommandBar, Icon } from "@fluentui/react";
import { DownloadCommand } from "../../ToolbarCommands/DownloadCommand";
import { RenameFileCommand } from "../../ToolbarCommands/RenameFileCommand";
import { MoveFileCommand } from "../../ToolbarCommands/MoveFileCommand";
import { ShareCommand } from "../../ToolbarCommands/ShareCommand";
import { DeleteFileCommand } from "../../ToolbarCommands/DeleteFileCommand";
import { OpenSharePointCommand } from "../../ToolbarCommands/OpenSharePointCommand";
import { ClosePreviewCommand } from "../../ToolbarCommands/ClosePreviewCommand";
import { PreviewNavCommand } from "../../ToolbarCommands/PreviewNavCommand";
import { loadImage } from "../../Api/File/LoadImage";
import { getFileTypeIconProps } from "@fluentui/react-file-type-icons";
import { Loading } from "../Loading";
import { useCommands } from "../../Utils/useCommands";
import { useAppSelector } from "../../Store/useAppDispatch";

export interface imageFilePreviewProps {
	file: FileSystemObject;
}

export const ImageFilePreview = (props: imageFilePreviewProps) => {
	const [ imagePreview, setImagePreview ] = React.useState<string>();
	const [ isLoading, setIsLoading ] = React.useState<boolean>();
	const {  isInitialized, accessToken } = useAppSelector(state => state.teams);

	const styles = useStyles();
	
	var imageContent = React.useMemo(async () => {
		setImagePreview(undefined);
		if(isInitialized && accessToken && props.file) {
			setIsLoading(true);
			return await loadImage(props.file, false, accessToken);
		}
		return undefined;
	},[props.file, accessToken, isInitialized]);

	imageContent.then(content => {
		setImagePreview(content);
		setIsLoading(false);
	});
	
	const [commands, addCommand] = useCommands(); 
	const [farCommands, addFarCommand] = useCommands();

	const iconProps = React.useMemo(() => {
		const extension = props.file?.Name.substring(props.file.Name.lastIndexOf('.'));
		return getFileTypeIconProps({extension: extension});
	},[props.file]);
	

	return <div className={styles.ImageFilePreview}>
		
			<DownloadCommand addCommandButton={addCommand} />
			<RenameFileCommand addCommandButton={addCommand} />
			<MoveFileCommand addCommandButton={addCommand} />
			<ShareCommand addCommandButton={addCommand} />
			<DeleteFileCommand addCommandButton={addCommand} />
			<OpenSharePointCommand addCommandButton={addCommand} />
			<PreviewNavCommand addCommandButton={addFarCommand} />
			<ClosePreviewCommand addCommandButton={addFarCommand} />

			<CommandBar	items={commands} farItems={farCommands} />
				
			{isLoading && <Loading />}
			{!isLoading && !imagePreview && <Icon iconName={iconProps.iconName} /> }
			{!isLoading && imagePreview && <Image src={imagePreview} alt="Preview" />}
		</div>;
}