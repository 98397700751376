import * as React from "react";
import i18n from "../i18n";
import { loadExternalShares } from "../Api/File/LoadExternalShares";
import { useAppDispatch } from "../Store/useAppDispatch";
import { CommandBarCommandProps } from "../Utils/useCommands";

export const RefreshExternalSharesCommand = (props: CommandBarCommandProps): JSX.Element => {
	const dispatch = useAppDispatch();
	
	props.addCommandButton({
		key: "refreshExternalShares",
		text: i18n.t("Commands.Refresh.Command"),
		iconProps: { iconName: "Refresh" },
		onClick: () => {
			dispatch(loadExternalShares());
			return false;
		},
	});

	return <></>;
}