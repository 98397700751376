import { IStyle, makeStyles } from "@fluentui/react";

export default () => {
	let styles = makeStyles(theme => ({
		TeamList: {
			padding: "2em",
		},
		Layout: {
			display: "flex",
			flexDirection: "row",
			flexWrap: "wrap",
		},
		CommandBar: {
			marginTop: "8px"
		},
		DetailsListWrapperBeforeLoaded: {
			width: "99.9%",
		},
		Highlight: {

		},
		Active: {
			backgroundColor: theme.palette.neutralSecondaryAlt
		},
		GroupHeader: {
			".ms-GroupHeader-title": {
				cursor: "default"
			}
		} as IStyle,
		Clickabel: {
			cursor: "pointer"
		},
		LinksColumn: {
			"&.ms-DetailsRow-cell": {
				paddingTop: "unset",
				paddingBottom: "unset",
				height: "100%"
			} as IStyle
		},
		DeepLinksContainer: {
			display: "grid",
			gridTemplateColumns: "repeat(4, 35px)",
			height: "100%"
		},
		DeepLinkItem: {
			display: "grid",	// centers icons vertically, but the file icon itself has spare pixels at its bottom 
			"button:hover": {
				backgroundColor: theme.palette.neutralLight
			} as IStyle
		}
	}));
	return styles();
};