import * as React from "react";
import { useResetSharePassword } from "../Api/File/ResetSharePassword";
import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import { CustomDialog } from "../Components/CustomDialog/CustomDialog";
import { css, DefaultButton, DialogFooter, DialogType, Label, Separator, Shimmer, Stack, Text } from "@fluentui/react";
import { ExternalShare } from "../Model/ExternalShare";
import { CopyToClipboard } from "../Components/CopyToClipboard/CopyToClipboard";
import useStyles from "./ResetSharePasswordCommand.style";
import { useAppSelector } from "../Store/useAppDispatch";
import { CommandBarCommandProps } from "../Utils/useCommands";

export const ResetSharePasswordCommand = (props: CommandBarCommandProps): JSX.Element => {
	const { selectedShares, downloadUrlBase } = useAppSelector(state => state.externalShares);
	const { t } = useTranslation();
	const [showDialog, setShowDialog] = useState(false);
	const [currentShare, setCurrentShare] = useState<ExternalShare | undefined>();
	const [newPassword, setNewPassword] = useState<string | undefined>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | undefined>();
	const resetSharePassword = useResetSharePassword();
	const styles = useStyles();

	const onResetPassword = useCallback((share: ExternalShare) => {
		setIsLoading(true);
		resetSharePassword(share).then((result) => {
			if (!result.response) {
				return;
			}
			if (result.response.ok) {
				setNewPassword(result.newPassword);
			} else {
				setError(`Error ${result.response.status}${result.response.statusText ? ":" : ""} ${result.response.statusText}`);
			}
			setIsLoading(false);
		});
	}, [resetSharePassword]);

	if (selectedShares && selectedShares.length === 1) {
		props.addCommandButton({
			key: "resetPassword",
			text: t("Commands.ResetPassword.Command"),
			iconProps: { iconName: "Permissions" },
			onClick: () => {
				const selectedShare = selectedShares[0];
				onResetPassword(selectedShare);
				setCurrentShare(selectedShare);
				setNewPassword(undefined);
				setShowDialog(true);
				return false;
			},
		});
	}

	const onCloseDialog = useCallback(() => {
		setNewPassword(undefined);
		setCurrentShare(undefined)
		setError(undefined);
		setShowDialog(false);
	}, [setShowDialog, setNewPassword]);

	const dialogContentProps = {
		type: DialogType.largeHeader,
		title: t("Commands.ResetPassword.DialogTitle"),
		closeButtonAriaLabel: 'Close'
	};

	return <>
		{showDialog && <CustomDialog hidden={false} dialogContentProps={dialogContentProps} onDismiss={() => onCloseDialog()} >
			<Stack>
				<Label>
					{currentShare?.id}
				</Label>

				<Separator />

				<Label>{t("Commands.ResetPassword.DownloadUrlLabel")}</Label>
				{!currentShare && <Shimmer />}
				{currentShare &&
					<CopyToClipboard text={downloadUrlBase + currentShare.id} className={css(styles.Link, "Hide-Overflow")}>
						<a target="_blank" rel="noopener noreferrer" href={downloadUrlBase + currentShare.id}>{downloadUrlBase + currentShare.id}</a>
					</CopyToClipboard>
				}
				<Label>{t("Commands.ResetPassword.PasswordLabel")}</Label>
				{isLoading && <Shimmer />}
				{!isLoading && newPassword && error == null &&
					<CopyToClipboard text={newPassword} className="Hide-Overflow">
						<Text nowrap block>{newPassword}</Text>
					</CopyToClipboard>
				}
				{error != null &&
					<Text className={styles.Error}>
						{error}
					</Text>
				}
			</Stack>

			<DialogFooter>
				<DefaultButton onClick={() => onCloseDialog()} text={t("Commands.ResetPassword.Close")} />
			</DialogFooter>
		</CustomDialog>}
	</>;
}