import * as React from "react";
import { ChecklistText } from "./ChecklistText";
import { ChecklistList } from "./ChecklistList";
import { ChecklistFile } from "./ChecklistFile";
import { ChecklistDate } from "./ChecklistDate";
import { ChecklistInt } from "./ChecklistInt";
import ChecklistFloat from "./ChecklistFloat";
import ChecklistDropdown, { getReadOnlyValues, mapDropdownOptions } from "./ChecklistDropdown";
import { ChecklistCheckBox } from "./ChecklistCheckBox";
import { ChecklistMemo } from "./ChecklistMemo";
import "./ChecklistValue.scss";
import { DockedActionBar, DockedAction } from "../DockedActionBar/DockedActionBar";
import { setNewCheckListValue, cancelCheckList, editCheckList, CheckListEntry } from "../../Store";
import { copyToClipboard } from "../../Api/System/CopyToClipboard";
import { saveCheckList } from "../../Api/CheckList/SaveCheckList";
import i18n from "../../i18n";
import { stringToDate } from "../../Utils/DateHelper";
import { getSeparator, toLocaleNumberString } from "../../Utils/NumberHelper";
import { useAppDispatch, useAppSelector } from "../../Store/useAppDispatch";


export interface ChecklistValueFactoryProps {
	checkListEntry: CheckListEntry
}

const getCopyValue = (checkListEntry: CheckListEntry): string => {
	const value = checkListEntry.value as string;
	if (!value || value.length === 0) {
		return "";
	}
	switch (checkListEntry.Type?.toLowerCase()) {
		case "option":
			return i18n.t(`TaskChecklist.Boolean.${value}`);
		case "list":
		case "listmulti":
			return getReadOnlyValues(value, mapDropdownOptions(checkListEntry.options));
		case "date":
			return stringToDate(value)?.toLocaleDateString() ?? "";
		case "float":
			const decimalSeperator = getSeparator("decimal");
			return toLocaleNumberString(decimalSeperator, value);
		case "int":
		case "file":
		case "image":
		case "filelist":
		case "imagelist":
		case "memo":
		default: // text
			return value;
	}
}

export const ChecklistValueFactory = (props: ChecklistValueFactoryProps): JSX.Element => {
	const isInternalUser = useAppSelector(state => state.teams.isInternalUser);
	const dispatch = useAppDispatch();

	const isEditing = props.checkListEntry.isEditing;
	const isSending = props.checkListEntry.isSending;
	const setNewValue = (value?: string) => dispatch(setNewCheckListValue(props.checkListEntry?.Location || "", props.checkListEntry?.Ident || 0, value || ""));

	const getValueControl = (): JSX.Element => {
		const value = isEditing || isSending ? props.checkListEntry.newValue || "" : props.checkListEntry.value;
		switch (props.checkListEntry.Type?.toLowerCase()) {
			case "option":
				return <ChecklistCheckBox value={value}
					isEditing={isEditing}
					onChange={setNewValue} />;
			case "list":
				return <ChecklistDropdown
					value={value}
					isEditing={isEditing}
					checkListOptions={props.checkListEntry.options}
					onChange={setNewValue}
					isMultiSelect={false}
				/>;
			case "listmulti":
				return <ChecklistDropdown
					value={value}
					isEditing={isEditing}
					checkListOptions={props.checkListEntry.options}
					onChange={setNewValue}
					isMultiSelect={true} />;
			// return <ChecklistList value={value}
			// 	isEditing={isEditing}
			// 	onChange={setNewValue}
			// 	renderItem={(x, onChangeHandler) => <ChecklistText value={x} isEditing={isEditing} onChange={onChangeHandler} />}
			// />;
			case "file":
			case "image":
				return <ChecklistFile value={value}
					isEditing={isEditing}
					isInternalUser={isInternalUser}
					onChange={setNewValue} />;
			case "filelist":
			case "imagelist":
				return <ChecklistList value={value}
					isEditing={isEditing}
					onChange={setNewValue}
					renderItem={(x, onChangeHandler) => <ChecklistFile value={x}
						isEditing={isEditing}
						isInternalUser={isInternalUser}
						onChange={onChangeHandler} />}
				/>;
			case "memo":
				return <ChecklistMemo value={value} isEditing={isEditing} onChange={setNewValue} />;
			case "date":
				return <ChecklistDate value={value} isEditing={isEditing} onChange={setNewValue} />;
			case "int":
				return <ChecklistInt value={value} isEditing={isEditing} onChange={setNewValue} />;
			case "float":
				return <ChecklistFloat value={value} isEditing={isEditing} onChange={setNewValue} />;
			default: // text
				return <ChecklistText value={value} isEditing={isEditing} onChange={setNewValue} />;
		}
	};

	var actions: DockedAction[] = [];

	if (isEditing) {
		actions = [
			{
				iconName: "CheckMark",
				click: () => dispatch(saveCheckList(props.checkListEntry.Location || "", props.checkListEntry.Ident || 0))
			},
			{
				iconName: "Cancel",
				click: () => dispatch(cancelCheckList(props.checkListEntry.Location || "", props.checkListEntry.Ident || 0))
			}
		];
	} else if (isInternalUser) {
		actions = [
			{
				iconName: "Edit",
				click: () => dispatch(editCheckList(props.checkListEntry.Location || "", props.checkListEntry.Ident || 0))
			},
			{
				iconName: "Copy",
				click: () => copyToClipboard(getCopyValue(props.checkListEntry))
			}
		];
	}

	return <DockedActionBar actions={actions} isWaiting={isSending} showAlways={isEditing || isSending}>
		{getValueControl()}
	</DockedActionBar>;
}
