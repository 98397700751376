import { IContextualMenuItem } from "@fluentui/react";
import { ICommandBarItemProps } from "@fluentui/react/lib/CommandBar";
import * as React from "react";
import { useCallback, useMemo } from "react";
import { addFileTagThunk } from "../../Api/File/AddTagThunk";
import { removeFileTagThunk } from "../../Api/File/RemoveTagThunk";
import { FileSystemObject } from "../../Model/FileSystemObject";
import { FileSystemObjectTag } from "../../Model/FileSystemObjectTag";
import { useAppDispatch, useAppSelector } from "../../Store/useAppDispatch";
import { LightenDarkenColor } from "../../Utils/LightenDarkenColor";
import ColumnContextMenu from "../ColumnContextMenu/ColumnContextMenu";
import useStyles from "./FileTags.style"

export interface FileTagsProps {
    file: FileSystemObject;
	tags?: FileSystemObjectTag[];
}

const colorChangeFactor = -90;

export const FileTags = (props: FileTagsProps): JSX.Element => {
	const styles = useStyles();
	const dispatch = useAppDispatch();
    const tagDefinitions = useAppSelector(state => state.file.tags);

    const addTag = useCallback((tag : FileSystemObjectTag) => {
        dispatch(addFileTagThunk(props.file, tag));
    },[dispatch, props.file]);

    const removeTag = useCallback((tag : FileSystemObjectTag) => {
        dispatch(removeFileTagThunk(props.file, tag));
    },[dispatch, props.file]);

	const tagsMenuItems : IContextualMenuItem[] = useMemo(() => 
        tagDefinitions.map(tag => {
            const isSelected = props?.tags?.some(x => x.TermGuid === tag.TermGuid);
            return {
                key: tag.TermGuid,
                text: tag.Label,
                iconProps: { iconName: isSelected ? "CheckMark" : "" },
                itemProps: { styles: { label: { backgroundColor: tag.Color ?? "#888888", color: LightenDarkenColor(tag.Color ?? "#888888",colorChangeFactor)}}},
                onClick: () => isSelected ? removeTag(tag) : addTag(tag)
            } as IContextualMenuItem;
        })
	,[addTag, props?.tags, removeTag, tagDefinitions]);

    const tags = React.useMemo(() => {
        if(!props.tags) return [];

        let newTags = [];    
        for(const tag of [...props.tags].sort((a,b) => (a.Label < b.Label ? -1 : 1))) {

            var bgColor = tagDefinitions?.find(x => x.TermGuid === tag.TermGuid)?.Color ?? "#888888";
            var fgColor = LightenDarkenColor(bgColor,colorChangeFactor);

            const tagStyle ={ backgroundColor: bgColor, color: fgColor };

            newTags.push(<span key={tag.TermGuid} style={tagStyle} className={styles.Tag}>{tag.Label}</span>);
        }
        return newTags;
    },[props.tags, styles.Tag, tagDefinitions])

    return (<>
        <ColumnContextMenu menuItems={tagsMenuItems} className={styles.ContextMenu}>
            {tags.length === 0 && "\u00a0"}
            {tags}
		</ColumnContextMenu>
    </>);
}