import { FileSystemObject } from "../../Model/FileSystemObject";
import { ApiUrlBuilder } from "../../ApiUrlBuilder";
import { ExternalFileShare } from "../../Model/ExternalFileShare";
import { addFiles, RootState, setRunningAction } from "../../Store";
import { useCallback } from "react";
import { ShareContentValueDto } from "../../Model/ShareContentValueDto";
import { generateId } from "../../Utils/GenerateId";
import i18n from "../../i18n";
import { areSameDrives } from "../../Utils/CompareDrive";
import { getFirstDrive } from "../../Utils/GetFirstDrive";
import { getFileSystemObjectKeyString } from "../../Utils/GetKeyString";
import { useAppDispatch, useAppSelector } from "../../Store/useAppDispatch";

function useShareContent() {
	const { drives } = useAppSelector((state: RootState) => state.file);
	const { isInitialized, accessToken } = useAppSelector((state: RootState) => state.teams);

	const getFiles = useCallback(async (files: FileSystemObject[], folders: FileSystemObject[]): Promise<ShareContentValueDto[] | undefined> => {
		if (((!files || files.length === 0) && (!folders || folders.length === 0))) return undefined;
		let shareContent = files
		.filter(file => drives.some(drive => drive.isSelected && areSameDrives(file.Drives, [drive])))
		.map((file) => ({ Drive: {Type: getFirstDrive(file.Drives)?.Type, Ident: getFirstDrive(file.Drives)?.Ident, Location: getFirstDrive(file.Drives)?.Location}, Path: `${file.Path}/${file.Name}`, Type: "File" } as ShareContentValueDto))

		const foldersToShare = folders.filter((folder) => folder.CanShareFile);
		for (const folderToShare of foldersToShare) {
			for (const folderDrive of folderToShare.Drives) {
				if (!drives.some(drive => drive.isSelected && areSameDrives([folderDrive], [drive]))) {
					continue;
				}
				shareContent.push({ Drive: {Type: folderDrive.Type, Ident: folderDrive.Ident, Location: folderDrive.Location}, Path: folderToShare.Path, Type: "Folder" } as ShareContentValueDto);
			}
		}

		return shareContent;
	}, [drives]);

	const shareContent = useCallback(async (url: string, body: string): Promise<Response | undefined> => {
		if (!body || !accessToken || !isInitialized) return undefined;

		const response: Response = await fetch(`/api${url}`, {
			method: "POST",
			headers: {
				"Authorization": `Bearer ${accessToken}`,
				"Content-Type": "application/json"
			},
			body: body
		});

		if (!response.ok) {
			throw new Error(`API request failed with ${response.status}: ${response.statusText}`);
		}

		return response;
	}, [accessToken, isInitialized]);

	return {getFiles, shareContent};
}

export function useShareContentByLink() {
	const {getFiles, shareContent}  = useShareContent();
	const dispatch = useAppDispatch();

	const shareContentByLink = useCallback(async (allFiles: FileSystemObject[], files: FileSystemObject[], folders: FileSystemObject[], shareDurationInDays: number): Promise<ExternalFileShare | undefined> => {
		const url = ApiUrlBuilder.ShareContentByLink();
		const shareFiles = await getFiles(files, folders);
		const body = JSON.stringify({ driveObjects:shareFiles, durationInDays: shareDurationInDays});

		const response = await shareContent(url, body);
		const responseData = (await response?.json())?.value as ExternalFileShare[];

		for (const file of allFiles) {
			file.SharedByLink = true;
		}

		dispatch(addFiles(allFiles)); 
		return responseData && responseData.length >= 1 ? responseData[0] : undefined;
	}, [dispatch, shareContent, getFiles]);

	return shareContentByLink;
}

export function useShareContentWithCustomer() {
	const {getFiles, shareContent} = useShareContent();
	const dispatch = useAppDispatch();

	const shareContentWithCustomer = useCallback(async (allFiles: FileSystemObject[], files: FileSystemObject[], folders: FileSystemObject[], shareWithCustomer: boolean) => {
		const url = ApiUrlBuilder.ShareContentWithCustomer();
		const runningActionId = generateId();
		allFiles.forEach(file =>
			dispatch(setRunningAction({ id: runningActionId + getFileSystemObjectKeyString(file), message: i18n.t(`Actions.${shareWithCustomer ? "" : "Remove"}Share`, { file: file.Name }) }, file.Id, file.Path))
		);
		try {

			const shareFiles = await getFiles(files, folders);
			const body = JSON.stringify({ driveObjects:shareFiles, shareWithCustomer})

			const response = await shareContent(url, body);
			let responseData = (await response?.json())?.value as FileSystemObject[];
			for (const newFile of responseData) {
				const drives = allFiles.find(file => file.Url === newFile.Url)?.Drives;
				if (!drives) {
					continue;
				}
				newFile.Drives = drives;
			}

			// if an error on a file shoud be set, use next line for one file:
			// dispatch(setFileError(file.Id, file.FolderId, response.status, response.statusText));

			dispatch(addFiles(responseData.filter(file => file.Drives != null))); // ensure only files with drives are saved
		}
		finally {
			allFiles.forEach(file =>
				dispatch(setRunningAction({ id: runningActionId + getFileSystemObjectKeyString(file), message: undefined }, file.Id, file.Path))
			);
		}
	}, [dispatch, shareContent, getFiles]);

	return shareContentWithCustomer;
}
