import { IStyle, makeStyles } from "@fluentui/react";

export default () => {
	let styles = makeStyles(theme => ({
		FileBase: {
			//padding: 2em,
			//background-color: #f3f2f1,
			display: "block",

			".ms-CommandBar": {
				paddingLeft: "0px",
			}
		},
		Layout: {
			display: "flex",
			flexDirection: "row",
		},
		// FolderColumn: {
		// 	/*align-items: stretch,*/
		// 	height: "100vh",
		// 	overflowY: "auto",
		// 	// border-right: solid 8px #F3F2F1,
		// 	//padding: 8px,
		// },
		FileColumn: {
			display: "flex",
			/*align-items: stretch,*/
			flexDirection: "column",
			height: "100vh",
		},
		DriveStatus: {
			textAlign: "left",
			minWidth: "30vw",
		},
		RunningTask: {
			position: "absolute",
			top: "8px",
			right: "8px",
		},
		HeadRow: {
			display: "grid",
			gridTemplateColumns: "1fr auto"
		},
		Breadcrumb: {
			Button: {
				fontSize: "14px",
			} as IStyle
		},

		// DeleteFileList {
		// 	margin-top: 16px,

		// 	.ms-List-cell {
		// 		height: 30px,
		// 		line-height: 30px,
		// 		overflow: hidden,
		// 		text-overflow: ellipsis,
		// 		white-space: nowrap,
		// 		padding: 6px,

		// 		&:nth-child(even) {
		// 			background: $ms-color-neutralLighter,
		// 		}

		// 		i {
		// 			overflow: visible,
		// 			margin-right: 6px,
		// 			vertical-align: sub,
		// 		}
		// 	}
		// }
	}));
	return styles();
};