import { RootState, savedTeamDetailsPTLims, teamDetailsPTLimsSavingFailed } from "../../Store";

export function setPTLimsReference(teamId: string, channelId: string, ident: number, location: string) {
    return async (dispatch: React.Dispatch<any>, getState: () => RootState): Promise<void> => {
        if (teamId.length === 0 || channelId.length === 0) { return; }

        const { teams: { isInitialized, accessToken } } = getState();
        if (!isInitialized || !accessToken) { return; }
        
        try {
            const response: Response = await fetch(`/api/channels/setptlimsreference`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    TeamId: teamId,
                    ChannelId: channelId,
                    Ident: ident,
                    Location: location
                })
            });

            if (response.ok) {
                dispatch(savedTeamDetailsPTLims(teamId, channelId, ident, location))
            } else {
                dispatch(teamDetailsPTLimsSavingFailed(teamId, channelId));
                throw new Error(`API request failed with ${response.status}: ${response.statusText}`);
            }
        }
        catch(err:any){
            dispatch(teamDetailsPTLimsSavingFailed(teamId, channelId));
        }
         finally {
        }
    }
}