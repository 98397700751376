import i18next from "i18next";
import addEN from "./i18n/en";
import addDE from "./i18n/de";
import { initReactI18next } from "react-i18next";

i18next
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources:{},
		interpolation: {
			escapeValue: false,
		},
		lng: "en",
		fallbackLng: ["en"],
	});

addEN();
addDE();

export default i18next;