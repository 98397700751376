import * as React from "react";
import { addFileTagFilter, removeFileTagFilter, toggleSelectedDrive } from "../Store";
import { Dropdown, IContextualMenuItem, IDropdownOption, IDropdownStyleProps, IDropdownStyles } from "@fluentui/react";
import useStyles from "./ViewTagsCommand.style"
import { useMemo } from "react";
import { IStyleFunctionOrObject, useTheme } from "@fluentui/react";
import { DriveRef } from "../Model/DriveRef";
import { areSameDrives } from "../Utils/CompareDrive";
import { SpecialFolders } from "../Model/SpecialFolder";
import { useAppDispatch, useAppSelector } from "../Store/useAppDispatch";
import { CommandBarCommandProps } from "../Utils/useCommands";
import { LightenDarkenColor } from "../Utils/LightenDarkenColor";
import { FileSystemObjectTag } from "../Model/FileSystemObjectTag";

const colorChangeFactor = -90;

export const ViewTagsCommand = (props: CommandBarCommandProps): JSX.Element => {
	const { tags, tagsViewFilter } = useAppSelector(state => state.file);
    //const tagDefinitions = useAppSelector(state => state.file.tags);
	const dispatch = useAppDispatch();
	const styles = useStyles();
	
	const tagsMenuItems : IDropdownOption[] = useMemo(() => 
		[{Label:'None',TermGuid:'NONE',Color:'',WssId:-1},...tags].map(tag => {
			const isSelected = tagsViewFilter?.some(x => x.TermGuid === tag.TermGuid);
            return {
                key: tag.TermGuid,
                text: tag.Label,
                iconProps: { iconName: isSelected ? "CheckMark" : "" },
                itemProps: { styles: { label: { backgroundColor: tag.Color ?? "#888888", color: LightenDarkenColor(tag.Color ?? "#888888",colorChangeFactor)}}},
				selected: isSelected,
				data: {...tag}
            } as IDropdownOption;
        })
	,[tags, tagsViewFilter]);

	const theme = useTheme();
	const dropdownItemStyles: IStyleFunctionOrObject<IDropdownStyleProps, IDropdownStyles> = {
		subComponentStyles: {
			multiSelectItem: {
				root: {
					":hover": {
						backgroundColor: theme?.palette.neutralLighter
					}
				}
			}
		}
	};

	const onChange = React.useCallback((event: React.FormEvent<HTMLDivElement>, option: IDropdownOption<FileSystemObjectTag> | undefined) => {
		if(!option) return;

		const tag = option.data;
		if(!tag) return;

		if(option.selected) {
			dispatch(addFileTagFilter(tag));
		} else {
			dispatch(removeFileTagFilter(tag));
		}
	},[dispatch]);

	props.addCommandButton({
		key: "viewTags",
		onRender: (item, dissmissMenu) => {
			return <div className={styles.ViewTagsCommand}>
				<Dropdown
					options={tagsMenuItems}
					className={styles.Dropdown}
					multiSelect
					styles={dropdownItemStyles}
					onChange={onChange}
				/>
			</div>;
		}
	});

	return <>
	</>;
}