import * as React from "react";
import { CustomDialog } from "../CustomDialog/CustomDialog";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { DialogType, TextField, DialogFooter, PrimaryButton, DefaultButton } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { areSameDrives } from "../../Utils/CompareDrive";
import useStyles from "./FileNameConflictRecall.style"
import { splitFilename } from "../../Utils/SplitFilename";
import { useAppSelector } from "../../Store/useAppDispatch";

export interface FileNameConflictRecallProps {
}

export const FileNameConflictRecall: React.FunctionComponent<FileNameConflictRecallProps> = (props: PropsWithChildren<FileNameConflictRecallProps>): JSX.Element => {
	const { overwriteFileRecalls, files, ident, location, sourceType } = useAppSelector(state => state.file);
	const { t } = useTranslation();
	const styles = useStyles();

	const overwriteRecallDialogContentProps = useMemo(() => ({
		type: DialogType.largeHeader,
		title: t("FileBase.File.FilenameConflictDialogTitle"),
		closeButtonAriaLabel: 'Close',
		//subText: 'Do you want to send this message without a subject?',
	}), [t]);

	const fileOverwriteRecall = useMemo(() =>
		overwriteFileRecalls && overwriteFileRecalls.length > 0 ? overwriteFileRecalls[0] : undefined
		, [overwriteFileRecalls]);

	const currentLocationFiles = useMemo(() =>
		fileOverwriteRecall && ident && location && sourceType ?
			files?.filter(f => f.Path === fileOverwriteRecall?.folderName && areSameDrives(f.Drives, [{ Ident: ident, Location: location, Type: sourceType }]))
			: []
		, [fileOverwriteRecall, files, ident, location, sourceType]);

	const [initialFileName, setInitialFileName] = useState(fileOverwriteRecall?.fileName);
	const [fileName, setFileName] = useState(fileOverwriteRecall?.fileName);
	const [suffix, setSuffix] = useState<string | undefined>();
	const [errorMessage, setErrorMessage] = useState<string | undefined>();
	const [isOverwrite, setIsOverwrite] = useState<boolean>(true);
	const [, setDebounceTimeout] = useState<NodeJS.Timeout | undefined>();

	useEffect(() => {
		if (`${fileOverwriteRecall?.fileName}.${suffix}` === initialFileName) {
			return;
		}
		setInitialFileName(fileOverwriteRecall?.fileName);

		const { name, suffix: newSuffix } = splitFilename(fileOverwriteRecall?.fileName)
		setFileName(name);
		setSuffix(newSuffix);
		setIsOverwrite(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fileOverwriteRecall])

	const checkFileName = useCallback((fileName: string | undefined) => {
		const hasNameConflict = currentLocationFiles?.some(f => f.Name === `${fileName}.${suffix}`);
		if (hasNameConflict && !isOverwrite) {
			setIsOverwrite(true);
		} else if (!hasNameConflict && isOverwrite) {
			setIsOverwrite(false);
		}

		if (!fileName || fileName?.length === 0) {
			setErrorMessage(t("FileBase.File.FilenameRequiredErrorMessage"));
			return;
		}

		if (fileName.match('[<>:"/\\|?*]')) {
			setErrorMessage(t("FileBase.File.FilenameInvalidCharacterErrorMessage"));
			return;
		}

		if (errorMessage) {
			setErrorMessage(undefined);
		}
	}, [currentLocationFiles, errorMessage, isOverwrite, suffix, t]);

	const setNewFileName = useCallback((newFileName: string | undefined) => {
		setDebounceTimeout(setTimeout(() => checkFileName(newFileName), 200));
		setFileName(newFileName);
	}, [checkFileName]);

	const accept = useCallback(() => {
		checkFileName(fileName);
		if (errorMessage) {
			return;
		}
		fileOverwriteRecall?.acceptAction(isOverwrite, `${fileName}.${suffix}`)
	}, [checkFileName, fileName, errorMessage, fileOverwriteRecall, isOverwrite, suffix]);

	const onKeyDown = useCallback(
		(event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			if (event.keyCode === 13 && errorMessage == null) {
				accept();
			}
		},
		[accept, errorMessage]
	);

	if (!fileOverwriteRecall) {
		return <></>;
	}
	return (
		<CustomDialog hidden={false} dialogContentProps={overwriteRecallDialogContentProps} onDismiss={() => fileOverwriteRecall.rejectAction()} >
			<TextField label={t("FileBase.File.File")} underlined readOnly value={fileOverwriteRecall.fileName} />
			<TextField label={t("FileBase.File.Folder")} underlined readOnly value={fileOverwriteRecall.folderName} />

			<div className={styles.FilenameConflictMessage}>
				{t("FileBase.File.FilenameConflictMessage")}
			</div>

			<TextField
				value={fileName}
				onChange={(e, newValue) => setNewFileName(newValue)}
				errorMessage={errorMessage}
				suffix={suffix}
				autoFocus
				onKeyDown={(event) => onKeyDown(event)}
			// onRenderPrefix={() => <Icon iconName="Edit" />}
			/>

			<DialogFooter>
				<PrimaryButton
					onClick={() => accept()} className={isOverwrite ? styles.ButtonOverwrite : ""}
					disabled={errorMessage != null}
				>
					{t(`FileBase.File.${isOverwrite ? "Overwrite" : "Rename"}`)}
					{/* <Icon iconName={isOverwrite ? "ChangeEntitlements" : "Rename"} className={styles.Icon} /> */}
				</PrimaryButton>
				<DefaultButton onClick={() => fileOverwriteRecall.rejectAction()} text={t("FileBase.File.Cancel")} />
			</DialogFooter>
		</CustomDialog>
	);
};