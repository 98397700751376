import { Task } from "../../Model/Task";

export enum TaskActions {
	Request = "Task/Request",
	Received = "Task/Received",
	ReceiveFailed = "Task/ReceiveFailed"
}

interface RequestTaskAction {
	type: typeof TaskActions.Request;
	location: string;
	taskIdent: number;
}
export const requestTask = (location: string, taskIdent: number): RequestTaskAction => ({
	type: TaskActions.Request,
	location,
	taskIdent
});

interface TaskReceivedAction {
	type: typeof TaskActions.Received;
	task: Task | undefined;
	taskStatusPercent: number;
}
export const taskReceived = (task: Task | undefined, taskStatusPercent: number): TaskReceivedAction => ({
	type: TaskActions.Received,
	task,
	taskStatusPercent
});

interface TaskReceiveFailedAction {
	type: typeof TaskActions.ReceiveFailed;
	code: number;
	message: string;
}
export const taskReceiveFailed = (code: number, message: string): TaskReceiveFailedAction => ({
	type: TaskActions.ReceiveFailed,
	code,
	message
});

export type TaskActionTypes =
	RequestTaskAction |
	TaskReceivedAction |
	TaskReceiveFailedAction;