import { IStyle, makeStyles } from "@fluentui/react";

export default () => {
	const styles = makeStyles(theme => ({
		ViewTagsCommand: {
			display: "flex",
			alignItems: "center",
			padding: "0px 5px",
		},

		Dropdown: {
			display: "flex",
			alignItems: "center",

			"> .ms-Dropdown": {
				width: "150px",
			} as IStyle,
		},

		ViewTagsCommandOption: {
			display: "grid",
			gridTemplateColumns: "1fr auto",
			width: "100%",
			color: theme.palette.neutralPrimary
		},

		FilesCount: {
			color: theme.palette.neutralTertiary,
			fontSize: theme.fonts.small.fontSize,
			textAlign: "right",
		}
	}));
	return styles();
};