import i18n from "i18next";
import moment from "moment";
import "moment/locale/de";


export default function (dateString: string | undefined, suffix: string = ""): string {

	if (!dateString) {
		return "";
	}

	var dateMoment: moment.Moment = moment(dateString);
	if (dateMoment.isValid()) {
		return dateMoment.format("lll") + suffix;
	} else {
		return "";
	}

}