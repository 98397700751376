import { RootState, setPreviewImage } from "../../Store";
import { FileSystemObject } from "../../Model/FileSystemObject";
import { batch } from "react-redux";
import { Semaphore } from "../../Logic/Semaphore";
import { loadImage } from "./LoadImage";

var semaphore = new Semaphore('LoadPreview',10);

/*const loadPreview = async (file: File, accessToken: string, dispatch: React.Dispatch<any>) => {

	var release = await semaphore.acquire();

	try {
		const url = ApiUrlBuilder.GetFilePreview(file.drive.location, file.drive.ident, file.drive.type, file.FolderId, file.Name);

		const response: Response = await fetch(`/api${url}`, {
			method: "GET",
			headers: {
				"Authorization": `Bearer ${accessToken}`
			}
		});

		if (response.status === 404) {
			return undefined;
		}

		if (!response.ok) {
			throw new Error(`API request failed with ${response.status}: ${response.statusText}`);
		}

		var fileContent: Blob = await response.blob();

		if (fileContent) {
			var contentUrl: string = await new Promise((resolve, reject) => {
				const reader: FileReader = new FileReader();
				reader.onerror = () => reject();
				reader.onload = () => {
					if (reader.result && !(reader.result instanceof ArrayBuffer)) {
						resolve(reader.result);
					} else {
						reject();
					}
				};
				if (fileContent) {
					reader.readAsDataURL(fileContent);
				} else {
					reject();
				}
			});

			dispatch(setPreviewImage(file.Id, file.FolderId, contentUrl));
		}

	} finally {
		release();
	}
}*/

export function loadPreviewImage(files: FileSystemObject[]) {
	return async (dispatch: React.Dispatch<any>, getState: () => RootState): Promise<void> => {
		if (!files || files.length === 0) { return; }

		const { teams: { isInitialized, accessToken } } = getState();
		if (!isInitialized || !accessToken) return;
		
		batch(() => {
			files.forEach((file) => dispatch(setPreviewImage(file.Id, file.Path, "")));
		});

		const loadPreviews = files.map(async (file) =>  {
			
			const lock = await semaphore.acquire();
			try {
				const contentUrl = await loadImage(file, true, accessToken);

				if(contentUrl) {
					dispatch(setPreviewImage(file.Id, file.Path, contentUrl));
				}
			} finally {
				lock.release();
			}
		});

		Promise.all(loadPreviews);
	}
}