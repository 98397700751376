import { IStyle, makeStyles } from "@fluentui/react";

export default () => {
	let styles = makeStyles(theme => ({
		ImageFilePreview: {
			position: "fixed",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			display: "flex",
			backgroundColor: theme.palette.white,
			flexDirection: "column",

			".ms-Image": {
				alignSelf: "center"
			} as IStyle,

			i: {
				alignSelf: "center"
			} as IStyle,

			".ms-Image-image": {
				maxWidth: "100%",
				maxHeight: "100%",
				margin: "auto"
			} as IStyle
		}
	}));
	return styles();
};