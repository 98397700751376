import * as React from "react";
import { RootState, requestTask, taskReceived, taskReceiveFailed, addContact } from "../../Store";
import { Task } from "../../Model/Task";
import { batch } from "react-redux";
import { GetFirstContact, StatusHeaderContact } from "../../Components/StatusHeader/StatusHeader";
import i18n from "../../i18n";
import {loadContactImage} from "./LoadContactImage";
import { initializeContacts } from "../../Store/Contacts/Actions";
import { getHttpStatusText } from "../../Utils/getHttpStatusText";

export function loadTask(location: string, taskIdent: number, withOrder: boolean = false) {
	return async (dispatch: React.Dispatch<any>, getState: () => RootState): Promise<void> => {
		try {
			dispatch(requestTask(location, taskIdent));

			const { teams: { accessToken } } = getState();

			let url: string = `/locations/${location}/tasks/${taskIdent}`;

			if (withOrder) {
				url += "?$expand=Orders,TaskContacts";
			} else {
				url += "?$expand=TaskContacts";
			}

			const response: Response = await fetch(`${window.location.origin}/api${url}`, {
				method: "GET",
				headers: {
					"Authorization": `Bearer ${accessToken}`
				}
			});

			if (!response.ok) {
				const errorMessage = response.statusText || getHttpStatusText(response.status);				
				dispatch(taskReceiveFailed(response.status, errorMessage));
				return;
			}

			const task: Task = await response.json();
			const taskStatusPercent: number = task && task.Status ? task.Status.ResultPercent / 100.0 : 0;

			batch(() => {
				dispatch(taskReceived(task, taskStatusPercent));
				dispatch(initializeContacts(task.Location, task.Ident, undefined));

				var taskContact: StatusHeaderContact | undefined = GetFirstContact(task?.TaskContacts, i18n.t("TaskChecklist.Detail.AccountManager"), "tskResponsible");
				if (taskContact) {
					taskContact.isPartOfTeam = false;
					dispatch(addContact(taskContact));
					dispatch(loadContactImage(taskContact));
				}
			});
		} catch (error:any) {
			dispatch(taskReceiveFailed(0, error.message));
		}
	};
}