export interface NamedItem {
	Name: string;
}

export const sortByName = <T extends NamedItem>(namedItems: T[] | undefined) : T[] | undefined => {	
	if(!namedItems) return namedItems;

	return namedItems?.sort((a, b) => 
	{
		if (a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase()) { return -1; }
		if (a.Name.toLocaleLowerCase() > b.Name.toLocaleLowerCase()) { return 1; }
		return 0;
	});
}