import { RootState, setRunningAction, removeFolder } from "../../Store";
import { FileSystemObject } from "../../Model/FileSystemObject";
import { ApiUrlBuilder } from "../../ApiUrlBuilder";
import { batch } from "react-redux";
import { generateId } from "../../Utils/GenerateId";
import i18n from "../../i18n";

export function deleteFolder(folder : FileSystemObject) {
	return async (dispatch: React.Dispatch<any>, getState: () => RootState): Promise<void> => {
		if (!folder) { return; }

		const { teams: { isInitialized, accessToken }, file: { location, ident, sourceType} } = getState();
		if(!isInitialized || !accessToken || !location || !ident || !sourceType) return;

		const runningActionId = generateId();
		try {	
			dispatch(setRunningAction({ id: runningActionId, message: i18n.t("Actions.DeleteFolder", { folder: folder.Name }) }, folder.Path, folder.Path, true));		

			const url = ApiUrlBuilder.DeleteFolder(location, ident, sourceType, folder.Path);

			const response: Response = await fetch(`/api${url}`, {
				method: "DELETE",
				headers: {
					"Authorization": `Bearer ${accessToken}`,
					"Content-Type":"application/json"
				}
			});
	
			if (response.status === 404) {
				return undefined;
			}
	
			if (!response.ok) {
				throw new Error(`API request failed with ${response.status}: ${response.statusText}`);
			}

			batch(() => {
				dispatch(removeFolder( folder ));
			});
		} finally {
			dispatch(setRunningAction({id: runningActionId, message: undefined}, folder.Path, folder.Id));
		}
	}
}