import { ContactsState } from "./State";
import { ContactsActionTypes, ContactsActions } from "./Actions";
import { StatusHeaderContact } from "../../Components/StatusHeader/StatusHeader";

const initialState: ContactsState = {
	taskIdent: undefined,
	orderIdent: undefined,
	location: undefined,
	contacts: [],
	sendMessage: {
		message: "",
		isSending: false
	}
};

export function contactsReducer(
	state: ContactsState = initialState,
	action: ContactsActionTypes
): ContactsState {
	switch (action.type) {

		case ContactsActions.Initialize:
			return {
				...state,
				location: action.location,
				orderIdent: action.orderIdent,
				taskIdent: action.taskIdent,
				contacts: [],
				sendMessage: {
					message: "",
					isSending: false
				}
			};

		case ContactsActions.AddContact:
			return {
				...state,
				contacts: [...state.contacts, action.contact]
			};

		case ContactsActions.SetContactImage:
			const contacts = state.contacts.map(x => ({...x}));// [...state.contacts];
			const contact: StatusHeaderContact | undefined = contacts.find(x => x.eMail === action.email);
			if (contact) {
				contact.imageUrl = action.image;
			}

			return {
				...state,
				contacts: contacts
			};

		case ContactsActions.SetContactMessage:
			return {
				...state,
				sendMessage: { ...state.sendMessage, message: action.message }
			};

		case ContactsActions.SetSendingContactMessage:
			return {
				...state,
				sendMessage: { ...state.sendMessage, isSending: action.isSending }
			};

		default:
			return state;
	}
}