import { RootState, requestExternalShares, externalSharesReceived, externalSharesReceiveFailed } from "../../Store";
import { ApiUrlBuilder } from "../../ApiUrlBuilder";
import { ExternalSharesResponse } from "../../Model/ExternalSharesResponse";

export function loadExternalShares() {
	return async (dispatch: React.Dispatch<any>, getState: () => RootState): Promise<void> => {

		const { teams: { isInitialized, accessToken }, file: { drives } } = getState();
		if (!isInitialized || !accessToken) return;

		try {
			dispatch(requestExternalShares());

			const url = ApiUrlBuilder.GetExternalShares();
			const requestBody = {
				drives: drives.map(drive => ({ Ident: drive.Ident, Location: drive.Location, Type: drive.Type}))
			};

			const response: Response = await fetch(`/api${url}`, {
				method: "POST",
				headers: {
					"Authorization": `Bearer ${accessToken}`,
					"Content-Type": "application/json"
				},
				body: JSON.stringify(requestBody)
			});

			if (!response.ok) {					
				dispatch(externalSharesReceiveFailed(response.status, response.statusText));
				return;
			}

			const result: ExternalSharesResponse = await response.json();
			
			dispatch(externalSharesReceived(result));
		} catch (error:any) {
			dispatch(externalSharesReceiveFailed(500, error));
		}
	}
}