import * as React from "react";
import { Text, Icon, TooltipDelay, TooltipHost, DirectionalHint } from "@fluentui/react";
import './PTLimsStatus.scss';

export interface PTLimsStatusProps {
	status: string;
	reasons?: string;
}

export class PTLimsStatus extends React.Component<PTLimsStatusProps> {

	private getIcon(statusNumber: string): [string, string] {
		switch (statusNumber) {
			case "1": // created
				return ["CircleAddition","PTLimsStatus_Icon_Ok"];
			case "2": // planned
				return ["SkypeCircleClock","PTLimsStatus_Icon_Ok"];
			case "3": // in work
				return ["MSNVideosSolid", "PTLimsStatus_Icon_Working"];
			case "4": // defered
				return ["CirclePause", "PTLimsStatus_Icon_Warning"];
			case "5": // ???
				return ["CircleRing", "PTLimsStatus_Icon_Ok"];
			case "6": // canceled
				return ["StatusErrorFull", "PTLimsStatus_Icon_Error"];
			case "7": // closed, add expense
				return ["Completed", "PTLimsStatus_Icon_Ok"];
			case "8": // order closed
				return ["Completed", "PTLimsStatus_Icon_Ok"];
			case "9": // commercial closed
				return ["CompletedSolid", "PTLimsStatus_Icon_Ok"];
		}

		return ["CircleRing", "PTLimsStatus_Icon"];
	}

	render(): JSX.Element {
		const parts: string[] = this.props.status.split("-", 2);
		if (parts.length <= 1) {
			return (<Text>{this.props.status}</Text>);
		}

		const [icon, iconClass] = this.getIcon(parts[0].trim());

		return (<>
			<Icon iconName={icon} className={iconClass} />
			<Text>{parts[1].trim()}</Text>
			{this.props.reasons && <TooltipHost content={this.props.reasons} delay={TooltipDelay.zero} directionalHint={DirectionalHint.rightCenter}>
					<Icon iconName="Info" className="PTLimsStatus_Reason" />
				</TooltipHost>
			}
		</>);
	}
}