/**
 * Converts a string to a date
 * @param stringDate string to be converted
 * @returns date
 */
export const stringToDate = (stringDate: string | undefined) => {
    if (!stringDate) {
        return undefined;
    }

    return new Date(stringDate);
}