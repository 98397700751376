import * as React from "react";
import "./ChecklistValue.scss";
import { TextField } from "@fluentui/react";
import { getSeparator, toLocaleNumberString } from "../../Utils/NumberHelper";
import { useCallback, useState } from "react";
import { useEffect } from "react";
import i18n from "../../i18n";

interface ChecklistFloatProps {
	value: string | undefined;
	isEditing: boolean;
	onChange: (value: string | undefined) => void;
}

/**
 * Removes all occurrences except the first one and returns the modified string
 * @param value value which will be modified
 * @param symbol a string expression to remove all occurrences except for the first one
 * @returns value string with only on occurrence of the provided expression
 */
const removeMultipleCharactersExceptFirst = (value: string, symbol: string) => {
	const splitted = value.split(symbol);
	if (splitted.length > 2) {
		// remove multiple decimal seperators
		value = `${splitted[0]}${symbol}${splitted[1]}`;
		for (var i = splitted.length; i > 1; i--) {
			value += splitted[i];
		}
	}
	return value;
}

// /**
//  * Gets the value string. If nessesary, replaces the decimal seperator with the locale character for it.
//  * @returns number string with locale decimal seperator
//  */
// const computeValue = (inputValue: string | undefined) => {
// 	if (!inputValue || decimalSeperator === ".") {
// 		return inputValue;
// 	}

// 	const splitted = inputValue.toString().split(".");
// 	if (splitted.length < 2) {
// 		return inputValue;
// 	}
// 	return `${splitted[0]}${decimalSeperator}${splitted[1]}`;
// };

export default function ChecklistFloat(props: ChecklistFloatProps) {
	const [decimalSeperator, setDecimalSeperator] = useState(() => getSeparator("decimal"));
	const [regExp, setRegExp] = useState(() => new RegExp(`[^\-0-9${decimalSeperator}]`, "g"));
	useEffect(() => {
		setDecimalSeperator(getSeparator("decimal"));
		setRegExp(new RegExp(`[^\-0-9${decimalSeperator}]`, "g"));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [i18n.language]);

	const [value, setValue] = useState(() => props.value);
	useEffect(() => {
		if (value !== props.value?.toString()) {
			setValue(props.value)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.value, setValue]);

	const onKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		if (event.key.length >= 2 || (event.ctrlKey && event.key.match(/[axcv]/g))) {
			// allow: function keys, select all, cut, copy, paste
			return true;
		}
		if (event.key.match(regExp) // cancel all characters except numbers and seperators
			|| (!(event.target as any).validity.valid) // cancel if not valid (input type tel)
			|| (event.key === "-" && (event.target as any).defaultValue.indexOf("-") >= 0) // cancel if minus, but there is already one there
			|| (event.key === decimalSeperator && (event.target as any).defaultValue.indexOf(decimalSeperator) >= 0)// cancel if decimal seperator, but there is already one there
		) {
			event.preventDefault();
			event.stopPropagation();
			return false;
		}
		return true;
	}, [decimalSeperator, regExp]);

	const onChangeGate = useCallback((event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string | undefined) => {
		if (newValue && newValue.length > (value?.length ?? 0) + 1) {
			// remove unallowed characters if something was pasted
			newValue = newValue.replace(regExp, "");

			newValue = removeMultipleCharactersExceptFirst(newValue, decimalSeperator);
			newValue = removeMultipleCharactersExceptFirst(newValue, "-");
		}
		if (newValue && (newValue.lastIndexOf("-") >= 1 || (!(event.target as any).validity.valid))) {
			// prevent a minus that is not the first character
			event.preventDefault();
			event.stopPropagation();
			return false;
		}

		// save always with dot as decimal seperator
		// replaceAll is currently not supported in Teams client. 
		//newValue = newValue?.replaceAll(decimalSeperator, ".");
		newValue = newValue?.replace(new RegExp(`[${decimalSeperator}]`, 'g'), ".");

		setValue(newValue);
		props.onChange(newValue);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value, setValue, props.onChange]);

	if (props.isEditing) {
		return <TextField
			value={toLocaleNumberString(decimalSeperator, value)}
			onChange={(event, newValue) => onChangeGate(event, newValue)}
			onKeyDown={(event) => onKeyDown(event)}
			autoComplete="off"
			type="tel"
		/>;
	} else {
		const number = Number(value);
		if (Number.MAX_SAFE_INTEGER < number || Number.MIN_SAFE_INTEGER > number) {
			return <>{toLocaleNumberString(decimalSeperator, value)}</>;
		} else {
			return <>{number?.toLocaleString(i18n.language)}</>;
		}
	}
}