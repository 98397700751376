import { makeStyles } from "@fluentui/react";

export default () => {
	let styles = makeStyles(theme => ({
		ButtonArea:{
			paddingTop: "8px",
			display: "flex",
			justifyContent: "flex-end"
		},
		SaveArea:{
			display: "grid",
			justifyContent: "space-around"
		},
		CancelButton:{
			marginLeft:"5px"
		},
		UserList:{
			paddingTop: "8px"
		},
		Heading:{
			color: theme.palette.neutralPrimary
		},
		Loading:{
			paddingTop: "10%"
		}
	}));
	return styles();
};