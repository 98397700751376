export enum SystemActions {
	SetGlobalError = "System/SetGlobalError",
	RemoveGlobalError = "System/RemoveGlobalError"
}

interface SetGlobalErrorAction {
	type: typeof SystemActions.SetGlobalError;
	errorMessage: string;
}
export const setGlobalError = (errorMessage: string): SetGlobalErrorAction => ({
	type: SystemActions.SetGlobalError,
	errorMessage
});

interface RemoveGlobalErrorAction {
	type: typeof SystemActions.RemoveGlobalError
}
export const removeGlobalError = (): RemoveGlobalErrorAction => ({
	type: SystemActions.RemoveGlobalError
});

export type SystemActionTypes =  SetGlobalErrorAction | RemoveGlobalErrorAction;