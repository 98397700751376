import { RootState, setRunningAction, addFolder } from "../../Store";
import { ApiUrlBuilder } from "../../ApiUrlBuilder";
import { batch } from "react-redux";
import { SpecialFolders } from "../../Model/SpecialFolder";
import { generateId } from "../../Utils/GenerateId";
import i18n from "../../i18n";
import { FileSystemObject } from "../../Model/FileSystemObject";

export function createFolder(targetFolder: string, newFolderName: string) {
	return async (dispatch: React.Dispatch<any>, getState: () => RootState): Promise<void> => {
		if (!newFolderName) { return; }

		const specialFolder = SpecialFolders.find(specialFolder => specialFolder.Id === targetFolder);
		if (specialFolder?.TargetFolderId) {
			targetFolder = specialFolder.TargetFolderId;
		}

		const { teams: { isInitialized, accessToken }, file: { location, ident, sourceType } } = getState();
		if (!isInitialized || !accessToken || !location || !ident || !sourceType) return;

		const runningActionId = generateId();
		try {
			dispatch(setRunningAction({ id: runningActionId, message: i18n.t("Actions.AddFolder", { folder: newFolderName }) }, newFolderName, targetFolder));

			const url = ApiUrlBuilder.GetFolders(location, ident, sourceType);

			const response: Response = await fetch(`/api${url}`, {
				method: "POST",
				headers: {
					"Authorization": `Bearer ${accessToken}`,
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					FolderName: `${targetFolder}/${newFolderName}`
				})
			});

			if (response.status === 404) {
				return undefined;
			}

			if (!response.ok) {
				throw new Error(`API request failed with ${response.status}: ${response.statusText}`);
			}
			const newFolder: FileSystemObject = await response.json();

			batch(() => {
				dispatch(addFolder(newFolder));
			});
		} finally {
			dispatch(setRunningAction({ id: runningActionId, message: undefined }, newFolderName, targetFolder));
		}
	}
}