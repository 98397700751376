import * as React from "react";
import useStyles from "./RunningActions.style"
import { Callout, Spinner } from "@fluentui/react";
import { useSelector } from "react-redux";
import { RootState } from "../../Store";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../Store/useAppDispatch";


export interface RunningActionsProps {
}

export const RunningActions = (props: RunningActionsProps) => {
	const styles = useStyles();
	const { runningActions } = useAppSelector(state => state.file);

	const [isCalloutVisible, setIsCalloutVisible] = useState(() => false);

	useEffect(() => {
		if (isCalloutVisible && runningActions.length === 0) {
			setIsCalloutVisible(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [runningActions.length]);

	if (runningActions.length === 0) {
		return <div className={styles.RunningActionWrapper}></div>
	}

	const spinnerId = "RunningActionsSpinner";
	return <div
		className={styles.RunningActionWrapper}
		onMouseEnter={() => setIsCalloutVisible(true)}
		onMouseLeave={() => setIsCalloutVisible(false)}
	>
		<Spinner id={spinnerId} label={runningActions.length.toLocaleString()} ariaLive="assertive" labelPosition="right" />
		{isCalloutVisible &&
			<Callout // cannot be a TooltippHost beacuse on every render it would be closed
				target={`#${spinnerId}`}
				className={styles.Callout}
			>
				<div className={styles.ActionList}>
					{runningActions.map((action) =>
						<div key={"listItem_" + action.id} className={styles.ListItem}>
							{action.message}
						</div>
					)}
				</div>
			</Callout>}
	</div>;
}

