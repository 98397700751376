import React from "react";
import { batch } from "react-redux";
import { ODataResult } from "../../Model/ODataResult";
import { Team } from "../../Model/Team";
import { RootState, requestTeamList, teamListReceiveFailed, teamListReceived } from "../../Store";
import { getHttpStatusText } from "../../Utils/getHttpStatusText";

export function loadTeamList() {
    return async (dispatch: React.Dispatch<any>, getState: () => RootState): Promise<void> => {
        try{
            dispatch(requestTeamList());
            
            const { teams: { accessToken } } = getState();

			let url: string = `/teams?$expand=Channels`;

            const response: Response = await fetch(`${window.location.origin}/api${url}`, {
				method: "GET",
				headers: {
					"Authorization": `Bearer ${accessToken}`
				}
			});

			if (!response.ok) {
				const errorMessage = response.statusText || getHttpStatusText(response.status);				
				dispatch(teamListReceiveFailed(response.status, errorMessage));
				return;
			}

            const teamList: ODataResult<Team> = await response.json();
            batch(()=> {
                dispatch(teamListReceived(teamList.value));
            });
        }catch (error:any){
            dispatch(teamListReceiveFailed(0, error.message))
        }
    }
};