import { IStyle, makeStyles } from "@fluentui/react";

export const useGlobalStyles = () => {
	let styles = makeStyles(theme => ({
		Scrollbar: {
			// unfortunately transitions are not possible on '::' styles
			"@media only screen and (min-width: 768px)": {
				"::-webkit-scrollbar-thumb": {
					backgroundColor: theme.palette.neutralLighter
				} as IStyle,

				":hover": {
					"::-webkit-scrollbar-thumb": {
						backgroundColor: theme.palette.neutralTertiaryAlt,
					} as IStyle,
				} as IStyle
			}
		}
	}));
	return styles();
};