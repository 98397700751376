import { IStyle, makeStyles } from "@fluentui/react";

export default () => {
	let styles = makeStyles(theme => ({
		FilenameConflictMessage: {
			paddingTop: "10px",
			paddingBottom: "25px"
		},
		Icon: {
			paddingLeft: "5px",
		},
		ButtonOverwrite: {
			backgroundColor: "rgb(255 101 0)", // orange

			borderColor: "rgb(255 101 0)",
			":hover": {
				borderColor: "rgb(255 101 0)",
				backgroundColor: "rgb(255 101 0 / 90%)",
			} as IStyle,
			":active": {
				borderColor: "rgb(255 101 0)",
				backgroundColor: "rgb(255 101 0 / 80%)",
			} as IStyle
		}
	}));
	return styles();
};