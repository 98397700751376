import { CheckListActions } from "./CheckListActions";

export interface EditCheckListAction {
	type: typeof CheckListActions.EditCheckList;
	location: string;
	ident: number;
}
export const editCheckList = (location: string, ident: number): EditCheckListAction => ({
	type: CheckListActions.EditCheckList,
	location,
	ident
});