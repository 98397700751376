import { CheckListActions } from "./CheckListActions";

export interface SavedCheckListAction {
	type: typeof CheckListActions.SavedCheckList;
	location: string;
	ident: number;
	newValue: string;
	updatedBy?: string;
	dateUpdated?: string;
}
export const savedCheckList = (location: string, ident: number, newValue: string, updatedBy?: string, dateUpdated?: string): SavedCheckListAction => ({
	type: CheckListActions.SavedCheckList,
	location,
	ident,
	newValue,
	updatedBy,
	dateUpdated
});