import { DriveRef } from "../Model/DriveRef";

export const areSameDrives = (driveA: DriveRef[] | undefined, driveB: DriveRef[] | undefined) => {
        if (!driveA || !driveB) {
                return false;
        }

        if (driveA.length !== driveB.length) {
                return false;
        }

        for (let i = 0; i < driveA.length; i++) {
                if (!isSameDrive(driveA[i], driveB[i])) {
                        return false;
                }
        }

        return true;
};

export const isSameDrive = (driveA: DriveRef | undefined, driveB: DriveRef | undefined) => {
        return driveA?.Ident === driveB?.Ident && driveA?.Location === driveB?.Location && driveA?.Type === driveB?.Type;
};