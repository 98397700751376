import { useTheme } from "@fluentui/react";
import * as React from "react";
import { useCallback, useRef } from "react";

export interface ProgressChartProps {
	value: number;
	width: number;
}

export const ProgressChart = (props: ProgressChartProps) => {
	const theme = useTheme();
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const drawCanvas = useCallback(() => {
		const canvas = canvasRef.current as HTMLCanvasElement;
		if (!canvas) {
			return;
		}
		const ctx = canvas.getContext("2d");
		if (ctx == null) {
			return;
		}

		var width = ctx.canvas.width;
		var height = ctx.canvas.height;

		var donutRadius = Math.min(width, height) / 2.0;
		var donutInnerRadius = donutRadius * 0.75;
		var startAngle = -90 / 180 * Math.PI;
		var progressAngle = startAngle + Math.PI * 2 * props.value;

		ctx.clearRect(0, 0, width, height);

		if (props.value < 1.0) {
			ctx.beginPath();
			ctx.fillStyle = theme.palette.neutralQuaternaryAlt;
			ctx.ellipse(donutRadius, donutRadius, donutRadius, donutRadius, 0, 0, 2 * Math.PI, true);
			ctx.ellipse(donutRadius, donutRadius, donutInnerRadius, donutInnerRadius, 0, 2 * Math.PI, 0, false);
			ctx.fill();
		}

		ctx.beginPath();
		ctx.fillStyle = theme.palette.themePrimary;
		ctx.arc(donutRadius, donutRadius, donutRadius, startAngle, progressAngle, false); // outer (filled)
		ctx.arc(donutRadius, donutRadius, donutInnerRadius, progressAngle, startAngle, true); // inner (unfills it)
		ctx.fill();

		ctx.fillStyle = theme.palette.neutralPrimary;
		ctx.textAlign = "center";
		ctx.textBaseline = "middle";
		ctx.font = `normal normal ${width / 5}px "Segoe UI Web (West European)",Segoe UI,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif`;

		ctx.fillText(`${(props.value * 100).toFixed(0)}%`, donutRadius, donutRadius, width);
	}, [props.value, theme]);

	React.useEffect(() => {
		drawCanvas();
	}, [drawCanvas]);

	return (
		<>
			<canvas className="ProgressChart" ref={canvasRef} width={props.width} height={props.width} />
		</>
	);

}