import * as React from "react";
import { DialogType, DialogFooter, PrimaryButton, DefaultButton, Dropdown, IDropdownOption } from "@fluentui/react";
import { renameFile } from "../Api/File/RenameFile";
import { moveFolder } from "../Api/File/MoveFolder";
import { CustomDialog } from "../Components/CustomDialog/CustomDialog";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { getFirstDrive } from "../Utils/GetFirstDrive";
import { useAppDispatch, useAppSelector } from "../Store/useAppDispatch";
import { CommandBarCommandProps } from "../Utils/useCommands";


export const MoveFileCommand = (props: CommandBarCommandProps): JSX.Element => {
	const [showDialog, setShowDialog] = React.useState(false);
	const [targetFolderId, setTargetFolderId] = React.useState("");
	const { selectedFiles, folders, selectedFolders, isReadonlyFolder: isReadOnlyFolder } = useAppSelector(state => state.file);
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const showCommand = useMemo(() =>
		!isReadOnlyFolder && ((selectedFolders && selectedFolders.length > 0) || (selectedFiles && selectedFiles.length > 0))
		, [isReadOnlyFolder, selectedFiles, selectedFolders]);

	const isDisabled = useMemo(() =>
		selectedFiles?.some(file => file.hasRunningAction) || selectedFolders?.some(folder => folder.hasRunningAction)
		, [selectedFiles, selectedFolders]);

	if (showCommand) {
		props.addCommandButton({
			key: "moveFile",
			text: t("Commands.MoveFile.Command"),
			iconProps: { iconName: "FabricMovetoFolder" },
			disabled: isDisabled,
			title: isDisabled ? t("Commands.IsBusy") : undefined,
			onClick: () => { setTargetFolderId(""); setShowDialog(true); return false; },
		});
	}

	const dialogContentProps = {
		type: DialogType.largeHeader,
		title: t("Commands.MoveFile.DialogTitle"),
		closeButtonAriaLabel: 'Close',

	};

	const onCommand = React.useCallback(() => {
		if (selectedFiles && selectedFiles.length > 0) {
			for (const file of selectedFiles) {
				dispatch(renameFile(file, targetFolderId, file.Name, getFirstDrive(file.Drives)));
			}
		}

		if (selectedFolders && selectedFolders.length > 0) {
			for (const folder of selectedFolders) {
				const targetFolder = `${targetFolderId}/${folder.Name}`;
				dispatch(moveFolder(folder, targetFolder));
			}
		}

		setShowDialog(false);
	}, [dispatch, selectedFiles, selectedFolders, targetFolderId]);

	const targetFolderOptions = React.useMemo(() =>
		folders?.filter(x => selectedFolders?.every(y => y.Path !== x.Path))
			.sort((a, b) => (a.Path < b.Path ? -1 : 1))
			.map(x => ({ key: x.Path, text: '- '.repeat((x.Path?.match(/\//g) ?? []).length) + x.Name } as IDropdownOption)),
		[folders, selectedFolders]
	);

	return <>
		{showDialog && <CustomDialog hidden={false} dialogContentProps={dialogContentProps} onDismiss={() => setShowDialog(false)} >
			<Dropdown
				label={t("Commands.MoveFile.TargetFolder")}
				options={targetFolderOptions ?? []}
				selectedKey={targetFolderId}
				onChange={(ev, option) => setTargetFolderId(option?.key.toString() ?? "")}
			/>
			<DialogFooter>
				<PrimaryButton onClick={onCommand} text={t("Commands.MoveFile.Ok")} />
				<DefaultButton onClick={() => setShowDialog(false)} text={t("Commands.MoveFile.Cancel")} />
			</DialogFooter>
		</CustomDialog>}
	</>;
}