import * as React from "react";
import { RootState, setGlobalError, setContactImage } from "../../Store";
import { StatusHeaderContact } from "../../Components/StatusHeader/StatusHeader";

export function loadContactImage(contact: StatusHeaderContact) {
	return async (dispatch: React.Dispatch<any>, getState: () => RootState): Promise<void> => {
		try {
			var { teams: { accessToken } } = getState();

			const response: Response = await fetch(`${window.location.origin}/api/contacts('${contact.eMail}')/photo`, {
				method: "GET",
				headers: {
					"Authorization": `Bearer ${accessToken}`
				}
			});

			if (!response.ok) {
				return; // Ignore error, keep initials
				//dispatch(setGlobalError(`API request failed with ${response.status}: ${response.statusText}`));
			}

			var blob: Blob = await response.blob();
			let image: string = await convertBlob2InlineImage(blob);
			dispatch(setContactImage(contact.eMail, image));

		} catch (error:any) {
			dispatch(setGlobalError(error.message));
		}
	};
}

async function convertBlob2InlineImage(blob: Blob): Promise<string> {
	if (!blob) { return ""; }

	return new Promise((resolve, reject) => {
		const reader: FileReader = new FileReader();
		reader.onerror = () => reject();
		reader.onload = () => {
			if (reader.result && !(reader.result instanceof ArrayBuffer)) {
				resolve(reader.result);
			} else {
				reject();
			}
		};
		reader.readAsDataURL(blob);
	});
}