import { TextField } from "@fluentui/react";
import React, { useCallback } from "react";
import { useEffect, useState } from "react";

export interface INumbersOnlyTextFieldProps {
    label: string;
    disabled?: boolean;
    minLength?: number;
    maxLength?: number;
    defaultValue?: string;
    onChange?: (newValue?: string) => void;
     onPasteValueUpdate?: (value: string) => string;
}

export function stripCharactersForNumbersOnlyTextField(value?: string): string | undefined {
    // Strip everything except numbers
    let convertedValue = value?.replace(/\D/g, "");

    return convertedValue;
}

export const NumbersOnlyTextField = (props: INumbersOnlyTextFieldProps) =>{
    const [initializationComplete, setInitializationComplete] = useState(false);
    const [completeTextSelected, setCompleteTextSelected] = useState(false); // Needed to determine if out past event can overwrite the text
    // const [value, setValue] = useState<string>("");
    let value = props.defaultValue;

    const onChange = useCallback(
        (newValue?: string): void => {
            let formattedValue = stripCharactersForNumbersOnlyTextField(newValue) ?? "";
            value = formattedValue;

            if (props.onChange != null) {
                props.onChange(formattedValue);
            }
        },
        [props]
    );

    const onPaste = useCallback(
        (event: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            

            // We have to make sure that we only paste in the right circumstances. If we replace the text on every "onPaste"-event,
            // we also replace partial values. To prevent this, we will only continuning in two cases:
            // - if the current value is empty (i.e. null, undefined, or empty string) and we cannot overwrite something
            // - if the complete text is selected and the user intended to replace everything. Complete selection will be detected in the onSelect 
            //   handler and will be signaled through the completeTextSelected state variable
            if(value != null && value.length > 0 && !completeTextSelected) {
                return;
            }

            const clipboardValue = event.clipboardData.getData("text/plain");
            

            let newValue = stripCharactersForNumbersOnlyTextField(clipboardValue) ?? "";
            if (props.onPasteValueUpdate != null) {
                newValue = props.onPasteValueUpdate(clipboardValue);
            }

            if(props.maxLength != null && newValue.length > props.maxLength) {
                newValue = newValue.substring(0, props.maxLength);
            }

            // setValue(newValue);
            value = newValue;
            onChange(newValue);
        },
        [props, completeTextSelected, onChange]
    );

    const onSelect = useCallback(
        (event: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            let { selectionStart, selectionEnd } = event.currentTarget;

            let isAllSelected = selectionStart === 0 && selectionEnd === value?.length;
            setCompleteTextSelected(isAllSelected);
        },
        [props]
    );

    useEffect(
        () => {
            if (!initializationComplete && props.defaultValue != null && props.defaultValue.length > 0) {
                onChange(props.defaultValue);
              
            }
            if (!initializationComplete) {
                setInitializationComplete(true);
            }
            if(value && value.length > 0){
                onChange(value);
            }       
        },
        [props.defaultValue, initializationComplete, onChange, value]
    );

    return (
        <div>
            <TextField label={props.label}
                disabled={props.disabled}
                minLength={props.minLength}
                maxLength={props.maxLength}
                onChange={(_, newValue) => onChange(newValue)}
                onPaste={(event) => onPaste(event)}
                onSelect={(event) => onSelect(event)}
                value={value} />
        </div>
    );
}