import * as React from "react";
import "./ChecklistValue.scss";
import { Dropdown, IDropdownOption } from "@fluentui/react";
import { useCallback } from "react";
import { useEffect } from "react";
import { CheckListOption } from "../../Model/CheckList";
import i18n from "../../i18n";

interface ChecklistDropdownProps {
	value: string | undefined;
	isEditing: boolean;
	checkListOptions?: CheckListOption[];
	onChange: (value: string | undefined) => void;
	isMultiSelect?: boolean;
}

export const mapDropdownOptions = (checkListOptions?: CheckListOption[]): IDropdownOption[] => {
	if (!checkListOptions) {
		return [];
	}

	return checkListOptions
		.sort((a, b) => a.SortCode - b.SortCode)
		.map((checkListOption) => ({
			text: i18n.language.startsWith("de") ? checkListOption.DescriptionDE : checkListOption.DescriptionEN,
			key: checkListOption.Ident.toString()
		} as IDropdownOption)
		);
}

export const getSelectedKeys = (value: string | undefined, options: IDropdownOption[]) => {
	if (!value) {
		return [];
	}
	const selectedIdents = value?.split("|")
	return options
		.filter((option => (selectedIdents?.findIndex((ident) => ident === option.key) ?? -1) >= 0))
		.map((option) => option.key as string);
}

export const getReadOnlyValues = (value: string | undefined, options: IDropdownOption[]) => {
	if (!value) {
		return "";
	}
	const selectedIdents = value?.split("|")
	const values = options
		.filter((option => (selectedIdents?.findIndex((ident) => ident === option.key) ?? -1) >= 0))
		.map((option) => option.text);

	if (values.length === 0) {
		return "";
	}
	return values.reduce((prev, curr) => `${prev}, ${curr}`);
}

export default function ChecklistDropdown(props: ChecklistDropdownProps) {

	const [options, setOptions] = React.useState<IDropdownOption[]>(mapDropdownOptions(props.checkListOptions));
	const [selectedKeys, setSelectedKeys] = React.useState<string[]>(getSelectedKeys(props.value, options));

	useEffect(() => {
		setSelectedKeys(getSelectedKeys(props.value, options));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [options, props.isEditing]);

	useEffect(() => {
		setOptions(mapDropdownOptions(props.checkListOptions));
	}, [props.checkListOptions]);

	const onChange = useCallback((item?: IDropdownOption): void => {
		let selectedKeysTemp = selectedKeys;
		if (item) {
			if (props.isMultiSelect) {
				selectedKeysTemp = item.selected ? [...selectedKeys, item.key as string] : selectedKeys.filter(key => key !== item.key)
				setSelectedKeys(selectedKeysTemp);
			} else {
				selectedKeysTemp = [item.key as string];
				setSelectedKeys(selectedKeysTemp);
			}
		}

		if (selectedKeysTemp.length === 0) {
			props.onChange("");
			return;
		}
		const newValue = selectedKeysTemp.reduce((prev, curr) => `${prev}|${curr}`);
		props.onChange(newValue);
	}, [props, selectedKeys]);

	if (props.isEditing) {
		return <Dropdown
			options={options}
			selectedKeys={props.isMultiSelect ? selectedKeys : undefined}
			selectedKey={props.isMultiSelect ? undefined : selectedKeys}
			multiSelect={props.isMultiSelect ?? false}
			onChange={(event, option) => onChange(option)}
		/>;
	} else {
		return <>{getReadOnlyValues(props.value, options)}</>;
	}
}