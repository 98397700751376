/**
 * Splits a file name into the name and its suffix
 * @param filename filename including suffix
 * @returns name of file without suffix and suffix without dot
 */
export const splitFilename = (filename: string | undefined): { name: string | undefined, suffix: string | undefined } => {
  if (!filename) {
    return { name: undefined, suffix: undefined };
  }

  const lastDotIndex = filename.lastIndexOf(".");
  if (lastDotIndex < 1) {
    if (lastDotIndex < 0) {
      // no dot in file name
      return { name: filename, suffix: undefined };
    }
    // starts with the only dot
    return { name: "", suffix: filename.substring(1) };
  }

  return { name: filename.substring(0, lastDotIndex), suffix: filename.substring(lastDotIndex + 1) };
}