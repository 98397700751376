import { StatusHeaderContact } from "../../Components/StatusHeader/StatusHeader";

export enum ContactsActions {
	Initialize = "Contact/Initialize",
	AddContact = "Contact/AddOrderContact",
	LoadContactImage = "Contact/LoadOrderContactImage",
	SetContactImage = "Contact/SetOrderContactImage",
	SetContactMessage = "Contact/SetMessage",
	SetSendingContactMessage = "Contact/SetSendingMessage"
}

interface InitializeContactsAction {
	type: typeof ContactsActions.Initialize;
	location: string;
	taskIdent?: number;
	orderIdent?: number;
}
export const initializeContacts = (location: string, taskIdent?: number, orderIdent?: number): InitializeContactsAction => ({
	type: ContactsActions.Initialize,
	location,
	taskIdent,
	orderIdent
});

interface AddContactAction {
	type: typeof ContactsActions.AddContact;
	contact: StatusHeaderContact;
}
export const addContact = (contact: StatusHeaderContact): AddContactAction => ({
	type: ContactsActions.AddContact,
	contact
});

interface SetContactImageAction {
	type: typeof ContactsActions.SetContactImage;
	email: string;
	image: string;
}
export const setContactImage = (email: string, image: string): SetContactImageAction => ({
	type: ContactsActions.SetContactImage,
	email,
	image
});

interface SetSendingContactMessageAction {
	type: typeof ContactsActions.SetSendingContactMessage;
	isSending: boolean;
}
export const setSendingContactMessage = (isSending: boolean): SetSendingContactMessageAction => ({
	type: ContactsActions.SetSendingContactMessage,
	isSending,
});

interface SetContactMessageAction {
	type: typeof ContactsActions.SetContactMessage;
	message: string;
}
export const setContactMessage = (message: string): SetContactMessageAction => ({
	type: ContactsActions.SetContactMessage,
	message,
});

export type ContactsActionTypes =
	InitializeContactsAction |
	AddContactAction |
	SetContactImageAction |
	SetSendingContactMessageAction |
	SetContactMessageAction;