import { CheckListActions } from "./CheckListActions";

export interface RequestCheckListAction {
	type: typeof CheckListActions.Request;
	location: string;
	taskIdent: number;
}
export const requestCheckList = (location: string, taskIdent: number): RequestCheckListAction => ({
	type: CheckListActions.Request,
	location,
	taskIdent
});