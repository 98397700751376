import * as React from "react";
import "./ChecklistValue.scss";

export interface ChecklistListProps {
	value: string;
	isEditing: boolean;
	onChange: (newValue: string | undefined) => void;
	renderItem: (value: string, onChange: (newValue: string | undefined) => void) => JSX.Element;
}
export class ChecklistList extends React.Component<ChecklistListProps, {}> {

	onChange(value: string | undefined, index: number): void {
		let newValue: string | undefined;

		if (!this.props.value) {
			newValue = value;
		} else if (index < 0 && value) {
			newValue = `|${this.props.value}|${value}|`;
		} else if (value != null) {
			const parts: string[] = this.props.value.split("|");

			parts.shift();
			parts.splice(parts.length - 1, 1);

			if (index <= parts.length && value) {
				parts[index] = value;
			} else {
				parts.splice(index, 1);
			}

			newValue = "|" + parts.join("|") + "|";
		}

		this.props.onChange(newValue);
	}

	render(): JSX.Element {
		const parts: string[] = this.props.value?.split("|") ?? [];

		if (parts.length >= 3) {
			parts.shift();
			parts.splice(parts.length - 1, 1);
		}

		if (this.props.isEditing) {
			parts.push("");
		}

		return <ul className="ChecklistList">
			{parts.map((x: string, index: number) => {
				return <li key={index}>{this.props.renderItem(x, (newValue: string | undefined) => this.onChange(newValue, index))}</li>;
			})}
		</ul>;
	}
}