import { Channel } from "../../../Model/Channel";
import { SaveState } from "../../../Model/SaveState";
import { Team } from "../../../Model/Team";


export enum  TeamDetailsPanelActions {
    TeamDetailsPanelToggle = "TeamDetailsPanel/TeamDetailsPanelToggle",
    TeamDetailsPanelSetRenderedChannel = "TeamDetailsPanel/TeamDetailsPanelSetRenderedChannel",
    TeamDetailsPanelSetRenderedTeam = "TeamDetailsPanel/TeamDetailsPanelSetRenderedTeam"
}

interface TeamDetailsPanelToggleAction {
    type: typeof TeamDetailsPanelActions.TeamDetailsPanelToggle;
}

export const toggleTeamDetailsPanel = (): TeamDetailsPanelToggleAction =>({
    type: TeamDetailsPanelActions.TeamDetailsPanelToggle
});

interface TeamDetailsPanelSetRenderedChannelAction {
    type: typeof TeamDetailsPanelActions.TeamDetailsPanelSetRenderedChannel;
    renderedChannel: Channel;
}

export const teamDetailsPanelSetRenderedChannel = (renderedChannel: Channel): TeamDetailsPanelSetRenderedChannelAction =>({
    type:TeamDetailsPanelActions.TeamDetailsPanelSetRenderedChannel,
    renderedChannel
});

interface TeamDetailsPanelSetRenderedTeamAction {
    type: typeof TeamDetailsPanelActions.TeamDetailsPanelSetRenderedTeam;
    renderedTeam: Team;
}

export const teamDetailsPanelSetRenderedTeam = (renderedTeam: Team): TeamDetailsPanelSetRenderedTeamAction =>({
    type:TeamDetailsPanelActions.TeamDetailsPanelSetRenderedTeam,
    renderedTeam
});


export type TeamDetailsPanelActionTypes = 
TeamDetailsPanelToggleAction |
TeamDetailsPanelSetRenderedChannelAction |
TeamDetailsPanelSetRenderedTeamAction ;