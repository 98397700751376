import { IStyle, makeStyles } from "@fluentui/react";

export default () => {
	let styles = makeStyles(theme => ({
		CustomDialog: {
			color: theme.palette.neutralPrimary,

			".ms-Overlay": {
				backgroundColor: `${theme.palette.black}66`
			} as IStyle
		}
	}));
	return styles();
};