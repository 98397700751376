import * as React from "react";
import { MessageBar, MessageBarType, MessageBarButton } from "@fluentui/react";
import logo from "../../logo.svg";
import i18n from "../../i18n";

import './ReloadComponent.scss';
export interface ReloadComponentProps {
	errorMessage: string;
	reload: () => void;
	hideLogo?: boolean;
} 

export const ReloadComponent = (props: ReloadComponentProps): JSX.Element => {	
	return (
		<div className="ReloadComponent" >
			{props.hideLogo !== true && <img src={logo} className="ReloadComponent-logo" alt="logo" /> }
			<MessageBar messageBarType={MessageBarType.error} isMultiline={true} 
				actions={<MessageBarButton onClick={() => props.reload()} >{i18n.t("ReloadComponent.Reload")}</MessageBarButton>}>
					{props.errorMessage}
			</MessageBar>
		</div>
	);
}