import * as React from "react";
import { DialogType, DialogFooter,PrimaryButton, DefaultButton, DatePicker, IDatePickerStrings, DayOfWeek, addDays } from "@fluentui/react";
import { extendExternalShares } from "../Api/File/ExtendExternalShares";
import { useTranslation } from "react-i18next";
import { CustomDialog } from "../Components/CustomDialog/CustomDialog";
import FormatDate from "../Formatter/FormatDate";
import { useAppDispatch, useAppSelector } from "../Store/useAppDispatch";
import { CommandBarCommandProps } from "../Utils/useCommands";


export const ExtendShareCommand = (props: CommandBarCommandProps): JSX.Element => {
	const { selectedShares } = useAppSelector(state => state.externalShares);
	const [showDialog, setShowDialog] = React.useState(false);
	const [validToDate, setValidToDate] = React.useState<Date | undefined>(new Date());
	const dispatch = useAppDispatch();
	const { t, i18n } = useTranslation();
	
	const minDate = React.useMemo(() => {
		const date = new Date();
		date.setHours(23);
		date.setMinutes(59);
		date.setSeconds(59);
		return date;
	},[]);

	const maxDate = React.useMemo(() => {
		return addDays(minDate, 7);
	},[minDate]);

	if(selectedShares && selectedShares.length > 0) {
		props.addCommandButton({
			key: "extendShare",
			text: t("Commands.ExtendShare.Command"),
			iconProps: { iconName: "DateTime" },
			onClick: () => { setValidToDate(maxDate); setShowDialog(true); return false; }
		});
	}

	const dialogContentProps = {
		type: DialogType.largeHeader,
		title: t("Commands.ExtendShare.DialogTitle"),
		closeButtonAriaLabel: 'Close'
	};

	const onCommand = React.useCallback(() => {
		if(!validToDate || !selectedShares) return;		
		dispatch(extendExternalShares( selectedShares?.map(x => x.id) || [], validToDate.toISOString() ));
		setShowDialog(false);
	}, [dispatch, selectedShares, validToDate]);

	let translation = i18n.t("DatePicker", { returnObjects: true }) as IDatePickerStrings;

	return <>
		{showDialog &&
		<CustomDialog hidden={false} dialogContentProps={dialogContentProps} onDismiss={() => setShowDialog(false)} >
			<DatePicker
				firstDayOfWeek={DayOfWeek.Monday}
				strings={translation}
				minDate={minDate}
				maxDate={maxDate}
				label={t("Commands.ExtendShare.ValidToDate")}
				value={validToDate}
				onSelectDate={date => setValidToDate(date || minDate)}
				formatDate={(date) => FormatDate(date?.toISOString())}
			/>
			<DialogFooter>
				<PrimaryButton onClick={() => onCommand()} disabled={!validToDate || !selectedShares} text={t("Commands.ExtendShare.Ok")} />
				<DefaultButton onClick={() => setShowDialog(false)} text={t("Commands.ExtendShare.Cancel")} />
			</DialogFooter>
		</CustomDialog>
		}
	</>;
}