import { IStyle, makeStyles } from "@fluentui/react";

export default () => {
	let styles = makeStyles(theme => ({
		StatusHeaderPersonaHoverCard: {
			padding: "1rem",
			backgroundColor: theme.palette.neutralLighter,
			color: theme.palette.neutralPrimary,
			boxShadow: `${theme.palette.black}21 0px 6.4px 14.4px 0px, ${theme.palette.black}1C 0px 1.2px 3.6px 0px`,
		},
		Row: {
			display: "flex",
			flexFlow: "row",
			alignItems: "flex-start"
		},
		HeadLine: {
			fontSize: theme.fonts.medium.fontSize, // 14
			fontWeight: 600
		} as IStyle,
		Details: {
			fontSize: theme.fonts.small.fontSize, // 12
		},
		StatusHeaderPersonaHoverCardContext: {
			flexGrow: 1
		},
		SendIcon: {
			marginLeft: "5px",
			":hover": {
				backgroundColor: theme.palette.neutralLight
			} as IStyle
		},
		FlexFill: {
			flex: "auto"
		},
		FlexEnd: {
			// alignSelf: "flex-start"
		}

	}));
	return styles();
};