import { IStyle, makeStyles } from "@fluentui/react";

export default () => {
	let styles = makeStyles(theme => ({
		Tag: { 
            padding: "2px 4px",
            borderRadius: "8px",
            marginRight: "4px"
        },
        ContextMenu: {
            justifyContent: "end"
        }
	}));
	return styles();
};

