import { IStyle, makeStyles, PartialTheme } from "@fluentui/react";

export default (theme: PartialTheme | undefined) => {
	let styles = makeStyles(t => ({
		Body: {
			":global(body)": {
				backgroundColor: (theme ?? t).palette?.white + " !important", // '!important' to overwrite html coded style
			} as IStyle,

			// Scrollbar
			// unfortunately transitions are not possible on '::' styles
			":global(::-webkit-scrollbar)": {
				width: "12px",
			} as IStyle,
			":global(::-webkit-scrollbar-track)": {
				backgroundColor: (theme ?? t).palette?.neutralLighter,
			} as IStyle,
			":global(::-webkit-scrollbar-thumb)": {
				borderRadius: "12px",
				backgroundColor: (theme ?? t).palette?.neutralTertiaryAlt,
				boxShadow: `inset 0px 0px 0px 3px ${(theme ?? t).palette?.neutralLighter}`,

				":hover": {
					boxShadow: `inset 0px 0px 0px 0px ${(theme ?? t).palette?.neutralLighter}`,
				}
			} as IStyle,

			".ms-MessageBar-innerText": {
				color: (theme ?? t).palette?.neutralPrimary
			}
		}
	}));
	return styles();
};