import * as React from "react";
import { ICommandBarItemProps } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { copyHtmlToClipboard } from "../Api/System/CopyToClipboard";
import { useAppSelector } from "../Store/useAppDispatch";
import { CommandBarCommandProps } from "../Utils/useCommands";

export const CopyLinkCommand = (props: CommandBarCommandProps): JSX.Element => {
	const { selectedFiles, selectedFolders } = useAppSelector(state => state.file);
	const { entityId, appId,channelId } = useAppSelector(state => state.teams);
	const { t } = useTranslation();

	const showCommand = useMemo(() =>
		(selectedFiles && selectedFiles.length > 0) || 
		(selectedFolders && selectedFolders.length > 0 && !!selectedFolders?.map(folder => folder.filesCount).reduce((prev, curr) => (prev ?? 0) + (curr ?? 0), 0)) // if no file is selected, at least one selected folder needs to contain at least one file
		, [selectedFiles, selectedFolders]);

	const isDisabled = useMemo(() =>
		[...selectedFiles ?? [], ...selectedFolders ?? []]?.some(fileSystemObject => fileSystemObject.hasRunningAction)
		, [selectedFiles, selectedFolders]);

	if (showCommand) {
		props.addCommandButton({
			key: "copyLink",
			text: t("Commands.CopyLink.Command"),
			iconProps: { iconName: "Link" },
			disabled: isDisabled,
			title: isDisabled ? t("Commands.IsBusy") : undefined,
			onClick: () => {
				
				var html = "";
				for (const fileSystemObject of [...selectedFiles ?? [], ...selectedFolders ?? []]) {
					const context = encodeURIComponent(`{"subEntityId":"${fileSystemObject.Path}","channelId":"${channelId}"}`)
					const url = `https://teams.microsoft.com/l/entity/${appId}/${entityId}?label=${fileSystemObject.Name}&context=${context}`

					html += `<a href='${url}'>${fileSystemObject.Name}</a><br>`;
				}
				copyHtmlToClipboard(html);
				return false;
			},
		});
	}
	
	return <></>;
}