import * as React from "react";
import { PropsWithChildren, useCallback, useState } from "react";
import { ContextualMenu, css, IContextualMenuItem, IContextualMenuStyleProps, IContextualMenuStyles, IStyleFunctionOrObject } from "@fluentui/react";

interface FileDetailListRowProps {
	menuItems: IContextualMenuItem[];
	className?: string;
	onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
	onContextMenu?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const MouseContextualMenu = (props: PropsWithChildren<FileDetailListRowProps>) => {
	const [showContextualMenu, setShowContextualMenu] = useState<boolean>(false);
	const [contextualMenuStyles, setContextualMenuStyles] = useState<IStyleFunctionOrObject<IContextualMenuStyleProps, IContextualMenuStyles>>();

	const closeMenu = useCallback(() => {
		setShowContextualMenu(false);
		document.removeEventListener("mouseup", closeMenu)
		document.removeEventListener("contextmenu", closeMenu)
		document.removeEventListener("keydown", closeMenu)
	}, []);

	const closeOnEsc = useCallback((event: KeyboardEvent) => {
		if (event.code === "Escape") {
			closeMenu();
		}
	}, [closeMenu]);

	const onContextMenu = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (props.onContextMenu) {
			props.onContextMenu(event);
		}

		event.stopPropagation();
		event.preventDefault();

		setContextualMenuStyles({
			subComponentStyles: {
				callout: {
					root: {
						top: `${event.clientY}px !important`, // must be !important to overwrite hardcoded element.style
						left: `${event.clientX}px !important`
					}
				}
			}
		});

		if (showContextualMenu) {
			return;
		}

		document.addEventListener("mouseup", closeMenu);
		document.addEventListener("contextmenu", closeMenu)
		document.addEventListener("keydown", closeOnEsc)

		setShowContextualMenu(true);

		return false;
	}, [closeMenu, closeOnEsc, props, showContextualMenu]);

	return <div onContextMenu={onContextMenu} className={css(props.className)} onClick={props.onClick}>
		{props.children}
		<ContextualMenu
			items={props.menuItems}
			hidden={!showContextualMenu}
			styles={contextualMenuStyles}
		// onItemClick={() => closeMenu()}
		// onDismiss={() => closeMenu()}
		// an out of scope ContextualMenu will also call onDismiss on this ContextualMenu
		// additionally the DetailsList seems to stop propagation/default on a select off a row (except the click is on the selected icon)
		// the stopped propagation causes that this menu does not close on the click
		// the easier and visually better workaround is to evaluate the close events manually 
		/>
	</div>;
}

