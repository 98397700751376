import { RootState, setRunningAction, setAvailableTags } from "../../Store"
import { generateId } from "../../Utils/GenerateId";
import i18n from "../../i18n";
import { FileSystemObjectTag } from "../../Model/FileSystemObjectTag";

export function getTags() {
	return async (dispatch: React.Dispatch<any>, getState: () => RootState): Promise<void> => {

		const { teams: { isInitialized, accessToken } } = getState();
		if(!isInitialized || !accessToken) return;

		const runningActionId = generateId();
		try {	
			dispatch(setRunningAction({ id: runningActionId, message: i18n.t("Actions.DeleteFolder") }, "" , "" , true));		

			const response: Response = await fetch(`/api/drives/tags`, {
				method: "GET",
				headers: {
					"Authorization": `Bearer ${accessToken}`,
					"Content-Type":"application/json"
				}
			});
	
			if (response.status === 404) {
				return undefined;
			}
	
			if (!response.ok) {
				throw new Error(`API request failed with ${response.status}: ${response.statusText}`);
			}

			var tags = (await response.json()) as FileSystemObjectTag[];

			dispatch(setAvailableTags( tags ));
			
		} finally {
			dispatch(setRunningAction({id: runningActionId, message: undefined}, "",""));
		}
	}
}