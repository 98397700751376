import { RootState, setRunningAction } from "../../Store";
import { FileSystemObject } from "../../Model/FileSystemObject";
import { ApiUrlBuilder } from "../../ApiUrlBuilder";
import { generateId } from "../../Utils/GenerateId";
import i18n from "../../i18n";
import { convertFileSystemObjectToDto } from "../../Model/FileSystemObjectDto";
import { areSameDrives } from "../../Utils/CompareDrive";
import download from "downloadjs";

export function downloadFileSystemObject(fileSystemObject: FileSystemObject) {
	return async (dispatch: React.Dispatch<any>, getState: () => RootState): Promise<void> => {
		if (!fileSystemObject) { return; }

		const { teams: { isInitialized, accessToken }, file: { drives } } = getState();
		if (!isInitialized || !accessToken || fileSystemObject.Drives == null || fileSystemObject.Drives.length === 0 ||
			(fileSystemObject.Type === "Folder" && !fileSystemObject.filesCount)) return;

		const runningActionId = generateId();
		try {
			dispatch(setRunningAction({ id: runningActionId, message: i18n.t("Actions.Downloading", { file: fileSystemObject.Name }) }, fileSystemObject.Id, fileSystemObject.Path, fileSystemObject.Type === "Folder"));

			// send only drives, that are selected in view
			const selectedDrives = drives.filter(drive => drive.isSelected);
			const requestDrives = fileSystemObject.Drives.filter(drive => selectedDrives.some(sDrive => areSameDrives([sDrive], [drive])))
			const requestObject = { ...fileSystemObject } // a copy is needed to not modify the original object
			requestObject.Drives = requestDrives;

			const url = ApiUrlBuilder.GetFileSystemObjectContent();
			const response: Response = await fetch(`/api${url}`, {
				method: "POST",
				headers: {
					"Authorization": `Bearer ${accessToken}`,
					"Content-Type": "application/json"
				},
				body: JSON.stringify({ fileSystemObject: convertFileSystemObjectToDto(requestObject) })
			});

			if (response.status === 404) {
				return undefined;
			}

			if (!response.ok) {
				throw new Error(`API request failed with ${response.status}: ${response.statusText}`);
			}

			var fileContent: Blob = await response.blob();


			if (fileContent) {

				download(fileContent, fileSystemObject.Name,"application/octet-stream");

				/*if ((window.navigator as any).msSaveBlob) {
					window.navigator.msSaveBlob(fileContent, fileSystemObject.Name);
				} else {
					var contentUrl: string = await new Promise((resolve, reject) => {
						const reader: FileReader = new FileReader();
						reader.onerror = () => reject();
						reader.onload = () => {
							if (reader.result && !(reader.result instanceof ArrayBuffer)) {
								resolve(reader.result);
							} else {
								reject();
							}
						};
						if (fileContent) {
							reader.readAsDataURL(fileContent);
						} else {
							reject();
						}
					});

					const anchor: HTMLAnchorElement = window.document.createElement("a");
					anchor.href = contentUrl;
					anchor.download = fileSystemObject.Name;
					anchor.target = "_blank";
					document.body.appendChild(anchor);
					anchor.click();
					setTimeout(() => {
						// window.URL.revokeObjectURL(anchor.href);
						document.body.removeChild(anchor);
					}, 1000);
				}*/
			} else {
				//this.setError(i18n.t("FileBase.File.DownloadFailed", { file }));
			}

		} finally {
			dispatch(setRunningAction({ id: runningActionId, message: undefined }, fileSystemObject.Id, fileSystemObject.Path, fileSystemObject.Type === "Folder"));
		}
	}
}