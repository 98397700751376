import * as React from "react";
import { uploadFiles } from "../Api/File/UploadFiles";
import { SpecialFolders } from "../Model/SpecialFolder";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../Store/useAppDispatch";
import { CommandBarCommandProps } from "../Utils/useCommands";

export const UploadCommand = (props: CommandBarCommandProps): JSX.Element => {
	const fileInput = React.useRef<HTMLInputElement>(null);
	const { selectedFiles, selectedFolderId, selectedSubFolderPath, isReadonlyFolder: isReadOnlyFolder } = useAppSelector(state => state.file);
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const specialFolder = useMemo(() => SpecialFolders.find(specialFolder => specialFolder.Id === selectedFolderId), [selectedFolderId]);
	const showCommand = useMemo(() =>
		!isReadOnlyFolder && (!selectedFiles || selectedFiles.length === 0) && selectedFolderId && (!specialFolder || specialFolder.TargetFolderId)
		, [isReadOnlyFolder, selectedFiles, selectedFolderId, specialFolder]);

	if (showCommand) {
		props.addCommandButton({
			key: "upload",
			text: t("Commands.Upload.Command"),
			iconProps: { iconName: "Upload" },
			onClick: () => { fileInput?.current?.click(); return false; },
		});
	}

	return <>
		<input type="file" ref={fileInput} multiple onInput={() => dispatch(uploadFiles(fileInput.current?.files, selectedSubFolderPath || selectedFolderId || ""))} hidden />
	</>;
}