import { SystemState } from "./State";
import { SystemActionTypes, SystemActions } from "./Actions";

const initialState: SystemState = {
	globalError: undefined
};

export function systemReducer(
	state: SystemState = initialState,
	action: SystemActionTypes
): SystemState {
	switch (action.type) {
		case SystemActions.SetGlobalError:
			return {
				...state,
				globalError: action.errorMessage
			};
		case SystemActions.RemoveGlobalError:
			return {
				...state,
				globalError: undefined
			};

		default:
			return state;
	}
}