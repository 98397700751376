import { ChecklistState, CheckListEntry } from "../types";
import { CancelCheckListAction, CheckListActions, CheckListReceivedAction, CheckListReceiveFailedAction, EditCheckListAction, RequestCheckListAction, SavedCheckListAction, SaveFailedCheckListAction, SavingCheckListAction, SetNewCheckListValueAction } from "../actions";
import { CheckListValue } from "../../../Model/CheckList";
import { Reducer } from "redux";

export type CheckListActionTypes =
	RequestCheckListAction |
	CheckListReceivedAction |
	CheckListReceiveFailedAction |
	EditCheckListAction |
	SetNewCheckListValueAction |
	CancelCheckListAction |
	SavingCheckListAction |
	SavedCheckListAction |
	SaveFailedCheckListAction;

const initialState: ChecklistState = {
	isLoading: false,
	checkListValues: []
};

function getCheckListEntry(state: ChecklistState, location: string, ident: number) {
	return state.checkListValues.find(x => x.Ident === ident && x.Location === location);
}

function mapCheckListValue(value: CheckListValue) : CheckListEntry {
	var entry : CheckListEntry = {
		isEditing: false,
		isSending: false,
		value: null,
		options: value.Options,
		...value
	};

	switch(value.Type?.toLowerCase()) {
		case "int":
			entry.value = value.ValueInt;
			break;

		case "date":
		case "datetime":
		case "time":
			entry.value = value.ValueDate;
			break;

		case "float":
			entry.value = value.ValueFloat;
			break;

		default:
			entry.value = value.ValueText;
			break;
	}

	return entry;
}

export const checkListReducer: Reducer<ChecklistState, CheckListActionTypes> = (state: ChecklistState | undefined = initialState, action: CheckListActionTypes): ChecklistState => {
	switch (action.type) {
		case CheckListActions.Request:
			return {
				...state,
				isLoading: true,
				location: action.location,
				ident: action.taskIdent,
				loadError: undefined
			};

		case CheckListActions.ReceiveFailed:
			return {
				...state,
				isLoading: false,
				checkListValues: [],
				loadError: action.message
			};

		case CheckListActions.Received:
			return {
				...state,
				checkListValues: action.checkList?.Values.map(x => mapCheckListValue(x)) || [],
				location: action.checkList?.Location,
				ident: action.checkList?.Ident,
				driveType: action.checkList?.Type,
				isLoading: false,
				loadError: undefined
			};

		case CheckListActions.EditCheckList: {
			const checkListEntry = getCheckListEntry(state, action.location, action.ident);
			if (!checkListEntry) {
				return state;
			}
			checkListEntry.isEditing = true;
			checkListEntry.newValue = checkListEntry.value;
			return {
				...state,
				checkListValues: [...state.checkListValues]
			};
		}

		case CheckListActions.SetNewCheckListValue: {
			const checkListEntry = getCheckListEntry(state, action.location, action.ident);
			if (!checkListEntry) {
				return state;
			}
			checkListEntry.newValue = action.newValue;
			return {
				...state,
				checkListValues: [...state.checkListValues]
			};
		}

		case CheckListActions.CancelCheckList: {
			const checkListEntry = getCheckListEntry(state, action.location, action.ident);
			if (!checkListEntry) {
				return state;
			}
			checkListEntry.isEditing = false;
			return {
				...state,
				checkListValues: [...state.checkListValues]
			};
		}

		case CheckListActions.SavingCheckList: {
			const checkListEntry = getCheckListEntry(state, action.location, action.ident);
			if (!checkListEntry) {
				return state;
			}
			checkListEntry.isEditing = false;
			checkListEntry.isSending = true;
			return {
				...state,
				checkListValues: [...state.checkListValues]
			};
		}

		case CheckListActions.SavedCheckList: {
			const checkListEntry = getCheckListEntry(state, action.location, action.ident);
			if (!checkListEntry) {
				return state;
			}
			checkListEntry.isEditing = false;
			checkListEntry.isSending = false;
			checkListEntry.value = action.newValue;
			checkListEntry.newValue = undefined;
			checkListEntry.UpdatedBy = action.updatedBy;
			checkListEntry.DateUpdated = action.dateUpdated;
			return {
				...state,
				checkListValues: [...state.checkListValues]
			};
		}

		case CheckListActions.SaveFailedCheckList: {
			const checkListEntry = getCheckListEntry(state, action.location, action.ident);
			if (!checkListEntry) {
				return state;
			}
			checkListEntry.isEditing = true;
			checkListEntry.isSending = false;
			return {
				...state,
				checkListValues: [...state.checkListValues]
			};
		}

		default:
			return state;
	}
}