import * as React from "react";
import useStyles from "./CustomDialog.style"
import { PropsWithChildren } from "react";
import { Dialog, IDialogProps } from "@fluentui/react";
import { css } from "@fluentui/react";

export const CustomDialog = (props: PropsWithChildren<IDialogProps>) => {
	const styles = useStyles();

	return <Dialog
		className={css(styles.CustomDialog, props.className)}
		closeButtonAriaLabel={props.closeButtonAriaLabel}
		dialogContentProps={props.dialogContentProps}
		elementToFocusOnDismiss={props.elementToFocusOnDismiss}
		firstFocusableSelector={props.firstFocusableSelector}
		forceFocusInsideTrap={props.forceFocusInsideTrap}
		hidden={props.hidden}
		ignoreExternalFocusing={props.ignoreExternalFocusing}
		isClickableOutsideFocusTrap={props.isClickableOutsideFocusTrap}
		key={props.key}
		maxWidth={props.maxWidth}
		minWidth={props.minWidth}
		modalProps={props.modalProps}
		onDismiss={props.onDismiss}
		ref={props.ref}
		responsiveMode={props.responsiveMode}
		styles={props.styles}
		// theme={props.theme} undefined causes an error
	>
		{props.children}
	</Dialog>;
}

