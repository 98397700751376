import { DriveRefDto } from './DriveRefDto';
import { FileSystemObject } from './FileSystemObject';

export type FileSystemObjectType = "File" | "Folder";

export interface FileSystemObjectDto {
	Id: string;
	Drives: DriveRefDto[];
	Name: string;
	Path: string;
	Type: FileSystemObjectType;
}

export const convertFileSystemObjectToDto = (fileSystemObject: FileSystemObject): FileSystemObjectDto => {
	return {
		Drives: fileSystemObject.Drives.map((drive) => ({
			Ident: drive.Ident,
			Location: drive.Location,
			Type: drive.Type
		})),
		Id: fileSystemObject.Id,
		Name: fileSystemObject.Name,
		Path: fileSystemObject.Path,
		Type: fileSystemObject.Type
	};
}

export const mapFileSystemObjectsToDto = (fileSystemObjects: FileSystemObject[]): FileSystemObjectDto[] => {
	return fileSystemObjects.map((fileSystemObject) => convertFileSystemObjectToDto(fileSystemObject));
}