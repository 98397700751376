import * as React from "react";
import { Dropdown, IDropdownOption } from "@fluentui/react";
import { useTranslation } from 'react-i18next';
import { getRegisteredLanguages, setGlobalLanguage } from "../../Utils/i18nHelper"

export const LanguageSelector = (): JSX.Element => {
    const { i18n } = useTranslation();

    const options: IDropdownOption[] = getRegisteredLanguages().map((lng) => ({ key: lng, text: lng.toUpperCase() }) as IDropdownOption)

    return (
        <Dropdown
            className="LngDropdown"
            options={options}
            selectedKey={i18n.language}
            onChange={(event, option) => setGlobalLanguage(`${option?.key}`)}
            disabled={options.length <= 1}
        />
    );
};