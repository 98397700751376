import * as React from "react";
import { ICommandBarItemProps , DialogType } from "@fluentui/react";
import { SpecialFolders } from "../Model/SpecialFolder";
import { createFolder } from "../Api/File/CreateFolder";
import { SingleLineTextfieldDialog } from "../Components/SingleLineTextfieldDialog/SingleLineTextfieldDialog";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../Store/useAppDispatch";
import { CommandBarCommandProps } from "../Utils/useCommands";

export const AddFolderCommand = (props: CommandBarCommandProps): JSX.Element => {
	const [showDialog, setShowDialog] = React.useState(false);
	const [newFolderName, setNewFolderName] = React.useState("");
	const { selectedFolderId, selectedSubFolderPath, isReadonlyFolder: isReadOnlyFolder } = useAppSelector(state => state.file);
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const specialFolder = useMemo(() => SpecialFolders.find(specialFolder => specialFolder.Id === selectedFolderId), [selectedFolderId]);
	const showCommand = useMemo(() => {
		return !isReadOnlyFolder && !!selectedFolderId && (!specialFolder || (specialFolder.TargetFolderId && specialFolder.HasSubfolders))
	}, [isReadOnlyFolder, selectedFolderId, specialFolder]);

	if (showCommand) {
		props.addCommandButton({
			key: "addFolder",
			text: t("Commands.AddFolder.Command"),
			iconProps: { iconName: "FabricNewFolder" },
			onClick: () => { setNewFolderName(""); setShowDialog(true); return false; },
		});
	}

	const dialogContentProps = {
		type: DialogType.largeHeader,
		title: t("Commands.AddFolder.DialogTitle"),
		closeButtonAriaLabel: 'Close'
	};

	const onCommand = React.useCallback(() => {
		dispatch(createFolder(selectedSubFolderPath || selectedFolderId || "", newFolderName));
		setShowDialog(false);
	}, [dispatch, selectedSubFolderPath, selectedFolderId, newFolderName]);

	return <>
		{showDialog &&
			<SingleLineTextfieldDialog
				dialogContentProps={dialogContentProps}
				onCommand={onCommand}
				onDismiss={() => setShowDialog(false)}
				commandIsDisabled={!newFolderName}
				hidden={false}
				label={t("Commands.AddFolder.NewFolderName")}
				value={newFolderName}
				onChange={(newValue) => setNewFolderName(newValue)}
				okLabel={t("Commands.AddFolder.Ok")}
			/>
		}
	</>;
}