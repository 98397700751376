import { IStyle, makeStyles } from "@fluentui/react";

export default () => {
	let styles = makeStyles(theme => ({
		FileDetailList: {
			flex: "1 1 auto",
			overflowY: "auto",
		},
		DetailsList: {
			".ColumnContextMenu": {
				color: theme.palette.neutralSecondary,
				".ms-Button:hover": {
					backgroundColor: theme.palette.neutralLight,
					i: {
						color: theme.palette.themePrimary
					}
				} as IStyle
			},
			"a": {
				color: theme.palette.neutralPrimary,
				text: "decoration: none"
			},
			"a:hover": {
				color: theme.palette.themePrimary
			},

			"i.Shared-File": {
				color: theme.palette.themePrimary
			},

			"i.Shared-File-Stack-1": {
				"-webkit-mask": "linear-gradient(to left bottom, black 50%, transparent 50%)",
				mask: "linear-gradient(to left bottom, black 50%, transparent 50%)"
			} as IStyle,
			"i.Shared-File-Stack-2": {
				marginLeft: "-16px"
				//-webkit-mask: linear-gradient( to right top, black 48%, transparent 48%);
				//mask: linear-gradient( to left top, black 48%, transparent 48%);
			} as IStyle,
			"i.Shared-File-Stack-3": {
				marginLeft: "-16px",
				"-webkit-mask": "linear-gradient(to right top, black 50%, transparent 50%)",
				mask: "linear-gradient(to right top, black 50%, transparent 50%)"
			} as IStyle,
			"i.Error-File": {
				// color: theme?.palette.themeDarker
			},
			"a.ms-Link.is-disabled": {
				color: theme.palette.neutralSecondary,

				":hover": {
					color: theme.palette.neutralSecondary,
				} as IStyle
			} as IStyle,
		},
		FolderLink: {
			width: "100%",
			display: "flex",
			textAlign: "left",
			justifyContent: "space-between",

			":hover": {
				textDecoration: "none"
			} as IStyle,

			".Files-Count": {
				color: theme.palette.neutralTertiary,
				fontSize: theme.fonts.small.fontSize,
				textAlign: "right",
				paddingRight: "5px"
			} as IStyle,
		},
		ProgressBar: {
			position: "absolute",
			width: "100%"
		},
		HiddenContentBanner: {
			marginTop: "7px",
			textAlign: "center",
			color: theme.palette.neutralTertiary,
			fontSize: theme.fonts.tiny.fontSize
		},
		DropZone: {
			border: `dashed 2px ${theme.palette.neutralPrimary}`,
			borderRadius: "8px",
			backgroundColor: theme.palette.neutralSecondary,
			position: "absolute",
			left: "0px",
			top: "0px",
			right: "0px",
			bottom: "0px",
			/*width: 100%;
			height: 100vh;*/
			zIndex: 1000,
			opacity: "20%"
		},
		RowError: {
			borderTop: "solid 1px " + theme.semanticColors.errorText,
			borderBottom: "solid 1px " + theme.semanticColors.errorText,
			backgroundColor: `${theme.semanticColors.errorBackground}99`,

			":hover": {
				backgroundColor: `${theme.semanticColors.errorBackground}AF`,
			},
			":active, :focus, :focus:hover, &.is-selected": {
				backgroundColor: `${theme.semanticColors.errorBackground}CC`,
			},
			"&.is-selected:hover": {
				backgroundColor: theme.semanticColors.errorBackground,
			},
		},
	}));
	return styles();
};