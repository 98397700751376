import { IStyle, makeStyles } from "@fluentui/react";

export default () => {
	let styles = makeStyles(theme => ({
		ColumnContextMenu: {
			display: "flex",
			justifyContent: "space-between",
			marginRight: "-20px",
		},
		MenuButton: {
			marginTop: "-7px",
			marginBottom: "-6px",
			verticalAlign: "middle",
			alignSelf: "flex-end"
		}
	}));
	return styles();
};