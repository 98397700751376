import { Step } from "react-shepherd";

const steps : Step.StepOptions[] = [
	{
		id: 'Intro',
		attachTo: { element: 'span.StatusHeader_Key', on: 'bottom-start' },
		title: 'Welcome to myPhoenixTestlab!',
		text: ['This is the check list tag where you can access the check list of your order']
	},
	{
		id: 'Progress',
		attachTo: { element: '.ProgressChart', on: 'right' },
		title: 'Overall progress',
		text: ['The progress shows here is the summary of all orders'],
	},
	{
		id: 'TaskChecklist_Description',
		attachTo: { element: '.TaskChecklist_Description', on: 'top' },
		title: 'Description',
		text: ['Description'],
	},
	{
		id: 'TaskChecklist_Value',
		attachTo: { element: '.TaskChecklist_Value', on: 'top' },
		title: 'Value',
		text: ['Value']
	}
];

export default steps;