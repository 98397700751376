export enum CheckListActions {
	Request = "CheckList/Request",
	Received = "CheckList/Received",
	ReceiveFailed = "CheckList/ReceiveFailed",
	EditCheckList = "CheckList/Edit",
	SetNewCheckListValue = "CheckList/SetNewValue",
	CancelCheckList = "CheckList/Cancel",
	SavingCheckList = "CheckList/Saving",
	SaveFailedCheckList = "CheckList/SaveFailed",
	SavedCheckList = "CheckList/Saved"
}