import * as React from "react";
import { useSelector } from "react-redux";
import { DetailsList, IColumn, SelectionMode, DetailsListLayoutMode, IGroup, IDetailsGroupDividerProps, IDetailsRowProps, DetailsRow, ColumnActionsMode, CommandBar, ICommandBarItemProps } from "@fluentui/react";
import { loadTeamList } from "../../Api/TeamList/LoadTeamList";
import { ReloadComponent } from "../../Components/ReloadComponent/ReloadComponent";
import { RootState, toggleTeamDetailsPanel } from "../../Store";
import { Loading } from "../../Components/Loading";
import { RefreshTeamListCommand, SearchTeamListCommand } from "../../ToolbarCommands";
import useStyles from "./TeamList.style"
import { useTranslation } from "react-i18next";
import { Channel } from "../../Model/Channel";
import { ActionButton, css, IRenderFunction } from "@fluentui/react";
import { loadTeamDetails } from "../../Api/TeamList/LoadTeamDetails";
import { TeamDetailsPanel } from "../../Components/TeamDetailsPanel/TeamDetailsPanel"
import Highlighter from "react-highlight-words"
import { MouseContextualMenu } from "../../Components/MouseContextualMenu/MouseContextualMenu";
import { useAppDispatch, useAppSelector } from "../../Store/useAppDispatch";

export const TeamList = () => {
	const { isLoading, teams: teamList, teamListLoadError, searchValue } = useAppSelector(state => state.teamList);

	const { isInitialized } = useAppSelector(state => state.teams);
	const dispatch: React.Dispatch<any> = useAppDispatch();
	const { t, i18n } = useTranslation();
	const styles = useStyles();
	const debouncedSearchTerm = useDebounce(searchValue, 500);

	const loadData = React.useCallback(
		() => {
			dispatch(loadTeamList());
		},
		[dispatch]
	);

	React.useEffect(() => {
		if (isInitialized && !isLoading && !teamListLoadError && !teamList) {
			loadData();
		}
	});

	const [items, setItems] = React.useState<Channel[]>([]);
	const [groups, setGroups] = React.useState<IGroup[]>([]);
	const [sortProperty, setSortProperty] = React.useState<string>("Name");
	const [sortPropertyDirectionASC, setSortPropertyDirectionASC] = React.useState<boolean>(true);
	const [selectedChannel, setSelectedChannel] = React.useState<Channel>({
		Description: "",
		Id: "",
		Name: "",
		ParentId: ""
	});

	const matchesSearchValue = React.useCallback(
		(value: string, searchValue: string): boolean => {
			let val = value.toLowerCase();
			let searchVal = (searchValue ?? "").toLowerCase();

			if (val.includes(searchVal) || searchVal === "") {
				return true;
			}
			return false;
		}, []);

	const sortGroups = React.useCallback((sortColumn: string) => {
		setGroups(groups.sort((a, b) => {
			const order = sortPropertyDirectionASC ? 1 : -1;
			switch (sortColumn) {
				case "Name":
				default:
					return a.name.toLowerCase() > b.name.toLowerCase() ? order : -order;
			}
		}))
		setSortProperty(sortColumn);
		setSortPropertyDirectionASC(sortColumn === sortProperty ? !sortPropertyDirectionASC : true)
	}, [groups, sortProperty, sortPropertyDirectionASC]);

	React.useEffect((): void => {
		if (!teamList || !teamList.length) {
			return;
		}

		const newGroups: IGroup[] = [];
		const newItems: Channel[] = [];

		teamList.forEach(team => {
			const newGroup: IGroup = {
				key: team.Id,
				name: team.Name,
				startIndex: newItems.length,
				count: team.Channels.length,
				level: 0,
				data: team,
				isCollapsed: groups.find((g) => g.key === team.Id)?.isCollapsed ?? true // if new data is loaded, use last state
			}

			const foundChannels: Channel[] = [];
			let addChannels = false;

			if (matchesSearchValue(team.Name, debouncedSearchTerm) &&
				newGroups.findIndex((item) => item.name === team.Name) < 0 &&
				debouncedSearchTerm.length > 0) {
				addChannels = true;
			}

			team.Channels.forEach(channel => {

				if (matchesSearchValue(channel.Name, debouncedSearchTerm)) {
					addChannels = true;
				}
			});

			if (addChannels) {
				foundChannels.push(...team.Channels);
				newGroup.count = foundChannels.length;
				if (debouncedSearchTerm.length > 0) {
					newGroup.isCollapsed = false;
				}
				newGroups.push(newGroup);
			}

			newItems.push(...foundChannels);
		});
		setGroups(newGroups);
		setItems(newItems);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [teamList, debouncedSearchTerm, matchesSearchValue]); // "groups" must be excluded here

	const [initialTeamListJustLoaded, setInitialTeamListJustLoaded] = React.useState(false);
	React.useEffect(() => {
		if (initialTeamListJustLoaded || !teamList) {
			return;
		}
		setTimeout(() => { setInitialTeamListJustLoaded(true) }, 100);
	}, [initialTeamListJustLoaded, teamList]);

	const commands: ICommandBarItemProps[] = [];
	const farCommands: ICommandBarItemProps[] = [];

	const columns: IColumn[] = [
		{
			key: "Name",
			name: t("TeamList.Title"),
			fieldName: "Name",
			minWidth: 200,
			maxWidth: 700,
			isRowHeader: true,
			isResizable: true,
			data: "string",
			isPadded: true,
			isSorted: sortProperty === "Name",
			isSortedDescending: sortProperty === "Name" ? !sortPropertyDirectionASC : undefined,
			onColumnClick: () => {
				sortGroups("Name");
			}
		},
		{
			key: "Status",
			name: t("TeamList.Status"),
			fieldName: "Status",
			minWidth: 50,
			maxWidth: 200,
			isResizable: true,
			data: "string",
			isPadded: true,
			columnActionsMode: ColumnActionsMode.disabled,
			onRender: (channel: Channel) => {
				return (i18n.language.startsWith("de") ? channel.StatusDe : channel.StatusEn) ?? t("TeamList.StatusNotFound");
			}
		},
		{
			key: "FileDeepLink",
			name: t("TeamList.Link"),
			fieldName: "FileDeepLink",
			minWidth: 50,
			isResizable: true,
			isPadded: true,
			columnActionsMode: ColumnActionsMode.disabled,
			className: styles.LinksColumn,
			onRender: (channel: Channel) => {
				const linkPrefix = t("TeamList.LinkTitlePrefix") + t(`TeamList.ChannelType.${channel.ChannelType??"Unknown"}`);
				return <div className={styles.DeepLinksContainer}>
					<div className={styles.DeepLinkItem}>
						{channel.ChatDeepLink &&
							<ActionButton
								styles={{ root: { height: "auto" } }}
								iconProps={{ iconName: channel.ChatDeepLink ? "OfficeChat" : "" }}
								onClick={(event) => {
									if (channel.ChatDeepLink) {
										microsoftTeams.executeDeepLink(channel.ChatDeepLink)
									}
									event.stopPropagation();
									event.preventDefault();
								}}
								title={t("TeamList.LinkTitlePrefix") + t("TeamList.ChatDeepLink")}
							/>
						}
					</div>
					<div className={styles.DeepLinkItem}>
						{channel.StatusDeepLink &&
							<ActionButton
								styles={{ root: { height: "auto" } }}
								iconProps={{ iconName: channel.StatusDeepLink ? "GoToDashboard" : "" }}
								onClick={(event) => {
									if (channel.StatusDeepLink) {
										microsoftTeams.executeDeepLink(channel.StatusDeepLink)
									}
									event.stopPropagation();
									event.preventDefault();
								}}
								title={linkPrefix +  t("TeamList.StatusDeepLink")}
							/>
						}
					</div>
					<div className={styles.DeepLinkItem}>
						{channel.SummaryDeepLink &&
							<ActionButton
								styles={{ root: { height: "auto" } }}
								iconProps={{ iconName: "ComplianceAudit" }}
								onClick={(event) => {
									if (channel.SummaryDeepLink) {
										microsoftTeams.executeDeepLink(channel.SummaryDeepLink)
									}
									event.stopPropagation();
									event.preventDefault();
								}}
								title={linkPrefix +  t("TeamList.SummaryDeepLink")}
							/>
						}
					</div>
					<div className={styles.DeepLinkItem}>
						{channel.FileDeepLink &&
							<ActionButton
								styles={{ root: { height: "auto" } }}
								iconProps={{ iconName: "FabricFolder" }}
								onClick={(event) => {
									if (channel.FileDeepLink) {
										microsoftTeams.executeDeepLink(channel.FileDeepLink)
									}
									event.stopPropagation();
									event.preventDefault();
								}}
								title={linkPrefix +  t("TeamList.FileDeepLink")}
							/>
						}
					</div>
				</div>
			},
		}
	];

	const onClickItem = React.useCallback((item: Channel) => {
		if (item.DetailsLoadingState !== "Loaded" && item.DetailsLoadingState !== "Loading") {
			dispatch(loadTeamDetails(item.ParentId))
		}
		setSelectedChannel(item);
		dispatch(toggleTeamDetailsPanel())
	}, [dispatch])

	function _renderItemColumn(item: Channel, index?: number, column?: IColumn) {
		const fieldContent = item[column?.fieldName as keyof Channel] as string;
		switch (column?.key) {
			case 'Name':
				return <Highlighter
					activeClassName={styles.Active}
					highlightClassName={styles.Highlight}
					searchWords={[searchValue]}
					textToHighlight={fieldContent}
				/>

		}
		return <Highlighter
			activeClassName={styles.Active}
			highlightClassName={styles.Highlight}
			searchWords={[searchValue]}
			textToHighlight={fieldContent}
		/>
	}

	const onRenderGroupHeader = React.useCallback((props: IDetailsGroupDividerProps | undefined, _defaultRender?: IRenderFunction<IDetailsGroupDividerProps>) => {
		// for fake groups - return empty element
		if (!props?.group!.data || !_defaultRender) {
			return <></>;
		}
		return <div onClick={() => props!.onToggleCollapse!(props!.group!)} className={styles.GroupHeader}>
			{_defaultRender(props)}
		</div>
		// default rendering for "real" groups

	}, [styles.GroupHeader])

	const renderRow = React.useCallback((props: IDetailsRowProps | undefined) => {
		if (!props) return null;

		return <MouseContextualMenu menuItems={commands} onClick={() => onClickItem(props.item)} className={styles.Clickabel}>
			<DetailsRow {...props} />
		</MouseContextualMenu>
	}, [commands, onClickItem, styles.Clickabel]);


	return (
		<div className={styles.TeamList}>
			<SearchTeamListCommand addCommandButton={(command) => farCommands.push(command)} />
			<RefreshTeamListCommand addCommandButton={(command) => commands.push(command)} />
			{isLoading && <Loading />
			}
			{!isLoading && teamListLoadError && !teamList &&
				<ReloadComponent errorMessage={teamListLoadError} reload={() => loadData()} />
			}
			{!isLoading && !teamListLoadError && <>
				<CommandBar className={styles.CommandBar} items={commands} farItems={farCommands} />

				{teamList &&
					<div className={css(initialTeamListJustLoaded ? styles.DetailsListWrapperBeforeLoaded : undefined)}>
						<DetailsList
							items={items ?? []}
							onRenderRow={renderRow}
							groups={groups}
							compact={false}
							columns={columns}
							selectionMode={SelectionMode.none}
							layoutMode={DetailsListLayoutMode.justified}
							isHeaderVisible={true}
							onRenderItemColumn={_renderItemColumn}
							groupProps={{
								isAllGroupsCollapsed: true,
								onRenderHeader: onRenderGroupHeader
							}}
						/>
						<TeamDetailsPanel
							selectedChannel={selectedChannel}
						/>
					</div>
				}
			</>
			}
		</div>
	);
}
// Hook
function useDebounce(value: string, delay: number) {
	// State and setters for debounced value
	const [debouncedValue, setDebouncedValue] = React.useState(value);
	React.useEffect(
		() => {
			// Update debounced value after delay
			const handler = setTimeout(() => {
				//check for undefined to set viable values only
				if (!value) {
					value = "";
				}
				setDebouncedValue(value);
			}, delay);
			// Cancel the timeout if value changes (also on delay change or unmount)
			// This is how we prevent debounced value from updating if value is changed ...
			// .. within the delay period. Timeout gets cleared and restarted.
			return () => {
				clearTimeout(handler);
			};
		},
		[value, delay] // Only re-call effect if value or delay changes
	);
	return debouncedValue;
}