import * as React from "react";
import { DetailsList, IColumn, Selection, SelectionMode, DetailsListLayoutMode, CheckboxVisibility, IDragDropEvents, IDragDropContext, IDetailsRowProps, DetailsRow , css, Icon, Link, ProgressIndicator, Spinner, SpinnerSize, TooltipHost, ICommandBarProps, ICommandBarItemProps } from "@fluentui/react";
import FormatByte from "../../Formatter/FormatByte";
import { FileSystemObject } from "../../Model/FileSystemObject";
import { parentFolderKey, SpecialFolders } from "../../Model/SpecialFolder";
import { batch } from "react-redux";
import { selectFiles, setDragDropFiles, selectSubFolderPath, selectFolders, setDragDropFolders, sortFiles } from "../../Store";
import { uploadDataTransfer } from "../../Api/File/UploadFiles";
import { OpenFileLink } from "../OpenFileLink/OpenFileLink";
import ColumnContextMenu from "../ColumnContextMenu/ColumnContextMenu";
import FormatDateTime from "../../Formatter/FormatDateTime";
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import { DownloadCommand, RenameFileCommand, MoveFileCommand, ShareCommand, DeleteFileCommand, CopyLinkCommand } from "../../ToolbarCommands";
import { renameFile } from "../../Api/File/RenameFile";
import { moveFolder } from "../../Api/File/MoveFolder";
import { useCallback, useMemo } from "react";
import useStyles from "./FileDetailList.style"
import { useTranslation } from "react-i18next";
import { areSameDrives } from "../../Utils/CompareDrive";
import { useGlobalStyles } from "../../Global.style";
import { RenameFolderCommand } from "../../ToolbarCommands";
import { MouseContextualMenu } from "../MouseContextualMenu/MouseContextualMenu"
import { useCommands } from "../../Utils/useCommands";
import { getFirstDrive } from "../../Utils/GetFirstDrive";
import { getFileSystemObjectKeyString } from "../../Utils/GetKeyString";
import { splitFilename } from "../../Utils/SplitFilename";
import { useAppDispatch, useAppSelector } from "../../Store/useAppDispatch";
import { FileTags } from "../FileTags/FileTags";

export interface FileDetailListProps {
}

export const FileDetailList = (props: FileDetailListProps) => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const { drives, selectedFolderId, selectedFolderFiles, selectedFolderSubFolders, selectedSubFolderPath, sourceType, dragDropFiles, dragDropFolders, sortProperty, sortPropertyDirectionASC, tags } = useAppSelector(state => state.file);
	const [dragOver, setDragOver] = React.useState<boolean>(false);
	const [dragOverItem, setDragOverItem] = React.useState<FileSystemObject | undefined>(undefined);

	const styles = useStyles();
	const globalStyles = useGlobalStyles()


	const columns: IColumn[] = [
		{
			key: "FileType",
			name: "",
			minWidth: 16,
			maxWidth: 16,
			data: "string",
			iconName: "TextDocument",
			onRender: (driveObject: FileSystemObject) => {
				if (driveObject.hasRunningAction) {
					return <Spinner size={SpinnerSize.small} />;
				}
				if (driveObject.hasError) {
					return <TooltipHost content={driveObject.errorMessage}>
						<Icon iconName={"ErrorBadge"} className="Error-File ms-fontSize-16" />
					</TooltipHost>;
				}
				if (driveObject.Type === "Folder") {
					return <Icon iconName={"FabricFolder"} />;
				}

				const iconProps = getFileTypeIconProps({ extension: splitFilename(driveObject.Name).suffix });
				return <Icon iconName={iconProps.iconName} />;
			}
		},
		{
			key: "SharedWithCustomer",
			name: "",
			minWidth: 16,
			maxWidth: 16,
			data: "string",
			iconName: "People",
			onRender: (driveObject: FileSystemObject) => {
				if (driveObject.SharedByLink && driveObject.SharedWithCustomer) {
					return <>
						<Icon iconName="People" className="Shared-File Shared-File-Stack-1 ms-fontSize-16" />
						<Icon iconName="Line" className="Shared-File Shared-File-Stack-2 ms-fontSize-16" />
						<Icon iconName="Globe" className="Shared-File Shared-File-Stack-3 ms-fontSize-16" />
					</>;
				}
				else if (driveObject.SharedByLink) {
					return <Icon iconName="Globe" className="Shared-File ms-fontSize-16" />;
				}
				else if (driveObject.SharedWithCustomer) {
					return <Icon iconName="People" className="Shared-File ms-fontSize-16" />;
				}
				else {
					return <></>;
				}
			}
		},
		{
			key: "FromTaskDrive",
			name: "",
			minWidth: 16,
			maxWidth: 16,
			data: "string",
			iconName: "Folder",
			onRender: (driveObject: FileSystemObject) => {
				if (getFirstDrive(driveObject?.Drives)?.Type === "Task") {
					return <Icon iconName="Folder" className="ms-fontSize-16" />;
				}
				else {
					return <></>;
				}
			}
		},
		{
			key: "Name",
			name: t("FileBase.File.Name"),
			fieldName: "Name",
			minWidth: 100,
			maxWidth: 800,
			isRowHeader: false,
			isResizable: true,
			isSorted: sortProperty === "Name",
			isSortedDescending: sortProperty === "Name" ? !sortPropertyDirectionASC : undefined,
			data: "string",
			isPadded: true,
			onColumnClick: () => {
				dispatch(sortFiles("Name", sortProperty === "Name" ? !sortPropertyDirectionASC : true))
			},
			onRender: (driveObject: FileSystemObject) => {
				if (driveObject.Type === "Folder") {
					return <ColumnContextMenu menuItems={menuItems} hideMenuButton={driveObject.Id === parentFolderKey}>
						<Link href="#" disabled={driveObject.hasRunningAction} className={styles.FolderLink} onClick={() => dispatch(selectSubFolderPath(driveObject.Path === "" ? undefined : driveObject.Path))}>
							{driveObject.Name}
							{(driveObject.filesCount ?? 0) > 0 && <span className="Files-Count">{driveObject.filesCount?.toLocaleString()}</span>}
						</Link>
					</ColumnContextMenu>;
				} else if (driveObject.Type === "File") {
					return <ColumnContextMenu menuItems={menuItems}>
						<OpenFileLink file={driveObject} >
							{driveObject.Name}
						</OpenFileLink>
					</ColumnContextMenu>;
				} else {
					return <></>;
				}
			},
		},
		{
			key: "Tags",
			name: t("FileBase.File.Tags"),
			fieldName: "Tags",
			minWidth: 100,
			maxWidth: 300,
			isRowHeader: false,
			isResizable: true,
			isSorted: sortProperty === "Size",
			isSortedDescending: sortProperty === "Size" ? !sortPropertyDirectionASC : undefined,
			data: "string",
			isPadded: true,
			onColumnClick: () => {
				dispatch(sortFiles("Size", sortProperty === "Size" ? !sortPropertyDirectionASC : true))
			},
			onRender: (driveObject: FileSystemObject) => {
				if (driveObject.Type === "Folder") {
					return "";
				}

				return <FileTags file={driveObject} tags={driveObject.Tags} />;
			}
		},
		{
			key: "Size",
			name: t("FileBase.File.Size"),
			fieldName: "Size",
			minWidth: 50,
			maxWidth: 100,
			isRowHeader: false,
			isResizable: true,
			isSorted: sortProperty === "Size",
			isSortedDescending: sortProperty === "Size" ? !sortPropertyDirectionASC : undefined,
			data: "string",
			isPadded: true,
			onColumnClick: () => {
				dispatch(sortFiles("Size", sortProperty === "Size" ? !sortPropertyDirectionASC : true))
			},
			onRender: (driveObject: FileSystemObject) => {
				if (driveObject.Type === "Folder") {
					return "";
				}

				const formattedSize = FormatByte(driveObject.Size, 2);
				return <>
					<div>
						{driveObject.processedSize != null &&
							<span>
								{`${parseFloat((driveObject.processedSize / formattedSize.dividend).toFixed(1))} ${t("FileBase.File.Of")} `}
							</span>
						}
						<span>
							{formattedSize.result}
						</span>
					</div>
					<div className={styles.ProgressBar}>
						{driveObject.processedSize != null &&
							<ProgressIndicator percentComplete={driveObject.processedSize ? driveObject.processedSize / driveObject.Size : 0} />
						}
					</div>
				</>;
			}
		},
		{
			key: "ModifiedOn",
			name: t("FileBase.File.ModifiedOn"),
			fieldName: "ModifiedOn",
			minWidth: 50,
			maxWidth: 100,
			isRowHeader: false,
			isResizable: true,
			isSorted: sortProperty === "ModifiedOn",
			isSortedDescending: sortProperty === "ModifiedOn" ? !sortPropertyDirectionASC : undefined,
			data: "string",
			isPadded: true,
			onColumnClick: () => {
				dispatch(sortFiles("ModifiedOn", sortProperty === "ModifiedOn" ? !sortPropertyDirectionASC : true))
			},
			onRender: (driveObject: FileSystemObject) => {
				return FormatDateTime(driveObject.ModifiedOn);
			}
		},
		{
			key: "ModifiedBy",
			name: t("FileBase.File.ModifiedBy"),
			fieldName: "ModifiedBy",
			minWidth: 50,
			maxWidth: 100,
			isRowHeader: false,
			isResizable: true,
			isSorted: sortProperty === "ModifiedBy",
			isSortedDescending: sortProperty === "ModifiedBy" ? !sortPropertyDirectionASC : undefined,
			data: "string",
			isPadded: true,
			onColumnClick: () => {
				dispatch(sortFiles("ModifiedBy", sortProperty === "ModifiedBy" ? !sortPropertyDirectionASC : true))
			},
			onRender: (driveObject: FileSystemObject) => {
				return driveObject.ModifiedBy;
			}
		}
	];

	if (sourceType === "Task") {
		columns.splice(5, 0,
			{
				key: "Drive",
				name: t("FileBase.File.Drive"),
				fieldName: "Drive",
				minWidth: 50,
				maxWidth: 150,
				isRowHeader: false,
				isResizable: true,
				isSorted: sortProperty === "Drive",
				isSortedDescending: sortProperty === "Drive" ? !sortPropertyDirectionASC : undefined,
				data: "string",
				isPadded: true,
				onColumnClick: () => {
					dispatch(sortFiles("Drive", sortProperty === "Drive" ? !sortPropertyDirectionASC : true))
				},
				onRender: (driveObject: FileSystemObject) => {
					const fileDrive = getFirstDrive(driveObject.Drives)
					if (fileDrive) {
						return fileDrive.Number;
					} else {
						return "";
					}
				}
			});
	}

	if (!selectedFolderId || SpecialFolders.some(specialFolder => specialFolder.Id === selectedFolderId && !specialFolder.HasSubfolders)) {
		columns.splice(5, 0, {
			key: "folder",
			name: t("FileBase.File.Folder"),
			fieldName: "Folder",
			minWidth: 50,
			maxWidth: 200,
			isRowHeader: false,
			isResizable: true,
			isSorted: sortProperty === "Folder",
			isSortedDescending: sortProperty === "Folder" ? !sortPropertyDirectionASC : undefined,
			data: "string",
			isPadded: true,
			onColumnClick: () => {
				dispatch(sortFiles("Folder", sortProperty === "Folder" ? !sortPropertyDirectionASC : true))
			},
			onRender: (driveObject: FileSystemObject) => {
				if (driveObject.Type === "File") {
					return driveObject.Path;
				} else {
					return "";
				}
			}
		});
	}


	const selection: Selection = new Selection({
		canSelectItem: (item) => {
			return (item as FileSystemObject).Id !== parentFolderKey;
		},
		onSelectionChanged: () => {
			if (selection.count > 0) {
				let selectedItems = selection.getSelection() as FileSystemObject[];
				batch(() => {
					dispatch(selectFiles(selectedItems.filter(item => item.Type === "File")));
					dispatch(selectFolders(selectedItems.filter(item => item.Type === "Folder")));
				});
			} else {
				batch(() => {
					dispatch(selectFiles([]));
					dispatch(selectFolders([]));
				});
			}
		}
	});

	const specialFolder = SpecialFolders.find(specialFolder => specialFolder.Id === selectedFolderId);
	const isFolderSelected = !!selectedFolderId && (!specialFolder || specialFolder.TargetFolderId != null);

	const fileDragDropEvents: IDragDropEvents = React.useMemo(() => ({
		canDrop: (dropContext?: IDragDropContext, dragContext?: IDragDropContext) => {
			return isFolderSelected;
		},
		canDrag: (item?: FileSystemObject) => {
			return true;
		},
		onDragEnter: (item?: any, event?: DragEvent) => {
			event?.stopPropagation();
			event?.preventDefault();

			if (!isFolderSelected || (event?.dataTransfer?.files?.length || 0) <= 0) {
				setDragOverItem(item);
				setDragOver(false);
			} else {
				setDragOver(true);
			}
			return "";
		},
		onDragStart: (item?: any, itemIndex?: number, selectedItems?: FileSystemObject[], event?: MouseEvent) => {
			let selectedFiles = selectedItems?.filter(item => item.Type === "File") ?? [];
			let selectedFolders = selectedItems?.filter(item => item.Type === "Folder") ?? [];
			
			if(item.Type === "File") {
				selectedFiles?.push(item);
			}else if(item.Type === "Folder") {
				selectedFolders?.push(item);
			}

			batch(() => {
				dispatch(setDragDropFiles(selectedFiles));
				dispatch(setDragDropFolders(selectedFolders));
			});
		},
		onDragEnd: (item?: FileSystemObject, event?: DragEvent) => {
			if (dragOverItem && dragOverItem.Type === "Folder") {
				if (dragDropFiles) {
					for (const file of dragDropFiles) {
						dispatch(renameFile(file, dragOverItem.Path, file.Name, getFirstDrive(file.Drives), false));
					}
				}
				if (dragDropFolders) {
					for (const folder of dragDropFolders) {
						dispatch(moveFolder(folder, `${dragOverItem.Path}/${folder.Name}`));
					}
				}
			}
			batch(() => {
				dispatch(selectFiles([]));
				dispatch(selectFolders([]));
				dispatch(setDragDropFiles([]));
				dispatch(setDragDropFolders([]));
				setDragOverItem(undefined);
			});
		},
	}), [dispatch, isFolderSelected, dragDropFiles, dragDropFolders, dragOverItem]);

	const onDragEnter = React.useCallback((event: any) => {
		event?.stopPropagation();
		event?.preventDefault();

		setDragOver(isFolderSelected && (dragDropFiles?.length ?? 0) === 0);
		return false;
	}, [isFolderSelected, dragDropFiles]);

	const onDragLeave = React.useCallback((event: any) => {
		event?.stopPropagation();
		event?.preventDefault();
		setDragOver(false);
		return false;
	}, []);

	const onDragOver = React.useCallback((event: any) => {
		event?.stopPropagation();
		event?.preventDefault();

		if (!isFolderSelected) {
			event.dataTransfer.dropEffect = "none";
			setDragOver(false);
		} else {
			event.dataTransfer.dropEffect = "move";
		}
		return false;
	}, [isFolderSelected]);

	const onDrop = React.useCallback((event: any) => {
		event?.stopPropagation();
		event?.preventDefault();


		setDragOver(false);
		const files = event?.dataTransfer?.files;
		if (isFolderSelected && files && files.length > 0) {
			dispatch(uploadDataTransfer(event?.dataTransfer, selectedSubFolderPath ?? selectedFolderId, false));
		}

		return false;
	}, [dispatch, isFolderSelected, selectedSubFolderPath, selectedFolderId]);

	const [menuItems, addMenuItem] = useCommands();
	const renderRow = useCallback((props: IDetailsRowProps | undefined) => {
		if (!props) return null;

		var driveObject = props.item as FileSystemObject;

		return <MouseContextualMenu menuItems={menuItems}>
			<DetailsRow {...props} className={css(driveObject.hasError && styles.RowError)} />
		</MouseContextualMenu>
	}, [menuItems, styles.RowError]);

	const parentPath = selectedSubFolderPath && selectedSubFolderPath.lastIndexOf("/") > 0 ? selectedSubFolderPath.substring(0, selectedSubFolderPath.lastIndexOf("/")) : "";

	const hasHiddenContent = useMemo(() =>
		selectedFolderFiles?.some(file => drives.some(drive => !drive.isSelected && areSameDrives(file?.Drives, [drive])))
		, [drives, selectedFolderFiles]);

	var items: FileSystemObject[] = useMemo(() => [
		...selectedSubFolderPath && selectedSubFolderPath !== selectedFolderId ? [
			{
				Id: parentFolderKey,
				Name: "...",
				Path: parentPath === specialFolder?.TargetFolderId ? "" : parentPath,
				Drives: [],
				IsReadonly: true,
				Type: "Folder",
				Size: -1,
				Url: "",
				CreatedOn: "",
				CreatedBy: "",
				ModifiedOn: "",
				ModifiedBy: ""
			} as FileSystemObject] : [],
		...selectedFolderSubFolders || [],
		...selectedFolderFiles?.filter(file => drives.some(drive => drive.isSelected && areSameDrives(file?.Drives, [drive]))) || []
	], [selectedSubFolderPath, selectedFolderId, specialFolder, parentPath, selectedFolderSubFolders, selectedFolderFiles, drives]);

	/*const onRenderCell = React.useCallback((item?: DriveItem) => {
	  return (
		<div
		  data-is-focusable
		  style={{
			  width: '20%',
		  }}
		>
		  <div>
			<div>
			  <span>{item?.file?.Name}</span>
			</div>
		  </div>
		</div>
	  );
	}, []);*/

	return <>
		<DownloadCommand addCommandButton={addMenuItem} />
		<RenameFileCommand addCommandButton={addMenuItem} />
		<RenameFolderCommand addCommandButton={addMenuItem} />
		<MoveFileCommand addCommandButton={addMenuItem} />
		<ShareCommand addCommandButton={addMenuItem} />
		<CopyLinkCommand addCommandButton={addMenuItem} />
		<DeleteFileCommand addCommandButton={addMenuItem} />

		<div className={css(styles.FileDetailList, globalStyles.Scrollbar)}
			onDragEnter={onDragEnter}
			onDragOver={onDragOver}
			//onDragLeave={onDragLeave}  
			onDrop={onDrop}>
			{dragOver && <div className={styles.DropZone} onDragEnter={onDragEnter} onDragOver={onDragOver} onDragLeave={onDragLeave} />}

			<DetailsList
				className={styles.DetailsList}
				checkboxVisibility={CheckboxVisibility.onHover}
				items={items}
				compact={false}
				columns={columns}
				selectionMode={SelectionMode.multiple}
				layoutMode={DetailsListLayoutMode.justified}
				isHeaderVisible={true}
				selection={selection}
				getKey={(fileSystemObject: FileSystemObject) => "FileDetailsList_" + getFileSystemObjectKeyString(fileSystemObject)}
				dragDropEvents={fileDragDropEvents}
				onRenderRow={renderRow}
			/>
			{hasHiddenContent &&
				<div className={styles.HiddenContentBanner}>{t("FileBase.HasHiddenFiles")}</div>
			}
		</div>
	</>;

}