import { Order } from "../../Model/Order";

export enum OrderActions {
	Request = "Order/Request",
	Received = "Order/Received",
	ReceiveFailed = "Order/ReceiveFailed"
}

interface RequestOrderAction {
	type: typeof OrderActions.Request;
	location: string;
	OrderIdent: number;
}
export const requestOrder = (location: string, OrderIdent: number): RequestOrderAction => ({
	type: OrderActions.Request,
	location,
	OrderIdent
});

interface OrderReceivedAction {
	type: typeof OrderActions.Received;
	Order: Order | undefined;
	OrderStatusPercent: number;
}
export const orderReceived = (Order: Order | undefined, OrderStatusPercent: number): OrderReceivedAction => ({
	type: OrderActions.Received,
	Order,
	OrderStatusPercent
});

interface OrderReceiveFailedAction {
	type: typeof OrderActions.ReceiveFailed;
	code: number;
	message: string;
}
export const orderReceiveFailed = (code: number, message: string): OrderReceiveFailedAction => ({
	type: OrderActions.ReceiveFailed,
	code,
	message
});

export type OrderActionTypes =
	RequestOrderAction |
	OrderReceivedAction |
	OrderReceiveFailedAction;