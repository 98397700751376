import * as React from "react";
import { previewFile } from "../Store";
import { FileSystemObject } from "../Model/FileSystemObject";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../Store/useAppDispatch";
import { CommandBarCommandProps } from "../Utils/useCommands";


export const PreviewNavCommand = (props: CommandBarCommandProps): JSX.Element => {
	const { selectedFiles, selectedFolderFiles } = useAppSelector(state => state.file);
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	let prevFile: FileSystemObject | undefined = undefined;
	let nextFile: FileSystemObject | undefined = undefined;

	if (selectedFiles && selectedFolderFiles && selectedFiles.length > 0) {
		const index = selectedFolderFiles.findIndex((x) => x === selectedFiles[0]);
		if (index > 0) {
			prevFile = selectedFolderFiles[index - 1];
		}
		if (index >= 0 && selectedFolderFiles.length > index + 1) {
			nextFile = selectedFolderFiles[index + 1];
		}
	}

	props.addCommandButton({
		key: "prevPreview",
		text: t(`Commands.PrevPreview.Command`),
		iconProps: { iconName: "ChevronLeft" },
		iconOnly: true,
		disabled: !prevFile,
		onClick: () => { dispatch(previewFile(prevFile)); }
	});

	props.addCommandButton({
		key: "PreviewFile",
		text: selectedFiles ? selectedFiles[0].Name : "",
		iconProps: {},
		iconOnly: false,
		disabled: false
	});

	props.addCommandButton({
		key: "nextPreview",
		text: t(`Commands.NextPreview.Command`),
		iconProps: { iconName: "ChevronRight" },
		iconOnly: true,
		disabled: !nextFile,
		onClick: () => { dispatch(previewFile(nextFile)); }
	});

	return <>
	</>;
}