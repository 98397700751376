import * as React from "react";
import "./ChecklistValue.scss";
import { TextField } from "@fluentui/react";

export interface ChecklistIntProps {
	value: string | undefined;
	isEditing: boolean;
	onChange: (value: string | undefined) => void;
}

export class ChecklistInt extends React.Component<ChecklistIntProps, {}> {

	onKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		if (event.key.length >= 2 || (event.ctrlKey && event.key.match(/[axcv]/g))) {
			// allow: function keys, select all, cut, copy, paste
			return true;
		}
		if (event.key.match(/[^\-0-9]/g) || (!(event.target as any).validity.valid) || (event.key === "-" && (event.target as any).defaultValue.indexOf("-") >= 0)) {
			// cancel all characters except numbers
			// cancel if not valid (input type tel)
			// cancel if minus, but there is already one there
			event.preventDefault();
			event.stopPropagation();
			return false;
		}
		return true;
	}

	onChangeGate = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string | undefined) => {
		if (newValue && newValue.length > (this.props.value?.length ?? 0) + 1) {
			// remove unallowed characters if something was pasted
			newValue = newValue.replace(/[^\-0-9]/g, "");
		}
		if (newValue && (newValue.lastIndexOf("-") >= 1 || (!(event.target as any).validity.valid))) {
			// prevent a minus that is not the first character
			event.preventDefault();
			event.stopPropagation();
			return false;
		}
		this.props.onChange(newValue);
	}

	render(): JSX.Element {
		if (this.props.isEditing) {
			return <TextField
				value={this.props.value}
				onChange={(event, newValue) => this.onChangeGate(event, newValue)}
				onKeyDown={(event) => this.onKeyDown(event)}
				autoComplete="off"
				type="tel"
			/>;
		} else {
			if (Number.isSafeInteger(this.props.value)) {
				return <>{Number(this.props.value).toLocaleString()}</>;
			} else {
				return <>{this.props.value}</>;
			}
		}
	}
}