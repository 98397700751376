export const copyToClipboard = (data: string): void => {
	const listener = (e: ClipboardEvent) => {
		e.clipboardData?.setData('text/plain', data);
		e.preventDefault();
		document.removeEventListener('copy', listener);
	};
	document.addEventListener('copy', listener);
	document.execCommand('copy');
}

export const copyHtmlToClipboard = (data: string): void => {
	const listener = (e: ClipboardEvent) => {
		e.clipboardData?.setData('text/html', data);
		e.preventDefault();
		document.removeEventListener('copy', listener);
	};
	document.addEventListener('copy', listener);
	document.execCommand('copy');
}