import { ExternalShare } from "../../Model/ExternalShare";
import { ExternalShareFile } from "../../Model/ExternalShareFile";
import { ExternalSharesResponse } from "../../Model/ExternalSharesResponse";

export enum ExternalShareActions {
	ExternalSharesRequest = "ExternalShares/Request",
	ExternalSharesReceived = "ExternalShares/Received",
	ExternalSharesReceiveFailed = "ExternalShares/ReceiveFailed",
	SortExternalSharesFiles = "ExternalShares/SortExternalSharesFiles",
	SelectExternalShare = "ExternalShares/SelectExternalShare",
	DeselectExternalShare = "ExternalShares/DeselectExternalShare",
	SelectExternalShareFiles = "ExternalShares/SelectExternalShareFiles",
	SetDownloadCountZero = "ExternalShares/SetDownloadCountZero",
	RemoveExternalShares = "ExternalShares/RemoveExternalShares",
	SetExternalSharesValidToDate = "ExternalShares/SetExternalSharesValidToDate"
}

interface ExternalSharesRequestAction {
	type: typeof ExternalShareActions.ExternalSharesRequest;
}
export const requestExternalShares = (): ExternalSharesRequestAction => ({
	type: ExternalShareActions.ExternalSharesRequest
});

interface ExternalSharesReceivedAction {
	type: typeof ExternalShareActions.ExternalSharesReceived;
	sharesResponse:ExternalSharesResponse;
}
export const externalSharesReceived = (sharesResponse: ExternalSharesResponse): ExternalSharesReceivedAction => ({
	type: ExternalShareActions.ExternalSharesReceived,
	sharesResponse: sharesResponse
});

interface ExternalSharesReceiveFailedAction {
	type: typeof ExternalShareActions.ExternalSharesReceiveFailed;
	code: number;
	message: string;
}
export const externalSharesReceiveFailed = (code: number, message: string): ExternalSharesReceiveFailedAction => ({
	type: ExternalShareActions.ExternalSharesReceiveFailed,
	code,
	message
});

interface SortExternalSharesFilesAction {
	type: typeof ExternalShareActions.SortExternalSharesFiles;
	property: keyof (ExternalShareFile);
	sortDirectionASC: boolean;
}
export const sortSharesFiles = (property: keyof (ExternalShareFile), sortDirectionASC: boolean): SortExternalSharesFilesAction => ({
	type: ExternalShareActions.SortExternalSharesFiles,
	property,
	sortDirectionASC
});

interface SelectExternalShareAction {
	type: typeof ExternalShareActions.SelectExternalShare;
	shareId: string;
}
export const selectExternalShare = (shareId: string): SelectExternalShareAction => ({
	type: ExternalShareActions.SelectExternalShare,
	shareId
});

interface DeselectExternalShareAction {
	type: typeof ExternalShareActions.DeselectExternalShare;
	shareId: string;
}
export const deselectExternalShare = (shareId: string): DeselectExternalShareAction => ({
	type: ExternalShareActions.DeselectExternalShare,
	shareId
});

interface SelectExternalShareFilesAction {
	type: typeof ExternalShareActions.SelectExternalShareFiles;
	files: ExternalShareFile[];
}
export const selectExternalShareFiles = (files: ExternalShareFile[]): SelectExternalShareFilesAction => ({
	type: ExternalShareActions.SelectExternalShareFiles,
	files
});

interface SetDownloadCountZeroAction {
	type: typeof ExternalShareActions.SetDownloadCountZero;
	files: ExternalShareFile[];
}
export const setDownloadCountZero = (files: ExternalShareFile[]): SetDownloadCountZeroAction => ({
	type: ExternalShareActions.SetDownloadCountZero,
	files
});

interface RemoveExternalSharesAction {
	type: typeof ExternalShareActions.RemoveExternalShares;
	shareIds: string[];
}
export const removeExternalShares = (shareIds: string[]): RemoveExternalSharesAction => ({
	type: ExternalShareActions.RemoveExternalShares,
	shareIds
});

interface SetExternalSharesValidToDateAction {
	type: typeof ExternalShareActions.SetExternalSharesValidToDate;
	shareIds: string[];
	validToDate: string;
}
export const setExternalSharesValidToDate = (shareIds: string[], validToDate: string): SetExternalSharesValidToDateAction => ({
	type: ExternalShareActions.SetExternalSharesValidToDate,
	shareIds,
	validToDate
});

export type ExternalShareActionTypes =
	ExternalSharesRequestAction |
	ExternalSharesReceivedAction |
	ExternalSharesReceiveFailedAction |
	SortExternalSharesFilesAction |
	SelectExternalShareAction |
	DeselectExternalShareAction |
	SelectExternalShareFilesAction |
	SetDownloadCountZeroAction |
	RemoveExternalSharesAction |
	SetExternalSharesValidToDateAction;