import * as React from "react";
import { PropsWithChildren, useEffect, useState } from "react";
import { PartialTheme, ThemeProvider } from "@fluentui/react";
import useStyles from "./CustomThemeProvider.style"

export interface CustomThemeProviderProps {
}

const getContext = (): Promise<microsoftTeams.Context> => {
	return new Promise((resolve, reject) => {
		microsoftTeams.getContext((context) => resolve(context));
	});
}

const selectTheme = (theme: string | undefined) => {
	// use only hex colors without alpha channel like '#258bd9'
	switch (theme) {
		case "dark":
			return {
				palette: {
					themePrimary: '#258bd9', // blue
					themeLighterAlt: '#010609',
					themeLighter: '#061623',
					themeLight: '#0b2a41',
					themeTertiary: '#165382',
					themeSecondary: '#207abf',
					themeDarkAlt: '#3895dd',
					themeDark: '#53a4e2',
					themeDarker: '#7dbbea',
					neutralLighterAlt: '#2b2929',
					neutralLighter: '#333232',
					neutralLight: '#414040',
					neutralQuaternaryAlt: '#4a4848',
					neutralQuaternary: '#514f4f',
					neutralTertiaryAlt: '#6f6c6c',
					neutralTertiary: '#c8c8c8',
					neutralSecondary: '#d0d0d0',
					neutralPrimaryAlt: '#dadada',
					neutralPrimary: '#ffffff',
					neutralDark: '#f4f4f4',
					black: '#f8f8f8',
					white: '#201f1f',
				},
				semanticColors: {
					errorBackground: "#632323"
				}
			}
		case "contrast":
			return {
				palette: {
					themePrimary: '#ffff00', // yellow
					themeLighterAlt: '#0a0a00',
					themeLighter: '#292900',
					themeLight: '#4d4d00',
					themeTertiary: '#999900',
					themeSecondary: '#e0e000',
					themeDarkAlt: '#ffff3d',
					themeDark: '#ffff70',
					themeDarker: '#ffffA0',
					neutralLighterAlt: '#0b0b0b',
					neutralLighter: '#252525',
					neutralLight: '#353535',
					neutralQuaternaryAlt: '#454545',
					neutralQuaternary: '#555555',
					neutralTertiaryAlt: '#656565',
					neutralTertiary: '#c8c8c8',
					neutralSecondary: '#d0d0d0',
					neutralPrimaryAlt: '#dadada',
					neutralPrimary: '#ffffff',
					neutralDark: '#f4f4f4',
					black: '#f8f8f8',
					white: '#000000',
				},
				semanticColors: {
					errorBackground: "#af0010"
				}
			}
		default:
			return {
				palette: {
					themePrimary: "#0078d4", // blue
					themeLighterAlt: "#f3f9fd",
					themeLighter: "#d0e7f8",
					themeLight: "#a9d3f2",
					themeTertiary: "#5ca9e5",
					themeSecondary: "#1a86d9",
					themeDarkAlt: "#006cbe",
					themeDark: "#005ba1",
					themeDarker: "#004377",
					neutralLighterAlt: "#f8f8f8",
					neutralLighter: "#f4f4f4",
					neutralLight: "#eaeaea",
					neutralQuaternaryAlt: "#dadada",
					neutralQuaternary: "#d0d0d0",
					neutralTertiaryAlt: "#c8c8c8",
					neutralTertiary: "#bab8b7",
					neutralSecondary: "#a3a2a0",
					neutralPrimaryAlt: "#8d8b8a",
					neutralPrimary: "#323130",
					neutralDark: "#605e5d",
					black: "#494847",
					white: "#ffffff",
				},
				semanticColors: {
					errorBackground: "#ffd5d9"
				}
			}
	}
}

export const CustomThemeProvider: React.FunctionComponent<CustomThemeProviderProps> = (props: PropsWithChildren<CustomThemeProviderProps>): JSX.Element => {
	const [theme, setTheme] = useState<PartialTheme | undefined>(() => undefined);

	useEffect(() => {
		getContext().then(context => {
			console.log("context theme loaded");
			setTheme(selectTheme(context.theme));
		});
	}, [])

	microsoftTeams.registerOnThemeChangeHandler(newTheme => {
		console.log("theme switched: ", newTheme);
		setTheme(selectTheme(newTheme));
	});

	// on startup a body backgroundColor is written, but the selected theme then is not yet set
	// therefore the backgroundColor will be set here to the current selected theme
	const styles = useStyles(theme);

	return (
		<ThemeProvider theme={theme} className={styles.Body}>
			{props.children}
		</ThemeProvider>
	);
};