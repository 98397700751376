import { IStyle, makeStyles } from "@fluentui/react";

export default () => {
	let styles = makeStyles(theme => ({
		FolderList: {
			height: "100vh",

			"a, i": {
				color: theme.palette.neutralPrimary
			} as IStyle,

			".ms-Button-flexContainer": {
				width: "100%",
				textAlign: "left"
			} as IStyle
		},

		DropFolder: {
			width: "100%",
			paddingRight: "20px",
			backgroundColor: theme.palette.neutralLight,

			".ms-Button-flexContainer": {
				color: theme.palette.themePrimary
			} as IStyle,
			i: {
				color: theme.palette.themePrimary
			} as IStyle
		},

		Flex: {
			display: "flex"
		},

		FolderItem: {
			display: "grid",
			gridAutoFlow: "column",
			columnGap: "5px",
			width: "100%",
			justifyContent: "space-between",

			".ReadonlyIcon": {
				color: theme.palette.neutralTertiary,
			} as IStyle,
		},

		FolderName: {
			textOverflow: "ellipsis",
			overflow: "hidden"
		},

		FilesCount: {
			paddingLeft: "5px",
			color: theme.palette.neutralTertiary,
			fontSize: theme.fonts.small.fontSize,
			textAlign: "right",
			minWidth: "12px"
		}
	}));
	return styles();
};