import { Step } from "react-shepherd";

const steps : Step.StepOptions[] = [
	{
		id: 'Intro',
		attachTo: { element: 'span.StatusHeader_Key', on: 'bottom-start' },
		title: '',
		text: ['Auftragsnummer: Diese wird von Phoenix Testlab vergeben und ist eindeutig. Sie wird ergänzt um Angaben zu den Dienstleistungen und dem Namen des Produkts (zum Stand der Auftragserteilung).']
	},
	{
		id: 'Progress',
		attachTo: { element: '.ProgressChart', on: 'right' },
		title: '',
		text: ['Kumulierter, prozentualer Fortschritt Ihres Auftrags. Ein Wert von 100% bedeutet üblicher Weise, dass der Auftrag komplett wie vereinbart abgewickelt wurde (inkl. Prüfbericht und Bezahlung).'],
	},
	{
		id: 'Order_Status',
		attachTo: { element: '.StatusHeader_Details .ms-Grid-row:first-child', on: 'bottom-start' },
		title: '',
		text: ['Gesamtstatus Ihres Auftrags. „Commercial closed“ bedeutet, dass die Dienstleistung erbracht und abgerechnet wurde. Das ist die Standardbezeichnung für Aufträge, die komplett abgeschlossen sind.'],
	},
	{
		id: 'Order_Number',
		attachTo: { element: '.StatusHeader_Details .ms-Grid-row:nth-child(2)', on: 'bottom-start' },
		title: '',
		text: ['Wenn Sie uns für den Auftrag eine Bestellnummer mitgeteilt haben, finden Sie diese hier.'],
	},
	{
		id: 'Order_Test_Object',
		attachTo: { element: '.StatusHeader_Details .ms-Grid-row:nth-child(3)', on: 'bottom-start' },
		title: '',
		text: ['Testobjekt oder Prüflingsbezeichnung. Bitte beachten: dieser Name steht für Ihr Produkt und ist Grundlage für unsere Prüfberichte. Sollte sich der Name nach der Auftragserteilung ändern, so teilen Sie uns das bitte unverzüglich mit, um Nachteile zu vermeiden.'],
	},
	{
		id: 'Order_Contact',
		attachTo: { element: '.StatusHeader_Details:nth-child(2)', on: 'bottom-start' },
		title: '',
		text: ['Ansprechpartner aus der Fachabteilung, z.B. Labor. Er ist Ihr Ansprechpartner für alle konkreten technischen Fragen zu den Prüfungen und der Zulassung Ihres Projekts.']
	},
	{
		id: 'Sales_Contact',
		attachTo: { element: '.StatusHeader_Details:nth-child(3)', on: 'bottom-start' },
		title: '',
		text: ['Ihr Kundenbetreuer und direkter Ansprechpartner zu Fragen rund um Ihren Auftrag und Ihrem Projekt. Ihr Kundenbetreuer beantwortet Ihnen auch gerne Fragen zu möglichen neuen Projekten und erstellt Ihnen ein passendes Angebot.<br>Ziehen Sie Ihre Maus über den Ansprechpartner, um die Kontaktdaten auszuklappen und direkte Nachrichten zu schreiben.']
	},
	{
		id: 'Test',
		attachTo: { element: '.ms-DetailsList', on: 'top' },
		title: '',
		text: ['In diesem Bereich finden Sie weiterführende Informationen zu den einzelnen Positionen Ihres Auftrags.']
	},
	{
		id: 'Test',
		attachTo: { element: '.ms-DetailsHeader-cell:nth-child(1)', on: 'bottom-start' },
		title: '',
		text: ['Name der Prüfungen/Zulassungen (sortierbar)']
	},
	{
		id: 'Test_Status',
		attachTo: { element: '.ms-DetailsHeader-cell:nth-child(3)', on: 'bottom-start' },
		title: '',
		text: ['Aktueller Status der Position']
	},
	{
		id: 'Test_Status',
		attachTo: { element: '.ms-DetailsHeader-cell:nth-child(5)', on: 'bottom-start' },
		title: '',
		text: ['Starttermin: An diesem Termin wurde die jeweilige Prüfung gestartet. Liegt das Datum in der Zukunft, handelt es sich um einen Plantermin, der sich je nach Planungsstand geringfügig ändern kann. Informieren Sie uns bitte umgehend, sobald Sie absehen können, dass Sie den Termin nicht einhalten können.']
	},
	{
		id: 'Test_Status',
		attachTo: { element: '.ms-DetailsHeader-cell:nth-child(7)', on: 'bottom-start' },
		title: '',
		text: ['Endtermin: an diesem Termin wurde diese Position beendet. Liegt das Datum in der Zukunft, handelt es sich um einen Plantermin, der sich aus den Angaben im Auftrag berechnet. Beachten Sie bitte, dass wir detaillierte Test-Ergebnisse erst aufbereiten müssen und diese daher nicht unmittelbar nach Ende der Prüfung bereitstellen können.']
	},
	{
		id: 'Test_Status',
		attachTo: { element: '.ms-DetailsHeader-cell:nth-child(9)', on: 'bottom-start' },
		title: '',
		text: ['Fortschrittsbalken für diese Position']
	}
];

export default steps;