import * as React from "react";
import { useSelector } from "react-redux";
import { DetailsList, IColumn, SelectionMode, DetailsListLayoutMode } from "@fluentui/react";
import { ProgressColumn } from "../../Components/ProgressColumn/ProgressColumn";
import { Loading } from "../../Components/Loading";
import "./OrderStatus.scss";
import FormatDate from "../../Formatter/FormatDate";
import { PTLimsStatus } from "../../Components/PtLimsStatus/PTLimsStatus";
import { StatusHeader, StatusHeaderDetailRow } from "../../Components/StatusHeader/StatusHeader";
import i18n from "../../i18n";
import { ReloadComponent } from "../../Components/ReloadComponent/ReloadComponent";
import { loadOrder } from "../../Api/Order/LoadOrder";
import { setGlobalError, RootState } from "../../Store";
import { Test } from "../../Model/Test";
import { Tour } from "../../Components/Tour/Tour";
import newSteps from "./TourSteps";
import { useAppDispatch, useAppSelector } from "../../Store/useAppDispatch";
import { useParams } from "react-router-dom";

interface OrderStatusProps {
}

export const OrderStatus = (props: OrderStatusProps) => {
	const { isLoading, order, orderStatusPercent, orderLoadError } = useAppSelector(state => state.order);
	const { contacts } = useAppSelector(state => state.contacts);
	const { isInitialized, teamName } = useAppSelector(state => state.teams);
	const dispatch: React.Dispatch<any> = useAppDispatch();
	const params = useParams();

	const loadData = () => {
		var location: string | undefined = params.location;
		var orderIdent: string | undefined = params.orderident;

		if (!location || !orderIdent) {
			dispatch(setGlobalError("This Teams tab is not configured correctly. Please check tab settings."));
			return;
		}

		dispatch(loadOrder(location, Number.parseInt(orderIdent, 10), true));
	};

	React.useEffect(() => {		
		if (isInitialized && !order && !isLoading && !orderLoadError) {
			loadData();
		}
	});

	const columns: IColumn[] = [
		{
			key: "testColumn",
			name: i18n.t("OrderStatus.Test"),
			fieldName: "Description",
			minWidth: 200,
			maxWidth: 300,
			isRowHeader: true,
			isResizable: true,
			isSorted: true,
			data: "string",
			isPadded: true
		},
		{
			key: "statusColumn",
			name: i18n.t("OrderStatus.Status"),
			fieldName: "StatusDE",
			minWidth: 100,
			maxWidth: 200,
			isRowHeader: true,
			isResizable: true,
			data: "string",
			isPadded: true,
			onRender: (item: Test) => {

				const isDe = i18n.language.startsWith("de")
				const status = isDe ? item.StatusDE : item.StatusEN;
				const reason = item.StatusDE.startsWith("4 -") && isDe ? item.ReasonsOfPostPoneDE : 
							   item.StatusDE.startsWith("4 -") ? item.ReasonsOfPostPoneEN :
							   item.StatusDE.startsWith("6 -") && isDe ? item.ReasonsOfAbortDE : 
							   item.StatusDE.startsWith("6 -") ? item.ReasonsOfAbortEN :
							   undefined;

				return <PTLimsStatus status={status} reasons={reason}  />;
			}
		},
		{
			key: "startColumn",
			name: i18n.t("OrderStatus.Start"),
			fieldName: "StartDate",
			minWidth: 150,
			maxWidth: 200,
			isRowHeader: true,
			isResizable: true,
			data: "string",
			isPadded: true,
			onRender: (item: Test) => {
				return FormatDate(item.StartDate);
			}
		},
		{
			key: "endColumn",
			name: i18n.t("OrderStatus.End"),
			fieldName: "EndDate",
			minWidth: 150,
			maxWidth: 200,
			isRowHeader: true,
			isResizable: true,
			data: "string",
			isPadded: true,
			onRender: (item: Test) => {
				return item.EndDate ? FormatDate(item.EndDate) : FormatDate(item.PlannedEndDate, ` (${i18n.t("OrderStatus.Planed")})`);
			}
		},
		{
			key: "progressColumn",
			name: i18n.t("OrderStatus.Progress"),
			fieldName: "progress",
			minWidth: 100,
			maxWidth: 300,
			isRowHeader: true,
			isResizable: true,
			data: "string",
			isPadded: true,
			onRender: (item: Test) => {
				return (<>{item.Status && <ProgressColumn percentComplete={item.Status.ResultPercent} />}</>);
			}
		}
	];

	let headerDetails: StatusHeaderDetailRow[] = [];
	if (order) {
		const isDe = i18n.language.startsWith("de")
		const status = isDe ? order.StatusDE : order.StatusEN;
		const reason = order.StatusDE.startsWith("4 -") && isDe ? order.ReasonsOfPostPoneDE : 
						order.StatusDE.startsWith("4 -") ? order.ReasonsOfPostPoneEN :
						order.StatusDE.startsWith("6 -") && isDe ? order.ReasonsOfAbortDE : 
						order.StatusDE.startsWith("6 -") ? order.ReasonsOfAbortEN :
					   undefined;

		headerDetails = [
			{
				name: i18n.t("OrderStatus.Detail.Status"),
				value: <PTLimsStatus status={status} reasons={reason} />
			},
			{
				name: i18n.t("OrderStatus.Detail.OrderNumber"),
				value: order.Bestellnummer
			},
			{
				name: i18n.t("OrderStatus.Detail.ProductName"),
				value: order.ProductName
			}
		] as StatusHeaderDetailRow[];
	}

	return (
		<div className="OrderStatus">
			{isLoading && <Loading />
			}
			{!isLoading && orderLoadError && !order &&
				<ReloadComponent errorMessage={orderLoadError} reload={() => loadData()} />
			}
			{order && !isLoading && <>
				<Tour steps={newSteps}>
					<StatusHeader header={teamName || order.Number}
						description={order.Description}
						statusPercent={orderStatusPercent}
						detailRows={headerDetails}
						contacts={contacts}
					/>
					<hr />
					<DetailsList
						items={order.Tests?.sort((a, b) => (a.Number < b.Number ? -1 : 1)) ?? []}
						compact={false}
						columns={columns}
						selectionMode={SelectionMode.none}
						layoutMode={DetailsListLayoutMode.justified}
						isHeaderVisible={true}
					/>
				</Tour>
			</>
			}
		</div>
	);
};