import { FileSystemObject } from "../../Model/FileSystemObject";
import { DriveLoadStatus, ViewMode } from "./State";
import { DriveType } from "../../Model/DriveType";
import { Action } from "../../Model/Action";
import { DriveRef } from "../../Model/DriveRef";
import { FileSystemObjectTag } from "../../Model/FileSystemObjectTag";

export enum FileActions {
	FilesRequest = "Files/Request",
	FilesReceived = "Files/Received",
	FilesReceiveFailed = "Files/ReceiveFailed",
	ClearError = "Files/ClearError",
	SelectFolder = "Files/SelectFolder",
	SelectSubFolderPath = "Files/SelectSubFolderPath",
	SelectFiles = "Files/SelectFiles",
	SelectFolders = "Files/SelectFolders",
	AddFiles = "Files/AddFiles",
	SetRunningAction = "Files/SetRunningAction",
	RemoveFiles = "Files/RemoveFiles",
	SetDargDropFiles = "Files/SetDargDropFiles",
	SetDargDropFolders = "Files/SetDargDropFolders",
	SetFileError = "Files/SetFileError",
	AskOverwriteFile = "File/AskOverwriteFile",
	RemoveOverwriteFileRecall = "File/RemoveOverwriteFileRecall",
	AddFolder = "Files/AddFolder",
	RemoveFolder = "Files/RemoveFolder",
	MoveFilesAndSubFolders = "Files/MoveFilesAndSubFolders",
	SetDriveUrl = "Files/SetDriveUrl",
	SetDrives = "Files/SetDrives",
	SetDriveLoading = "Files/SetDriveLoading",
	SortFiles = "Files/SortFiles",
	SetView = "Files/SetView",
	ToggleDriveSelection = "Files/ToggleSelectedDrive",
	SetPreviewImage = "Files/SetPreviewImage",
	PreviewFile = "Files/PreviewFile",
	SetAvailableTags = "Files/SetAvailableTags",
	AddFileTag = "Files/AddFileTag",
	RemoveFileTag = "Files/RemoveFileTag",
	AddFileTagFilter = "Files/AddFileTagFilter",
	RemoveFileTagFilter = "Files/RemoveFileTagFilter"
}

interface RequestFilesAction {
	type: typeof FileActions.FilesRequest;
	location: string;
	ident: string;
	sourceType?: DriveType;
}
export const requestFiles = (location: string, ident: string, sourceType?: DriveType): RequestFilesAction => ({
	type: FileActions.FilesRequest,
	location,
	ident,
	sourceType
});

interface FilesReceivedAction {
	type: typeof FileActions.FilesReceived;
	files: FileSystemObject[] | undefined;
	folders: FileSystemObject[] | undefined;
}
export const filesReceived = (files: FileSystemObject[] | undefined, folders: FileSystemObject[] | undefined): FilesReceivedAction => ({
	type: FileActions.FilesReceived,
	files,
	folders
});

interface FilesReceiveFailedAction {
	type: typeof FileActions.FilesReceiveFailed;
	code: number;
	message: string;
}
export const filesReceiveFailed = (code: number, message: string): FilesReceiveFailedAction => ({
	type: FileActions.FilesReceiveFailed,
	code,
	message
});

interface SetFileErrorAction {
	type: typeof FileActions.SetFileError;
	code: number;
	message: string;
	fileId?: string;
	folderId?: string;
}
export const setFileError = (fileId: string | undefined, folderId: string, code: number, message: string): SetFileErrorAction => ({
	type: FileActions.SetFileError,
	code,
	message,
	fileId,
	folderId
});

interface ClearErrorAction {
	type: typeof FileActions.ClearError;
}
export const clearFileError = (): ClearErrorAction => ({
	type: FileActions.ClearError
});

interface SelectFolderAction {
	type: typeof FileActions.SelectFolder;
	folderId: string | undefined;
}
export const selectFolder = (folderId: string | undefined): SelectFolderAction => ({
	type: FileActions.SelectFolder,
	folderId
});

interface SelectSubFolderPathAction {
	type: typeof FileActions.SelectSubFolderPath;
	subFolderPath: string | undefined;
}
export const selectSubFolderPath = (subFolderPath: string | undefined): SelectSubFolderPathAction => ({
	type: FileActions.SelectSubFolderPath,
	subFolderPath
});

interface SelectFilesAction {
	type: typeof FileActions.SelectFiles;
	files: FileSystemObject[];
}
export const selectFiles = (files: FileSystemObject[]): SelectFilesAction => ({
	type: FileActions.SelectFiles,
	files
});

interface SelectFoldersAction {
	type: typeof FileActions.SelectFolders;
	folders: FileSystemObject[];
}
export const selectFolders = (folders: FileSystemObject[]): SelectFoldersAction => ({
	type: FileActions.SelectFolders,
	folders
});

interface AddFilesAction {
	type: typeof FileActions.AddFiles;
	files: FileSystemObject[];
}
export const addFiles = (files: FileSystemObject[]): AddFilesAction => ({
	type: FileActions.AddFiles,
	files
});

interface SetRunningAction {
	type: typeof FileActions.SetRunningAction;
	setFolderAction?: boolean;
	action: Action;
	fileId: string;
	folderId: string;
}
export const setRunningAction = (action: Action, fileId: string, folderId: string, setFolderAction?: boolean): SetRunningAction => ({
	type: FileActions.SetRunningAction,
	action,
	fileId,
	folderId,
	setFolderAction
});

interface SetPreviewImageAction {
	type: typeof FileActions.SetPreviewImage;
	fileId: string;
	folderId: string;
	contentUrl: string;
}
export const setPreviewImage = (fileId: string, folderId: string,contentUrl: string): SetPreviewImageAction => ({
	type: FileActions.SetPreviewImage,
	fileId,
	folderId,
	contentUrl
});

interface RemoveFilesAction {
	type: typeof FileActions.RemoveFiles;
	files: FileSystemObject[];
}
export const removeFiles = (files: FileSystemObject[]): RemoveFilesAction => ({
	type: FileActions.RemoveFiles,
	files
});

interface SetDargDropFilesAction {
	type: typeof FileActions.SetDargDropFiles;
	files?: FileSystemObject[];
}
export const setDragDropFiles = (files: FileSystemObject[] | undefined): SetDargDropFilesAction => ({
	type: FileActions.SetDargDropFiles,
	files
});

interface SetDargDropFoldersAction {
	type: typeof FileActions.SetDargDropFolders;
	folders?: FileSystemObject[];
}
export const setDragDropFolders = (folders: FileSystemObject[] | undefined): SetDargDropFoldersAction => ({
	type: FileActions.SetDargDropFolders,
	folders
});

interface AskOverwriteFileAction {
	type: typeof FileActions.AskOverwriteFile;
	fileName: string;
	folderName: string;
	acceptAction: (overwrite: boolean, newName?: string) => void;
	rejectAction: () => void;
}
export const askOverwriteFile = (fileName: string, folderName: string, acceptAction: (overwrite: boolean, newName?: string) => void, rejectAction: () => void): AskOverwriteFileAction => ({
	type: FileActions.AskOverwriteFile,
	fileName,
	folderName,
	acceptAction, 
	rejectAction
});

interface RemoveOverwriteFileRecallAction {
	type: typeof FileActions.RemoveOverwriteFileRecall;
	fileName: string;
	folderName: string;
}
export const removeOverwriteFileRecall = (fileName: string, folderName: string): RemoveOverwriteFileRecallAction => ({
	type: FileActions.RemoveOverwriteFileRecall,
	fileName,
	folderName
});

interface AddFolderAction {
	type: typeof FileActions.AddFolder;
	folder: Omit<FileSystemObject, "Drives">;
}
export const addFolder = (folder: FileSystemObject): AddFolderAction => ({
	type: FileActions.AddFolder,
	folder
});

interface RemoveFolderAction {
	type: typeof FileActions.RemoveFolder;
	folder: FileSystemObject;
}
export const removeFolder = (folder: FileSystemObject): RemoveFolderAction => ({
	type: FileActions.RemoveFolder,
	folder
});

interface MoveFilesAndSubFoldersAction {
	type: typeof FileActions.MoveFilesAndSubFolders;
	sourcePath: string;
	targetPath: string;
}
export const moveFilesAndSubFolders = (sourcePath: string, targetPath: string): MoveFilesAndSubFoldersAction => ({
	type: FileActions.MoveFilesAndSubFolders,
	sourcePath,
	targetPath
});

interface SetDriveUrlAction {
	type: typeof FileActions.SetDriveUrl;
	url: string;
}
export const setDriveUrl = (url: string): SetDriveUrlAction => ({
	type: FileActions.SetDriveUrl,
	url
});

interface SetDrivesAction {
	type: typeof FileActions.SetDrives;
	drives: DriveLoadStatus[];
}
export const setDrives = (drives: DriveLoadStatus[]): SetDrivesAction => ({
	type: FileActions.SetDrives,
	drives
});

interface SetDriveLoadingAction {
	type: typeof FileActions.SetDriveLoading;
	drive: DriveLoadStatus;
}
export const setDriveLoading = (drive: DriveLoadStatus): SetDriveLoadingAction => ({
	type: FileActions.SetDriveLoading,
	drive
});

interface SortFilesAction {
	type: typeof FileActions.SortFiles;
	property: string;
	sortDirectionASC: boolean;
}
export const sortFiles = (property: string,	sortDirectionASC: boolean): SortFilesAction => ({
	type: FileActions.SortFiles,
	property,
	sortDirectionASC
});

interface SetViewAction {
	type: typeof FileActions.SetView;
	mode: ViewMode;
}
export const setView = (mode: ViewMode): SetViewAction => ({
	type: FileActions.SetView,
	mode
});

interface ToggleSelectedDriveAction {
	type: typeof FileActions.ToggleDriveSelection;
	drive: DriveRef;
}
export const toggleSelectedDrive = (drive: DriveRef): ToggleSelectedDriveAction => ({
	type: FileActions.ToggleDriveSelection,
	drive
});

interface PreviewFileAction {
	type: typeof FileActions.PreviewFile;
	file?: FileSystemObject;
}
export const previewFile = (file?: FileSystemObject): PreviewFileAction => ({
	type: FileActions.PreviewFile,
	file
});

interface SetAvailableTagsAction {
	type: typeof FileActions.SetAvailableTags;
	tags: FileSystemObjectTag[]
}
export const setAvailableTags = (tags: FileSystemObjectTag[]): SetAvailableTagsAction => ({
	type: FileActions.SetAvailableTags,
	tags
});

interface AddFileTagAction {
	type: typeof FileActions.AddFileTag;
	tag: FileSystemObjectTag;
	fileId: string;
	folderId: string;
}
export const addFileTag = (fileId: string, folderId: string,tag: FileSystemObjectTag): AddFileTagAction => ({
	type: FileActions.AddFileTag,
	tag,
	fileId,
	folderId
});

interface RemoveFileTagAction {
	type: typeof FileActions.RemoveFileTag;
	tag: FileSystemObjectTag;
	fileId: string;
	folderId: string;
}
export const removeFileTag = (fileId: string, folderId: string,tag: FileSystemObjectTag): RemoveFileTagAction => ({
	type: FileActions.RemoveFileTag,
	tag,
	fileId,
	folderId
});

interface AddFileTagFilterAction {
	type: typeof FileActions.AddFileTagFilter;
	tag: FileSystemObjectTag;
}
export const addFileTagFilter = (tag: FileSystemObjectTag): AddFileTagFilterAction => ({
	type: FileActions.AddFileTagFilter,
	tag
});

interface RemoveFileTagFilterAction {
	type: typeof FileActions.RemoveFileTagFilter;
	tag: FileSystemObjectTag;
}
export const removeFileTagFilter = (tag: FileSystemObjectTag): RemoveFileTagFilterAction => ({
	type: FileActions.RemoveFileTagFilter,
	tag
});

export type FileActionTypes =
	RequestFilesAction |
	FilesReceivedAction |
	FilesReceiveFailedAction | 
	ClearErrorAction |
	SelectFolderAction |
	SelectSubFolderPathAction |
	SelectFilesAction | 
	SelectFoldersAction | 
	AddFilesAction |
	SetRunningAction | 
	RemoveFilesAction | 
	SetDargDropFilesAction | 
	SetDargDropFoldersAction |
	SetFileErrorAction |  
	AskOverwriteFileAction | 
	RemoveOverwriteFileRecallAction |
	AddFolderAction | 
	RemoveFolderAction |
	MoveFilesAndSubFoldersAction | 
	SetDriveUrlAction | 
	SetDrivesAction |
	SetDriveLoadingAction |
	SortFilesAction | 
	SetViewAction | 
	ToggleSelectedDriveAction |
	SetPreviewImageAction | 
	PreviewFileAction | 
	SetAvailableTagsAction |
	AddFileTagAction | 
	RemoveFileTagAction |
	AddFileTagFilterAction | 
	RemoveFileTagFilterAction;