import { RootState, setRunningAction, addFolder, addFiles, removeFolder, moveFilesAndSubFolders } from "../../Store";
import { ApiUrlBuilder } from "../../ApiUrlBuilder";
import { batch } from "react-redux";
import { FileSystemObject } from "../../Model/FileSystemObject";
import { generateId } from "../../Utils/GenerateId";
import i18n from "../../i18n";

interface MoveFolderResponse {
	Folder: FileSystemObject;
	Files: FileSystemObject[];
}

export function moveFolder(folder: FileSystemObject, targetFolder: string) {
	return async (dispatch: React.Dispatch<any>, getState: () => RootState): Promise<void> => {

		const { teams: { isInitialized, accessToken }, file: { location, ident, sourceType } } = getState();
		if (!isInitialized || !accessToken || !location || !ident || !sourceType || folder.Path === targetFolder) return;
		const runningActionId = generateId();
		try {
			dispatch(setRunningAction({ id: runningActionId, message: i18n.t("Actions.Renaming", { file: folder.Name }) }, folder.Name, folder.Path, true));

			let newFolder: FileSystemObject | null = null;
			const movedFiles: FileSystemObject[] = [];

			for (const drive of folder.Drives) {
				const url = ApiUrlBuilder.MoveFolder(drive.Location, drive.Ident, drive.Type, folder.Path);

				const response: Response = await fetch(`/api${url}`, {
					method: "POST",
					headers: {
						"Authorization": `Bearer ${accessToken}`,
						"Content-Type": "application/json"
					},
					body: JSON.stringify({ targetFolder })
				});

				if (response.status === 404) {
					continue;
				}

				if (!response.ok) {
					throw new Error(`API request failed with ${response.status}: ${response.statusText}`);
				}

				const renamedFolder: MoveFolderResponse = await response.json();
				newFolder = renamedFolder.Folder;
				if (renamedFolder.Files) {
					movedFiles.push(...renamedFolder.Files.map(x => {
						x.Drives = [drive];
						return x;
					}));
				}
			}


			batch(() => {
				if (newFolder) {
					dispatch(addFolder(newFolder));
					dispatch(moveFilesAndSubFolders(folder.Path, newFolder.Path));
					dispatch(addFiles(movedFiles));
					dispatch(removeFolder(folder));
				}
			});


		} finally {
			dispatch(setRunningAction({ id: runningActionId, message: undefined }, folder.Name, folder.Id));
		}
	}
}