import * as React from "react";
import { setView } from "../Store";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../Store/useAppDispatch";
import { CommandBarCommandProps } from "../Utils/useCommands";

export const ViewCommand = (props: CommandBarCommandProps): JSX.Element => {
	const { viewMode, } = useAppSelector(state => state.file);
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	props.addCommandButton({
		key: "view",
		text: t(`Commands.View.${viewMode || "Command"}`),
		iconProps: { iconName: viewMode === "Tiles" ? "GridViewMedium" : "List" },
		subMenuProps: {
			items: [
				{
					key: 'viewList',
					text: t("Commands.View.List"),
					iconProps: { iconName: 'List' },
					onClick: () => { dispatch(setView("List")); return false; }
				},
				{
					key: 'viewTiles',
					text: t("Commands.View.Tiles"),
					iconProps: { iconName: 'GridViewMedium' },
					onClick: () => { dispatch(setView("Tiles")); return false; }
				},
			],
		},
	});

	return <>
	</>;
}