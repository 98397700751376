import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { TabConfig } from "./Pages/TabConfig";
import { OrderStatus } from "./Tabs/OrderStatus/OrderStatus";
import { Info } from "./Pages/Info";
import "./App.css";
//import "@fluentui/react/dist/css/fabric.min.css";
import { TaskStatus } from "./Tabs/TaskStatus/TaskStatus";
import { Checklist } from "./Tabs/Checklist/Checklist";
import { TaskFile } from "./Tabs/TaskFile/TaskFile";
import { OrderFile } from "./Tabs/OrderFile/OrderFile";
import { TenderFile } from "./Tabs/TenderFile/TenderFile";
import { TestFile } from "./Tabs/TestFile/TestFile";
import { TeamList } from "./Tabs/TeamList/TeamList";
import { Invitation } from "./Tabs/Invitation/Invitation";
import { Debug } from "./Pages/Debug";
import { Download } from "./Pages/Download";
import { Provider } from "react-redux";
import store, { initializeTeams } from "./Store";
import { GlobalError } from "./Components/GlobalError/GlobalError";

import { initializeIcons } from "@fluentui/react";
import { initializeFileTypeIcons } from '@fluentui/react-file-type-icons';
import { CustomThemeProvider } from "./Components/CustomThemeProvider/CustomThemeProvider";
initializeIcons();
initializeFileTypeIcons();

var dispatch = store.dispatch as React.Dispatch<any>;
dispatch(initializeTeams());

const routing = (
	<Provider store={store}>
		<CustomThemeProvider>
			<GlobalError>
				<Router>
					<Routes>
						<Route path="/config" element={<TabConfig />} />
						<Route path="/tab/locations/:location/taskstatus/:taskident" element={<TaskStatus />} />
						<Route path="/tab/locations/:location/orderstatus/:orderident" element={<OrderStatus />} />
						<Route path="/tab/locations/:location/:type/checklist/:ident" element={<Checklist />} />
						<Route path="/tab/locations/:location/taskfile/:taskident" element={<TaskFile />} />
						<Route path="/tab/locations/:location/orderfile/:orderident" element={<OrderFile />} />
						<Route path="/tab/locations/:location/tenderfile/:tenderident" element={<TenderFile />} />
						<Route path="/tab/locations/:location/testfile/:testident" element={<TestFile />} />
						<Route path="/tab/teamlist" element={<TeamList />} />
						<Route path="/tab/invitation" element={<Invitation />}/>
						<Route path="/webcube" element={<Debug />} />
						<Route path="/" element={<Info />} />
						<Route path="/download/:shareId" element={<Download />} />
					</Routes>
				</Router>
			</GlobalError>
		</CustomThemeProvider>
	</Provider>
);

ReactDOM.render(routing, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
