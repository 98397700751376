import { TeamsState } from "./State";
import { TeamsActions, TeamsActionTypes } from "./Actions";

const initialState: TeamsState = {
	isInitialized: false,
	teamName: "",
	isInternalUser: false,
	language: "",
	accessToken: undefined
};

export function teamsReducer(
	state: TeamsState = initialState,
	action: TeamsActionTypes
): TeamsState {
	switch (action.type) {

		case TeamsActions.Initialize:
			return {
				...state,
				isInitialized: true,
				teamName: action.teamName,
				isInternalUser: action.isInternalUser,
				language: action.language,
				accessToken: action.accessToken,
				appId: action.appId,
				entityId: action.entityId,
				channelId: action.channelId
			};

		default:
			return state;
	}
}