import { TaskState } from "./State";
import { TaskActionTypes, TaskActions } from "./Actions";

const initialState: TaskState = {
	task: undefined,
	taskStatusPercent: 0,
	isLoading: false,
	location: undefined,
	taskIdent: undefined
};

export function taskReducer(
	state: TaskState = initialState,
	action: TaskActionTypes
): TaskState {
	switch (action.type) {

		case TaskActions.Request:
			return {
				...state,
				isLoading: true,
				location: action.location,
				taskIdent: action.taskIdent,
				taskLoadError: undefined
			};

		case TaskActions.Received:
			return {
				...state,
				isLoading: false,
				task: action.task,
				taskStatusPercent: action.taskStatusPercent,
				taskLoadError: undefined
			};

		case TaskActions.ReceiveFailed:
			return {
				...state,
				isLoading: false,
				task: undefined,
				taskLoadError: action.message
			};

		default:
			return state;
	}
}