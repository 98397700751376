import * as React from "react";
import { RootState, requestOrder, orderReceived, orderReceiveFailed, addContact } from "../../Store";
import { Order } from "../../Model/Order";
import { batch } from "react-redux";
import { GetFirstContact, StatusHeaderContact } from "../../Components/StatusHeader/StatusHeader";
import i18n from "../../i18n";
import {loadContactImage} from "../Task/LoadContactImage";
import { initializeContacts } from "../../Store/Contacts/Actions";
import { getHttpStatusText } from "../../Utils/getHttpStatusText";

export function loadOrder(location: string, orderIdent: number, withTests: boolean = true) {
	return async (dispatch: React.Dispatch<any>, getState: () => RootState): Promise<void> => {
		try {
			dispatch(requestOrder(location, orderIdent));

			const { teams: { accessToken } } = getState();

			let url: string = `/locations/${location}/orders/${orderIdent}`;

			if (withTests) {
				url += "?$expand=Tests,OrderContacts,SalesContacts";
			} 

			const response: Response = await fetch(`${window.location.origin}/api${url}`, {
				method: "GET",
				headers: {
					"Authorization": `Bearer ${accessToken}`
				}
			});

			if (!response.ok) {
				const errorMessage = response.statusText || getHttpStatusText(response.status);				
				dispatch(orderReceiveFailed(response.status, errorMessage));
				return;
			}

			const order: Order = await response.json();
			const orderStatusPercent: number = order && order.Status ? order.Status.ResultPercent / 100.0 : 0;

			batch(() => {
				dispatch(orderReceived(order, orderStatusPercent));
				dispatch(initializeContacts(order.Location, undefined, order.Ident));
				
				var orderContact: StatusHeaderContact | undefined = GetFirstContact(order?.OrderContacts, i18n.t("OrderStatus.Detail.Laboratory"), "OrderResp");
				if (orderContact) {
					orderContact.isPartOfTeam = true;
					dispatch(addContact(orderContact));
					dispatch(loadContactImage(orderContact));
				}

				var salesContact: StatusHeaderContact | undefined = GetFirstContact(order?.SalesContacts, i18n.t("OrderStatus.Detail.Sales"), "tenInitiator");
				if (salesContact) {
					salesContact.isPartOfTeam = true;
					dispatch(addContact(salesContact));
					dispatch(loadContactImage(salesContact));
				}
			});
		} catch (error:any) {
			dispatch(orderReceiveFailed(0, error.message));
		}
	};
}