import * as React from "react";
import { resendInvitationMail } from "../Api/Invitation/ResendInvitation";
import i18n from "../i18n";
import { useAppDispatch, useAppSelector } from "../Store/useAppDispatch";
import { CommandBarCommandProps } from "../Utils/useCommands";


export const ResendInvitationMailCommand = (props: CommandBarCommandProps): JSX.Element => {
	const dispatch = useAppDispatch();

    let {selectedUsers} = useAppSelector(state => state.invitation);

		props.addCommandButton({
			key: "resendInvitation",
			text: i18n.t("Commands.Resend.Command"),
			iconProps: { iconName: "Mail" },
			onClick: () => {
				if(selectedUsers !== undefined || selectedUsers){
					dispatch(resendInvitationMail(selectedUsers));
				}
				return false;
			},
		});
	return <></>;
}