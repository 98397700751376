import { TeamDetailsPanelActions, TeamDetailsPanelActionTypes } from "./Actions";
import { TeamDetailsPanelState } from "./State";

const initialState: TeamDetailsPanelState = {
    isDetailsPanelOpen: false,
    renderedChannel: {
        Description: "",
        Id: "",
        Name: "",
        ParentId: ""
    },
    renderedTeam: {
        Channels: [],
        Id: "",
        Name: "",
        PrimaryChannelName: "",
        Details: undefined,
        DetailsLoadingState: "NotLoaded"
    }
}

export function teamDetailsPanelReducer(
    state: TeamDetailsPanelState = initialState,
    action: TeamDetailsPanelActionTypes
): TeamDetailsPanelState {
    switch (action.type) {

        case TeamDetailsPanelActions.TeamDetailsPanelToggle:
            return {
                ...state,
                isDetailsPanelOpen: !state.isDetailsPanelOpen,
            }
        case TeamDetailsPanelActions.TeamDetailsPanelSetRenderedChannel:
            {                
                let newState = { ...state };
                newState.renderedChannel = action.renderedChannel;
                return newState;
            }
        case TeamDetailsPanelActions.TeamDetailsPanelSetRenderedTeam:
            {
                let newState = { ...state };
                newState.renderedTeam = action.renderedTeam;
                return newState;
            }      
        default:
            return state;

    }
}