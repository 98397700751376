import { IStyle, makeStyles } from "@fluentui/react";

export default () => {
	const styles = makeStyles(theme => ({
		ShareDocumentList: {
			"> div": {
				padding: "3px",

				":nth-child(even)": {
					background: `${theme.palette.neutralPrimary}07`,
				} as IStyle,
			} as IStyle
		},

		ShareDurationLabel: {
			paddingTop: "5px",
			paddingBottom: "2px",
			fontSize: theme.fonts.small.fontSize,
			FontWeights: theme.fonts.small.fontWeight //@include ms-fontWeight-regular,
		},

		ShareDurationDropdown: {
			paddingBottom: "5px",
		},

		Icon: {
			paddingRight: "5px",
		},

		Link: {
			":hover": {
				color: theme.palette.themePrimary
			} as IStyle,
			"a": {
				textDecoration: "none",
				color: "inherit",
				":hover": {
					color: theme.palette.themePrimary
				} as IStyle
			} as IStyle
		}
	}));
	return styles();
};