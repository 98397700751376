import { RootState, externalSharesReceiveFailed, setRunningAction, FileActionTypes, setDownloadCountZero } from "../../Store";
import { ApiUrlBuilder } from "../../ApiUrlBuilder";
import { ExternalShareFile } from "../../Model/ExternalShareFile";
import { batch } from "react-redux";
import i18n from "../../i18n";
import { generateId } from "../../Utils/GenerateId";

export function resetDownloads(files: ExternalShareFile[]) {
	return async (dispatch: React.Dispatch<any>, getState: () => RootState): Promise<void> => {

		const { teams: { isInitialized, accessToken } } = getState();
		if (!isInitialized || !accessToken) return;

		const resetActions: FileActionTypes[] = [];

		try {
			const url = ApiUrlBuilder.ResetDownloads();
			const requestBody = {
				files: files.map(file => ({
					ShareId: file.shareId,
					Drive: {
						Ident: file.drive.ident,
						Location: file.drive.location,
						Type: file.drive.type.toString()
					},
					Folder: file.folder,
					Name: file.name
				})),
			};

			batch(() => {
				const runningActionId = generateId();
				files.forEach(file => {
					dispatch(setRunningAction({ id: runningActionId, message: i18n.t("Actions.ResetDownloads", { file: file.name }) }, file.id, file.folder));
					resetActions.push(setRunningAction({ id: runningActionId, message: undefined }, file.name, file.folder));
				});
			})

			const response: Response = await fetch(`/api${url}`, {
				method: "POST",
				headers: {
					"Authorization": `Bearer ${accessToken}`,
					"Content-Type": "application/json"
				},
				body: JSON.stringify(requestBody)
			});

			if (!response.ok) {
				dispatch(externalSharesReceiveFailed(response.status, response.statusText));
				return;
			}

			batch(() => {
				dispatch(setDownloadCountZero(files));
				resetActions.forEach(action => dispatch(action));
			});
		} catch (error:any) {
			batch(() => {
				resetActions.forEach(action => dispatch(action));
			});
		}
	}
}