import { CheckList } from "../../../Model/CheckList";
import { CheckListActions } from "./CheckListActions";

export interface CheckListReceivedAction {
	type: typeof CheckListActions.Received;
	checkList: CheckList | undefined;
}
export const checkListReceived = (checkList: CheckList | undefined): CheckListReceivedAction => ({
	type: CheckListActions.Received,
	checkList
});