import * as React from "react";
import { Icon, List, ImageFit, Image, css } from "@fluentui/react";
import { FileSystemObject } from "../../Model/FileSystemObject";
import { parentFolderKey, SpecialFolders } from "../../Model/SpecialFolder";
import { batch } from "react-redux";
import { selectFiles, setDragDropFiles, setDragDropFolders, selectFolders, selectSubFolderPath } from "../../Store";
import { uploadDataTransfer } from "../../Api/File/UploadFiles";
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import { DownloadCommand, RenameFileCommand,MoveFileCommand,ShareCommand,DeleteFileCommand,CopyLinkCommand } from "../../ToolbarCommands";
import { loadPreviewImage } from "../../Api/File/LoadPreviewImage";
import { renameFile } from "../../Api/File/RenameFile";
import { moveFolder } from "../../Api/File/MoveFolder";
import { OpenFileLink } from "../OpenFileLink/OpenFileLink";
import { useMemo } from "react";
import i18n from "../../i18n";
import useStyles from "./FileTiles.style"
import { areSameDrives } from "../../Utils/CompareDrive";
import { useGlobalStyles } from "../../Global.style";
import { useCommands } from "../../Utils/useCommands";
import { getFirstDrive } from "../../Utils/GetFirstDrive";
import { useAppDispatch, useAppSelector } from "../../Store/useAppDispatch";

export interface FileTilesProps {
}

interface TilesItem {
	fileSystemObject: FileSystemObject;
	isSelected: boolean;
}

export const FileTiles = (props: FileTilesProps) => {
	const dispatch = useAppDispatch();
	const { drives, selectedFolderId, selectedFolderFiles, selectedFolderSubFolders, selectedSubFolderPath, dragDropFiles, dragDropFolders, selectedFiles, selectedFolders } = useAppSelector(state => state.file);
	const [dragOver, setDragOver] = React.useState<boolean>(false);

	const specialFolder = SpecialFolders.find(specialFolder => specialFolder.Id === selectedFolderId);
	const isFolderSelected = !!selectedFolderId && (!specialFolder || specialFolder.TargetFolderId != null);

	const styles = useStyles();
	const globalStyles = useGlobalStyles();

	const onDragStart = React.useCallback((event: React.DragEvent<HTMLDivElement>, item: TilesItem) => {
		event?.dataTransfer.setData("text/plain", (selectedFiles || []).toString());

		var dragFiles = [...(selectedFiles || [])];
		var dragFolders = [...(selectedFolders || [])];


		batch(() => {
			if (!item.isSelected) {
				if (item.fileSystemObject && item.fileSystemObject.Type === "File") {
					dragFiles.push(item.fileSystemObject);
					dispatch(selectFiles(dragFiles));
				}
				if (item.fileSystemObject && item.fileSystemObject.Type === "Folder") {
					dragFolders.push(item.fileSystemObject);
					dispatch(selectFolders(dragFolders));
				}
			}

			dispatch(setDragDropFiles(dragFiles));
			dispatch(setDragDropFolders(dragFolders));

			console.log("onDragStart", dragFiles, dragFolders);
		});
	}, [selectedFiles, selectedFolders, dispatch]);

	const onDragEnter = React.useCallback((event: any) => {
		event?.stopPropagation();
		event?.preventDefault();

		setDragOver(isFolderSelected && (dragDropFiles?.length ?? 0) === 0);
		return false;
	}, [isFolderSelected, dragDropFiles]);

	const onDragLeave = React.useCallback((event: any) => {
		event?.stopPropagation();
		event?.preventDefault();

		setDragOver(false);
		return false;
	}, []);

	const onDragOver = React.useCallback((event: any) => {
		event?.stopPropagation();
		event?.preventDefault();

		if (!isFolderSelected) {
			event.dataTransfer.dropEffect = "none";
			setDragOver(false);
		} else {
			event.dataTransfer.dropEffect = "move";
		}
		return false;
	}, [isFolderSelected]);

	const onDrop = React.useCallback((event: any, item?: TilesItem) => {
		event?.stopPropagation();
		event?.preventDefault();

		setDragOver(false);
		const files = event?.dataTransfer?.files;
		if (isFolderSelected && files && files.length > 0) {
			dispatch(uploadDataTransfer(files, selectedSubFolderPath ?? selectedFolderId, false));
		}

		if (item && item.fileSystemObject) {
			if (dragDropFiles) {
				batch(() => {
					dragDropFiles.forEach((file) => dispatch(renameFile(file, item?.fileSystemObject?.Path || "", file.Name, getFirstDrive(file.Drives), false)));
				});
			}
			if (dragDropFolders) {
				batch(() => {
					dragDropFolders.forEach((folder) => dispatch(moveFolder(folder, `${item?.fileSystemObject?.Path}/${folder.Name}`)));
				});
			}
		}

		batch(() => {
			dispatch(selectFiles([]));
			dispatch(selectFolders([]));
			dispatch(setDragDropFiles([]));
			dispatch(setDragDropFolders([]));
		});

		return false;
	}, [dispatch, isFolderSelected, selectedSubFolderPath, selectedFolderId, dragDropFiles, dragDropFolders]);

	const parentPath = selectedSubFolderPath && selectedSubFolderPath.lastIndexOf("/") > 0 ? selectedSubFolderPath.substring(0, selectedSubFolderPath.lastIndexOf("/")) : "";

	const hasHiddenContent = useMemo(() =>
		selectedFolderFiles?.some(file => drives.some(drive => !drive.isSelected && areSameDrives(file.Drives, [drive])))
		, [drives, selectedFolderFiles]);

	const items = useMemo(() => [
		...selectedSubFolderPath && selectedSubFolderPath !== selectedFolderId ? [
			{
				fileSystemObject: {
					Id: parentFolderKey,
					Name: "...",
					Path: parentPath === specialFolder?.TargetFolderId ? "" : parentPath,
					Drives: [],
					IsReadonly: true,
					Type: "Folder",
					Size: -1,
					Url: "",
					CreatedOn: "",
					CreatedBy: "",
					ModifiedOn: "",
					ModifiedBy: ""
				},
				isSelected: false
			} as TilesItem] : [],
		...selectedFolderSubFolders?.map(x => ({fileSystemObject: x, isSelected: !!selectedFolders?.some((y) => y.Path === x.Path) } as TilesItem)) || [],
		...selectedFolderFiles?.filter(file => drives.some(drive => drive.isSelected && areSameDrives(file.Drives, [drive])))
			.map(file => ({ fileSystemObject: file, isSelected: !!selectedFiles?.some((y) => y.Id === file.Id && y.Path === file.Path) } as TilesItem)) || []
	], [selectedSubFolderPath, selectedFolderId, parentPath, specialFolder, selectedFolderSubFolders, selectedFolderFiles, selectedFolders, drives, selectedFiles]);

	const [commands,addMenuItem] = useCommands();

	const selectFile = React.useCallback((event?: React.MouseEvent<HTMLElement, MouseEvent>, item?: TilesItem) => {
		event?.stopPropagation();
		event?.preventDefault();
		if (!item?.fileSystemObject) return;

		const fileIndex = (selectedFiles || []).findIndex((x) => x.Id === item?.fileSystemObject?.Id && x.Path === item?.fileSystemObject?.Path);
		if (fileIndex >= 0) {
			const newSelection = [...selectedFiles || []];
			newSelection.splice(fileIndex, 1);
			dispatch(selectFiles(newSelection));
		} else {
			dispatch(selectFiles([...selectedFiles || [], item?.fileSystemObject]))
		}
	}, [dispatch, selectedFiles]);

	const selectFolder = React.useCallback((event?: React.MouseEvent<HTMLElement, MouseEvent>, item?: TilesItem) => {
		event?.stopPropagation();
		event?.preventDefault();
		if (!item?.fileSystemObject) return;

		const folderIndex = (selectedFolders || []).findIndex((x) => x.Path === item?.fileSystemObject?.Path);
		if (folderIndex >= 0) {
			const newSelection = [...selectedFolders || []];
			newSelection.splice(folderIndex, 1);
			dispatch(selectFolders(newSelection));
		} else {
			dispatch(selectFolders([...selectedFolders || [], item?.fileSystemObject]))
		}
	}, [dispatch, selectedFolders]);

	React.useEffect(() => {
		const missingPreviews = selectedFolderFiles?.filter(x => !x.previewLoaded);
		if (missingPreviews && missingPreviews.length > 0) {
			dispatch(loadPreviewImage(missingPreviews));
		}
	})

	const onRenderCell = React.useCallback((item?: TilesItem) => {
		if (item?.fileSystemObject.Type === "File") {
			const extension = item?.fileSystemObject?.Name.substring(item.fileSystemObject.Name.lastIndexOf('.'));
			const iconProps = getFileTypeIconProps({ extension: extension });

			const hasPreview = !!item?.fileSystemObject?.previewImageUrl;

			return (
				<>
					{!hasPreview && <Icon iconName={iconProps.iconName} />}
					{hasPreview && <Image imageFit={ImageFit.centerContain} src={item?.fileSystemObject?.previewImageUrl} alt={item?.fileSystemObject?.Name} />}
					<span>{item?.fileSystemObject?.Name}</span>
					<OpenFileLink file={item.fileSystemObject} >
						<div draggable={true}
							className={item?.isSelected ? "select is-selected" : "select"}
							onDragStart={(event) => onDragStart(event, item)}
							onDrop={(event) => onDrop(event, item)}>
							<Icon
								iconName={item?.isSelected ? "CompletedSolid" : "CircleRing"}
								onClick={(event) => selectFile(event, item)} />
						</div>
					</OpenFileLink>
				</>
			);
		} else if (item?.fileSystemObject.Type === "Folder") {
			return (
				<>
					<Icon iconName={item.fileSystemObject.Id === parentFolderKey ? "Back" : "FabricFolder"} />
					<span>{item?.fileSystemObject?.Name}</span>
					<div draggable={item.fileSystemObject.Id !== parentFolderKey}
						className={item?.isSelected ? "select is-selected" : "select"}
						onClick={() => dispatch(selectSubFolderPath(item.fileSystemObject?.Path === "" ? undefined : item.fileSystemObject?.Path))}
						onDragStart={(event) => onDragStart(event, item)}
						onDrop={(event) => onDrop(event, item)}>
						{item.fileSystemObject.Id !== parentFolderKey &&
							<Icon
								iconName={item?.isSelected ? "CompletedSolid" : "CircleRing"}
								onClick={(event) => selectFolder(event, item)} />
						}
					</div>
				</>
			);
		} else {
			return <></>;
		}
	}, [dispatch, selectFile, onDragStart, selectFolder, onDrop]);

	return <>
		<DownloadCommand addCommandButton={addMenuItem} />
		<RenameFileCommand addCommandButton={addMenuItem} />
		<MoveFileCommand addCommandButton={addMenuItem} />
		<ShareCommand addCommandButton={addMenuItem} />
		<CopyLinkCommand addCommandButton={addMenuItem}/>
		<DeleteFileCommand addCommandButton={addMenuItem} />

		<div
			className={css(styles.FileTiles, globalStyles.Scrollbar)}
			onDragEnter={onDragEnter}
			onDragOver={onDragOver}
			onDrop={onDrop}>

			{dragOver && <div className={styles.DropZone} onDragEnter={onDragEnter} onDragOver={onDragOver} onDragLeave={onDragLeave} />}

			<List
				className={styles.TilesContainer}
				items={items}
				renderedWindowsAhead={2}
				getItemCountForPage={(x) => 12}
				onRenderCell={onRenderCell}
			/>
			{hasHiddenContent &&
				<div className={styles.HiddenContentBanner}>{i18n.t("FileBase.HasHiddenFiles")}</div>
			}
		</div>
	</>;

}