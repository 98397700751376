import * as React from "react";
import "./ChecklistValue.scss";
import { Checkbox } from "@fluentui/react";

export interface ChecklistCheckBoxProps {
	value: string;
	isEditing: boolean;
	onChange: (value: string | undefined) => void;
}

export class ChecklistCheckBox extends React.Component<ChecklistCheckBoxProps, {}> {
	render(): JSX.Element {
		return <><Checkbox checked={!!this.props.value && this.props.value !== "0"}
			disabled={!this.props.isEditing}
			onChange={(x, newValue) => this.props.onChange(newValue ? "-1" : "0")} /></>;
	}
}