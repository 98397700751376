import * as React from "react";
import { Loading } from "../../Components/Loading";
import { MessageBar, MessageBarType, CommandBar, Breadcrumb, IBreadcrumbItem, ProgressIndicator } from "@fluentui/react";
import useStyles from "./FileBase.style"
import { SpecialFolderIds, SpecialFolders } from "../../Model/SpecialFolder";
import { ReloadComponent } from "../../Components/ReloadComponent/ReloadComponent";
import { FolderList } from "../../Components/FolderList/FolderList";
import { FileDetailList } from "../../Components/FileDetailList/FileDetailList";
import { FileTiles } from "../../Components/FileTiles/FileTiles";
import { FilesSharedByLink } from "../../Components/FilesSharedByLink/FilesSharedByLink";
import { ImageFilePreview } from "../../Components/ImageFilePreview/ImageFilePreview";
import { useSelector } from "react-redux";
import { RootState, clearFileError, selectSubFolderPath } from "../../Store";
import { useEffect } from "react";
import { loadFilesAndFolders } from "../../Api/File/LoadFilesAndFolders";
import { AddFolderCommand, RenameFileCommand, MoveFileCommand, MoveFileBetweenCommand, DownloadCommand, DeleteFileCommand, RefreshCommand, RefreshExternalSharesCommand, UploadCommand, ShareCommand, RenameFolderCommand, OpenSharePointCommand, ViewCommand, ViewDrivesCommand, PullFileFromTaskCommand, ExtendShareCommand, ResetDownloadsCommand, StopShareCommand, ResetSharePasswordCommand,CopyLinkCommand } from "../../ToolbarCommands";
import { FileNameConflictRecall } from "../../Components/FileNameConflictRecall/FileNameConflictRecall";
import { RunningActions } from "../../Components/RunningActions/RunningActions"
import { useCommands } from "../../Utils/useCommands"
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../Store/useAppDispatch";
import { getTags } from "../../Api/File/GetTags";
import { ViewTagsCommand } from "../../ToolbarCommands/ViewTagsCommand";
//import { useBeforeunload } from 'react-beforeunload';

interface FileBaseProps {
	sourceType: "Task" | "Order" | "Tender" | "Test",
	location: string,
	ident: string
}

export const FileBase = (props: FileBaseProps) => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const styles = useStyles();
	const [ commands, addCommand ] = useCommands();
	const [ farCommands, addFarCommand ] = useCommands();
	//const [ commands, setCommands] = useState([] as ICommandBarItemProps[]);
	//const [ farCommands, setFarCommands] = useState([] as ICommandBarItemProps[]);
	const { isInitialized } = useAppSelector(state => state.teams);
	const { status, loadError, selectedFolderId, selectedSubFolderPath, drives, viewMode, previewFile } = useAppSelector(state => state.file);
	useAppSelector(state => state.externalShares); // needed to re-render on selection in Shared by Link view

	useEffect(() => {
		if (status === "NotLoaded" && isInitialized) {
			dispatch(loadFilesAndFolders(props.sourceType, props.location, props.ident));
		}
	});

	useEffect(() => {
		if (status === "NotLoaded" && isInitialized) {
			dispatch(getTags());
		}
	},[dispatch, isInitialized, status]);


	const breadcrumbItems: IBreadcrumbItem[] = React.useMemo(() => {
		const pathSegments = (selectedSubFolderPath || selectedFolderId)?.split('/');

		const specialFolder = SpecialFolders.find(specialFolder => specialFolder.Id === selectedFolderId)
		if (specialFolder?.HasSubfolders && specialFolder.TargetFolderId && pathSegments && pathSegments.length >= 1) {
			pathSegments[0] = t(`FileBase.Folders.${specialFolder.i18n}`); // set display name of special folder
		}

		return pathSegments?.map((x, i) => ({
			text: x,
			key: `segment${i}`,
			onClick: () => {
				let selectedSegments = pathSegments?.slice(0, i + 1);
				if (specialFolder?.TargetFolderId && specialFolder.HasSubfolders && selectedSegments?.length) {
					if (selectedSegments.length === 1) {
						selectedSegments = []; // if the special folder itself is selected, the subpath needs to be undefined
					} else {
						selectedSegments[0] = specialFolder.TargetFolderId; // set first part of subPath to target folder for correct folder routing
					}
				}
				dispatch(selectSubFolderPath(selectedSegments?.length ? selectedSegments.join('/') : undefined))
			}
		}) as IBreadcrumbItem);
	}, [selectedSubFolderPath, selectedFolderId, t, dispatch]) || [];

	const mode : "List"|"Share"|"Tiles"|undefined = selectedFolderId === SpecialFolderIds.SharedByLink ? "Share" : viewMode;
	
	return (
		<div className={styles.FileBase}>		
			{ mode === "Share" && <>
				<RefreshExternalSharesCommand addCommandButton={addCommand} />
				<ExtendShareCommand addCommandButton={addCommand} />
				<ResetDownloadsCommand addCommandButton={addCommand} />
				<ResetSharePasswordCommand addCommandButton={addCommand} />
				<StopShareCommand addCommandButton={addCommand} />
			</>}

			{ (mode === "List" || mode === "Tiles") && <>
				<UploadCommand addCommandButton={addCommand} />
				<DownloadCommand addCommandButton={addCommand} />
				<AddFolderCommand addCommandButton={addCommand} />
				<RenameFileCommand addCommandButton={addCommand} />
				<RenameFolderCommand addCommandButton={addCommand} />
				<MoveFileCommand addCommandButton={addCommand} />
				<MoveFileBetweenCommand addCommandButton={addCommand} />
				<PullFileFromTaskCommand addCommandButton={addCommand} />
				<ShareCommand addCommandButton={addCommand} />
				<DeleteFileCommand addCommandButton={addCommand} />
				<CopyLinkCommand addCommandButton={addCommand} />
				<RefreshCommand addCommandButton={addCommand} />
				<OpenSharePointCommand addCommandButton={addCommand} />

				<ViewDrivesCommand addCommandButton={addFarCommand} />
				<ViewTagsCommand addCommandButton={addFarCommand} />
				<ViewCommand addCommandButton={addFarCommand} />
			</>}

			<FileNameConflictRecall />

			{loadError && status === "Loaded" &&
				<MessageBar
					messageBarType={MessageBarType.error}
					isMultiline={true}
					onDismiss={() => dispatch(clearFileError())} dismissButtonAriaLabel="Close">
					{loadError}
				</MessageBar>
			}

			{status === "Loading" &&
				<Loading >
					{drives.map((x, i) => {
						const progress = x.isFilesLoading && x.isFoldersLoading ?
							undefined :
							(x.isFilesLoading ? 0 : 0.6) + (x.isFoldersLoading ? 0 : 0.4);

						return <ProgressIndicator
							className={styles.DriveStatus}
							key={i}
							label={x.Number}
							percentComplete={progress} />
					}
					)}
				</Loading>
			}

			{status === "LoadFailed" && loadError &&
				<ReloadComponent errorMessage={loadError} reload={() => dispatch(loadFilesAndFolders(props.sourceType, props.location, props.ident))} />
			}

			{status === "Loaded" && !previewFile && <>
				<div className={styles.Layout}>
					<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3 folder-column">
						<FolderList />
					</div>
					<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg9">
						<div className={styles.FileColumn}>
							<CommandBar items={commands} farItems={farCommands} />
							<div className={styles.HeadRow}>
								<div>
									{!SpecialFolders.some(specialFolder => specialFolder.Id === selectedFolderId && !specialFolder.HasSubfolders) &&
										<Breadcrumb items={breadcrumbItems} className={styles.Breadcrumb} />
									}
								</div>
								<RunningActions />
							</div>
							{mode === "Share" && <FilesSharedByLink />}
							{mode === "List" && <FileDetailList />}
							{mode === "Tiles" && <FileTiles />}
						</div>
					</div>
				</div>
			</>
			}
			{status === "Loaded" && previewFile &&
				<ImageFilePreview file={previewFile} />
			}
		</div>
	);
}