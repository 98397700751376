import { RootState, setRunningAction, addFiles, removeFiles, askOverwriteFile, removeOverwriteFileRecall, setFileError } from "../../Store";
import { FileSystemObject } from "../../Model/FileSystemObject";
import { ApiUrlBuilder } from "../../ApiUrlBuilder";
import { batch } from "react-redux";
import { ODataResult } from "../../Model/ODataResult";
import { ODataErrorResult } from "../../Model/ODataErrorResult";
import { DriveRef } from "../../Model/DriveRef";
import { generateId } from "../../Utils/GenerateId";
import { getFirstDrive } from "../../Utils/GetFirstDrive";
import i18n from "../../i18n";
import { areSameDrives } from "../../Utils/CompareDrive";

export function renameFile(file: FileSystemObject, targetFolder: string, targetFile: string, targetDrive: DriveRef | undefined, overwrite: boolean = false) {
	return async (dispatch: React.Dispatch<any>, getState: () => RootState): Promise<void> => {
		const fileDrive = getFirstDrive(file.Drives);
		if (!file || !fileDrive || !targetDrive || (targetFolder === file.Path && targetFile === file.Name && areSameDrives([targetDrive], [fileDrive]))) { return; }

		const { teams: { isInitialized, accessToken } } = getState();
		if (!isInitialized || !accessToken) return;
		const runningActionId = generateId();
		try {
			if (targetFolder !== file.Path) {
				dispatch(setRunningAction({ id: runningActionId, message: i18n.t("Actions.Moving", { file: file.Name }) }, file.Id, file.Path));
			}
			else {
				dispatch(setRunningAction({ id: runningActionId, message: i18n.t("Actions.Renaming", { file: file.Name }) }, file.Id, file.Path));
			}

			if (getFirstDrive(file.Drives) !== targetDrive) {
				targetFolder = `$drive(${targetDrive.Type},${targetDrive.Location},${targetDrive.Ident}):${targetFolder}`;
			}

			const url = ApiUrlBuilder.MoveFile(fileDrive, file.Path, file.Name);

			const response: Response = await fetch(`/api${url}`, {
				method: "POST",
				headers: {
					"Authorization": `Bearer ${accessToken}`,
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					TargetFolder: targetFolder,
					TargetFile: targetFile,
					Overwrite: overwrite
				})
			});

			if (response.status === 400) {
				const errorResult: ODataErrorResult = await response.json();
				if (errorResult && errorResult.value === "File already exists") {
					dispatch(askOverwriteFile(targetFile, targetFolder,
						() => batch(() => {
							dispatch(removeOverwriteFileRecall(targetFile, targetFolder));
							dispatch(renameFile(file, targetFolder, targetFile, targetDrive, true))
						}),
						() => batch(() => {
							dispatch(removeOverwriteFileRecall(targetFile, targetFolder));
							dispatch(setRunningAction({ id: runningActionId, message: undefined }, file.Name, file.Path));
						})
					));
					return undefined;
				}
			}

			if (response.status === 404) {
				return undefined;
			}

			if (!response.ok) {
				dispatch(setFileError(file.Id, file.Path, response.status, response.statusText));
				throw new Error(`API request failed with ${response.status}: ${response.statusText}`);
			}

			var renamedFile: ODataResult<FileSystemObject> = await response.json();
			renamedFile.value[0].Drives = [targetDrive];

			batch(() => {
				dispatch(removeFiles([file]));
				dispatch(addFiles([renamedFile.value[0]]));
			});

		} finally {
			dispatch(setRunningAction({ id: runningActionId, message: undefined }, file.Id, file.Path));
		}
	}
}
