import * as React from "react";
import { RootState, requestCheckList, checkListReceived, checkListReceiveFailed, } from "../../Store";
import { batch } from "react-redux";
import { CheckList } from "../../Model/CheckList";
import { DriveType } from "../../Model/DriveType";

export function loadCheckList(location: string, taskIdent: number, type: DriveType) {
	return async (dispatch: React.Dispatch<any>, getState: () => RootState): Promise<void> => {
		try {
			dispatch(requestCheckList(location, taskIdent));

			const { teams: { accessToken } } = getState();

			let url: string = `/checklists(ident=${taskIdent},location='${location}',type='${type}')?$expand=Values`;

			const response: Response = await fetch(`${window.location.origin}/api${url}`, {
				method: "GET",
				headers: {
					"Authorization": `Bearer ${accessToken}`
				}
			});

			if (!response.ok) {
				dispatch(checkListReceiveFailed(response.status, response.statusText));
				return;
			}

			const checkList: CheckList = await response.json();

			batch(() => {
				dispatch(checkListReceived(checkList));
			});
		} catch (error:any) {
			dispatch(checkListReceiveFailed(0, error.message));
		}
	};
}