import * as React from "react";
import "./ChecklistValue.scss";
import { Link, TextField } from "@fluentui/react";

export interface ChecklistFileProps {
	value: string;
	isEditing: boolean;
	isInternalUser: boolean;
	onChange: (value: string | undefined) => void;
}

export class ChecklistFile extends React.Component<ChecklistFileProps, {}> {
	render(): JSX.Element {
		if (this.props.isEditing && this.props.isInternalUser) {
			return <TextField value={this.props.value} onChange={(x,newValue) => this.props.onChange(newValue)} />;
		}
		if (!this.props.value) {
			return <></>;
		}

		if (this.props.isInternalUser) {
			return <Link href={"file:///" + this.props.value.replace("\\", "/")}>{this.props.value}</Link>;
		}
		else {
			let pathElements: string[] = this.props.value.split("\\");
			let fileName: string = pathElements[pathElements.length - 1];

			return <>{fileName}</>;
		}
	}
}