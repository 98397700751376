import * as React from "react";
import { IconButton, Spinner, SpinnerSize } from "@fluentui/react";
import useStyles from "./DockedActionBar.style";
import { useState } from "react";

export interface DockedAction {
	iconName: string;
	click: () => void;
}

export interface DockedActionBarProps {
	actions: DockedAction[];
	isWaiting: Boolean;
	showAlways: Boolean;
}

export const DockedActionBar = (props: React.PropsWithChildren<DockedActionBarProps>) => {
	const [hovering, setHovering] = useState(() => false);
	const styles = useStyles();

	return (
		<div className={styles.DockedActionBar}
			onMouseEnter={() => setHovering(true)}
			onMouseLeave={() => setHovering(false)}>
			<div className={styles.DockedActionBar_Context}>{props.children}</div>
			<div>
				{props.isWaiting &&
					<Spinner size={SpinnerSize.medium}
						className={hovering || props.showAlways ? styles.DockedActionBar_Hovering : styles.DockedActionBar_NotHovering} />
				}
				{!props.isWaiting && props.actions.map((x, i) =>
					<IconButton key={i}
						className={hovering || props.showAlways ? styles.DockedActionBar_Hovering : styles.DockedActionBar_NotHovering}
						iconProps={{ iconName: x.iconName }} onClick={() => x.click()} />
				)}
			</div>
		</div>
	)
}

