import { RootState, setRunningAction, setFileError, addFileTag } from "../../Store";
import { FileSystemObject } from "../../Model/FileSystemObject";
import { ApiUrlBuilder } from "../../ApiUrlBuilder";
import { batch } from "react-redux";
import i18n from "../../i18n";
import { generateId } from "../../Utils/GenerateId";
import { getFirstDrive } from "../../Utils/GetFirstDrive";
import { FileSystemObjectTag } from "../../Model/FileSystemObjectTag";

export function addFileTagThunk(file : FileSystemObject, tag: FileSystemObjectTag) {
	return async (dispatch: React.Dispatch<any>, getState: () => RootState): Promise<void> => {

		if (!file ) { return; }

		const { teams: { isInitialized, accessToken }, file: { selectedFiles } } = getState();
		if(!isInitialized || !accessToken) return;

		const files = selectedFiles && selectedFiles.length > 0 ? selectedFiles : [file];

		var actions = files.map(async (targetFile) => {
			const fileDrive = getFirstDrive(file.Drives);
			if(!fileDrive) return;

			const runningActionId = generateId();
			try {	
				dispatch(setRunningAction({ id: runningActionId, message: i18n.t("Actions.AddTag", { file: targetFile.Name }) }, targetFile.Id, targetFile.Path));		

				const url = ApiUrlBuilder.AddTag(fileDrive, targetFile.Path, targetFile.Name);

				const response: Response = await fetch(`/api${url}`, {
					method: "POST",
					headers: {
						"Authorization": `Bearer ${accessToken}`,
						"Content-Type":"application/json"
					},
					body: JSON.stringify({
						TermGuid: tag.TermGuid,
						Label: tag.Label,
						WssId: tag.WssId
					})
				});

				console.log("Add Tag", tag);
		
				if (response.status === 404) {
					return;
				}
		
				if (!response.ok) {
					dispatch(setFileError(targetFile.Id, targetFile.Path, response.status, response.statusText));
					throw new Error(`API request failed with ${response.status}: ${response.statusText}`);
				}

				batch(() => {
					dispatch(addFileTag(targetFile.Id, targetFile.Path, tag));
				});
			} finally {
				dispatch(setRunningAction({id: runningActionId, message: undefined}, targetFile.Id, targetFile.Path));
			}
		});

		Promise.all(actions);
	}
}