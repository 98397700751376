import { DriveRef } from "./Model/DriveRef";
import { DriveType } from "./Model/DriveType";

export class ApiUrlBuilder {

	public static GetTask(location: string, taskIdent: string) {
		return `/locations/${location}/tasks/${taskIdent}`;
	}

	public static GetTaskInfo(location: string, taskIdent: string) {
		return `/locations/${location}/tasks/${taskIdent}?$select=Ident,Location,Number`;
	}

	public static GetTaskWithChecklist(location: string, taskIdent: string) {
		return `/locations/${location}/tasks/${taskIdent}?$expand=Checklist`;
	}

	public static GetTaskWithOrders(location: string, taskIdent: string) {
		return `/locations/${location}/tasks/${taskIdent}?$expand=Orders`;
	}

	public static GetOrder(location: string, orderIdent: string) {
		return `/locations/${location}/orders/${orderIdent}`;
	}

	public static GetOrderWithTests(location: string, orderIdent: string) {
		return `/locations/${location}/orders/${orderIdent}?$expand=Tests`;
	}

	public static GetContactImage(email: string) {
		return `/contacts('${email}')/photo`;
	}

	public static GetTender(location: string, orderIdent: string) {
		return `/locations/${location}/tenders/${orderIdent}`;
	}

	public static GetTest(location: string, testIdent: string) {
		return `/locations/${location}/tests/${testIdent}`;
	}

	public static GetDrive(location: string, ident: string, type: DriveType) {
		return `/drives(ident=${ident},location='${location}',type='${type.toLowerCase()}')`;
	}

	public static GetFolders(location: string, ident: string, type: DriveType) {
		return `${ApiUrlBuilder.GetDrive(location, ident, type)}/folders`;
	}

	public static GetFiles(location: string, ident: string, type: DriveType) {
		return `${ApiUrlBuilder.GetDrive(location, ident, type)}/files`;
	}

	public static GetFileSystemObjects(location: string, ident: string, type: DriveType) {
		return `${ApiUrlBuilder.GetDrive(location, ident, type)}/fileSystemObjects`;
	}

	static ShareContentByLink() {
		return `/drives/shareContentByLink`;
	}

	static ShareContentWithCustomer() {
		return `/drives/shareContentWithCustomer`;
	}

	static GetContentByLink() {
		return `/drives/getContentByLink`;
	}

	public static GetFileSystemObjectContent() {
		return `/fileSystemObjects/value`;
	}

	public static GetFile(drive: DriveRef, folder: string, file: string) {
		// Escapes URI component. Single quotes are not automatically encoded, so we have to do that ourselves.
		let folderName = this.escapeUriPart(folder);
		let fileName = this.escapeUriPart(file);
		// Escaped values have to be escaped again so that they are preserved on the server
		return `${ApiUrlBuilder.GetDrive(drive.Location, drive.Ident, drive.Type)}/folders('${encodeURIComponent(folderName)}')/files('${encodeURIComponent(fileName)}')/$value`;
	}

	public static GetFilePreview(drive: DriveRef, folder: string, file: string) {
		// Escapes URI component. Single quotes are not automatically encoded, so we have to do that ourselves.
		let folderName = this.escapeUriPart(folder);
		let fileName = this.escapeUriPart(file);
		// Escaped values have to be escaped again so that they are preserved on the server
		return `${ApiUrlBuilder.GetDrive(drive.Location, drive.Ident, drive.Type)}/folders('${encodeURIComponent(folderName)}')/files('${encodeURIComponent(fileName)}')/preview`;
	}

	public static AddFile(location: string, ident: string, type: DriveType, folder: string, file: string, overwrite: boolean) {
		// Escapes URI component. Single quotes are not automatically encoded, so we have to do that ourselves.
		let folderName = this.escapeUriPart(folder);
		let fileName = this.escapeUriPart(file);
		// Escaped values have to be escaped again so that they are preserved on the server
		return `${ApiUrlBuilder.GetDrive(location, ident, type)}/folders('${encodeURIComponent(folderName)}')/files/add(filename='${encodeURIComponent(fileName)}',overwrite=${overwrite})`;
	}

	public static UploadFile(location: string, ident: string, type: DriveType, folder: string, file: string) {
		// Escapes URI component. Single quotes are not automatically encoded, so we have to do that ourselves.
		let folderName = this.escapeUriPart(folder);
		let fileName = this.escapeUriPart(file);
		// Escaped values have to be escaped again so that they are preserved on the server
		return `${ApiUrlBuilder.GetDrive(location, ident, type)}/folders('${encodeURIComponent(folderName)}')/files('${encodeURIComponent(fileName)}')`;
	}

	public static MoveFile(drive: DriveRef, folder: string, file: string) {
		// Escapes URI component. Single quotes are not automatically encoded, so we have to do that ourselves.
		let folderName = this.escapeUriPart(folder);
		let fileName = this.escapeUriPart(file);
		// Escaped values have to be escaped again so that they are preserved on the server
		return `${ApiUrlBuilder.GetDrive(drive.Location, drive.Ident, drive.Type)}/folders('${encodeURIComponent(folderName)}')/files('${encodeURIComponent(fileName)}')/move`;
	}

	public static MoveFolder(location: string, ident: string, type: DriveType, folder: string) {
		// Escapes URI component. Single quotes are not automatically encoded, so we have to do that ourselves.
		let folderName = this.escapeUriPart(folder); 
		// Escaped values have to be escaped again so that they are preserved on the server
		return `${ApiUrlBuilder.GetDrive(location, ident, type)}/folders('${encodeURIComponent(folderName)}')/moveFolder`;
	}

	public static DeleteFile(drive: DriveRef, folder: string, file: string) {
		// Escapes URI component. Single quotes are not automatically encoded, so we have to do that ourselves.
		let folderName = this.escapeUriPart(folder); 
		let fileName = this.escapeUriPart(file); 
		// Escaped values have to be escaped again so that they are preserved on the server
		return `${ApiUrlBuilder.GetDrive(drive.Location, drive.Ident, drive.Type)}/folders('${encodeURIComponent(folderName)}')/files('${encodeURIComponent(fileName)}')`;
	}

	public static DeleteFolder(location: string, ident: string, type: DriveType, folder: string) {
		// Escapes URI component. Single quotes are not automatically encoded, so we have to do that ourselves.
		let folderName = this.escapeUriPart(folder); 
		// Escaped values have to be escaped again so that they are preserved on the server
		return `${ApiUrlBuilder.GetDrive(location, ident, type)}/folders('${encodeURIComponent(folderName)}')`;
	}

	public static ShareFile(location: string, ident: string, type: DriveType, folder: string, file: string) {
		// Escapes URI component. Single quotes are not automatically encoded, so we have to do that ourselves.
		let folderName = this.escapeUriPart(folder); 
		let fileName = this.escapeUriPart(file); 
		// Escaped values have to be escaped again so that they are preserved on the server
		return `${ApiUrlBuilder.GetDrive(location, ident, type)}/folders('${encodeURIComponent(folderName)}')/files('${encodeURIComponent(fileName)}')/share`;
	}

	public static AddTag(drive: DriveRef, folder: string, file: string) {
		// Escapes URI component. Single quotes are not automatically encoded, so we have to do that ourselves.
		let folderName = this.escapeUriPart(folder); 
		let fileName = this.escapeUriPart(file); 
		// Escaped values have to be escaped again so that they are preserved on the server
		return `${ApiUrlBuilder.GetDrive(drive.Location, drive.Ident, drive.Type)}/folders('${encodeURIComponent(folderName)}')/files('${encodeURIComponent(fileName)}')/addtag`;
	}

	public static RemoveTag(drive: DriveRef, folder: string, file: string) {
		// Escapes URI component. Single quotes are not automatically encoded, so we have to do that ourselves.
		let folderName = this.escapeUriPart(folder); 
		let fileName = this.escapeUriPart(file); 
		// Escaped values have to be escaped again so that they are preserved on the server
		return `${ApiUrlBuilder.GetDrive(drive.Location, drive.Ident, drive.Type)}/folders('${encodeURIComponent(folderName)}')/files('${encodeURIComponent(fileName)}')/removetag`;
	}

	public static SendTaskMessage(location: string, taskIdent: string) {
		return `/locations/${location}/tasks/${taskIdent}/messages`;
	}

	public static SendOrderMessage(location: string, taskIdent: string) {
		return `/locations/${location}/tasks/${taskIdent}/messages`;
	}

	public static SetChecklistValue(location: string, taskIdent: string, checkListItemIdent: number) {
		return `/locations/${location}/tasks/${taskIdent}/Checklist/${checkListItemIdent}`;
	}

	/**
	 * Escapes the URI and also ensures that a single quotation mark is escaped
	 * @param part The URI part that should be escaped
	 */
	private static escapeUriPart(part: string): string {
		return encodeURIComponent(part).replace(/'/g, "%27");
	}

	public static GetExternalShares() {
		return `/drives/getExternalShares`;
	}

	public static ResetDownloads() {
		return `/drives/resetExternalShareDownloads`;
	}

	public static ResetSharePassword() {
		return `/drives/resetExternalSharePassword`;
	}

	public static StopExternalShares() {
		return `/drives/stopExternalShares`;
	}

	public static ExtendExternalShares() {
		return `/drives/extendExternalShares`;
	}
}