import * as React from "react";
import { Link } from "@fluentui/react";
import { previewFile } from "../../Store";
import { FileSystemObject } from "../../Model/FileSystemObject";
import { generateId } from "../../Utils/GenerateId";
import { useAppDispatch } from "../../Store/useAppDispatch";

export interface OpenFileLinkProps {
	file: FileSystemObject;
}

export const OpenFileLink = (props: React.PropsWithChildren<OpenFileLinkProps>) => {

	var dispatch = useAppDispatch();

	let linkUrl = props.file?.Url;
	let newTab = false;
	let dispatchAction : any | undefined = undefined;
	const randomUrlPart = "?myPxTLHash=" + generateId();

	if (props.file?.Url) {
		const extension = props.file?.Url.substring(props.file?.Url.lastIndexOf('.'));

		switch (extension.toLowerCase()) {
			case ".docx":
			case ".doc":
			case ".dot":
			case ".dotx":
			case ".docm":
				linkUrl = "ms-word:ofe|u|" + linkUrl + randomUrlPart;
				break;

			case ".xls":
			case ".xlt":
			case ".xlm":
			case ".xlsx":
			case ".xlsm":
			case ".xltx":
			case ".xltm":
				linkUrl = "ms-excel:ofe|u|" + linkUrl + randomUrlPart;
				break;

			case ".ppt":
			case ".pot":
			case ".pps":
			case ".pptx":
			case ".pptm":
			case ".potx":
			case ".potm":
			case ".ppsx":
			case ".ppsm":
				linkUrl = "ms-powerpoint:ofe|u|" + linkUrl + randomUrlPart;
				break;

			case ".vdx":
			case ".vsd":
			case ".vsdx":
				linkUrl = "ms-visio:ofe|u|" + linkUrl + randomUrlPart;
				break;

			case ".mdb":
				linkUrl = "ms-access:ofe|u|" + linkUrl + randomUrlPart;
				break;

			case ".mpp":
				linkUrl = "ms-project:ofe|u|" + linkUrl + randomUrlPart;
				break;

			case ".pub":
				linkUrl = "ms-publisher:ofe|u|" + linkUrl + randomUrlPart;
				break;

			case ".one":
				linkUrl = "ms-onenote:ofe|u|" + linkUrl + randomUrlPart;
				break;

			case ".msg":
				// Use link
				//linkUrl = "ms-outlook:ofv|u|" + linkUrl;
				break;

			case ".pdf":
				/*const openPDF = () => {
					/*const filePreview = {
						entityId:"TestPDF",
						title:"TestPDF", 
						type: "pdf", 
						objectUrl: "https://myphoenixtestlab.clouddev.one/api/drives(ident=3619,location='LocMars',type='order')/folders('Customer%2520exchange')/files('Test%2520Dokument.pdf')/$value",
						//downloadUrl: "/api/drives(ident=3619,location='LocMars',type='order')/folders('Customer%2520exchange')/files('Test%2520Dokument.pdf')/$value",
						//webPreviewUrl: "/api/drives(ident=3619,location='LocMars',type='order')/folders('Customer%2520exchange')/files('Test%2520Dokument.pdf')/$value",
						editFile: false,
						subEntityId:"",
						viewerAction:"view",
						description:"Test PDF"//,
						//baseUrl:"https://myphoenixtestlab.clouddev.one/"
					} as FilePreviewParameters;
					microsoftTeams.openFilePreview(filePreview);
					microsoftTeams.executeDeepLink(props.url);
				};
				return <Link href="#" onClick={openPDF} >{props.children}</Link>;*/
				linkUrl = props.file?.Url;
				newTab = true;
				break;

			case ".png":
			case ".jpg":
			case ".jpeg":
			case ".jpe":
			case ".jfif":
			case ".bmp":
			case ".gif":
			case ".tif":
			case ".tiff":
				dispatchAction = previewFile(props.file);
				linkUrl = "";
				break; //return <Link href="#" onClick={(e) => {e.stopPropagation(); e.preventDefault(); dispatch(); return false;}} target={newTab? "_blank":""} >{props.children}</Link>

			default:
				linkUrl = "";
				break;
		}
	}

	const onClick = React.useCallback((e:React.MouseEvent<HTMLElement, MouseEvent>) => {
		e.stopPropagation();
		e.preventDefault(); 
		
		if(dispatchAction) {
			dispatch(dispatchAction);
		}
		else {
			console.log("Link", linkUrl);
			microsoftTeams.executeDeepLink(linkUrl); 
		}
		return false;
	}, [linkUrl, dispatch, dispatchAction]);

	if(linkUrl || dispatchAction) {
		return <Link href="#" disabled={props.file.hasRunningAction} data-selection-disabled={true} onClick={(e:React.MouseEvent<HTMLElement, MouseEvent>) => onClick(e)} target={newTab? "_blank":""} >{props.children}</Link>
	} else {
		return <>{props.children}</>;
	}
}