import { ICommandBarItemProps } from "@fluentui/react";

export type AddCommandCallback = (command: ICommandBarItemProps) => void;

export interface CommandBarCommandProps {
	addCommandButton: AddCommandCallback;
}

export function useCommands(): [ICommandBarItemProps[], AddCommandCallback] {
	const commands: ICommandBarItemProps[] = [];

	const addCommand: AddCommandCallback = (command: ICommandBarItemProps) =>  {
		// prevent multiple of same commands (like when the commands opens a dialog, then closed by cancel and the item remains selected)
		if (commands.some(c => c.key === command.key)) {
			return;
		}
		commands.push(command);
	}

	// const commandsAreEqual = (a: ICommandBarItemProps[], b: ICommandBarItemProps[]) => {
	// 	if (!a && !b) return true;
	// 	if (!a || !b) return false;

	// 	if (a.length != b.length) return false;

	// 	for (let index = 0; index < a.length; index++) {
	// 		if (a[index].key !== b[index].key) return false;
	// 	}

	// 	return true;
	// }

	return [commands, addCommand];
}