import * as React from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import { TextField, Text, ChoiceGroup, IChoiceGroupOption } from "@fluentui/react";
import tabConfigurations, { ITabConfiguration } from "../Config/TabConfiguration";
import i18n from "../i18n";
import { PrimaryButton } from "@fluentui/react";

interface ITabConfigState {
	Tab?: ITabConfiguration;
	DisplayName?: string;
	PTLimsIdent?: string;
	PTLimsLocation?: string;
	IsNewTab: boolean;
	CanChangeIdent: boolean;
	Mode: string;
	GroupId: string;
	ChannelId: string;
}

interface IPtLimsSettings {	
	Ident: number;
	Location: string;
}

export class TabConfig extends React.Component<{}, ITabConfigState> {

	constructor(props: any) {
		super(props);

		this.state = {
			Tab: tabConfigurations[0],
			DisplayName: tabConfigurations[0].displayName,
			PTLimsIdent: "",
			PTLimsLocation: "",
			IsNewTab: false,
			CanChangeIdent: false,
			Mode: "",
			GroupId: "",
			ChannelId: ""
		};
	}

	componentDidMount(): void {
		this.initialize();
	}

	private async initialize() {
		await this.initializeTeams();
		const context = await this.getContext();
		let settings = await this.getSettings();

		if (!settings || !settings.entityId || !settings.contentUrl) { 			
			settings = await this.getSettings(); // Retry
		}

		const isGeneralTab = context.channelId === context.teamId && context.groupId;

		const newState : ITabConfigState = {...this.state};
		newState.Mode = isGeneralTab ? "task":"order";
		newState.CanChangeIdent = true;		

		let tab: ITabConfiguration | undefined = tabConfigurations.find(x => x.entityId === settings.entityId);
		if (!tab) { 
			tab = tabConfigurations.filter(x => x.target === newState.Mode)[0];	
			newState.IsNewTab = true;
		}
		else {	
			newState.IsNewTab = false;
		}
		newState.Tab = tab;
		newState.DisplayName = tab.displayName;
		newState.GroupId = context.groupId || "";
		newState.ChannelId = context.channelId || "";

		if(settings?.suggestedDisplayName) {
			newState.DisplayName = settings.suggestedDisplayName;
		}

		//microsoftTeams.openFilePreview( microsoftTeams.File);
				
		var ptLimsSettings = await this.getPtLimsSettings(newState.GroupId, newState.ChannelId);

		if(ptLimsSettings) {
			newState.PTLimsIdent = ptLimsSettings.Ident.toString();
			newState.PTLimsLocation = ptLimsSettings.Location;
			newState.CanChangeIdent = false;
		}
		else if(settings.contentUrl) {			
			let url: string = settings.contentUrl;
			for (const urlPart of tab.contentUrl) {
				if(urlPart.startsWith("{") && urlPart.endsWith("}")) {
					let nextBlockIndex: number = url.indexOf("/");
					let partContent: string = nextBlockIndex >= 0 ? url.substring(0, nextBlockIndex) : url.substring(0);
	
					switch (urlPart) {
						case "{Location}":
							newState.PTLimsLocation = partContent;
							break;
						case "{Ident}":
							newState.PTLimsIdent = partContent;
							break;
					}
					url = nextBlockIndex >= 0 ? url.substring(nextBlockIndex+1) : "";
	
				} else {
					url = url.substring(urlPart.length+1);
				}
			}
		}

		this.setState(() => newState);
		this.validateComponent(newState);		
	}

	private initializeTeams() : Promise<void> {
		return new Promise((resolve) =>
		microsoftTeams.initialize(() => resolve()));
	}

	private getContext() : Promise<microsoftTeams.Context> {
		return new Promise((resolve) =>
		microsoftTeams.getContext(x => resolve(x)));
	}

	private getToken() : Promise<string> {
		return new Promise((resolve, reject) =>
		microsoftTeams.authentication.getAuthToken({
			resources: [],
			failureCallback: (reason: any) => reject(reason),
			successCallback: (token: any) => resolve(token)
		}));
	}

	private getSettings() : Promise<microsoftTeams.settings.Settings> {
		return new Promise((resolve) => {
			microsoftTeams.settings.getSettings((settings) => resolve(settings));
		});
	}

	componentDidUpdate(): void {
		this.validateComponent(this.state);
	}

	private async getPtLimsSettings(teamId: string, channelId: string) : Promise<IPtLimsSettings | null> {
		let accessToken = await this.getToken();

		const response: Response = await fetch(`${window.location.origin}/api/channels/PTLimsReference(teamId='${teamId}',channel='${channelId}')`, {
			method: "GET",
			headers: {
				"Authorization": `Bearer ${accessToken}`
			}
		});

		if (!response.ok) {
			return null;
		}

		const settings: IPtLimsSettings = await response.json();
		return settings;
	}

	private async setPtLimsSettings(teamId: string, channelId: string, ident: string, location: string) : Promise<boolean> {
		let accessToken = await this.getToken();

		const response: Response = await fetch(`${window.location.origin}/api/channels/SetPTLimsReference`, {
			method: "POST",
			headers: {
				"Authorization": `Bearer ${accessToken}`,
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
				"TeamId":teamId,
				"ChannelId":channelId,
				"Ident": Number(ident),
				"Location": location
			})
		});

		return response.ok;
	}

	private async saveTab(saveEvent: microsoftTeams.settings.SaveEvent): Promise<void> {
		const settings: microsoftTeams.settings.Settings | undefined = this.createSettings();

		if (!settings) {
			saveEvent.notifyFailure("No settings provided");
			return;
		}	

		microsoftTeams.settings.setSettings(settings, (status, reason) => {
			if(status) {
				saveEvent.notifySuccess();
			} else {
				saveEvent.notifyFailure(reason);
			}
		});		
	}

	public validateComponent(state: ITabConfigState): void {
		let isValid: boolean = true;

		if (!state.Tab) { isValid = false; }
		if (!state.DisplayName) { isValid = false; }
		if (!state.PTLimsIdent) { isValid = false; }
		if (!state.PTLimsLocation) { isValid = false; }

		if(state.Mode === "task" && state.CanChangeIdent) { isValid = false; }

		microsoftTeams.settings.setValidityState(isValid);
		microsoftTeams.settings.registerOnSaveHandler((saveEvent) => this.saveTab(saveEvent));
	}

	createSettings(): microsoftTeams.settings.Settings | undefined {
		if (!this.state.Tab || 
			!this.state.DisplayName || 
			!this.state.PTLimsIdent || 
			!this.state.PTLimsLocation) { 
				return undefined; 
		}
			
		const ident: string = encodeURIComponent(this.state.PTLimsIdent || "");
		const location: string = encodeURIComponent(this.state.PTLimsLocation || "");

		const contentUrl: string = this.state.Tab.contentUrl.map(x => {
			switch (x) {
				case "{Location}":
					return location;
				case "{Ident}":
					return ident;
				default:
					return x;
			}
		}).join("/");
		
		return {
			entityId: this.state.Tab.entityId,
			contentUrl: contentUrl,
			suggestedDisplayName: this.state.DisplayName
		};
	}

	private onTabChange(option: IChoiceGroupOption | undefined): void {
		if (!option) { return; }

		const selectedTabConfig: ITabConfiguration | undefined = tabConfigurations.find((x: ITabConfiguration) => x.entityId === option.key);
		if (!selectedTabConfig) { return; }

		if (this.state.Tab && this.state.DisplayName === this.state.Tab.displayName) {
			this.setState({
				Tab: selectedTabConfig,
				DisplayName: selectedTabConfig.displayName
			});
		} else {
			this.setState({
				Tab: selectedTabConfig
			});
		}
	}

	private async onBind() : Promise<void> {		
		var settingsValid = await this.setPtLimsSettings(this.state.GroupId, this.state.ChannelId, this.state.PTLimsIdent || "", this.state.PTLimsLocation || "");

		if(settingsValid) {			
			this.setState({
				CanChangeIdent: false
			});
		}
	}

	render(): JSX.Element {
		var tabOptions: IChoiceGroupOption[] = tabConfigurations
			.filter(x => x.target === this.state.Mode)
			.map<IChoiceGroupOption>(x => ({
			key: x.entityId,
			text: x.name,
			iconProps: { iconName: x.icon }
		}));

		return (
			<>
				<Text>
					{i18n.t("TabConfig.Intro")}
				</Text>
				<ChoiceGroup
					label={i18n.t("TabConfig.Content")}
					required={true}
					options={tabOptions}
					selectedKey={this.state.Tab ? this.state.Tab.entityId : ""}
					onChange={(_ev, option) => this.onTabChange(option)} />
				{this.state.IsNewTab &&
					<TextField
						label={i18n.t("TabConfig.TabName")}
						required={true}
						value={this.state.DisplayName}
						onChange={(_ev, newValue) => this.setState({ DisplayName: newValue })} /> 
					}
				<TextField
					label={i18n.t("TabConfig.PTLimsIdent")}
					required={true}
					disabled={!this.state.CanChangeIdent}
					value={this.state.PTLimsIdent}
					onChange={(_ev, newValue) => this.setState({ PTLimsIdent: newValue })} />
				<TextField
					label={i18n.t("TabConfig.PTLimsLocation")}
					required={true}
					disabled={!this.state.CanChangeIdent}
					value={this.state.PTLimsLocation}
					onChange={(_ev, newValue) => this.setState({ PTLimsLocation: newValue })} />
				{this.state.CanChangeIdent &&
					<PrimaryButton 
						text={i18n.t("TabConfig.BindPTLims")} 
						onClick={() => this.onBind()} />}
			</>
		);
	}
}
