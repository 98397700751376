import * as React from "react";
import { renameFile } from "../Api/File/RenameFile";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { getFirstDrive } from "../Utils/GetFirstDrive";
import { useAppDispatch, useAppSelector } from "../Store/useAppDispatch";
import { CommandBarCommandProps } from "../Utils/useCommands";


export const PullFileFromTaskCommand = (props: CommandBarCommandProps): JSX.Element => {
	const { selectedFiles, selectedFolders, ident, location, sourceType, drives, files, isReadonlyFolder: isReadOnlyFolder } = useAppSelector(state => state.file);
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const onCommand = React.useCallback(() => {
		const targetDrive = drives.find(x => x.Type === sourceType && x.Ident === ident && x.Location === location);
		if (!targetDrive) return;

		if (selectedFiles) {
			for (const file of selectedFiles) {
				dispatch(renameFile(file, file.Path, file.Name, targetDrive));
			}
		}

		if (selectedFolders) {
			for (const folder of selectedFolders) {
				var subFiles = files?.filter(file => file.Path.startsWith(folder.Path) && getFirstDrive(file.Drives)?.Type === "Task");
				if (subFiles) {
					for (const file of subFiles) {
						dispatch(renameFile(file, file.Path, file.Name, targetDrive));
					}
				}
			}
		}

	}, [dispatch, drives, selectedFiles, selectedFolders, files, ident, location, sourceType]);

	const showCommand = useMemo(() =>
		!isReadOnlyFolder && (sourceType === "Order" || sourceType === "Tender") && ((selectedFolders && selectedFolders.length > 0) || (selectedFiles && selectedFiles.length > 0 && selectedFiles.every(file => getFirstDrive(file.Drives)?.Type === "Task")))
		, [isReadOnlyFolder, selectedFiles, selectedFolders, sourceType]);

	const isDisabled = useMemo(() =>
		selectedFiles?.some(file => file.hasRunningAction) || selectedFolders?.some(folder => folder.hasRunningAction)
		, [selectedFiles, selectedFolders]);

	if (showCommand) {
		props.addCommandButton({
			key: "pullFileFromTask",
			text: t(sourceType === "Order" ? "Commands.PullFileFromTask.CommandOrder" : "Commands.PullFileFromTask.CommandTender"),
			iconProps: { iconName: "MoveToFolder" },
			disabled: isDisabled,
			title: isDisabled ? t("Commands.IsBusy") : undefined,
			onClick: () => { onCommand(); return false; },
		});
	}

	return <>
	</>;
}