import * as React from "react";
import { useCallback, useMemo } from "react";
import { useTranslation } from 'react-i18next';
import { FileSystemObject } from "../Model/FileSystemObject";
import { DriveRef } from "../Model/DriveRef";
import { getFirstDrive } from "../Utils/GetFirstDrive";
import { CommandBarCommandProps } from "../Utils/useCommands";

export interface ExternalDownloadCommandProps extends CommandBarCommandProps {
	triggerRerender: () => void;
	selectedFiles: FileSystemObject[] | undefined;
	shareId: string;
	password: string | undefined;
}

interface ExternalFileDownloadDto {
	ShareId: string;
	Password: string;
	Folder: string;
	FileId: string;
	Drive: DriveRef;
}

export const ExternalDownloadCommand = (props: ExternalDownloadCommandProps): JSX.Element => {
	const { t } = useTranslation();
	const { selectedFiles, shareId, password } = props;
	const submitForm = React.useRef<HTMLFormElement>(null);
	const driveIdentRef = React.useRef<HTMLInputElement>(null);
	const driveLocationRef = React.useRef<HTMLInputElement>(null);
	const driveTypeRef = React.useRef<HTMLInputElement>(null);
	const folderRef = React.useRef<HTMLInputElement>(null);
	const fileIdRef = React.useRef<HTMLInputElement>(null);
	const shareIdRef = React.useRef<HTMLInputElement>(null);
	const passwordRef = React.useRef<HTMLInputElement>(null);

	const downloadableSelectedFiles = useMemo(() => selectedFiles?.filter((file) => !file.hasRunningAction), [selectedFiles]);

	const onDownload = useCallback(async (file: FileSystemObject) => {

		if(submitForm.current 
			&& driveIdentRef.current 
			&& driveLocationRef.current 
			&& driveTypeRef.current 
			&& folderRef.current 
			&& fileIdRef.current 
			&& shareIdRef.current 
			&& passwordRef.current) {	

			const firstDrive = getFirstDrive(file.Drives)

			driveIdentRef.current.value = firstDrive?.Ident ?? "";
			driveLocationRef.current.value = firstDrive?.Location ?? "";
			driveTypeRef.current.value = firstDrive?.Type ?? "";
			folderRef.current.value = file.Path;
			fileIdRef.current.value = file.Name;
			shareIdRef.current.value = shareId;
			passwordRef.current.value = password ?? "";

			submitForm.current?.submit();
		}
	}, [shareId, password, t, props]);

	if (selectedFiles && selectedFiles.length > 0) {
		props.addCommandButton({
			key: "externalDownload",
			text: t("Commands.Download.Command"),
			iconProps: { iconName: "Download" },
			onClick: () => {
				for (const file of selectedFiles) {
					onDownload(file);
				}
				return false;
			},
			disabled: (downloadableSelectedFiles?.length ?? 0) === 0
		});
	}

	return <>
		<form ref={submitForm} action="/api/drives/downloadByLink" method="POST">
			<input type="hidden" ref={driveIdentRef} name="driveIdent" />
			<input type="hidden" ref={driveLocationRef} name="driveLocation" />
			<input type="hidden" ref={driveTypeRef} name="driveType" />
			<input type="hidden" ref={folderRef} name="folder" />
			<input type="hidden" ref={fileIdRef} name="fileId" />
			<input type="hidden" ref={shareIdRef} name="shareId" />
			<input type="hidden" ref={passwordRef} name="password" />
		</form>
	</>;
}