import { DriveRef } from "../Model/DriveRef";
import { FileSystemObject } from "../Model/FileSystemObject";
import { getFirstDrive } from "./GetFirstDrive";

export const getDriveKeyString = (drives: DriveRef[] | undefined) => {
        if (!drives) {
                return "";
        }
        const fileDrive = getFirstDrive(drives);
        return `${fileDrive?.Ident}_${fileDrive?.Location}_${fileDrive?.Type}`;
};

export const getFileSystemObjectKeyString = (fileSystemObject: FileSystemObject) => {
        return `${getDriveKeyString(fileSystemObject.Drives)}_${fileSystemObject.Path}_${fileSystemObject.Id}`;
};