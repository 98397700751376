import moment from "moment";
import i18n from "../i18n";

/**
 * Sets the language for i18n.
 * Ignores dialects and not registered languages
 * @param lng language-code
 */
export const setGlobalLanguage = (lng: string | undefined) => {
    if (!lng) {
        return;
    }
    const lngArray = lng.toLowerCase().split("-"); // split to ignore dialects written after hyphen

    if (lngArray.length >= 1 && getRegisteredLanguages().some(lng => lng.toLowerCase() === lngArray[0])) {
        i18n.changeLanguage(lngArray[0]);
    }

    moment.locale(lng);
}

/**
 * Gets all registered languages from i18n.
 * i18n.languages does not return all languages, therefore this method can be used.
 * @returns A string array containing all registered languages
 */
export const getRegisteredLanguages = (): string[] => {
    // i18n.languages are only whitelisted languages, e.g. current language and fallback languages)
    return Object.keys(i18n.services.resourceStore.data) ?? [];
}