import * as React from "react";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { useAppSelector } from "../Store/useAppDispatch";
import { CommandBarCommandProps } from "../Utils/useCommands";


export const OpenSharePointCommand = (props: CommandBarCommandProps): JSX.Element => {
	const { driveUrl } = useAppSelector(state => state.file);
	const { t } = useTranslation();

	const showCommand = useMemo(() =>
		driveUrl && driveUrl !== "#"
		, [driveUrl]);

	if (showCommand) {
		props.addCommandButton({
			key: "openSharePoint",
			text: t("Commands.OpenSharePoint.Command"),
			iconProps: { iconName: "SharepointLogo" },
			onClick: () => {
				window.open(driveUrl, '_blank');
				return false;
			},
		});
	}

	return <>
	</>;
}