export interface ITabConfiguration {
	name: string;
	entityId: string;
	displayName: string;
	contentUrl: string[];
	websiteUrl: string;
	icon: string;
	target: string;
}

var TabConfigurations : ITabConfiguration[] = [
	{
		name: "Task status",
		entityId: "tab.taskstatus",
		contentUrl: [
			window.location.origin,
			"Tab",
			"locations",
			"{Location}",
			"taskstatus",
			"{Ident}"
		],
		displayName: "Task status",
		websiteUrl: window.location.origin,
		icon: "TimelineProgress",
		target: "task"
	},
	{
		name: "Task summary",
		entityId: "tab.taskchecklist",
		contentUrl: [
			window.location.origin,
			"Tab",
			"locations",
			"{Location}",
			"{Type}",
			"checklist",
			"{Ident}"
		],
		displayName: "Task summary",
		websiteUrl: window.location.origin,
		icon: "CheckList",
		target: "task"
	},
	{
		name: "Task file",
		entityId: "tab.webcube.task",
		contentUrl: [
			window.location.origin,
			"Tab",
			"locations",
			"{Location}",
			"taskfile",
			"{Ident}"
		],
		displayName: "Task file",
		websiteUrl: window.location.origin,
		icon: "DocumentSet",
		target: "task"
	},
	{
		name: "Order status",
		entityId: "tab.orderstatus",
		contentUrl: [
			window.location.origin,
			"Tab",
			"locations",
			"{Location}",
			"orderstatus",
			"{Ident}"
		],
		displayName: "Order status",
		websiteUrl: window.location.origin,
		icon: "TimelineProgress",
		target: "order"
	},
	{
		name: "Order file",
		entityId: "tab.webcube.order",
		contentUrl: [
			window.location.origin,
			"Tab",
			"locations",
			"{Location}",
			"orderfile",
			"{Ident}"
		],
		displayName: "Order file",
		websiteUrl: window.location.origin,
		icon: "DocumentSet",
		target: "order"
	}
];

export default TabConfigurations;