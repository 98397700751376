import * as React from "react";
import { toggleSelectedDrive } from "../Store";
import { Dropdown, IDropdownOption, IDropdownStyleProps, IDropdownStyles } from "@fluentui/react";
import useStyles from "./ViewDrivesCommand.style"
import { useMemo } from "react";
import { IStyleFunctionOrObject, useTheme } from "@fluentui/react";
import { DriveRef } from "../Model/DriveRef";
import { areSameDrives } from "../Utils/CompareDrive";
import { SpecialFolders } from "../Model/SpecialFolder";
import { useAppDispatch, useAppSelector } from "../Store/useAppDispatch";
import { CommandBarCommandProps } from "../Utils/useCommands";

export const ViewDrivesCommand = (props: CommandBarCommandProps): JSX.Element => {
	const { drives, files, selectedFolderId, selectedFolderFiles } = useAppSelector(state => state.file);
	const dispatch = useAppDispatch();
	const styles = useStyles();

	const currentFiles = useMemo(() => {
		const specialFolder = SpecialFolders.find(specialFolder => specialFolder.Id === selectedFolderId);
		if (!specialFolder) {
			return files?.filter(file => (selectedFolderId && (file.Path === selectedFolderId || file.Path.startsWith(selectedFolderId + "/"))))
		} else if (specialFolder.HasSubfolders) {
			return [...(selectedFolderFiles ?? []), ...(files?.filter(file => specialFolder.TargetFolderId && file.Path.startsWith(specialFolder.TargetFolderId + "/")) ?? [])]
		} else {
			return selectedFolderFiles;
		}
	}
		, [files, selectedFolderFiles, selectedFolderId]);

	const options = useMemo(() =>
		drives.map((drive) => (
			{
				key: JSON.stringify({ Ident: drive.Ident, Location: drive.Location, Type: drive.Type } as DriveRef),
				text: drive.Number,
				selected: drive.isSelected,
				data: currentFiles?.filter(file => areSameDrives(file.Drives, [drive])).length
			} as IDropdownOption))
		, [drives, currentFiles]);

	const theme = useTheme();
	const dropdownItemStyles: IStyleFunctionOrObject<IDropdownStyleProps, IDropdownStyles> = {
		subComponentStyles: {
			multiSelectItem: {
				root: {
					":hover": {
						backgroundColor: theme?.palette.neutralLighter
					}
				}
			}
		}
	};

	props.addCommandButton({
		key: "viewFolders",
		onRender: (item, dissmissMenu) => {
			return <div className={styles.ViewDrivesCommand}>
				<Dropdown
					options={options}
					// selectedKeys={selectedDrives}
					className={styles.Dropdown}
					multiSelect
					styles={dropdownItemStyles}
					onRenderOption={(option): JSX.Element => {
						return (
							<div className={styles.ViewDrivesCommandOption}>
								<span>{option?.text}</span>
								<span className={styles.FilesCount}>{option?.data ? option.data : ""}</span>
							</div>
						);
					}}
					onChange={(event, option) => option ? dispatch(toggleSelectedDrive(JSON.parse(option.key.toString()))) : undefined}
				/>
			</div>;
		}
	});

	return <>
	</>;
}