import { IStyle, makeStyles } from "@fluentui/react";

export default () => {
	let styles = makeStyles(theme => ({
		RunningActionWrapper: {
			// ensure there is no jumping content
			minWidth: "41px",
			minHeight: "23px",

			".ms-Spinner-label": {
				fontSize: theme.fonts.medium.fontSize
			} as IStyle
		},
		Callout: {
			boxShadow: `${theme.palette.black}21 0px 6.4px 14.4px 0px, ${theme.palette.black}1C 0px 1.2px 3.6px 0px`,

			".ms-Callout-main, .ms-Callout-beak": {
				backgroundColor: theme.palette.neutralLighter
			}
		},
		ActionList: {
			color: theme.palette.neutralPrimary
		},
		ListItem: {
			padding: "7px",
			borderBottom: `solid 1px ${theme.palette.neutralTertiaryAlt}`,

			":last-child": {
				borderBottom: "none"
			}
		}
	}));
	return styles();
};