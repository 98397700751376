import { FileState, OverwriteFileRecall } from "./State";
import { FileActionTypes, FileActions } from "./Actions";
import { FileSystemObject } from "../../Model/FileSystemObject";
import { SpecialFolderIds, SpecialFolders } from "../../Model/SpecialFolder";
import { sortByName } from "../../Utils/Sort";
import { areSameDrives, isSameDrive } from "../../Utils/CompareDrive";
import { stringToDate } from "../../Utils/DateHelper";
import { getFirstDrive } from "../../Utils/GetFirstDrive";
import { DriveRef } from "../../Model/DriveRef";
import { FileSystemObjectTag } from "../../Model/FileSystemObjectTag";

const initialState: FileState = {
	sourceType: undefined,
	ident: undefined,
	location: undefined,
	driveNumber: undefined,

	status: "NotLoaded",
	loadError: undefined,

	sortProperty: "Name",
	sortPropertyDirectionASC: true,

	files: undefined,
	folders: undefined,

	runningActions: [],
	drives: [],
	viewMode: "List",

	tags: [],
	tagsViewFilter: []
};

const filesByFolderId = (files: FileSystemObject[] | undefined, folderId: string | undefined, subFolderPath: string | undefined, sortProperty: string, sortPropertyDirectionASC: boolean, tags: FileSystemObjectTag[]) => {
	if (!files) return files;

	const specialFolder = SpecialFolders.find(specialFolder => specialFolder.Id === folderId);
	let result: FileSystemObject[] = [];
	if (!folderId || folderId === SpecialFolderIds.All) {
		result = [...files];
	} else if (folderId === SpecialFolderIds.CustomerExchange) {
		if (specialFolder?.HasSubfolders) {
			// select all shared files and consider subfolders in customer exchange folder
			result = files?.filter(x => x.SharedWithCustomer && (subFolderPath ? x.Path === subFolderPath : !x.Path?.startsWith(`${specialFolder?.TargetFolderId}/`))) ?? []
		} else {
			// select all files shared with customer
			result = files?.filter(x => x.SharedWithCustomer) ?? []
		}
	} else if (folderId === SpecialFolderIds.SharedByLink) {
		result = files?.filter(x => x.SharedByLink) ?? []
	} else if (!specialFolder || (specialFolder.HasSubfolders && specialFolder.TargetFolderId)) {
		result = files?.filter(x => x.Path === (subFolderPath ?? specialFolder?.TargetFolderId ?? folderId)) ?? []
	}

	const showWithoutTags = tags.some(x => x.TermGuid === 'NONE');

	result = result.filter(x => 
		(showWithoutTags && (!x.Tags || x.Tags?.length === 0 ))
		|| x.Tags?.some(y => tags.some(z => z.TermGuid === y.TermGuid)));

	return result.sort((a, b) => {
		const order = sortPropertyDirectionASC ? 1 : -1;
		switch (sortProperty) {
			case "Size":
				return a.Size > b.Size ? order : -order;
			case "ModifiedOn":
				return a.ModifiedOn > b.ModifiedOn ? order : -order;
			case "ModifiedBy":
				return a.ModifiedBy > b.ModifiedBy ? order : -order;
			case "Drive":
				return (getFirstDrive(a.Drives)?.Number ?? 0) > (getFirstDrive(b.Drives)?.Number ?? 0) ? order : -order;
			case "Folder":
				return a.Path > b.Path ? order : -order;
			default:
				return a.Name.toLowerCase() > b.Name.toLowerCase() ? order : -order;
		}
	});
}

const foldersByFolderId = (folders: FileSystemObject[] | undefined, folderId: string | undefined, subFolderPath: string | undefined) => {
	if (!folders) return folders;

	const specialFolder = SpecialFolders.find(specialFolder => specialFolder.Id === folderId);
	if (!folderId || (specialFolder && !specialFolder.HasSubfolders)) {
		return [];
	}

	// if (SpecialFolderIds.CustomerExchange === folderId) {
	// 	if (subFolderPath) {
	// 		return folders?.filter(x => x.SharedWithCustomer && x.Path.substring(0, x.Path.length - x.Name.length - 1) === (subFolderPath ?? folderId));
	// 	}
	// 	return folders?.filter(x => x.SharedWithCustomer && !x.Path.includes("/") && x.Path !== specialFolder?.TargetFolderId);
	// }

	// Only direkt sub folders
	return folders?.filter(x => x.Path.substring(0, x.Path.length - x.Name.length - 1) === (subFolderPath ?? specialFolder?.TargetFolderId ?? folderId));
}

const updateFolderAggregatedProperties = (folder: FileSystemObject, files: FileSystemObject[]) => {
	const folderFiles = files.filter(file => file.Path === folder.Path || file.Path?.startsWith(folder.Path + "/"));

	folder.filesCount = folderFiles.length;
	folder.CanShareFile = folderFiles.some(file => file.CanShareFile);
	folder.SharedWithCustomer = folderFiles.some(file => file.SharedWithCustomer);
	folder.SharedByLink = folderFiles.some(file => file.SharedByLink);

	if (!folderFiles?.length) {
		return;
	}

	const latestModifiedFile = folderFiles.reduce((prev, curr) => {
		const currDate = stringToDate(curr.ModifiedOn);
		const prevDate = stringToDate(prev.ModifiedOn);
		if (currDate && prevDate) {
			return currDate > prevDate ? curr : prev;
		}
		return curr ? curr : prev;
	});
	folder.ModifiedOn = latestModifiedFile.ModifiedOn;
	folder.ModifiedBy = latestModifiedFile.ModifiedBy;
}

const getCustomerFilesCount = (files: FileSystemObject[] | undefined) => {
	return files?.reduce((val, file) => val + (file.SharedWithCustomer ? 1 : 0), 0);
}

const getSharedByLinkCount = (files: FileSystemObject[] | undefined) => {
	return 0; // currently the count on "Shared by Link" is removed
	// return files?.reduce((val, file) => val + (file.SharedByLink ? 1 : 0), 0);
}

const cloneFSO = (files: FileSystemObject[] | undefined) : FileSystemObject[] => {
	return (files || []).map( f => ({...f, Tags:f?.Tags?.map(t => ({...t})),Drives:f.Drives.map(d => ({...d}))}) );
}

const cloneOverwrite = (overwrites: OverwriteFileRecall[] | undefined) : OverwriteFileRecall[] => {
	return (overwrites || []).map( f => ({...f}) );
}

export function fileReducer(
	state: FileState = initialState,
	action: FileActionTypes
): FileState {
	switch (action.type) {

		case FileActions.FilesRequest:
			const drive = state.drives.find(drive => drive.Ident === action.ident && drive.Location === action.location && drive.Type === action.sourceType);
			return {
				...state,
				status: "Loading",
				location: action.location,
				ident: action.ident,
				sourceType: action.sourceType,
				driveNumber: drive?.Number,
				loadError: undefined
			};

		case FileActions.FilesReceived:
			{
				let selectedFolderId: string | undefined = state?.selectedFolderId;
				let selectedSubFolderPath: string | undefined = state?.selectedSubFolderPath;

				if (selectedFolderId) {
					selectedFolderId = action.folders?.find(x => x.Id === state.selectedFolderId)?.Id;
				}

				if (!selectedFolderId) {
					selectedFolderId = SpecialFolderIds.All;
					selectedSubFolderPath = undefined;
				}

				if (selectedSubFolderPath) {
					selectedSubFolderPath = action.folders?.find(x => x.Path === state.selectedSubFolderPath)?.Path;
				}

				if (action.folders) {
					for (const folder of action.folders) {
						updateFolderAggregatedProperties(folder, action.files || []);
					}
				}
				if (action.files) {
					for (const file of action.files) {
						if (file.Size === 0) {
							file.hasError = true;
						}
					}
				}

				const selectedFolderFiles = filesByFolderId(action.files, state.selectedFolderId, state.selectedSubFolderPath, state.sortProperty, state.sortPropertyDirectionASC, state.tagsViewFilter);
				const selectedFolderSubFolders = foldersByFolderId(action.folders, state.selectedFolderId, state.selectedSubFolderPath);

				return {
					...state,
					status: "Loaded",
					files: action.files,
					folders: action.folders,
					isReadonlyFolder: state.folders?.find(folder => folder.Id === selectedFolderId)?.IsReadonly,
					selectedFolderId: selectedFolderId,
					selectedFolderFiles: selectedFolderFiles,
					selectedFolderSubFolders: selectedFolderSubFolders,
					selectedSubFolderPath: selectedSubFolderPath,
					filesCount: action.files?.length,
					customerFilesCount: getCustomerFilesCount(action.files),
					sharedByLinkFilesCount: getSharedByLinkCount(action.files),
					loadError: undefined
				};
			}

		case FileActions.FilesReceiveFailed:
			return {
				...state,
				status: "LoadFailed",
				files: undefined,
				folders: undefined,
				loadError: action.message
			};

		case FileActions.ClearError:
			return {
				...state,
				loadError: undefined
			};

		case FileActions.SetFileError: {
			const setRunningActionStatus = (files: FileSystemObject[]) => {
				files.forEach(x => {
					if (x.Id === action.fileId && x.Path === action.folderId) {
						x.hasError = true;
						x.errorMessage = action.message;
						x.hasRunningAction = false;
					}
				})
			}

			const files = cloneFSO(state.files); // state.files ? [...state.files] : [];
			setRunningActionStatus(files);

			const selectedFiles = cloneFSO(state.selectedFiles); // ? [...state.selectedFiles] : [];
			setRunningActionStatus(selectedFiles);

			const selectedFolderFiles = cloneFSO(state.selectedFolderFiles); // ? [...state.selectedFolderFiles] : [];
			setRunningActionStatus(selectedFolderFiles);

			return {
				...state,
				files,
				selectedFiles,
				selectedFolderFiles
			};
		}

		case FileActions.SelectFolder: {
			let files: FileSystemObject[] | undefined = filesByFolderId(state.files, action.folderId, undefined, state.sortProperty, state.sortPropertyDirectionASC, state.tagsViewFilter);
			let subFolders: FileSystemObject[] | undefined = foldersByFolderId(state.folders, action.folderId, undefined);

			return {
				...state,
				isReadonlyFolder: state.folders?.find(folder => folder.Id === action.folderId)?.IsReadonly,
				selectedFolderId: action.folderId,
				selectedSubFolderPath: undefined,
				selectedFolderFiles: files,
				selectedFolderSubFolders: subFolders,
				selectedFiles: [],
				selectedFolders: []
			};
		}

		case FileActions.SelectSubFolderPath: {
			let files: FileSystemObject[] | undefined = filesByFolderId(state.files, state.selectedFolderId, action.subFolderPath, state.sortProperty, state.sortPropertyDirectionASC, state.tagsViewFilter);
			let subFolders: FileSystemObject[] | undefined = foldersByFolderId(state.folders, state.selectedFolderId, action.subFolderPath);

			return {
				...state,
				selectedSubFolderPath: action.subFolderPath,
				selectedFolderFiles: files,
				selectedFolderSubFolders: subFolders,
				sortProperty: "name",
				sortPropertyDirectionASC: true,
				selectedFiles: [],
				selectedFolders: []
			};
		}

		case FileActions.SelectFiles:
			return {
				...state,
				selectedFiles: action.files,
			};

		case FileActions.SelectFolders:
			return {
				...state,
				selectedFolders: action.folders,
			};

		case FileActions.SetRunningAction:
			{
				const setRunningActionStatus = (files: FileSystemObject[]) => {
					files.forEach(x => {
						if (x.Id === action.fileId && x.Path === action.folderId) {
							x.hasRunningAction = action.action.message ? true : undefined
							x.hasError = action.action.message ? false : x.hasError; // Remove Message if new action starts
							x.errorMessage = action.action.message ? undefined : x.errorMessage;
						}
					})
				}

				const files = cloneFSO(state.files); // ? [...state.files] : [];
				setRunningActionStatus(files);

				const selectedFiles = cloneFSO(state.selectedFiles);// [...state.selectedFiles || []];
				setRunningActionStatus(selectedFiles);

				const selectedFolderFiles = cloneFSO(state.selectedFolderFiles); // ? [...state.selectedFolderFiles] : [];
				setRunningActionStatus(selectedFolderFiles);

				let folders, selectedFolders, selectedFolderSubFolders;
				if (action.setFolderAction) {
					const setFoldersRunningActionStatus = (folders: FileSystemObject[]) => {
						folders.forEach(x => {
							if (x.Path === action.folderId) {
								x.hasRunningAction = action.action.message ? true : undefined
								x.hasError = action.action.message ? false : x.hasError; // Remove Message if new action starts
								x.errorMessage = action.action.message ? undefined : x.errorMessage;
							}
						})
					}

					folders = cloneFSO(state.folders); // ? [...state.folders] : [];
					setFoldersRunningActionStatus(folders);

					selectedFolders = cloneFSO(state.selectedFolders); // ? [...state.selectedFolders] : [];
					setFoldersRunningActionStatus(selectedFolders);

					selectedFolderSubFolders = cloneFSO(state.selectedFolderSubFolders); // ? [...state.selectedFolderSubFolders] : [];
					setFoldersRunningActionStatus(selectedFolderSubFolders);
				}

				return {
					...state,
					files,
					selectedFiles,
					selectedFolderFiles,
					folders: folders ?? state.folders,
					selectedFolders: selectedFolders ?? state.selectedFolders,
					selectedFolderSubFolders: selectedFolderSubFolders ?? state.selectedFolderSubFolders,
					runningActions: action.action.message ? state.runningActions.concat(action.action) : state.runningActions.filter(item => item.id !== action.action.id),
				};
			}

		case FileActions.AddFiles:
			{
				let updatedFiles = cloneFSO(state.files); // || []];
				let folders = cloneFSO(state.folders); // || []];
				let selectedFiles = cloneFSO(state.selectedFiles); //) || []];

				action.files.forEach(newFile => {
					if (newFile.Size === 0) {
						newFile.hasError = true;
					}

					var index: number = updatedFiles.findIndex(x => x.Id === newFile.Id
						&& x.Path === newFile.Path
						&& areSameDrives(x.Drives, newFile.Drives));
					if (index >= 0) {
						updatedFiles.splice(index, 1);
					}

					var selectedIndex: number = selectedFiles.findIndex(x => x.Id === newFile.Id
						&& x.Path === newFile.Path
						&& areSameDrives(x.Drives, newFile.Drives));
					if (selectedIndex >= 0) {
						selectedFiles.splice(selectedIndex, 1);
						selectedFiles.push(newFile);
					}

					// Ensure all parent folders include the drive of the moved file
					var newFileDrive = getFirstDrive(newFile.Drives);
					if(newFileDrive) {
						folders.forEach(folder => {
							if(!newFile.Path.startsWith(folder.Path)) return;
							if(folder.Drives.some(drive => isSameDrive(drive, newFileDrive))) return;

							folder.Drives.push(newFileDrive as DriveRef);
						});
					}
				});

				console.log("Add files", action.files);
				updatedFiles.push(...action.files);
				updatedFiles = sortByName<FileSystemObject>(updatedFiles) || [];

				let selectedFolderFiles: FileSystemObject[] | undefined = filesByFolderId(updatedFiles, state.selectedFolderId, state.selectedSubFolderPath, state.sortProperty, state.sortPropertyDirectionASC, state.tagsViewFilter);

				console.log("selectedFolderFiles", selectedFolderFiles);
				
				for (const folder of folders) {
					updateFolderAggregatedProperties(folder, updatedFiles);
				}

				return {
					...state,
					files: updatedFiles,
					folders: folders,
					selectedFiles: selectedFiles,
					filesCount: updatedFiles.length,
					customerFilesCount: getCustomerFilesCount(updatedFiles),
					sharedByLinkFilesCount: getSharedByLinkCount(updatedFiles),
					selectedFolderFiles: selectedFolderFiles,
				};
			}

		case FileActions.RemoveFiles:
			{
				let updatedFiles = cloneFSO(state.files);
				let folders = cloneFSO(state.folders);

				action.files.forEach(newFile => {
					var index: number = updatedFiles.findIndex(x => x.Id === newFile.Id
						&& x.Path === newFile.Path
						&& areSameDrives(x.Drives, newFile.Drives));
					if (index >= 0) {
						updatedFiles.splice(index, 1);
					}
				});

				let selectedFolderFiles: FileSystemObject[] | undefined = filesByFolderId(updatedFiles, state.selectedFolderId, state.selectedSubFolderPath, state.sortProperty, state.sortPropertyDirectionASC, state.tagsViewFilter);

				for (const folder of folders) {
					updateFolderAggregatedProperties(folder, updatedFiles);
				}

				return {
					...state,
					files: updatedFiles,
					filesCount: updatedFiles.length,
					selectedFolderFiles: selectedFolderFiles,
				};
			}

		case FileActions.SetDargDropFiles:
			return {
				...state,
				dragDropFiles: action.files
			};

		case FileActions.SetDargDropFolders:
			return {
				...state,
				dragDropFolders: action.folders
			};

		case FileActions.AskOverwriteFile: {

			let overwriteFileRecalls = cloneOverwrite(state.overwriteFileRecalls);
			overwriteFileRecalls.push({ fileName: action.fileName, folderName: action.folderName, acceptAction: action.acceptAction, rejectAction: action.rejectAction });

			return {
				...state,
				overwriteFileRecalls: overwriteFileRecalls
			};
		}

		case FileActions.RemoveOverwriteFileRecall: {
			let overwriteFileRecalls = cloneOverwrite(state.overwriteFileRecalls);

			const index: number = overwriteFileRecalls.findIndex(x => x.fileName === action.fileName && x.folderName === action.folderName);
			if (index >= 0) {
				overwriteFileRecalls.splice(index, 1);
			}

			return {
				...state,
				overwriteFileRecalls: overwriteFileRecalls
			};
		}

		case FileActions.AddFolder: {
			if (state.location == null || state.sourceType == null || state.ident == null) {
				return state;
			}
			let updatedFolders = cloneFSO(state.folders);

			var folderIndex: number = updatedFolders.findIndex(x => x.Path === action.folder.Path);
			if (folderIndex < 0) {
				updatedFolders.push({ ...action.folder, Drives: [{ Ident: state.ident, Location: state.location, Type: state.sourceType, Number: state.driveNumber! }] });
			}

			let subFolders: FileSystemObject[] | undefined = foldersByFolderId(updatedFolders, state.selectedFolderId, state.selectedSubFolderPath);

			return {
				...state,
				folders: updatedFolders,
				selectedFolderSubFolders: subFolders
			};
		}

		case FileActions.RemoveFolder: {
			let files = cloneFSO(state.files);
			let folders = cloneFSO(state.folders);

			folders = folders.filter((folder) => folder.Path !== action.folder.Path && !folder.Path?.startsWith(`${action.folder.Path}/`));
			files = files.filter((file) => !file.Path?.startsWith(`${action.folder.Path}/`));

			folders.forEach((folder) => updateFolderAggregatedProperties(folder, files));

			let selectedFolderSubFolders: FileSystemObject[] | undefined = foldersByFolderId(folders, state.selectedFolderId, state.selectedSubFolderPath);
			let selectedFolderFiles: FileSystemObject[] | undefined = filesByFolderId(files, state.selectedFolderId, state.selectedSubFolderPath, state.sortProperty, state.sortPropertyDirectionASC, state.tagsViewFilter);

			return {
				...state,
				files,
				filesCount: files.length,
				customerFilesCount: getCustomerFilesCount(files),
				sharedByLinkFilesCount: getSharedByLinkCount(files),
				folders,
				selectedFolderFiles,
				selectedFolderSubFolders
			};
		}

		case FileActions.MoveFilesAndSubFolders: {
			let files = cloneFSO(state.files);
			let folders = cloneFSO(state.folders);

			for (const file of files) {
				if (action.sourcePath === file.Path || file.Path?.startsWith(`${action.sourcePath}/`)) {
					file.Path = file.Path.replace(action.sourcePath, action.targetPath)
				}
			}

			for (const folder of folders) {
				if (folder.Path?.startsWith(`${action.sourcePath}/`)) {
					folder.Path = folder.Path.replace(action.sourcePath, action.targetPath)
				}

				updateFolderAggregatedProperties(folder, files);
			}

			let subFolders: FileSystemObject[] | undefined = foldersByFolderId(folders, state.selectedFolderId, state.selectedSubFolderPath);

			return {
				...state,
				files: files,
				folders: folders,
				selectedFolderSubFolders: subFolders
			};
		}

		case FileActions.SetDriveUrl:
			return {
				...state,
				driveUrl: action.url
			};

		case FileActions.SetDrives:
			for (const drive of action.drives) {
				drive.isSelected = true;
			}

			return {
				...state,
				drives: action.drives
			};

		case FileActions.SetDriveLoading:
			{
				const drives = state.drives.map(x => ({...x})); // [...state.drives];

				const drive = drives.find(x => areSameDrives([x], [action.drive]));

				if (drive && action.drive.isFilesLoading !== undefined) {
					drive.isFilesLoading = !!action.drive.isFilesLoading;
				}

				if (drive && action.drive.isFoldersLoading !== undefined) {
					drive.isFoldersLoading = !!action.drive.isFoldersLoading;
				}

				return {
					...state,
					drives: drives
				};
			}
		case FileActions.SortFiles:
			{
				let files: FileSystemObject[] | undefined = filesByFolderId(state.files, state.selectedFolderId, state.selectedSubFolderPath, action.property, action.sortDirectionASC, state.tagsViewFilter);

				return {
					...state,
					selectedFolderFiles: files,
					sortProperty: action.property,
					sortPropertyDirectionASC: action.sortDirectionASC
				};
			}

		case FileActions.SetView:
			return {
				...state,
				viewMode: action.mode
			};

		case FileActions.ToggleDriveSelection:
			const index = state.drives.findIndex(drive => areSameDrives([drive], [action.drive]));

			if (index < 0) {
				return state;
			}

			const newDrives = state.drives.slice();
			newDrives[index].isSelected = !newDrives[index].isSelected;

			const selectedDrives = newDrives.filter(drive => drive.isSelected);
			let folders = [...state.folders || []];
			const selectedDrivesFiles = state.files?.filter(file => selectedDrives.some(drive => areSameDrives([drive], file.Drives))) || [];
			for (const folder of folders) {
				updateFolderAggregatedProperties(folder, selectedDrivesFiles);
			}

			return {
				...state,
				folders: folders,
				selectedFolders: !newDrives[index].isSelected ? state.selectedFolders?.filter(folder => selectedDrivesFiles?.some(file => file.Path?.startsWith(folder.Path))) : state.selectedFolders,
				selectedFiles: !newDrives[index].isSelected ? state.selectedFiles?.filter(file => areSameDrives(file.Drives, [action.drive])) : state.selectedFiles,
				drives: newDrives,
				filesCount: selectedDrivesFiles?.length,
				customerFilesCount: getCustomerFilesCount(selectedDrivesFiles),
				sharedByLinkFilesCount: getSharedByLinkCount(selectedDrivesFiles),
			};

		case FileActions.SetPreviewImage:
			{
				const setLoaded = (files: FileSystemObject[]) => {
					files.forEach(x => {
						if (x.Id === action.fileId && x.Path === action.folderId) {
							x.previewLoaded = true;
							x.previewImageUrl = action.contentUrl;
						}
					})
				}

				let files = cloneFSO(state.files);
				setLoaded(files);

				const selectedFiles = cloneFSO(state.selectedFiles);
				setLoaded(selectedFiles);

				const selectedFolderFiles = cloneFSO(state.selectedFolderFiles);
				setLoaded(selectedFolderFiles);

				return {
					...state,
					files,
					selectedFiles,
					selectedFolderFiles
				};
			}

		case FileActions.PreviewFile:
			return {
				...state,
				previewFile: action.file,
				selectedFiles: action.file ? [action.file] : []
			};

		case FileActions.SetAvailableTags:
			return {
				...state,
				tags: action.tags,
				tagsViewFilter: [{Label:'None',TermGuid:'NONE',Color:'',WssId:-1},...action.tags.map(x => ({...x}))]
			};

		case FileActions.AddFileTag:
			{
				const setLoaded = (files: FileSystemObject[]) => {
					files.forEach(x => {
						if (x.Id === action.fileId && x.Path === action.folderId) {
							if(!x.Tags) x.Tags = [];

							if(x.Tags.every(y => y.TermGuid !== action.tag.TermGuid)) {
								x.Tags.push({...action.tag});
							}
						}
					})
				}

				let files = cloneFSO(state.files);
				setLoaded(files);

				const selectedFiles = cloneFSO(state.selectedFiles);
				setLoaded(selectedFiles);

				const selectedFolderFiles = cloneFSO(state.selectedFolderFiles);
				setLoaded(selectedFolderFiles);

				return {
					...state,
					files,
					selectedFiles,
					selectedFolderFiles
				};
			}

		case FileActions.RemoveFileTag:
			{
				const setLoaded = (files: FileSystemObject[]) => {
					files.forEach(x => {
						if (x.Id === action.fileId && x.Path === action.folderId) {
							if(!x.Tags) x.Tags = [];

							x.Tags = x.Tags.filter(y => y.TermGuid !== action.tag.TermGuid);
						}
					})
				}

				let files = cloneFSO(state.files);
				setLoaded(files);

				const selectedFiles = cloneFSO(state.selectedFiles);
				setLoaded(selectedFiles);

				const selectedFolderFiles = cloneFSO(state.selectedFolderFiles);
				setLoaded(selectedFolderFiles);

				return {
					...state,
					files,
					selectedFiles,
					selectedFolderFiles
				};
			}

		case FileActions.AddFileTagFilter:
			{
				let tagsViewFilter = [...state.tagsViewFilter.map(x => ({...x}))];

				if(tagsViewFilter.every(x => x.TermGuid !== action.tag.TermGuid)) {
					tagsViewFilter.push({...action.tag});
				}

				let files: FileSystemObject[] | undefined = cloneFSO(state.files);
				files = filesByFolderId(state.files, state.selectedFolderId, state.selectedSubFolderPath, state.sortProperty, state.sortPropertyDirectionASC, tagsViewFilter);

				return {
					...state,
					selectedFolderFiles: files,
					tagsViewFilter
				}
			}

			case FileActions.RemoveFileTagFilter:
				{
					let tagsViewFilter = [...state.tagsViewFilter.map(x => ({...x}))];
	
					if(tagsViewFilter.some(x => x.TermGuid === action.tag.TermGuid)) {
						tagsViewFilter = tagsViewFilter.filter(x => x.TermGuid !== action.tag.TermGuid);
					}

					let files: FileSystemObject[] | undefined = cloneFSO(state.files);
					files = filesByFolderId(state.files, state.selectedFolderId, state.selectedSubFolderPath, state.sortProperty, state.sortPropertyDirectionASC, tagsViewFilter);
	
					return {
						...state,
						selectedFolderFiles: files,
						tagsViewFilter
					}
				}

		default:
			return state;
	}
}