import * as React from "react";
import "./ChecklistValue.scss";
import { DatePicker, IDatePickerStrings } from "@fluentui/react";
import { stringToDate } from "../../Utils/DateHelper"
import FormatDate from "../../Formatter/FormatDate";
import moment from "moment";
import i18n from "../../i18n";

export interface ChecklistDateProps {
	value: string | undefined;
	isEditing: boolean;
	onChange: (value: string | undefined) => void;
}

export const ChecklistDate = (props: ChecklistDateProps): JSX.Element => {
	// fluent ui natively provides only en localisation for DatePicker
	let translation = i18n.t("DatePicker", { returnObjects: true }) as IDatePickerStrings;

	if (props.isEditing) {
		return <DatePicker
			value={stringToDate(props.value)}
			onSelectDate={(newValue) => props.onChange(newValue?.toISOString() ?? undefined)}
			formatDate={(date) => FormatDate(date?.toISOString())}
			strings={translation}
			firstDayOfWeek={moment().startOf("week").day()}
		/>;
	} else {
		return <>{FormatDate(props.value)}</>;
	}

}