import { CheckListActions } from "./CheckListActions";

export interface SavingCheckListAction {
	type: typeof CheckListActions.SavingCheckList;
	location: string;
	ident: number;
}
export const savingCheckList = (location: string, ident: number): SavingCheckListAction => ({
	type: CheckListActions.SavingCheckList,
	location,
	ident
});