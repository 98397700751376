import { batch } from "react-redux";
import { User } from "../../Model/User";
import { RootState, setGlobalError, setResendingInvitationMail } from "../../Store";

export function resendInvitationMail(InvitedUsers: User[]) {
    return async (dispatch: React.Dispatch<any>, getState: () => RootState): Promise<void> => {
        try {
            dispatch(setResendingInvitationMail(true));
            let { teams: { accessToken }} = getState();
            let url = `${window.location.origin}/api/invitedUsers/resendInvitation`;

            await fetch(url, {
				method: "POST",
				headers: {
					"Authorization": `Bearer ${accessToken}`,
					"Content-Type": "application/json"
				},
				body: JSON.stringify({ InvitedUsers })
			});

        } catch (error:any) {
            batch(() => {
                dispatch(setGlobalError(error.message));
                dispatch(setResendingInvitationMail(false));
            });
        }
    }
}