import * as React from "react";
import "./ChecklistValue.scss";
import { TextField } from "@fluentui/react";

export interface ChecklistMemoProps {
	value: string;
	isEditing: boolean;
	onChange: (value: string | undefined) => void;
}

export class ChecklistMemo extends React.Component<ChecklistMemoProps, {}> {
	render(): JSX.Element {
		if (this.props.isEditing) {
			return <TextField value={this.props.value}
				multiline={true}
				autoAdjustHeight={true}
				onChange={(x, newValue) => this.props.onChange(newValue)} />;
		} else {
			return <span className="CheckListMemo">{this.props.value}</span>;
		}
	}
}