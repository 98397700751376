import React from "react";
import { Team } from "../../Model/Team";
import { RootState, teamDetailsReceivedFailed, teamDetailsReceived, requestTeamDetails } from "../../Store";
import { getHttpStatusText } from "../../Utils/getHttpStatusText";

interface DetailsNoOData {
    ident: number;
	location: string;
    users: {
        eMail: string
        name: string
    }[];
}

interface ChannelNoOData {
    id: string;
    name: string;
    description: string;
    parentId: string;
    details?: DetailsNoOData;
}

interface TeamNoOData {
    id: string;
    name: string;
    primaryChannelName: string;
    channels: ChannelNoOData[];
    details?: DetailsNoOData;
    
}

function ConvertToOData<TResult>(source: TeamNoOData | ChannelNoOData | DetailsNoOData | undefined): TResult | undefined {
    let result: TResult = {} as TResult;
    
    if(source == null){
        return undefined;
    }
    
    for(let key of Object.keys(source)){
        let upperKey = `${key[0].toUpperCase()}${key.substring(1)}`;
        let sourceValue = (source as any)[key];
        let targetValue: unknown;
   
        if((key === "channels" || key === "users" ) && Array.isArray(sourceValue)){
            let targetValueArray = [];

            for(let element of sourceValue){
                    targetValueArray.push(ConvertToOData(element));
            }
            targetValue = targetValueArray;
        }else if(key === "details"){
            targetValue = ConvertToOData(sourceValue);
        }else{
            targetValue = sourceValue;
        }
        (result as any)[upperKey] = targetValue;
    }
    return result;
}

export function loadTeamDetails(teamId: string) {
    return async (dispatch: React.Dispatch<any>, getState: () => RootState): Promise<void> => {
        try {
            dispatch(requestTeamDetails(teamId));

            const { teams: { accessToken } } = getState();

            let url: string = `/teams/${teamId}/getDetails`;

            const response: Response = await fetch(`${window.location.origin}/api${url}`, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });

            if (!response.ok) {
                const errorMessage = response.statusText || getHttpStatusText(response.status);
                dispatch(teamDetailsReceivedFailed(response.status, errorMessage));
                return;
            }

            const teamDetails: TeamNoOData = await response.json();
            

            let team  = ConvertToOData<Team>(teamDetails);
            dispatch(teamDetailsReceived(team));

        } catch (error:any) {
            dispatch(teamDetailsReceivedFailed(0, error.message))
        }
    }
};