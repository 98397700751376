import * as React from "react";
import { ProgressIndicator, Stack } from "@fluentui/react";
import "./ProgressColumn.scss";

export interface ProgressColumnProps {
	percentComplete: number;
}

export const ProgressColumn = (props: ProgressColumnProps): JSX.Element => {
	return <Stack horizontal className="ProgressColumn">		
        <Stack.Item>
			<span>{Math.round(props.percentComplete)}%</span>
		</Stack.Item>
		<Stack.Item grow={true}>
		<ProgressIndicator percentComplete={props.percentComplete / 100.0 } />
		</Stack.Item>
	</Stack>;
}