import * as React from "react";
import i18n from "../i18n";
import { loadTeamList } from "../Api/TeamList/LoadTeamList";
import { useAppDispatch } from "../Store/useAppDispatch";
import { CommandBarCommandProps } from "../Utils/useCommands";


export const RefreshTeamListCommand = (props: CommandBarCommandProps): JSX.Element => {
	const dispatch = useAppDispatch();

		props.addCommandButton({
			key: "refreshTeamList",
			text: i18n.t("Commands.Refresh.Command"),
			iconProps: { iconName: "Refresh" },
			onClick: () => {
				dispatch(loadTeamList());
				return false;
			},
		});

	return <></>;
}