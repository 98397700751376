import i18n from "../i18n";

export const getSeparator = (separatorType: "decimal" | "group") => {
    const numberWithGroupAndDecimalSeparator = 100000.1;
    const seperator = Intl.NumberFormat(i18n.language)
        .formatToParts(numberWithGroupAndDecimalSeparator)
        .find(part => part.type === separatorType)
        ?.value;

    if (seperator) {
        return seperator;
    }

    // fallback to ensure the locale seperator
    if (separatorType === "decimal") {
        return (1.1).toLocaleString().replace(/[0-9]/g, "");
    } else {
        // not all languages are using a group seperator on thousands
        const groupSeperator = (100000).toLocaleString().replace(/[0-9]/g, "");
        if (groupSeperator.length >= 1) {
            return groupSeperator.substring(0, 1);
        } else {
            return "";
        }
    }
};

/**
 * Removes local characteristics from a string and converts it to a Number
 */
export const stringToNumber = (string?: string) => {
    if (getSeparator("group") === ",") {
        return Number(string?.replace(/[^0-9\- .]/g, ""));
    } else {
        return Number(string?.replace(/[^0-9\- ,]/g, "").replace(",", "."));
    }
};

export const toLocaleNumberString = (decimalSeperator: string, value?: string) => {
    if (!value) {
        return "";
    }

    return decimalSeperator === "." ? value : value?.toString().replace(".", decimalSeperator);
}