import { IStyle, makeStyles } from "@fluentui/react";

export default () => {
	let styles = makeStyles(theme => ({
		DetailsList: {
			".ColumnContextMenu": {
				color: theme.palette.neutralSecondary,
				".ms-Button:hover": {
					backgroundColor: theme.palette.neutralLight,
					i: {
						color: theme.palette.themePrimary
					}
				} as IStyle
			},
			"a, i": {
				color: theme.palette.neutralPrimary,
				text: "decoration: none"
			},
			"a:hover": {
				color: theme.palette.themePrimary
			},
			"i.Shared-File": {
				color: theme.palette.themePrimary
			},
			"i.Error-File": {
				// color: theme?.palette.themeDarker
			},
			".Folder-link": {
				display: "flex",
				textAlign: "left",
				justifyContent: "space-between",

				".Files-Count": {
					color: theme.palette.neutralTertiary,
					fontSize: theme.fonts.small.fontSize,
					textAlign: "right"
				}
			} as IStyle
		},
		ProgressBar: {
			position: "absolute",
			width: "100%"
		}
	}));
	return styles();
};