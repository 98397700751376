import * as React from "react";
import { loadFilesAndFolders } from "../Api/File/LoadFilesAndFolders";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../Store/useAppDispatch";
import { CommandBarCommandProps } from "../Utils/useCommands";


export const RefreshCommand = (props: CommandBarCommandProps): JSX.Element => {
	const { sourceType, location, ident } = useAppSelector(state => state.file);
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	if (sourceType && location && ident) {
		props.addCommandButton({
			key: "refresh",
			text: t("Commands.Refresh.Command"),
			iconProps: { iconName: "Refresh" },
			onClick: () => {
				dispatch(loadFilesAndFolders(sourceType, location, ident));
				return false;
			},
		});
	}

	return <></>;
}