import * as React from "react";
import { stopShares } from "../Api/File/stopShares";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../Store/useAppDispatch";
import { CommandBarCommandProps } from "../Utils/useCommands";

export const StopShareCommand = (props: CommandBarCommandProps): JSX.Element => {
	const { selectedShares } = useAppSelector(state => state.externalShares);
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	
	if(selectedShares && selectedShares.length > 0) {
		props.addCommandButton({
			key: "stopShare",
			text: t("Commands.StopShare.Command"),
			iconProps: { iconName: "Cancel" },
			onClick: () => { dispatch(stopShares(selectedShares.map(x => x.id))); return false; },
		});
	}

	return <>
	</>;
}