import { RootState, setRunningAction, removeFiles, setFileError } from "../../Store";
import { FileSystemObject } from "../../Model/FileSystemObject";
import { ApiUrlBuilder } from "../../ApiUrlBuilder";
import { batch } from "react-redux";
import i18n from "../../i18n";
import { generateId } from "../../Utils/GenerateId";
import { getFirstDrive } from "../../Utils/GetFirstDrive";

export function deleteFile(file : FileSystemObject) {
	return async (dispatch: React.Dispatch<any>, getState: () => RootState): Promise<void> => {

		if (!file ) { return; }

		const { teams: { isInitialized, accessToken } } = getState();
		const fileDrive = getFirstDrive(file.Drives);
		if(!isInitialized || !accessToken || !fileDrive) return;

		const runningActionId = generateId();
		try {	
			dispatch(setRunningAction({ id: runningActionId, message: i18n.t("Actions.DeleteFile", { file: file.Name }) }, file.Id, file.Path));		

			const url = ApiUrlBuilder.DeleteFile(fileDrive, file.Path, file.Name);

			const response: Response = await fetch(`/api${url}`, {
				method: "DELETE",
				headers: {
					"Authorization": `Bearer ${accessToken}`,
					"Content-Type":"application/json"
				}
			});
	
			if (response.status === 404) {
				return undefined;
			}
	
			if (!response.ok) {
				dispatch(setFileError(file.Id, file.Path, response.status, response.statusText));
				throw new Error(`API request failed with ${response.status}: ${response.statusText}`);
			}

			batch(() => {
				dispatch(removeFiles([ file ]));
			});
		} finally {
			dispatch(setRunningAction({id: runningActionId, message: undefined}, file.Id, file.Path));
		}
	}
}