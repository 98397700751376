import { combineReducers } from "redux";
import { configureStore } from '@reduxjs/toolkit'
import { taskReducer } from "./Task/Reducers";
import { orderReducer } from "./Order/Reducers";
import { fileReducer } from "./File/Reducers";
import { externalSharesReducer } from "./ExternalShares/Reducers";
import { contactsReducer } from "./Contacts/Reducers";
import { teamsReducer } from "./Teams/Reducers";
import { systemReducer } from "./System/Reducers";
import { checkListReducer, ChecklistState } from "./CheckList";
import { teamListReducer } from "./PersonalApp/TeamList/Reducers";
import { teamDetailsPanelReducer } from "./PersonalApp/TeamDetailsPanel/Reducers"
import { invitationReducer } from "./PersonalApp/Invitation/Reducers"
import { TaskState } from "./Task/State";
import { OrderState } from "./Order/State";
import { FileState } from "./File/State";
import { ExternalSharesState } from "./ExternalShares/State";
import { ContactsState } from "./Contacts/State";
import { TeamsState } from "./Teams/State";
import { SystemState } from "./System/State";
import { TeamListState } from "./PersonalApp/TeamList/State";
import { TeamDetailsPanelState } from "./PersonalApp/TeamDetailsPanel/State";
import { InvitationState } from "./PersonalApp/Invitation/State";

export * from "./Task/Actions";
export * from "./Order/Actions";
export * from "./File/Actions";
export * from "./ExternalShares/Actions";
export * from "./Contacts/Actions";
export * from "./Teams/Actions";
export * from "./System/Actions";
export * from "./CheckList";
export * from "./PersonalApp/TeamList/Actions";
export * from "./PersonalApp/TeamDetailsPanel/Actions"
export * from "./PersonalApp/Invitation/Actions"

export interface RootState2 {
	task: TaskState;
	order: OrderState;
	file: FileState;
	externalShares: ExternalSharesState;
	contacts: ContactsState;
	teams: TeamsState;
	system: SystemState;
	checklist: ChecklistState;
	teamList: TeamListState;
	teamDetailsPanel: TeamDetailsPanelState;
	invitation: InvitationState;
}

type RootStateType = keyof RootState2;

// // eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducers: {[key in RootStateType]: any} = {
    task: taskReducer,
	order: orderReducer,
	file: fileReducer,
	externalShares: externalSharesReducer,
	contacts: contactsReducer,
	teams: teamsReducer,
	system: systemReducer,
	checklist: checkListReducer,
	teamList: teamListReducer,
	teamDetailsPanel: teamDetailsPanelReducer,
	invitation: invitationReducer
};

const rootReducer = combineReducers({
    task: taskReducer,
	order: orderReducer,
	file: fileReducer,
	externalShares: externalSharesReducer,
	contacts: contactsReducer,
	teams: teamsReducer,
	system: systemReducer,
	checklist: checkListReducer,
	teamList: teamListReducer,
	teamDetailsPanel: teamDetailsPanelReducer,
	invitation: invitationReducer
});

const store = configureStore({
	reducer: rootReducer,
	middleware: getDefaultMiddleware =>
	  getDefaultMiddleware()
  })

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch

export default store;