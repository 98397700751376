export default function (bytes: number, decimals: number = 2): { dividend: number, result: string } {
    if (bytes === 0) { return { dividend: 1, result: "0 Bytes" }; }

    const k: number = 1024;
    const dm: number = decimals < 0 ? 0 : decimals;
    const sizes: string[] = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i: number = Math.floor(Math.log(bytes) / Math.log(k));

    return { dividend: Math.pow(k, i), result: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i] };
}