import { IStyle, makeStyles } from "@fluentui/react";

export default () => {
	let styles = makeStyles(theme => ({
		Invitation: {
			padding: "2em"
		},
		CommandBar: {
			marginTop: "8px"
		}
	}));
	return styles();
};