import * as React from "react";
import { previewFile } from "../Store";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../Store/useAppDispatch";
import { CommandBarCommandProps } from "../Utils/useCommands";

export const ClosePreviewCommand = (props: CommandBarCommandProps): JSX.Element => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	props.addCommandButton({
		key: "closePreview",
		text: t(`Commands.ClosePreview.Command`),
		iconProps: { iconName: "Cancel" },
		iconOnly: true,
		onClick: () => { dispatch(previewFile(undefined)); }
	});

	return <>
	</>;
}