import { CheckListActions } from "./CheckListActions";

export interface CancelCheckListAction {
	type: typeof CheckListActions.CancelCheckList;
	location: string;
	ident: number;
}
export const cancelCheckList = (location: string, ident: number): CancelCheckListAction => ({
	type: CheckListActions.CancelCheckList,
	location,
	ident
});