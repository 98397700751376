import * as React from "react";
import { PropsWithChildren, useCallback } from "react";
import { DialogFooter, IDialogContentProps, DefaultButton, PrimaryButton, TextField } from "@fluentui/react";
import { CustomDialog } from "../CustomDialog/CustomDialog";
import { useTranslation } from "react-i18next";

interface SingleLineTextfieldDialogProps {
	dialogContentProps: IDialogContentProps;
	hidden?: boolean;
	onDismiss: () => void;
	onCommand: () => void;
	commandIsDisabled: boolean;

	label: string;
	onChange: (newValue: string) => void;
	value: string;
	suffix?: string;
	okLabel: string;
}

export const SingleLineTextfieldDialog = (props: PropsWithChildren<SingleLineTextfieldDialogProps>) => {
	const { t } = useTranslation();

	const onKeyDown = useCallback(
		(event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			if (event.keyCode === 13 && !props.commandIsDisabled) {
				props.onCommand();
			}
		},
		[props]
	);

	return (
		<CustomDialog hidden={props.hidden} dialogContentProps={props.dialogContentProps} onDismiss={props.onDismiss} >
			<TextField
				label={props.label}
				onChange={(ev, newValue) => props.onChange(newValue || "")}
				value={props.value}
				onKeyDown={(event) => onKeyDown(event)}
				suffix={props.suffix}
			/>
			<DialogFooter>
				<PrimaryButton onClick={props.onCommand} disabled={props.commandIsDisabled} text={props.okLabel} />
				<DefaultButton onClick={props.onDismiss} text={t("Commands.Dialog.Cancel")} />
			</DialogFooter>
		</CustomDialog>
	);
}

