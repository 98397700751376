import * as React from "react";
import i18n from "../i18n";
import { SearchBox } from "@fluentui/react";
import useStyles from "./SearchTeamListCommand.style";
import { setSearchValue } from "../Store";
import { useAppDispatch } from "../Store/useAppDispatch";
import { CommandBarCommandProps } from "../Utils/useCommands";

export const SearchTeamListCommand = (props: CommandBarCommandProps): JSX.Element => {
    const dispatch = useAppDispatch();

    const onClear = React.useCallback(() => {
        dispatch(setSearchValue(""));
    }, [dispatch]);

    const onChange = React.useCallback((event, searchValue) => {
        if (!searchValue) {
            dispatch(setSearchValue(""));
        } else {
            dispatch(setSearchValue(searchValue));
        }
    }, [dispatch]);

    const styles = useStyles();
    props.addCommandButton({
        key: "search",
        text: i18n.t("Commands.Refresh.Command"),
        iconProps: { iconName: "Refresh" },
        onRender: () => <SearchBox className={styles.SearchBox} onClear={onClear} onChange={onChange}></SearchBox>
    });
    return <></>;
}


