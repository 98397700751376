import * as React from "react";
import useStyles from "./CopyToClipboard.style"
import { IconButton, IIconProps } from "@fluentui/react";
import { PropsWithChildren, useState } from "react";
import { useCallback } from "react";

export interface CopyToClipboardProps {
	text: string;
	className?: string;
	iconVisibility?: "always" | "hover";
}

export const CopyToClipboard = (props: PropsWithChildren<CopyToClipboardProps>) => {
	const [hovering, setHovering] = useState(() => false);
	const styles = useStyles();

	const copy = useCallback((): void => {
		const selBox: HTMLTextAreaElement = document.createElement("textarea");
		selBox.style.position = "fixed";
		selBox.style.left = "0";
		selBox.style.top = "0";
		selBox.style.opacity = "0";
		selBox.value = props.text;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand("copy");
		document.body.removeChild(selBox);
	}, [props.text]);

	const copyIcon: IIconProps = { iconName: "Copy" };

	return <div
		className={styles.CopyToClipboard}
		onMouseEnter={() => setHovering(true)}
		onMouseLeave={() => setHovering(false)}>
		<div className={styles.CopyToClipboardContext + " " + props.className}>{props.children}</div>
		<div>
			<IconButton className={props.iconVisibility === "always" || hovering ? styles.CopyToClipboardHovering : styles.CopyToClipboardNotHovering}
				iconProps={copyIcon} onClick={() => copy()} />
		</div>
	</div>;

}

