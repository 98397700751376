import * as React from "react";
import { IconButton, IIconProps } from "@fluentui/react";
import { ShepherdTour, ShepherdTourContext } from "react-shepherd";
import Step from 'shepherd.js/src/types/step';
import { default as STour } from 'shepherd.js/src/types/tour';
import "shepherd.js/dist/css/shepherd.css";
import useStyles from "./Tour.style"

export interface TourProps {
	steps: Step.StepOptions[]
}


export const Tour = (props: React.PropsWithChildren<TourProps>): JSX.Element => {
	const styles = useStyles();
	const tourOptions = {
		defaultStepOptions: {
			scrollTo: false,
			showCancelLink: true,
			cancelIcon: {
				enabled: true
			},
			modalOverlayOpeningPadding: 8
		},
		useModalOverlay: true
	};

	const StartTour = (): JSX.Element => {
		const tour = React.useContext(ShepherdTourContext);
		if (!tour) {
			return <></>;
		}

		const emojiIcon: IIconProps = { iconName: 'Help' };
		return (<IconButton className={styles.StartTour} iconProps={emojiIcon} onClick={() => tour.start()} />);
	}

	// Fix step buttons
	const steps = props.steps;
	steps.forEach(x => x.buttons = [
		{
			text: "Back",
			action: function () { (this as STour).back(); },
		},
		{
			text: "Next",
			action: function () { (this as STour).next(); }
		}
	]);

	const firstStep = steps[0];
	const firstBackButton = firstStep?.buttons?.[0];
	if (firstBackButton) {
		firstBackButton.disabled = true;
	}

	const lastStep = steps[steps.length - 1];
	const lastNextButton = lastStep?.buttons?.[1];
	if (lastNextButton) {
		lastNextButton.text = "Close";
	}

	return (
		<div className={styles.ShepherdTour}>
			<ShepherdTour steps={props.steps} tourOptions={tourOptions}> 
				<StartTour />
				{props.children}
			</ShepherdTour>
		</div>
	);
}