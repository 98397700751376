import { IStyle, makeStyles } from "@fluentui/react";

export default () => {
	let styles = makeStyles(theme => ({
		FileTiles: {
			paddingTop: "3px", // spacing for box-shadow
			overflowY: "auto",
		},
		TilesContainer: {
			".ms-List-page": {
				display: "flex", /* or inline-flex */
				flexDirection: "row",
				flexWrap: "wrap",

				".ms-List-cell": {
					backgroundColor: theme.palette.neutralLight,
					width: "16%",
					paddingTop: "16%",
					//height: 200px,
					//max-width: 200px,
					//max-height: 200px,
					marginLeft: "0.6%",
					marginBottom: "8px",
					display: "flex",
					position: "relative",
					justifyContent: "center",

					"@keyframes click": {
						from: {
							transform: "scale(1)",
						},
						to: {
							transform: "scale(0.95)"
						}
					} as IStyle,

					i: {
						position: "absolute",
						alignSelf: "center",
						width: "50%",
						height: "50%",
						top: "25%",
						left: "25%",
						fontSize: "60pt",
						textAlign: "center",
					} as IStyle,

					".ms-Image": {
						position: "absolute",
						width: "100%",
						height: "100%",
						left: 0,
						top: 0,
					} as IStyle,

					span: {
						position: "absolute",
						alignSelf: "flex-end",
						width: "100%",
						textAlign: "left",
						textOverflow: "ellipsis",
						whiteSpace: "nowrap",
						overflow: "hidden",
						padding: "2px 2px 2px 5px",
						backgroundColor: `${theme.palette.white}bf`, // use color from palette, but with added transparency
					} as IStyle,

					".select": {
						boxSizing: "border-box",
						"-moz-box-sizing": "border-box",
						"-webkit-box-sizing": "border-box",
						position: "absolute",
						width: "100%",
						height: "100%",
						left: 0,
						top: 0,
						backgroundColor: "transparent",
						cursor: "pointer",

						i: {
							position: "absolute",
							alignSelf: "center",
							top: "0px",
							left: "0px",
							padding: "4px",
							width: "16px",
							height: "16px",
							display: "none",
							fontSize: "16px",
							backgroundColor: `${theme.palette.white}bf`, // use color from palette, but with added transparency
						} as IStyle,
					} as IStyle,

					".select:hover": {
						// border: $ms-color-neutralPrimary $select-border-width solid,
						boxShadow: `0px 0px 0px 2px ${theme.palette.neutralPrimary}`,
						i: {
							display: "block"
						} as IStyle
					} as IStyle,

					".is-selected": {
						// border: $ms-color-themePrimary $select-border-width solid,
						boxShadow: `0px 0px 0px 2px ${theme.palette.themePrimary}`,
						i: {
							display: "block",
							color: theme.palette.themePrimary,
						} as IStyle,

					} as IStyle,
					".is-selected:hover": {
						// border: $ms-color-themePrimary $select-border-width solid,
						boxShadow: `0px 0px 0px 2px ${theme.palette.themePrimary}`,
					} as IStyle
				} as IStyle,

				".ms-List-cell:active": {
					animationName: "click",
					animationDuration: "0.25s",
					animationFillMode: "forwards",
					animationTimingFunction: "ease-in-out",
				} as IStyle,

				"@media (max-width: 799px)": {
					".ms-List-cell": {
						width: "49%",
						paddingTop: "49%",
						marginLeft: "1%",
					} as IStyle
				},

				"@media (min-width: 800px) and (max-width: 999px)": {
					".ms-List-cell": {
						width: "32%",
						paddingTop: "32%",
						marginLeft: "1.3%",
					} as IStyle
				},

				"@media (min-width: 1000px) and (max-width: 1199px)": {
					".ms-List-cell": {
						width: "24%",
						paddingTop: "24%",
						marginLeft: "1%",
					} as IStyle
				},

				"@media (min-width: 1200px)": {
					".ms-List-cell": {
						width: "16%",
						paddingTop: "16%",
						marginLeft: "0.6%",
					} as IStyle
				}
			} as IStyle
		},
		HiddenContentBanner: {
			marginTop: "7px",
			textAlign: "center",
			color: theme.palette.neutralTertiary,
			fontSize: theme.fonts.tiny.fontSize
		},
		DropZone: {
			border: `dashed 2px ${theme.palette.neutralPrimary}`,
			borderRadius: "8px",
			backgroundColor: theme.palette.neutralSecondary,
			position: "absolute",
			left: "0px",
			top: "0px",
			right: "0px",
			bottom: "0px",
			/*width: 100%,
			height: 100vh,*/
			zIndex: 1000,
			opacity: "20%"
		}
	}));
	return styles();
};