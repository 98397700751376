import { User } from "../../../Model/User";
import { InvitationActions, InvitationActionTypes } from "./Actions";
import { InvitationState } from "./State";

const initialState: InvitationState = {
    invitedUsers: undefined,
    selectedUsers: undefined,
    isLoading: false,
	sortProperty: "State",
	sortPropertyDirectionASC: false,
    isSending: false
}

const invitedUsersSorted = (users: User[] | undefined, sortProperty: string, sortPropertyDirectionASC: boolean) => {
	if (!users) return users;

	return users.sort((a, b) => {
		const order = sortPropertyDirectionASC ? 1 : -1;
		switch (sortProperty) {
			case "EMail":
				return a.EMail > b.EMail ? order : -order;
			case "Name":
				return a.Name > b.Name ? order : -order;
			case "UserType":
                return a.UserType > b.UserType ? order : -order;
            case "InvitationDate":
                return a.InvitationDate > b.InvitationDate ? order : -order;
            case "StatusChangeDate":
                return a.StatusChangeDate > b.StatusChangeDate ? order : -order;
			default:
				return a.StatusChangeDate > b.StatusChangeDate ? order : -order;
		}
	});
}

export function invitationReducer(
    state: InvitationState = initialState,
    action: InvitationActionTypes
): InvitationState {
    switch(action.type){
        case InvitationActions.Request:
        return{
            ...state,
            isLoading: true,
            invitationLoadError: undefined
        }
        case InvitationActions.Received:
            {
                const sortedInvitedUsers = invitedUsersSorted(action.invitedUsers, state.sortProperty, state.sortPropertyDirectionASC);
                return{
                    ...state,
                    isLoading: false,
                    invitedUsers: sortedInvitedUsers,
                    invitationLoadError: undefined
                }
            }
        case InvitationActions.ReceiveFailed:
            return {
				...state,
				isLoading: false,
				invitedUsers: undefined,
				invitationLoadError: action.message
			};
        case InvitationActions.SortInvitation:
            let result = invitedUsersSorted(state.invitedUsers, action.property, action.sortDirectionASC);
			return {
				...state,
				invitedUsers: result,
				sortProperty: action.property,
				sortPropertyDirectionASC: action.sortDirectionASC
			};
        case InvitationActions.SetResendingInvitationMail:
            return{
                ...state,
                isSending: action.isSending
            }
        case InvitationActions.SetSelectedUsers:
            return{
                ...state,
                selectedUsers: action.selectedUsers
            }
        default:
                return state;
    }
}