import * as React from "react";
import { useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { FileSystemObject } from "../Model/FileSystemObject";
import { CommandBarCommandProps } from "../Utils/useCommands";

export interface ExternalDownloadAllCommandProps extends CommandBarCommandProps {
	triggerRerender: () => void;
	files: FileSystemObject[] | undefined;
	shareId: string;
	password: string | undefined;
}

export const ExternalDownloadAllCommand = (props: ExternalDownloadAllCommandProps): JSX.Element => {
	const { t } = useTranslation();
	const { files, shareId, password } = props;
	const submitForm = React.useRef<HTMLFormElement>(null);	
	const shareIdRef = React.useRef<HTMLInputElement>(null);
	const passwordRef = React.useRef<HTMLInputElement>(null);

	const onDownload = useCallback(async () => {
		
		if(submitForm.current 
			&& shareIdRef.current 
			&& passwordRef.current) {	

			shareIdRef.current.value = shareId;
			passwordRef.current.value = password ?? "";

			submitForm.current?.submit();
		}
	}, [shareId, password, t]);

	const downloadAll = useCallback(async () => {
		if (!files?.some((file) => !file.hasRunningAction)) {
			// run only if at least one file is not currently downloading
			return;
		}
		files?.forEach((file) => file.hasRunningAction = true);
		props.triggerRerender();
		await onDownload();
		files?.forEach((file) => file.hasRunningAction = false);
		props.triggerRerender();
	}, [files, onDownload, props]);

	if (files && files.length > 0) {
		props.addCommandButton({
			key: "externalDownloadAll",
			text: t("Commands.DownloadAll.Command"),
			iconProps: { iconName: "ZipFolder" },
			onClick: () => {
				downloadAll();
				return false;
			},
			disabled: files.every((file) => file.hasRunningAction === true)
		});
	}

	return <>
		<form ref={submitForm} action="/api/drives/downloadAllByLink" method="POST">
			<input type="hidden" ref={shareIdRef} name="shareId" />
			<input type="hidden" ref={passwordRef} name="password" />
		</form>
	</>;
}