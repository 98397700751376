import { IStyle, makeStyles } from "@fluentui/react";

export default () => {
	let styles = makeStyles(theme => ({
		DockedActionBar: {
			display: "flex",

			".ms-Spinner": {
				margin: "0.4rem"
			}
		},
		DockedActionBar_Context: {
			flexGrow: 1
		},
		DockedActionBar_Hovering: {
			visibility: "visible",
			color: theme.palette.neutralSecondary
		},
		DockedActionBar_NotHovering: {
			visibility: "hidden"
		}
	}));
	return styles();
};