import * as React from "react";
import { moveFolder } from "../Api/File/MoveFolder";
import { SingleLineTextfieldDialog } from "../Components/SingleLineTextfieldDialog/SingleLineTextfieldDialog";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../Store/useAppDispatch";
import { CommandBarCommandProps } from "../Utils/useCommands";
import { DialogType } from "@fluentui/react";

export const RenameFolderCommand = (props: CommandBarCommandProps): JSX.Element => {
	const [showDialog, setShowDialog] = React.useState(false);
	const [newFoldername, setNewFoldername] = React.useState("");
	const { selectedFiles, selectedFolders, isReadonlyFolder: isReadOnlyFolder } = useAppSelector(state => state.file);
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const showCommand = useMemo(() =>
	!isReadOnlyFolder && (!selectedFiles || selectedFiles.length === 0) && selectedFolders && selectedFolders.length === 1
		, [isReadOnlyFolder, selectedFiles, selectedFolders]);

	const isDisabled = useMemo(() =>
		selectedFolders?.some(folder => folder.hasRunningAction)
		, [selectedFolders]);

	if (showCommand) {
		props.addCommandButton({
			key: "RenameFolder",
			text: t("Commands.RenameFolder.Command"),
			iconProps: { iconName: "Rename" },
			disabled: isDisabled,
			title: isDisabled ? t("Commands.IsBusy") : undefined,
			onClick: () => { setNewFoldername(selectedFolders ? selectedFolders[0].Name : ""); setShowDialog(true); return false; },
		});
	}

	const dialogContentProps = {
		type: DialogType.largeHeader,
		title: t("Commands.RenameFolder.DialogTitle"),
		closeButtonAriaLabel: 'Close'
	};

	const onCommand = React.useCallback(() => {
		const folder = selectedFolders && selectedFolders.length > 0 ? selectedFolders[0] : undefined;
		if (!folder) return;

		const newPath = folder.Path.substring(0, folder.Path.length - folder.Name.length) + newFoldername;

		dispatch(moveFolder(folder, newPath));
		setShowDialog(false);
	}, [dispatch, selectedFolders, newFoldername]);

	return <>
		{showDialog &&
			<SingleLineTextfieldDialog
				dialogContentProps={dialogContentProps}
				onCommand={onCommand}
				onDismiss={() => setShowDialog(false)}
				commandIsDisabled={!newFoldername}
				hidden={false}
				label={t("Commands.RenameFolder.NewFolderName")}
				value={newFoldername}
				onChange={(newValue) => setNewFoldername(newValue)}
				okLabel={t("Commands.RenameFolder.Ok")}
			/>
		}
	</>;
}