export const parentFolderKey = "###Parent###";

export const SpecialFolderIds = {
	All: "§ALL_FILES§",
	CustomerExchange: "§CUSTOMER_FILES",
	SharedByLink: "§SHARED_BY_LINK_FILES"
}

export interface SpecialFolder {
	Id: string,
	i18n: string,
	Icon: string,
	IconOpen: string,
	HasSubfolders?: boolean,
	TargetFolderId?: string,
}

export const SpecialFolders: SpecialFolder[] = [
	{
		Id: "§ALL_FILES§",
		i18n: "All",
		Icon: "FabricFolderSearch",
		IconOpen: "FabricOpenFolderHorizontal"
	},
	{
		Id: "§SHARED_BY_LINK_FILES",
		i18n: "SharedByLink",
		Icon: "FabricNetworkFolder",
		IconOpen: "FabricOpenFolderHorizontal"
	},
	{
		Id: "§CUSTOMER_FILES",
		i18n: "CustomerExchange",
		Icon: "FabricPublicFolder",
		IconOpen: "FabricOpenFolderHorizontal",
		HasSubfolders: false,
		TargetFolderId: "Customer exchange"
	}
]