import * as React from "react";
import { renameFile } from "../Api/File/RenameFile";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getFirstDrive } from "../Utils/GetFirstDrive";
import { useAppDispatch, useAppSelector } from "../Store/useAppDispatch";
import { CommandBarCommandProps } from "../Utils/useCommands";


export const MoveFileBetweenCommand = (props: CommandBarCommandProps): JSX.Element => {
	const { selectedFiles, selectedFolders, sourceType, drives, files, isReadonlyFolder: isReadOnlyFolder } = useAppSelector(state => state.file);
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const onCommand = React.useCallback(() => {
		const taskDrive = drives.find(x => x.Type === "Task");
		if (!taskDrive) return;

		if (selectedFiles) {
			for (const file of selectedFiles) {
				dispatch(renameFile(file, file.Path, file.Name, taskDrive));
			}
		}

		if (selectedFolders) {
			for (const folder of selectedFolders) {
				var subFiles = files?.filter(file => file.Path.startsWith(folder.Path) && (getFirstDrive(file.Drives)?.Type === "Order" || getFirstDrive(file.Drives)?.Type === "Tender"));
				if (subFiles) {
					for (const file of subFiles) {
						dispatch(renameFile(file, file.Path, file.Name, taskDrive));
					}
				}
			}
		}

	}, [dispatch, drives, selectedFiles, selectedFolders, files]);

	const showCommand = useMemo(() =>
	!isReadOnlyFolder && (sourceType === "Order" || sourceType === "Tender") && ((selectedFolders && selectedFolders.length > 0) || (selectedFiles && selectedFiles.length > 0 && selectedFiles.every(x => getFirstDrive(x.Drives)?.Type === sourceType)))
		, [isReadOnlyFolder, selectedFiles, selectedFolders, sourceType]);

	const isDisabled = useMemo(() =>
		selectedFiles?.some(file => file.hasRunningAction) || selectedFolders?.some(folder => folder.hasRunningAction)
		, [selectedFiles, selectedFolders]);

	if (showCommand) {
		props.addCommandButton({
			key: "moveFileBetween",
			text: t("Commands.MoveFileBetween.Command"), //driveType === "task" ? i18n.t("Commands.MoveFileBetween.CommandOrder") : ,
			iconProps: { iconName: "MoveToFolder" },
			disabled: isDisabled,
			title: isDisabled ? t("Commands.IsBusy") : undefined,
			onClick: () => { onCommand(); return false; },
		});
	}

	return <>
	</>;
}