import * as React from "react";
import { Text } from "@fluentui/react";
import { ProgressChart } from "../ProgressChart";
import { Contact } from "../../Model/Contact";
import { ContactPersona } from "../ContactPersona/ContactPersona";
import useStyles from "./StatusHeader.style"
import { css } from "@fluentui/react";
import { useCallback } from "react";

export function GetFirstContact(contacts: Contact[], jobFunction: string, character: string): StatusHeaderContact | undefined {
	if (!contacts || contacts.length <= 0) return undefined;

	let contact = contacts.filter(x => x.Character?.toLowerCase() === character?.toLowerCase())[0];

	if (contact) {

		let initials: string | undefined = undefined;
		if (contact.FirstName && contact.LastName) {
			initials = (contact.FirstName[0] + contact.LastName[0]).toUpperCase();
		}

		return {
			name: contact.FullName,
			jobFunction: jobFunction,
			imageUrl: contact.Image,
			eMail: contact.Email,
			phone: contact.Phone,
			firstName: contact.FirstName,
			lastName: contact.LastName,
			initials: initials
		};
	} else {
		return undefined;
	}
}

export interface StatusHeaderDetailRow {
	name: string;
	value: JSX.Element;
}

export interface StatusHeaderContact {
	name: string;
	jobFunction: string;
	imageUrl: string;
	eMail: string;
	phone: string;
	firstName: string;
	lastName: string;
	initials: string | undefined;
	isPartOfTeam?: boolean;
}

export interface StatusHeaderProps {
	statusPercent: number | undefined;
	header: string;
	description: string;

	detailRows?: StatusHeaderDetailRow[] | undefined;
	contacts?: StatusHeaderContact[] | undefined;
}

//const colSize: number = 12 / ((this.props.detailRows ? 1 : 0) + (this.props.contacts ? this.props.contacts.length : 0));
const colDetailStyle: string = `ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl6`;
const colContactsStyle: string = `ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl3`;

export const StatusHeader = (props: StatusHeaderProps) => {
	const styles = useStyles();

	const renderDetailTable = useCallback((colStyle: string): JSX.Element => {
		if (!props.detailRows) {
			return <></>;
		}

		return (
			<div className={css(colStyle, styles.StatusHeaderDetails)}>
				<div className="ms-Grid" dir="ltr">
					{props.detailRows.map((detail, i) =>
						<div key={i} className="ms-Grid-row">
							<div className="ms-Grid-col ms-sm6 ms-md4 ms-lg3">
								<Text>{detail.name}</Text>
							</div>
							<div className="ms-Grid-col ms-sm6 ms-md8 ms-lg9">
								<Text>{detail.value}</Text>
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}, [props.detailRows, styles.StatusHeaderDetails]);

	const renderContacts = useCallback((colStyle: string): JSX.Element => {
		if (!props.contacts) {
			return <></>;
		}

		return <>
			{props.contacts.map((contact, i) =>
				<div key={i} className={css(colStyle, styles.StatusHeaderDetails)} >
					<ContactPersona contact={contact} />
				</div>)
			}
		</>;
	}, [props.contacts, styles.StatusHeaderDetails]);

	return (
		<div className="ms-Grid" dir="ltr">
			<div className="ms-Grid-row header-row">
				{props.statusPercent != null &&
					<div className="ms-Grid-col ms-hiddenSm ms-md4 ms-lg3 ms-xl2">
						<ProgressChart value={props.statusPercent} width={120} />
					</div>
				}
				<div className="ms-Grid-col ms-sm12 ms-md8 ms-lg9 ms-xl10">
					<Text className={styles.StatusHeaderKey}>{props.header}</Text><br />
					<Text className={styles.StatusHeaderDescription}>{props.description}</Text>

					<div className="ms-Grid" dir="ltr">
						<div className="ms-Grid-row">
							{renderDetailTable(colDetailStyle)}
							{renderContacts(colContactsStyle)}
						</div>
					</div>
				</div>
			</div>
		</div >
	);

}