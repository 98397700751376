import { CheckListActions } from "./CheckListActions";

export interface CheckListReceiveFailedAction {
	type: typeof CheckListActions.ReceiveFailed;
	code: number;
	message: string;
}
export const checkListReceiveFailed = (code: number, message: string): CheckListReceiveFailedAction => ({
	type: CheckListActions.ReceiveFailed,
	code,
	message
});