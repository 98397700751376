import * as React from "react";
import { useSelector } from "react-redux";
import useStyles from "./TeamDetailsPanel.style"
import { Panel, Spinner, SpinnerSize, DefaultButton, MessageBar, MessageBarType, Persona, PersonaSize, PrimaryButton } from "@fluentui/react";
import { RootState, teamDetailsPanelSetRenderedChannel, teamDetailsPanelSetRenderedTeam, toggleTeamDetailsPanel } from "../../Store";
import { Channel } from "../../Model/Channel";
import { useBoolean } from "@fluentui/react-hooks";
import i18n from "../../i18n";
import { useCallback } from "react";
import { NumbersOnlyTextField } from "../NumbersOnlyTextField/NumbersOnlyTextField";
import { CustomTextField } from "../CustomTextField/CustomTextField";
import { setPTLimsReference } from "../../Api/Channel/SetPTLimsReference";
import { stringToNumber } from "../../Utils/NumberHelper";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../Store/useAppDispatch";

export interface TeamDetailsPanelProps {
    
    selectedChannel: Channel;
}

export const TeamDetailsPanel = (props: TeamDetailsPanelProps) => {
    const dispatch = useAppDispatch();
    const styles = useStyles();
    const { t } = useTranslation();

    const selectedChannel = props.selectedChannel;
    const { teams } = useAppSelector(state => state.teamList);
    const { isDetailsPanelOpen, renderedChannel, renderedTeam } = useAppSelector(state => state.teamDetailsPanel);

    const [isEditMode, { setTrue: enterEditMode, setFalse: leaveEditMode }] = useBoolean(false);
    const [isSaveableDataAvailable, { setTrue: setDataAvailable, setFalse: setDataUnavailable }] = useBoolean(false);
    const [ident, setIdent] = React.useState<string>("");
    const [location, setLocation] = React.useState<string>("");

    const onClose = () => {
        leaveEditMode();
        dispatch(toggleTeamDetailsPanel());
    };

    const onSaveChanges = React.useCallback(() => {
        leaveEditMode();

        let newRenderedChannel = { ...renderedChannel };
        let newRenderedTeam = {...renderedTeam};

        if (newRenderedTeam.Details) {
            //setting the new values for persistent visualisation
            newRenderedTeam.Details.Ident = stringToNumber(ident);
            newRenderedTeam.Details.Location = location;
            newRenderedTeam.DetailsSaveState = "Saving";

            let channelPos = renderedTeam.Channels.findIndex(c => c.Name === "General");
            
            if(channelPos < 0){
                return;
            }

            let channelId = renderedTeam.Channels[channelPos].Id;
 
            dispatch(teamDetailsPanelSetRenderedTeam(newRenderedTeam));
            dispatch(teamDetailsPanelSetRenderedChannel(newRenderedChannel));
   
            dispatch(setPTLimsReference(renderedTeam.Id, channelId, stringToNumber(ident), location));
        }
    }, [leaveEditMode, dispatch, renderedChannel, ident, location, renderedTeam]);

    const onCancel = React.useCallback(() => {

        if (renderedTeam.Details !== undefined) {
            setIdent(renderedTeam.Details?.Ident.toString());
            setLocation(renderedTeam.Details?.Location);
        }

        leaveEditMode();
    }, [leaveEditMode, renderedTeam.Details])

    const updateValue = useCallback(
        (name: "Ident" | "Location", newValue: string | undefined) => {
            if (newValue) {
                switch (name) {
                    case "Ident":
                        setIdent(newValue);
                        break;
                    case "Location":
                        setLocation(newValue)
                        break;
                }
            }
        }, []
    );

    React.useEffect(() => {
        if ((ident && ident !== renderedTeam.Details?.Ident.toString()) || (location && location !== renderedTeam.Details?.Location)) {
            setDataAvailable();
        } else {
            setDataUnavailable();
        }
    }, [ident, location, renderedTeam.Details, setDataAvailable, setDataUnavailable, isEditMode, renderedTeam.DetailsSaveState]);

    React.useEffect((): void => {
        if (!teams || !teams.length) {
            return;
        }
        let teamPosition = teams.findIndex((team) => team.Id === selectedChannel.ParentId);
        if (teamPosition < 0) {
            return;
        }
        let team = teams[teamPosition];

        dispatch(teamDetailsPanelSetRenderedTeam(team));

        let channelPosition = team.Channels.findIndex((channel) => channel.Id === selectedChannel.Id);
        if (channelPosition < 0) {
            return;
        }

        let channel = team.Channels[channelPosition];

        dispatch(teamDetailsPanelSetRenderedChannel(channel));

        //set initial ident and location
        let newIdent = "0";
        let newLocation = "";

        if (renderedTeam.Details) {
            newIdent = renderedTeam.Details.Ident.toString();
            newLocation = renderedTeam.Details?.Location;
        }
        setIdent(newIdent);
        setLocation(newLocation);

    }, [dispatch, renderedChannel.Details, renderedTeam.Details, selectedChannel.Id, selectedChannel.ParentId, teams]);


    const getPanelTitle = React.useCallback(() => {

		if(renderedChannel.Description)	{
			return `${renderedChannel.Description } | ${renderedChannel.Name ?? ""}`
		}
		else {
			return renderedChannel.Name ?? "";
		}
    }, [renderedChannel]);

    const renderUsers = useCallback((): JSX.Element => {
        return <>
            {renderedChannel.Details?.Users.map((user, i) =>
                <div key={i}>
                    <Persona imageAlt={user.Name} text={user.Name} size={PersonaSize.size32} />
                </div>)
            }
        </>;
    }, [renderedChannel.Details]);

    return <>
        <Panel
            headerText={getPanelTitle()}
            isOpen={isDetailsPanelOpen}
            onDismiss={() => onClose()}
            // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
            closeButtonAriaLabel={t("TeamDetailsPanel.Close")}>
            {renderedChannel &&
                <>
                    {renderedChannel.DetailsLoadingState === "Loading" &&
                        <div className={styles.Loading}>
                            <Spinner size={SpinnerSize.large} label={t("TeamDetailsPanel.Loading")} labelPosition="bottom" />
                        </div>
                    }
                    {renderedChannel.DetailsLoadingState === "Loaded" &&
                        <>
                            <h3 className={styles.Heading}>{t("TeamDetailsPanel.PTLimsTitle")}</h3>
                            {renderedChannel.DetailsSaveState === "SavingFailed" &&
                                <MessageBar messageBarType={MessageBarType.error}>{t("TeamDetailsPanel.SavingFailed")}</MessageBar>
                            }
                            <NumbersOnlyTextField
                                label={t("TeamDetailsPanel.Ident")}
                                defaultValue={ident}
                                onChange={(newValue) => updateValue("Ident", newValue)}
                                disabled={!isEditMode}></NumbersOnlyTextField>

                            <CustomTextField
                                label={t("TeamDetailsPanel.Location")}
                                defaultValue={location}
                                onChange={(newValue) => updateValue("Location", newValue)}
                                disabled={!isEditMode}></CustomTextField>

                            {isEditMode &&
                                <div className={styles.ButtonArea}>
                                    {isSaveableDataAvailable &&
                                        <PrimaryButton text={t("TeamDetailsPanel.Save")} onClick={onSaveChanges} />
                                    }
                                    <DefaultButton className={styles.CancelButton} text={t("TeamDetailsPanel.Cancel")} onClick={onCancel} />
                                </div>
                            }
                            {!isEditMode &&
                                <>
                                    <div className={styles.ButtonArea}>
                                        {renderedTeam.DetailsSaveState !== "Saving" &&
                                            <PrimaryButton text={t("TeamDetailsPanel.Edit")} onClick={enterEditMode} />
                                        }

                                    </div>
                                    <div className={styles.SaveArea}>
                                        {renderedTeam.DetailsSaveState === "Saving" &&
                                            <Spinner size={SpinnerSize.medium} label={t("TeamDetailsPanel.Saving")} />
                                        }
                                    </div>
                                </>
                            }
                            <hr></hr>
                            <div className={styles.UserList}>
                                {renderUsers()}
                            </div>
                        </>
                    }
                </>
            }
        </Panel>
    </>
}

export default TeamDetailsPanel;