import * as React from "react";
import { RootState } from "../Store";
import { DialogType, DialogFooter, PrimaryButton, DefaultButton, List, Icon } from "@fluentui/react";
import { getFileTypeIconProps } from "@fluentui/react-file-type-icons";
import { deleteFile } from "../Api/File/DeleteFile";
import { deleteFolder } from "../Api/File/DeleteFolder";
import { FileSystemObject } from "../Model/FileSystemObject";
import { CustomDialog } from "../Components/CustomDialog/CustomDialog";
import useStyles from "./DeleteFileCommand.style"
import { FolderWithFilesFoldable } from "../Components/FolderWithFilesFoldable";
import { CopyToClipboard } from "../Components/CopyToClipboard/CopyToClipboard";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../Store/useAppDispatch";
import { CommandBarCommandProps } from "../Utils/useCommands";

interface DeleteDriveItem {
	name: string;
	icon: string;
	file?: FileSystemObject;
	folder?: FileSystemObject;
}

export const DeleteFileCommand = (props: CommandBarCommandProps): JSX.Element => {
	const [showDialog, setShowDialog] = React.useState(false);
	const { selectedFiles, selectedFolders, isReadonlyFolder: isReadOnlyFolder } = useAppSelector((state: RootState) => state.file);
	const dispatch = useAppDispatch();
	const styles = useStyles();
	const { t } = useTranslation();

	const showCommand = useMemo(() =>
	!isReadOnlyFolder && ((selectedFolders && selectedFolders.length > 0) || (selectedFiles && selectedFiles.length > 0))
		, [isReadOnlyFolder, selectedFiles, selectedFolders]);

	const isDisabled = useMemo(() =>
		selectedFiles?.some(file => file.hasRunningAction) || selectedFolders?.some(folder => folder.hasRunningAction)
		, [selectedFiles, selectedFolders]);

	if (showCommand) {
		props.addCommandButton({
			key: "deleteFile",
			text: t("Commands.DeleteFile.Command"),
			iconProps: { iconName: "Delete" },
			disabled: isDisabled,
			title: isDisabled ? t("Commands.IsBusy") : undefined,
			onClick: () => { setShowDialog(true); return false; },
		});
	}

	const dialogContentProps = {
		type: DialogType.largeHeader,
		title: t("Commands.DeleteFile.DialogTitle"),
		closeButtonAriaLabel: 'Close'
	};

	const onCommand = React.useCallback(() => {
		if (selectedFiles && selectedFiles.length > 0) {
			for (const file of selectedFiles) {
				dispatch(deleteFile(file));
			}
		}
		if (selectedFolders && selectedFolders.length > 0) {
			for (const folder of selectedFolders) {
				dispatch(deleteFolder(folder));
			}
		}
		setShowDialog(false);
	}, [dispatch, selectedFiles, selectedFolders]);

	const deleteItems: DeleteDriveItem[] = React.useMemo(() => {
		let items: DeleteDriveItem[] = [];
		if (selectedFolders && selectedFolders.length > 0) {
			items = selectedFolders.map(x => ({
				file: undefined,
				folder: x,
				name: x.Name,
				icon: "FabricFolder"
			}) as DeleteDriveItem)
		}
		if (selectedFiles && selectedFiles.length > 0) {
			items = [...items, ...selectedFiles.map(x => ({
				file: x,
				folder: undefined,
				name: x.Name,
				icon: getFileTypeIconProps({ extension: x.Name.substring(x.Name.lastIndexOf('.')) }).iconName,
			}) as DeleteDriveItem)];
		}
		return items;
	}, [selectedFiles, selectedFolders]);

	return <>
		{showDialog && <CustomDialog hidden={false} dialogContentProps={dialogContentProps} onDismiss={() => setShowDialog(false)} >
			{deleteItems.length === 1 && t(`Commands.DeleteFile.DeleteFileMessage${selectedFolders?.length === 1 ? "Folder" : ""}`, { file: deleteItems ? deleteItems[0].name : "" })}
			{deleteItems.length > 1 && <>
				{t(`Commands.DeleteFile.DeleteFilesMessage${selectedFolders?.length === 1 ? "Folder" : ""}`, { fileCount: deleteItems.length })}
				<List className={styles.DeleteFileList} items={deleteItems} onRenderCell={(item) => {

					return <>
						{item?.folder ?
							<FolderWithFilesFoldable folder={item.folder} />
							:
							<CopyToClipboard key={item?.file?.Id} text={item?.name ?? ""}>
								<Icon iconName={item?.icon} />
								{item?.name}
							</CopyToClipboard>
						}
					</>;
				}} />
			</>}
			<DialogFooter>
				<PrimaryButton onClick={onCommand} text={t("Commands.DeleteFile.Ok")} />
				<DefaultButton onClick={() => setShowDialog(false)} text={t("Commands.DeleteFile.Cancel")} />
			</DialogFooter>
		</CustomDialog>}
	</>;
}