import * as React from "react";
import { useSelector } from "react-redux";
import { RootState, setGlobalError } from "../../Store";
import { DetailsList, IColumn, SelectionMode, DetailsListLayoutMode } from "@fluentui/react";
import { loadTask } from "../../Api/Task/LoadTask";
import { loadCheckList } from "../../Api/CheckList/LoadCheckList";
import { ChecklistValueFactory } from "../../Components/ChecklistValues/ChecklistValueFactory";
import "./Checklist.scss";
import { Loading } from "../../Components/Loading";
import { StatusHeader } from "../../Components/StatusHeader/StatusHeader";
import { ReloadComponent } from "../../Components/ReloadComponent/ReloadComponent";
import { Tour } from "../../Components/Tour/Tour";
import { CheckListEntry, editCheckList } from "../../Store/CheckList";
import taskSteps from "./TaskTourSteps";
import orderSteps from "./OrderTourSteps";
import tenderSteps from "./TenderTourSteps";
import FormatDate from "../../Formatter/FormatDate";
import { DriveType } from "../../Model/DriveType";
import { loadOrder } from "../../Api/Order/LoadOrder";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../Store/useAppDispatch";
import { useParams } from "react-router-dom";

export interface TaskChecklistProps {
	//ident?: string; 
	//location?: string; 
	//type?: DriveType;
}

export const Checklist = (props: TaskChecklistProps): JSX.Element => {
	const { t, i18n } = useTranslation();
	const params = useParams();

	const driveType = React.useMemo<DriveType | undefined>(() => {
		switch (params.type?.toLocaleLowerCase()) {
			case "task":
				return "Task"
			case "order":
				return "Order"
			case "tender":
				return "Tender"
			case "none":
				return "None"
		}
	}, [params.type]);

	const { isLoading: taskIsLoading, task, taskStatusPercent, taskLoadError } = useAppSelector(state => state.task);
	const { isLoading: orderIsLoading, order, orderStatusPercent, orderLoadError } = useAppSelector(state => state.order);
	const isInternalUser = useAppSelector(state => state.teams.isInternalUser);

	const isLoading = React.useMemo(() =>
		driveType === "Task" ? taskIsLoading : driveType === "Order" ? orderIsLoading : false
		, [driveType, orderIsLoading, taskIsLoading]);
	const statusPercent = React.useMemo(() =>
		driveType === "Task" ? taskStatusPercent : driveType === "Order" ? orderStatusPercent : undefined
		, [driveType, orderStatusPercent, taskStatusPercent]);

	const { contacts } = useAppSelector(state => state.contacts);
	const { isInitialized, teamName } = useAppSelector(state => state.teams);
	const { checkListValues, isLoading: checkListIsLoading, loadError: checkListLoadError } = useAppSelector(state => state.checklist);
	const dispatch: React.Dispatch<any> = useAppDispatch();

	const columns: IColumn[] = React.useMemo(() => [
		{
			key: "description",
			name: t("TaskChecklist.Description"),
			headerClassName: "TaskChecklist_Description",
			fieldName: "Description",
			minWidth: 200,
			maxWidth: 300,
			isRowHeader: true,
			isResizable: true,
			data: "string",
			isPadded: true,
			onRender: (item: CheckListEntry) => {
				return i18n.language.startsWith("de") ? item.Description : item.DescriptionE;
			}
		},
		{
			key: "value",
			name: t("TaskChecklist.Value"),
			headerClassName: "TaskChecklist_Value",
			fieldName: "Value",
			minWidth: 100,
			maxWidth: 1600,
			isRowHeader: true,
			isResizable: true,
			data: "string",
			isPadded: true,
			onRender: (item: CheckListEntry) => {
				return <ChecklistValueFactory checkListEntry={item} />;
			}
		},
		{
			key: "updatedBy",
			name: t("TaskChecklist.UpdatedBy"),
			headerClassName: "TaskChecklist_UpdatedBy",
			fieldName: "UpdatedBy",
			minWidth: 100,
			maxWidth: 200,
			isRowHeader: true,
			isResizable: true,
			data: "string",
			isPadded: true
		},
		{
			key: "dateUpdated",
			name: t("TaskChecklist.DateUpdated"),
			headerClassName: "TaskChecklist_DateUpdated",
			fieldName: "DateUpdated",
			minWidth: 100,
			maxWidth: 250,
			isRowHeader: true,
			isResizable: true,
			data: "date",
			isPadded: true,
			onRender: (item: CheckListEntry) => {
				return <>{FormatDate(item.DateUpdated)}</>;
			}
		}
	], [i18n.language, t]);

	const loadData = React.useCallback(() => {
		var location: string | undefined = params.location;
		var ident: string | undefined = params.ident;

		if (!location || !ident || !driveType) {
			dispatch(setGlobalError("This Teams tab is not configured correctly. Please check tab settings."));
			return;
		}

		if (driveType === "Task") {
			dispatch(loadTask(location, Number.parseInt(ident, 10), false));
		}
		if (driveType === "Order") {
			dispatch(loadOrder(location, Number.parseInt(ident, 10), false));
		}

		dispatch(loadCheckList(location, Number.parseInt(ident, 10), driveType));
	}, [dispatch, driveType, params.ident, params.location]);

	React.useEffect(() => {
		if (!isInitialized || isLoading) {
			return;
		}

		if (driveType === "Task" && !task && !taskLoadError) {
			loadData();
		}
		else if (driveType === "Order" && !order && !orderLoadError) {
			loadData();
		} else if (driveType === "Tender" && !checkListIsLoading && !checkListLoadError && !checkListValues.length) {
			loadData();
		}
	}, [checkListIsLoading, checkListLoadError, checkListValues, driveType, isInitialized, isLoading, loadData, order, orderIsLoading, orderLoadError, params.type, task, taskIsLoading, taskLoadError]);

	return (
		<div className="TaskStatus">
			{(!isInitialized || isLoading || checkListIsLoading) && <Loading />}
			{!isLoading && ((taskLoadError && !task) || (orderLoadError && !order)) &&
				<ReloadComponent errorMessage={taskLoadError ?? orderLoadError ?? ""} reload={() => loadData()} />
			}
			{!checkListIsLoading && checkListLoadError && checkListValues.length === 0 &&
				<ReloadComponent errorMessage={checkListLoadError} reload={() => loadData()} />
			}
			{!isLoading && !checkListIsLoading && checkListValues.length > 0 && <>
				<Tour steps={driveType === "Task" ? taskSteps : driveType === "Order" ? orderSteps : tenderSteps}>
					<StatusHeader header={teamName || (task?.Number ?? order?.Number ?? params.ident ?? "")}
						description=""
						contacts={contacts}
						statusPercent={statusPercent}
					/>
					<hr />
					<DetailsList
						items={checkListValues}
						compact={false}
						columns={columns}
						selectionMode={SelectionMode.none}
						layoutMode={DetailsListLayoutMode.justified}
						isHeaderVisible={true}
						onItemInvoked={(entry: CheckListEntry) => isInternalUser ? dispatch(editCheckList(entry.Location || "", entry.Ident || 0)): undefined}
					/>
				</Tour>
			</>
			}
		</div>
	);
};