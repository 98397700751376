import { User } from "../../../Model/User";

export enum InvitationActions {
    Request = "Invitation/Request",
    Received = "Invitation/Received",
    ReceiveFailed = "Invitation/ReceiveFailed",
    SortInvitation = "Invitation/Sort",
    SetResendingInvitationMail = "Invitation/SetResendingInvitationMail",
    SetSelectedUsers = "Invitation/SetSelectedUsers"
}

interface RequestInvitationAction {
    type: typeof InvitationActions.Request;
}

export const requestInvitation = (): RequestInvitationAction =>({
    type: InvitationActions.Request
});


interface InvitationReceivedAction {
    type: typeof InvitationActions.Received;
    invitedUsers: User[] | undefined;
}

export const invitationReceived = (invitedUsers: User[] | undefined): InvitationReceivedAction => ({
    type: InvitationActions.Received,
    invitedUsers: invitedUsers
});

interface InvitationReceiveFailedAction {
    type: typeof InvitationActions.ReceiveFailed;
    code: number;
    message: string;
}

export const invitationReceiveFailed = (code: number, message: string): InvitationReceiveFailedAction => ({
    type: InvitationActions.ReceiveFailed,
    code,
    message
});

interface SortInvitationAction {
    type: typeof InvitationActions.SortInvitation;
    property: string;
    sortDirectionASC: boolean;
}

export const sortInvitation = (property: string, sortDirectionASC: boolean): SortInvitationAction => ({
    type: InvitationActions.SortInvitation,
    property,
    sortDirectionASC
});

interface SetResendingInvitationMailAction {
	type: typeof InvitationActions.SetResendingInvitationMail;
	isSending: boolean;
}
export const setResendingInvitationMail = (isSending: boolean): SetResendingInvitationMailAction => ({
	type: InvitationActions.SetResendingInvitationMail,
	isSending,
});

interface SetSelectedUsersAction {
    type: typeof InvitationActions.SetSelectedUsers;
    selectedUsers: User[] | undefined;
}

export const setSelectedUsers = (selectedUsers: User[] | undefined): SetSelectedUsersAction => ({
    type: InvitationActions.SetSelectedUsers,
    selectedUsers: selectedUsers
});

export type InvitationActionTypes = 
    RequestInvitationAction |
    InvitationReceivedAction |
    InvitationReceiveFailedAction |
    SortInvitationAction |
    SetResendingInvitationMailAction|
    SetSelectedUsersAction;