import { IStyle, makeStyles } from "@fluentui/react";

export default () => {
	let styles = makeStyles(theme => ({
		StartTour: {
			position: "absolute",
			right: "0px",
			top: "0px"
		},

		ShepherdTour: {
			":global(.shepherd-element)": {
				".shepherd-arrow:before": {
					backgroundColor: theme.palette.neutralLight
				} as IStyle,

				".shepherd-has-title, .shepherd-content, .shepherd-header": {
					background: theme.palette.neutralLight,
					padding: "0.4em .75em 0em 0.75em",

					".shepherd-title": {
						fontSize: "12px",
						fontFamily: theme.fonts.medium.fontFamily,
					} as IStyle
				} as IStyle,

				".shepherd-cancel-icon": {
					fontSize: "16px",
					color: theme.palette.neutralPrimary,

					":hover": {
						color: theme.palette.themePrimary,
					}
				} as IStyle,

				".shepherd-text": {
					color: theme.palette.neutralPrimary,
					overflow: "auto",
				} as IStyle,

				".shepherd-button": {
					background: theme.palette.themePrimary,
					border: `solid 1px ${theme.palette.themePrimary}`,
					color: theme.palette.white,

					":not(:disabled):hover": {
						background: theme.palette.themeSecondary,
						color: theme.palette.white
					},

					":disabled": {
						backgroundColor: theme.semanticColors.buttonBackgroundDisabled,
						color: theme.semanticColors.buttonTextDisabled,
						borderColor: theme.semanticColors.buttonBorderDisabled
					} as IStyle
				} as IStyle
			}

		}
	}));
	return styles();
};