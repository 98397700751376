import i18next from "i18next";

export default function (): void {
	i18next.addResourceBundle("de", "translation", {
		Times: {
			Day: "Tag",
			DayMulti: "Tage"
		},
		OrderStatus: {
			Test: "Prüfung",
			Status: "Status",
			Start: "Start",
			End: "Ende",
			Progress: "Fortschritt",
			Planed: "geplant",
			Detail: {
				Status: "Status",
				OrderNumber: "Bestellnummer",
				Laboratory: "Labor",
				Sales: "Vertrieb",
				ProductName: "Prüfling"
			}
		},
		TaskStatus: {
			OrderNumber: "Auftrags-Nr.",
			Order: "Auftrag",
			Status: "Status",
			Start: "Start",
			End: "Ende (geplant)",
			Progress: "Fortschritt",
			Detail: {
				AccountManager: "Kundenbetreuer"
			}
		},
		TeamList: {
			TeamId: "ID",
			Title: "Name",
			Status: "Status",
			Link: "Links",
			LinkTitlePrefix: "Gehe zu ",
			ChatDeepLink: "Unterhaltung Tab",
			StatusDeepLink: "Status Tab",
			SummaryDeepLink: "Summary Tab",
			FileDeepLink: "file Tab",
			StatusNotFound: "",
			ChannelType: {
				Order: "Order ",
				Task: "Task ",
				Tender: "Tender ",
				Test: "Test ",
				Unknown: ""
			}
		},
		TeamDetailsPanel: {
			PTLimsTitle: "PTLims",
			Ident: "Ident",
			Location: "Location",
			Loading: "Laden",
			Saving: "Speichern",
			SavingFailed: "Speichern fehlgeschlagen",
			Close: "Schließen",
			Edit: "Bearbeiten",
			Cancel: "Abbrechen",
			Save: "Speichern"
		},
		Invitation: {
			EMail: "E-Mail",
			Name: "Name",
			State: "Einladungsstatus ",
			InvitationDate: "Datum der Einladung",
			InvitedTeam: "Team"
		},
		ReloadComponent: {
			Reload: "Erneut versuchen"
		},
		TaskChecklist: {
			Description: "Beschreibung",
			Value: "Wert",
			DateUpdated: "Änderungsdatum",
			UpdatedBy: "Geändert von",
			Detail: {
				TaskNumber: "Vorgangsnummer",
				AccountManager: "Kundenbetreuer"
			},
			Boolean: {
				true: "Ja",
				false: "Nein"
			}
		},
		TabConfig: {
			Intro: "Fügen Sie eine weiteren Tab zu Ihrem Kanal hinzu.",
			Content: "Inhalt der Registerkarte",
			TabName: "Name der Registerkarte",
			PTLimsIdent: "Ident aus PTLims",
			PTLimsLocation: "Location code aus PTLims",
			BindPTLims: "Team konfigurieren"
		},
		FileBase: {
			File: {
				Name: "Name",
				Size: "Größe",
				Tags: "Tags",
				Of: "von",
				File: "Datei",
				Folder: "Ordner",
				DownloadFailed: "Herunterladen der Datei '{{file}}' ist fehlgeschlagen",
				Downloads: "Downloads",
				ModifiedOn: "Geändert",
				ModifiedBy: "Geändert von",
				Cancel: "Abbrechen",
				Drive: "Akte",
				FilenameConflictDialogTitle: "Namenskonflikt",
				Overwrite: "Überschreiben",
				Rename: "Umbenennen",
				FilenameConflictMessage: "Eine Datei mit dem gleichen Namen existiert bereits in dem Ordner. Möchten Sie die Datei überschreiben oder umbenennen?",
				FilenameRequiredErrorMessage: "Dateiname wird benötigt",
				FilenameInvalidCharacterErrorMessage: "Ungültige Zeichen:  \\ / : * ? \" < > |",
			},
			Folders: {
				Heading: "Ordner",
				All: "Alle Dateien",
				CustomerExchange: "Customer Exchange",
				SharedByLink: "Per Link geteilt"
			},
			Uploading: "Datei wird hochladen...",
			FileUploadMessage: "Die Datei '{{file}}' wurde in den Ordner '{{path}}' hochgeladen.",
			Downloading: "Datei wird heruntergeladen...",
			HasHiddenFiles: "Durch Filter versteckte Dateien vorhanden",
			ExternalShareSubTitle: "Gültig von {{from}} bis {{to}}, Erstellt von {{user}}",
			ExternalShareDownloadLinkLabel: "Link",
			ExternalShareOverdueTooltip: "Die Freigabe der Dateien ist abgelaufen."
		},
		Commands: {
			Dialog: {
				Cancel:"Abbrechen"
			},
			IsBusy: "Auf dem Ziel werden momentan Aktionen durchgeführt",
			AddFolder: {
				Command: "Neuer Ordner",
				DialogTitle: "Einen neuen Ordner erstellen",
				NewFolderName: "Name",
				Ok: "Erstellen",
				Cancel: "Abbrechen",
			},
			RenameFile: {
				Command: "Umbenennen",
				DialogTitle: "Datei unbenennen",
				NewFileName: "Neuer Dateiname",
				Ok: "Umbenennen",
				Cancel: "Cancel",
			},
			MoveFile: {
				Command: "Verschieben",
				DialogTitle: "Datei verschieben",
				TargetFolder: "Ziel Ordner",
				Ok: "Verschieben",
				Cancel: "Cancel",
			},
			Download: {
				Command: "Herunterladen"
			},
			DownloadAll: {
				Command: "Download Alles (ZIP)"
			},
			DeleteFile: {
				Command: "Löschen",
				DialogTitle: "Löschen",
				DeleteFileMessage: "Möchten Sie die Datei '{{file}}' wirklich löschen?",
				DeleteFileMessageFolder: "Möchten Sie den Ordner '{{file}}' und dessen gesamten Inhalt löschen?",
				DeleteFilesMessage: "Möchten Sie die {{fileCount}} Dateien wirklich löschen?",
				DeleteFilesMessageFolder: "Möchten Sie die Elemente wirklich löschen? Alle Inhalte der Ordner werden dabei gelöscht.",
				Ok: "Löschen",
				Cancel: "Abbrechen",
			},
			Refresh: {
				Command: "Aktualisieren"
			},
			Upload: {
				Command: "Hochladen"
			},
			Resend: {
				Command:"Erneut senden"
			},
			Share: {
				Command: "Teilen",
				DialogTitle: "Dokument teilen",
				DialogTitleMulti: "Dokumente teilen",
				Share: "Teilen",
				StopShare: "Teilen beenden",
				Close: "Schließen",
				DownloadUrlLabel: "Download url",
				PasswordLabel: "Passwort",
				ShareWithCustomer: "Im Portal mit Kunden teilen",
				ShareWithCustomerYes: "Ja",
				ShareWithCustomerNo: "Nein",
				ShareByLink: "Per Link teilen",
				ShareDurationLabel: "Gültigkeitszeitraum der url",
				GenerateLink: "Download url erstellen",
			},
			ResetPassword: {
				Command: "Passwort zurücksetzen",
				DialogTitle: "Passwort zurücksetzen",
				DownloadUrlLabel: "Download url",
				PasswordLabel: "Passwort",
				Close: "Schließen",
			},
			OpenSharePoint: {
				Command: "Open in SharePoint"
			},
			MoveFileBetween: {
				Command: "In Vorgangsakte verschieben"
			},
			PullFileFromTask: {
				CommandOrder: "In Auftragsakte verschieben",
				CommandTender: "In Angebotsakte verschieben"
			},
			RenameFolder: {
				Command: "Umbenennen",
				DialogTitle: "Ordner umbenennen",
				NewFolderName: "Neuer Ordner name",
				Ok: "Umbenennen",
				Cancel: "Cancel",
			},
			View: {
				Command: "Anzeige",
				List: "Liste",
				Tiles: "Kacheln"
			},
			CopyLink: {
				Command: "Link kopieren"
			},
			ClosePreview: {
				Command: ""
			},
			NextPreview: {
				Command: ""
			},
			PrevPreview: {
				Command: ""
			},
			ExtendShare: {
				Command: "Zeit verlängern",
				DialogTitle: "Gültigkeitszeitraum verlängern",
				ValidToDate: "Download ist gültig bis zum",
				Ok:"Verlängern",
				Cancel:"Abbrechen"
			},
			ResetDownloads: {
				Command: "Downloads zurücksetzen"
			},
			StopShare: {
				Command: "Teilen beenden"
			},
			NoActionAvailable: "Keine Aktion verfügbar"
		},
		Actions: {
			Uploading: "'{{file}}' wird hochgeladen",
			Downloading: "'{{file}}' wird heruntergeladen",
			AddFolder: "Erstelle Ordner '{{folder}}'",
			DeleteFile: "Lösche Datei '{{file}}'",
			DeleteFolder: "Lösche Ordner '{{folder}}'",
			Renaming: "Bennene Detei '{{file}}' um",
			Moving: "Verschiebe Datei '{{file}}'",
			Share: "Teile Datei '{{file}}'",
			RemoveShare: "Entferne Freigabe der Datei '{{file}}'",
			ResetDownloads: "Download Zähler der Datei '{{file}}' wird zurückgesetzt",
			GetTags: "Lade verfügbare tags",
			AddTag: "Tag {{tag}} hinzufügen",
			RemoveTag: "Tag {{tag}} entfernen"
		},
		ExternalShare: {
			Verification: {
				Title: "Dateitransfer",
				Description: {
					Line1: "Sie haben von uns einen sicheren Link zum Download von Dateien erhalten.",
					Line2: "Bitte geben Sie nun das Password ein, um die Dateien einzusehen."
				},
				PasswordLabel: "Passwort",
				SubmitButtonText: "Zugriff erhalten",
				OnAccessLoading: "Dateien werden abgefragt",
				ErrorMessages: {
					Error: "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder melden Sie sich bei dem Absender.",
					NotFound: "Es wurde keine gültige Freigabe gefunden. Bitte melden Sie sich bei dem Absender.",
					Unauthorized: "Passwort oder Freigabelink sind falsch. Bitte versuchen Sie es erneut oder melden Sie sich bei dem Absender.",
				}
			},
			Files: {
				ErrorMessages: {
					Error: "Es ist ein Fehler beim Download einer Datei aufgetreten. Bitte versuchen Sie es erneut oder melden Sie sich bei dem Absender.",
					NotFound: "Die maximale Anzahl an Downloads wurde erreicht. Bitte aktualisieren Sie die Seite oder melden sich, wenn nötig, bei dem Absender.",
					Unauthorized: "Sie sind nicht berechtigt, diese Aktion durchzuführen. Bitte versuchen Sie es erneut oder melden Sie sich bei dem Absender.",
				}
			}
		},
		DatePicker: {
			months: [
				"Januar",
				"Februar",
				"März",
				"April",
				"Mai",
				"Juni",
				"Juli",
				"August",
				"September",
				"Oktober",
				"November",
				"Dezember",
			],

			shortMonths: ["Jan", "Feb", "Mrz", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"],

			days: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],

			shortDays: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],

			goToToday: "Gehe zu Heute",
			prevMonthAriaLabel: "Gehe zu letzen Monat",
			nextMonthAriaLabel: "Gehe zu nächsten Monat",
			prevYearAriaLabel: "Gehe zum letzen Jahr",
			nextYearAriaLabel: "Gehe zum nächsten Jahr",
			closeButtonAriaLabel: "Schließe Kalender",
			monthPickerHeaderAriaLabel: "{0}, wähle, um das Jahr zu ändern",
			yearPickerHeaderAriaLabel: "{0}, wähle, um den Monat zu ändern",

			isRequiredErrorMessage: "Datum wird benötigt.",

			invalidInputErrorMessage: "Kein korrektes Datum-Format."
		},
		HttpStatusCodes: {
			100: "Continue",
			101: "Switching Protocols",
			102: "Processing",
			103: "Early Hints",
			200: "OK",
			201: "Created",
			202: "Accepted",
			203: "Non-Authoritative Information",
			204: "No Content",
			205: "Reset Content",
			206: "Partial Content",
			207: "Multi-Status",
			208: "Already Reported",
			226: "IM Used",
			300: "Multiple Choices",
			301: "Moved Permanently",
			302: "Found (Moved Temporarily)",
			303: "See Other",
			304: "Not Modified",
			305: "Use Proxy",
			306: "(reserviert)",
			307: "Temporary Redirect",
			308: "Permanent Redirect",
			400: "Bad Request",
			401: "Unauthorized",
			402: "Payment Required",
			403: "Forbidden",
			404: "Not Found",
			405: "Method Not Allowed",
			406: "Not Acceptable",
			407: "Proxy Authentication Required",
			408: "Request Timeout",
			409: "Conflict",
			410: "Gone",
			411: "Length Required",
			412: "Precondition Failed",
			413: "Payload Too Large",
			414: "URI Too Long",
			415: "Unsupported Media Type",
			416: "Range Not Satisfiable",
			417: "Expectation Failed",
			421: "Misdirected Request",
			422: "Unprocessable Entity",
			423: "Locked",
			424: "Failed Dependency",
			425: "Too Early",
			426: "Upgrade Required",
			428: "Precondition Required",
			429: "Too Many Requests",
			431: "Request Header Fields Too Large",
			451: "Unavailable For Legal Reasons",
			418: "I’m a teapot",
			420: "Policy Not Fulfilled",
			444: "No Response",
			449: "The request should be retried after doing the appropriate action",
			499: "Client Closed Request",
			500: "Internal Server Error",
			501: "Not Implemented",
			502: "Bad Gateway",
			503: "Service Unavailable",
			504: "Gateway Timeout",
			505: "HTTP Version not supported",
			506: "Variant Also Negotiates",
			507: "Insufficient Storage",
			508: "Loop Detected",
			509: "Bandwidth Limit Exceeded",
			510: "Not Extended",
			511: "Network Authentication Required"
		}
	}, true, true);
}
