import { CommandBar, DetailsList, DetailsListLayoutMode, IColumn, ICommandBarItemProps, SelectionMode, Selection } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { loadInvitation } from "../../Api/Invitation/LoadInvitation"
import { Loading } from "../../Components/Loading";
import { ReloadComponent } from "../../Components/ReloadComponent/ReloadComponent";
import i18n from "../../i18n";
import { RootState } from "../../Store";
import { sortInvitation, setSelectedUsers } from "../../Store/PersonalApp/Invitation/Actions";
import useStyles from "./Invitation.style"
import { RefreshInvitationCommand, ResendInvitationMailCommand } from "../../ToolbarCommands";
import { User } from "../../Model/User";
import { useAppDispatch, useAppSelector } from "../../Store/useAppDispatch";

interface InvitationProps {

}

export const Invitation = (props: InvitationProps) => {

	const { isInitialized } = useAppSelector(state => state.teams);
	const { isLoading, invitationLoadError, invitedUsers, sortProperty, sortPropertyDirectionASC, selectedUsers } = useAppSelector(state => state.invitation);
	const dispatch: React.Dispatch<any> = useAppDispatch();
	const { t } = useTranslation();
	const styles = useStyles();
	const [canSendData, { setTrue: setDataAvailable, setFalse: setDataUnavailable }] = useBoolean(false);

	const selection: Selection = new Selection({
		onSelectionChanged: () => {
			dispatch(setSelectedUsers(selection.getSelection() as User[]));
		}
	});

	const loadData = React.useCallback(
		() => {
			dispatch(loadInvitation());
		},
		[dispatch]
	);

	React.useEffect(() => {
		if (isInitialized && !isLoading && !invitationLoadError && !invitedUsers) {
			loadData();
		}
	});

	const commands: ICommandBarItemProps[] = [];
	const farCommands: ICommandBarItemProps[] = [];

	React.useEffect(() => {
		if (selectedUsers === undefined || selectedUsers.length === 0) {
			setDataUnavailable();
		} else {
			setDataAvailable();
		}
	}, [selectedUsers, setDataAvailable, setDataUnavailable])

	const columns: IColumn[] = [
		{
			key: "emailColumn",
			name: i18n.t("Invitation.EMail"),
			fieldName: "EMail",
			minWidth: 100,
			maxWidth: 400,
			isRowHeader: true,
			isResizable: true,
			data: "string",
			isPadded: true,
			isSorted: sortProperty === "EMail",
			isSortedDescending: sortProperty === "EMail" ? !sortPropertyDirectionASC : undefined,
			onColumnClick: () => {
				sortInvitation("EMail", !sortPropertyDirectionASC);
			},
		},
		{
			key: "nameColumn",
			name: t("Invitation.Name"),
			fieldName: "Name",
			minWidth: 100,
			maxWidth: 300,
			isRowHeader: true,
			isResizable: true,
			data: "string",
			isPadded: true,
			isSorted: sortProperty === "Name",
			isSortedDescending: sortProperty === "Name" ? !sortPropertyDirectionASC : undefined,
			onColumnClick: () => {
				sortInvitation("Name", !sortPropertyDirectionASC);
			},
		},
		{
			key: "stateColumn",
			name: t("Invitation.State"),
			fieldName: "ExternalUserState",
			minWidth: 100,
			maxWidth: 200,
			isRowHeader: true,
			isResizable: true,
			data: "string",
			isPadded: true,
			isSorted: sortProperty === "ExternalUserState",
			isSortedDescending: sortProperty === "ExternalUserState" ? !sortPropertyDirectionASC : undefined,
			onColumnClick: () => {
				sortInvitation("ExternalUserState", !sortPropertyDirectionASC);
			},
		},
		{
			key: "inviteDateColumn",
			name: t("Invitation.InvitationDate"),
			fieldName: "InvitationDate",
			minWidth: 100,
			maxWidth: 200,
			isRowHeader: true,
			isResizable: true,
			data: "string",
			isPadded: true,
			isSorted: sortProperty === "InvitationDate",
			isSortedDescending: sortProperty === "InvitationDate" ? !sortPropertyDirectionASC : undefined,
			onColumnClick: () => {
				sortInvitation("InvitationDate", !sortPropertyDirectionASC);
			},
		},
		{
			key: "invitedTeam",
			name: t("Invitation.InvitedTeam"),
			fieldName: "InvitedTeam",
			minWidth: 100,
			maxWidth: 200,
			isRowHeader: true,
			isResizable: true,
			data: "string",
			isPadded: true,
			isSorted: sortProperty === "InvitedTeam",
			isSortedDescending: sortProperty === "InvitedTeam" ? !sortPropertyDirectionASC : undefined,
			onColumnClick: () => {
				sortInvitation("InvitedTeam", !sortPropertyDirectionASC);
			},
		}
	];



	return (
		<div className={styles.Invitation}>
			{canSendData === true &&
				<ResendInvitationMailCommand addCommandButton={(command) => commands.push(command)} />
			}
			<RefreshInvitationCommand addCommandButton={(command) => commands.push(command)} />

			{isLoading && <Loading />
			}
			{!isLoading && invitationLoadError && !invitedUsers &&
				<ReloadComponent errorMessage={invitationLoadError} reload={() => loadData()} />
			}
			{!isLoading && <>
				<CommandBar className={styles.CommandBar} items={commands} farItems={farCommands} />

				{invitedUsers &&
					<>
						<DetailsList
							items={invitedUsers ?? []}

							compact={false}
							columns={columns}
							selectionMode={SelectionMode.multiple}
							selection={selection}
							layoutMode={DetailsListLayoutMode.justified}
							isHeaderVisible={true}
						/>
					</>
				}
			</>
			}
		</div>
	);
}

