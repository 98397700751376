import * as React from 'react';
import logo from '../logo.svg';
import { TextField, Text, Stack, PrimaryButton, MessageBar, MessageBarType, Spinner } from '@fluentui/react';
import "./Download.scss";
import { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageSelector } from "../Components/LanguageSelector/LanguageSelector"
import { setGlobalLanguage } from '../Utils/i18nHelper';
import { ODataResult } from '../Model/ODataResult';
import { ExternalShareFileDetailList } from "../Components/ExternalShareFileDetailList/ExternalShareFileDetailList"
import { FileSystemObject } from "../Model/FileSystemObject";
import { useParams } from 'react-router-dom';

export const Download = () => {
	const { t } = useTranslation();
	const [password, setPassword] = useState<string>();
	const [errorMessage, setErrorMessage] = useState<string>();
	const [isLoading, setIsLoading] = useState(false);
	const [files, setFiles] = useState<FileSystemObject[]>([]);
	const params = useParams();

	const shareId = params.shareId || "";

	useEffect(() => {
		// set language one time after landing on this page
		// language = window.navigator.languages[0]; // maybe this can use additional
		setGlobalLanguage((window.navigator as any).userLanguage ?? window.navigator.language);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onSubmitAccess = useCallback(async () => {
		setIsLoading(true);
		setErrorMessage("");
		try {
			const response: Response = await fetch(`${window.location.origin}/api/drives/getContentByLink`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					// the property names are currently case sensitive (propably this endpoint uses ASP.NET Core Web API instead of OData)
					Link: shareId,
					Password: password
				})
			});

			if (response.status === 401) {
				setErrorMessage(t("ExternalShare.Verification.ErrorMessages.Unauthorized"));
				return;
			}

			if (response.status === 404) {
				setErrorMessage(t("ExternalShare.Verification.ErrorMessages.NotFound"));
				return;
			}

			if (!response.ok) {
				setErrorMessage(t("ExternalShare.Verification.ErrorMessages.Error"))
				return;
			}

			const filesResponse: ODataResult<FileSystemObject> = await response.json();
			if (filesResponse.value) {
				setFiles(filesResponse.value);
			}
		}
		finally {
			setIsLoading(false);
		}
	}, [shareId, password, t]);

	return <div className="Page-Wrapper">
		<div className="Page-Header">
			<LanguageSelector />
		</div>
		<div className="File-Download">
			<Stack className="Page">
				<header className="Header">
					<img src={logo} className="Logo" alt="logo" />
				</header>
				{files.length === 0 ?
					<Stack tokens={{ childrenGap: 15 }} className="Form ms-depth-16">
						<Text variant={"large"} nowrap block>
							{t("ExternalShare.Verification.Title")}
						</Text>
						<Text variant={"medium"} block>
							{t("ExternalShare.Verification.Description.Line1")}
							<br />
							{t("ExternalShare.Verification.Description.Line2")}
						</Text>
						<TextField label={t("ExternalShare.Verification.PasswordLabel")} onChange={(ev, newValue) => setPassword(newValue || "")} value={password} type="password" canRevealPassword />

						<Stack horizontal tokens={{ childrenGap: 40 }}>
							<PrimaryButton disabled={isLoading || !password} text={t("ExternalShare.Verification.SubmitButtonText")} onClick={onSubmitAccess} />
							{isLoading && <Spinner label={t("ExternalShare.Verification.OnAccessLoading")} labelPosition="right" />}
						</Stack>

						{errorMessage && <MessageBar
							messageBarType={MessageBarType.error}
							isMultiline={false}
							onDismiss={() => setErrorMessage("")}
							dismissButtonAriaLabel="Close" >
							{errorMessage}
						</MessageBar>}
					</Stack>
					:
					<div className="ExternalShareFileDetailListWrapper">
						<ExternalShareFileDetailList files={files} shareId={shareId} password={password} />
					</div>
				}
			</Stack>
		</div>
	</div>;
}