import { RootState } from "../../Store";
import { ApiUrlBuilder } from "../../ApiUrlBuilder";
import { ExternalShare } from "../../Model/ExternalShare";
import { useSelector } from "react-redux";
import { useCallback } from "react";
import { useAppSelector } from "../../Store/useAppDispatch";

export function useResetSharePassword() {
	const { teams: { isInitialized, accessToken } } = useAppSelector((state: RootState) => state);

	const resetPassword = useCallback(async (share: ExternalShare): Promise<{ newPassword: string | undefined; response: Response | undefined; }> => {
		if (!isInitialized || !accessToken) return { newPassword: undefined, response: undefined };

		const url = ApiUrlBuilder.ResetSharePassword();
		const requestBody = { id: share.id };

		const response: Response = await fetch(`/api${url}`, {
			method: "POST",
			headers: {
				"Authorization": `Bearer ${accessToken}`,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(requestBody)
		});

		let newPassword: string | undefined;
		if (response.ok) {
			newPassword = (await response?.json())?.password as string;
		}

		return { newPassword, response };
	}, [accessToken, isInitialized]);

	return resetPassword;
}