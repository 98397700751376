import React from "react";
import { batch } from "react-redux";
import { ODataResult } from "../../Model/ODataResult";
import { User } from "../../Model/User";
import { RootState, teamListReceiveFailed } from "../../Store";
import { requestInvitation, invitationReceived, invitationReceiveFailed } from "../../Store/PersonalApp/Invitation/Actions";
import { getHttpStatusText } from "../../Utils/getHttpStatusText";

export function loadInvitation() {
    return async (dispatch: React.Dispatch<any>, getState: () => RootState): Promise<void> => {
        try{
            dispatch(requestInvitation());
            
            const { teams: { accessToken } } = getState();

			let url: string = `/invitedUsers`;

            const response: Response = await fetch(`${window.location.origin}/api${url}`, {
				method: "GET",
				headers: {
					"Authorization": `Bearer ${accessToken}`
				}
			});

			if (!response.ok) {
				const errorMessage = response.statusText || getHttpStatusText(response.status);				
				dispatch(teamListReceiveFailed(response.status, errorMessage));
				return;
			}

            const invitations: ODataResult<User> = await response.json();
            batch(()=> {
                dispatch(invitationReceived(invitations.value));
            });
        }catch (error:any){
            dispatch(invitationReceiveFailed(0, error.message))
        }
    }
};