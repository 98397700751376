import { ExternalSharesState } from "./State";
import { ExternalShareActionTypes, ExternalShareActions } from "./Actions";
import { ExternalShare } from "../../Model/ExternalShare";
import { ExternalShareFile } from "../../Model/ExternalShareFile";
import { filesReceived } from "..";

const initialState: ExternalSharesState = {
	shares: [],
	status: "NotLoaded",
	shareSortProperty: "validTo",
	shareSortPropertyDirectionASC: false,
	shareFilesSortProperty: "name",
	shareFilesSortPropertyDirectionASC: true
};

const sortExternalShares = (shares: ExternalShare[], sortProperty?: keyof (ExternalShare), sortPropertyDirectionASC?: boolean) => {
	return [...shares].sort((a, b) => {
		const order = sortPropertyDirectionASC ? 1 : -1;
		switch (sortProperty) {
			case "id":
				return a.id > b.id ? order : -order;
			case "createdBy":
				return a.createdBy > b.createdBy ? order : -order;
			case "createdOn":
				return a.createdOn > b.createdOn ? order : -order;
			case "modifiedBy":
				return a.modifiedBy > b.modifiedBy ? order : -order;
			case "modifiedOn":
				return a.modifiedOn > b.modifiedOn ? order : -order;
			case "files":
				return a.files.length > b.files.length ? order : -order;
			case "validFrom":
				return a.validFrom > b.validFrom ? order : -order;
			default:
				return a.validTo > b.validTo ? order : -order;
		}
	});
}

const sortExternalSharesFiles = (shares: ExternalShare[], sortProperty?: keyof (ExternalShareFile), sortPropertyDirectionASC?: boolean | undefined) => {
	const sortedShares = [...shares]
	for (const share of sortedShares) {
		share.files = [...share.files].sort((a, b) => {
			const order = sortPropertyDirectionASC ? 1 : -1;
			switch (sortProperty) {
				case "downloadCount":
					return a.downloadCount > b.downloadCount ? order : -order;
				case "lastDownloadedOn":
					return a.lastDownloadedOn > b.lastDownloadedOn ? order : -order;
				case "folder":
					return a.folder.toLocaleLowerCase() > b.folder.toLocaleLowerCase() ? order : -order;
				default:
					return a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? order : -order;
			}
		});
	}
	return sortedShares;
}

export function externalSharesReducer(
	state: ExternalSharesState = initialState,
	action: ExternalShareActionTypes
): ExternalSharesState {
	switch (action.type) {
		case ExternalShareActions.ExternalSharesRequest:
			return {
				...state,
				shares: [],
				status: "Loading",
				loadError: undefined,
				selectedShares: []
			};

		case ExternalShareActions.ExternalSharesReceived:

		// add shareId to all shareFiles to be used as a part of their unique identifier
			const modifiedShares = action.sharesResponse.fileShares.map((share) => ({
				...share,
				files: share.files.map((file) => ({
					...file,
					shareId: share.id
				}))
			}));
			const sortedShares = sortExternalShares(modifiedShares, state.shareSortProperty, state.shareSortPropertyDirectionASC);
			const sortedSharesAndFiles = sortExternalSharesFiles(sortedShares, state.shareFilesSortProperty, state.shareFilesSortPropertyDirectionASC)
			return {
				...state,
				downloadUrlBase: action.sharesResponse.downloadUrlBase,
				shares: sortedSharesAndFiles,
				status: "Loaded",
				loadError: undefined,
				selectedShares: []
			};

		case ExternalShareActions.ExternalSharesReceiveFailed:
			return {
				...state,
				shares: [],
				status: "LoadFailed",
				loadError: action.message,
				selectedShares: []
			};

		case ExternalShareActions.SortExternalSharesFiles:
			return {
				...state,
				shares: sortExternalSharesFiles(state.shares, action.property, action.sortDirectionASC),
				shareFilesSortProperty: action.property,
				shareFilesSortPropertyDirectionASC: action.sortDirectionASC
			};

		case ExternalShareActions.SelectExternalShare:
			{
				// If selected, do nothing
				if (!!state.selectedShares?.some(x => x.id === action.shareId)) {
					return state;
				}

				// If share not found, do nothing
				const share = state.shares.find(x => x.id === action.shareId);
				if (!share) {
					return state;
				}

				const selectedShares = [...state.selectedShares || [], share]

				return {
					...state,
					selectedShares,
					selectedFiles:  [] // do not allow selection of selectedShares and selectedFiles at the same time
				};
			}

		case ExternalShareActions.DeselectExternalShare:
			{
				// If not selected, do nothing
				if (!state.selectedShares?.some(x => x.id === action.shareId)) {
					return state;
				}

				const selectedShares = [...state.selectedShares?.filter(x => x.id !== action.shareId)];

				return {
					...state,
					selectedShares
				};
			}

		case ExternalShareActions.SetDownloadCountZero:
			{
				const shares = state.shares.map(share => ({
					...share,
					files: share.files.map(file => ({
						...file,
						downloadCount: action.files.some(x => x.id === file.id
							&& x.folder === file.folder
							&& x.drive.ident === file.drive.ident
							&& x.drive.location === file.drive.location
							&& x.drive.type === file.drive.type) ? 0 : file.downloadCount
					}))
				}));

				return {
					...state,
					shares
				};
			}

		case ExternalShareActions.SelectExternalShareFiles:
			{
				return {
					...state,
					selectedFiles: action.files,
					selectedShares: action.files.length > 0 ? [] : state.selectedShares  // do not allow selection of selectedShares and selectedFiles at the same time
				};
			}

		case ExternalShareActions.RemoveExternalShares:
			{
				const shares = state.shares.filter(share => !action.shareIds.some(shareId => shareId === share.id));
				const selectedShares = state.selectedShares?.filter(share => !action.shareIds.some(shareId => shareId === share.id));

				return {
					...state,
					shares,
					selectedShares,
				};
			}

		case ExternalShareActions.SetExternalSharesValidToDate:
			{
				const shares = state.shares.map(share => ({
					...share,
					validTo: action.shareIds.some(shareId => shareId === share.id) ? action.validToDate : share.validTo
				}));
				return {
					...state,
					shares
				};
			}

		default:
			return state;
	}
}